import React from 'react';
import { Row } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router';
import { STEP4 } from 'src/constants';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IOfferItem } from '../../../models/rewards.model';

import styleClasses from './offer.module.scss';
import OfferItemCard from './OfferItemCard';
import { useAppSelector } from 'src/redux/store/store';
import { checkTheStepNo } from 'src/helper/helperMethods';

interface IOfferItemListProps {
  offers: IOfferItem[];
  selectedOfferItem: IOfferItem;
  title: string;
  subTotal: number;
}

const OfferItemsList: React.FC<IOfferItemListProps> = (props) => {
  const { offers, selectedOfferItem, title, subTotal } = props;

  const wizardSteps = useAppSelector((state) => state.wizard);
  const isThisStep4 = checkTheStepNo(wizardSteps, STEP4);

  const getBtnText = (reward) => {
    return reward.in_cart ? 'Redeemed' : 'Redeem';
  };
  if (!offers?.length) return null;

  return (
    <>
      <div
        className={`${
          isThisStep4
            ? `px-3 ${styleClasses.other_products_wrap_for_step4}`
            : styleClasses.other_products_wrap
        }`}
      >
        <h4
          className={`f-s18 font-plex lh-normal ls-normal mb-0 pb-1 light-blue ${
            !isThisStep4 && 'pt-3'
          }`}
        >
          {title}
        </h4>
        {isThisStep4 ? (
          <div>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar]}
              spaceBetween={34}
              slidesPerView={4}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1199: {
                  slidesPerView: 7,
                  spaceBetween: 10,
                },
              }}
              className={`w-100`}
            >
              {offers.map((offerItem: IOfferItem, index: number) => {
                return (
                  <SwiperSlide>
                    <OfferItemCard
                      key={index}
                      offerItem={offerItem}
                      selectedOfferItem={selectedOfferItem}
                      btnText={getBtnText(offerItem)}
                      subTotal={subTotal}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : (
          <Row className={styleClasses.suggestion_row + ' flex-nowrap pt-1 '}>
            <Scrollbars
              className={styleClasses.offers_scrolbar_height}
              renderThumbHorizontal={(props) => (
                <div {...props} className={styleClasses.Scrollbar_thumb} />
              )}
              renderView={(props) => (
                <div {...props} className={styleClasses.Scrollbar_view} />
              )}
            >
              {offers.map((offerItem: IOfferItem, index: number) => {
                return (
                  <OfferItemCard
                    key={index}
                    offerItem={offerItem}
                    selectedOfferItem={selectedOfferItem}
                    btnText={getBtnText(offerItem)}
                    subTotal={subTotal}
                  />
                );
              })}
            </Scrollbars>
          </Row>
        )}
      </div>
    </>
  );
};

export default OfferItemsList;

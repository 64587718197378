import React, { useState } from 'react';

import styleClasses from '../../../containers/Modifiers/try2.module.scss';
import { useAppSelector } from 'src/redux/store/store';
import * as CONSTANT from '../../../constants';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { groupBy } from 'lodash';
import { CORE_RELATED } from 'src/priceCalculation/constants';
interface ISectionDetail {
  currentItemName: string;
  defaultItemName: string;
  image: string;
  id: string;
  changeItemSize: string;
  title: string;
  isHalf?: number;
  selectedtitle?: string;
  weight?: string;
}
const SectionDetailTry2Combo: React.FC<ISectionDetail> = (props) => {
  const {
    currentItemName,
    defaultItemName,
    image,
    id,
    changeItemSize,
    title,
    isHalf,
    selectedtitle,
    weight,
  } = props;
  const modifiersList = {
    addModifiers: [],
    removeModifers: [],
  };
  let addedSubstituded = null;
  let removedSubstituded = null;
  const reduxModifiers = useAppSelector(
    (state) => state.itemCustomization.modifiers,
  );
  const buckets = itemBuckets.getBuckets();
  const handleShowCustomization = () => {
    if (reduxModifiers[Number(id) - 1].id === null) {
      return;
    }
    const currentBucket = buckets[Number(id) - 1].bucket;
    const bucketKeys = Object.keys(currentBucket);
    bucketKeys.map((bucketKey) => {
      const currentBucetModifiers = [];
      for (
        let index = 0;
        index < currentBucket[bucketKey].additionalAdded.modifiers.length;
        index++
      ) {
        const currentModifier =
          currentBucket[bucketKey].additionalAdded.modifiers[index];
        if (
          (currentModifier?.modifier_group_extendable_limit === 1 ||
            currentModifier?.extendableLimitValue === 1) &&
          currentModifier?.modifier_group_max === 1 &&
          currentModifier?.modifier_group_min === 1 &&
          currentModifier.treat_as === CONSTANT.CORE_RELATED
        ) {
          continue;
        }

        currentBucetModifiers.push(currentModifier);
      }
      const modifiersGrouped = groupBy(currentBucetModifiers, 'modifier_name');
      Object.keys(modifiersGrouped).map((modifierName) => {
        const arrvalue = modifiersGrouped[modifierName];
        modifiersList.addModifiers.push({
          name: modifierName,
          quantity: arrvalue[arrvalue.length - 1].quantity,
        });
      });
      if (bucketKey == 'core_modifiers') {
        const filteredModifiers = currentBucket[
          bucketKey
        ].byDefaultAddedV2.modifiers.filter(
          (originalModifier) =>
            !currentBucket[bucketKey].byDefaultAdded.modifiers.some(
              (copyModifier) =>
                originalModifier.modifier_id === copyModifier.modifier_id,
            ),
        );
        modifiersList.removeModifers = [...filteredModifiers];
      }
    });
  };
  const handleSubstitutedModifierCustomization = () => {
    if (reduxModifiers[Number(id) - 1].id === null) {
      return;
    }
    const currentBucket = buckets[Number(id) - 1].bucket;
    currentBucket.core_modifiers.byDefaultAddedV2.modifiers.map((modifier) => {
      if (
        modifier?.modifier_group_extendable_limit &&
        modifier?.modifier_group_max &&
        modifier?.modifier_group_min
      ) {
        addedSubstituded = modifier;
      }
    });
    if (addedSubstituded === null) return;
    const modifierExist = currentBucket.core_modifiers.modifiers.find(
      (modifier) => {
        return (
          modifier.modifier_group_id === addedSubstituded.modifier_group_id
        );
      },
    );
    if (modifierExist.modifier_id === addedSubstituded.modifier_id) {
      return;
    }

    if (modifierExist) {
      removedSubstituded = addedSubstituded;
      addedSubstituded = modifierExist;
    }
    return;
  };
  handleShowCustomization();
  handleSubstitutedModifierCustomization();
  const handleModifierCode = (code: string, modifier: any) => {
    const quantity = modifier.quantity > 1 ? `(${modifier.quantity})` : '';
    switch (code.toLocaleLowerCase()) {
      case CONSTANT.ADD.toLocaleLowerCase():
        return (
          <p
            className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
          >
            + {modifier?.modifier_name}
            {quantity}
          </p>
        );
      case CONSTANT.NO.toLocaleLowerCase():
        return (
          <p
            className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
          >
            - {modifier?.modifier_name}
            {quantity}
          </p>
        );
      case CONSTANT.SUB.toLocaleLowerCase():
        return (
          <>
            <span className="ms-0 mb-2 clr-dark-red">{`+ ${modifier?.substituted_modifier?.modifier_name}`}</span>
            <p
              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
            >
              + {modifier?.substituted_modifier?.modifier_name}
            </p>
            <p
              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
            >
              - {modifier?.modifier_name}
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <label
      htmlFor={id}
      className={`
      ${styleClasses.optional_selector}
       f-s18 font-Vcf w-100  optional-selector d-flex align-items-center  justify-content-start p-0 cz-px-20 ${
         currentItemName !== defaultItemName &&
         `${styleClasses.optional_selector_selected}`
       } ${styleClasses.try2_combo_optional_selector} ${
        image ? styleClasses.withBorder : ''
      }`}
    >
      <div className={`${styleClasses.try2combo_inner_div} w-100`}>
        <div
          className={
            image
              ? `img_box px-0 mx-auto mx-md-0`
              : `${styleClasses.rounded_box} rounded-box clr-sec-white mx-auto mx-md-0`
          }
        >
          {image ? (
            <div className={`img-fluid mb-1 ${styleClasses.image_box}`}></div>
          ) : (
            <span className="">{id}</span>
          )}
        </div>
        {currentItemName !== defaultItemName ? (
          <div
            className={`${styleClasses.try2_selected_item_content} ${styleClasses.try2_title_content} f-s0 w-100 pb-2`}
          >
            <p
              className={`${changeItemSize} p-16 clr-lt-black ls-normal d-md-none mb-3 mb-md-4 px-2 f-sm-s11`}
            >
              {currentItemName}
              {/* {reduxModifiers[Number(id) - 1].modifiers?.map((modifier) =>
                handleModifierCode(modifier.code, modifier),
              )} */}
              {modifiersList.addModifiers.map((modifier) => (
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  + {modifier.name}
                  {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
                </p>
              ))}
              {modifiersList.removeModifers.map((modifier) => (
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  - {modifier.modifier_name}
                  {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
                </p>
              ))}
              {addedSubstituded && removedSubstituded ? (
                <>
                  {' '}
                  <p
                    className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                  >
                    + {addedSubstituded.modifier_name}
                    {addedSubstituded.quantity > 1
                      ? ` (${addedSubstituded.quantity})`
                      : ''}
                  </p>{' '}
                  <p
                    className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                  >
                    - {removedSubstituded.modifier_name}
                    {removedSubstituded.quantity > 1
                      ? ` (${removedSubstituded.quantity})`
                      : ''}
                  </p>
                </>
              ) : (
                ''
              )}
              <span className="d-block">{weight}</span>
            </p>

            <h6
              className={`${changeItemSize} f-s20 f-w4 font-plex light-blue mb-0 clr-lt-black ls-normal my-3 d-none  d-md-block`}
            >
              {currentItemName}
            </h6>
            {/* {reduxModifiers.length
              ? reduxModifiers[Number(id) - 1]?.modifiers.map((modifier) =>
                  handleModifierCode(modifier.code, modifier),
                )
              : ''} */}
            {modifiersList.addModifiers.map((modifier) => (
              <p
                className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
              >
                + {modifier.name}
                {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
              </p>
            ))}
            {modifiersList.removeModifers.map((modifier) => (
              <p
                className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
              >
                - {modifier.modifier_name}
                {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
              </p>
            ))}
            {addedSubstituded && removedSubstituded ? (
              <>
                {' '}
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  + {addedSubstituded.modifier_name}
                  {addedSubstituded.quantity > 1
                    ? ` (${addedSubstituded.quantity})`
                    : ''}
                </p>{' '}
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  - {removedSubstituded.modifier_name}
                  {removedSubstituded.quantity > 1
                    ? ` (${removedSubstituded.quantity})`
                    : ''}
                </p>
              </>
            ) : (
              ''
            )}
            <span className="d-block">{weight}</span>
            <span
              className={`${styleClasses.edit_btn} d-inline-block py-1 bg-lt-black py-md-3 px-3 d-md-none f-s16 clr-sec-white text-uppercase`}
            >
              {selectedtitle ? selectedtitle : 'click to edit'}
            </span>
          </div>
        ) : (
          <div className="pe-md-4 ps-md-5 mx-auto mx-md-0">
            <h6
              className={`${changeItemSize} f-s20 f-w4 font-plex light-blue ls-normal my-3 d-none  d-md-block font-plex`}
            >
              {currentItemName}
              {/* {reduxModifiers.find((modifier) => modifier.item == id)?.map &&
                reduxModifiers
                  .find((modifier) => modifier.item == id)
                  ?.map((modifier) =>
                    handleModifierCode(modifier.code, modifier),
                  )} */}
              {modifiersList.addModifiers.map((modifier) => (
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  + {modifier.name}
                  {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
                </p>
              ))}
              {modifiersList.removeModifers.map((modifier) => (
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  - {modifier.modifier_name}
                  {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
                </p>
              ))}
              {addedSubstituded && removedSubstituded ? (
                <>
                  {' '}
                  <p
                    className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                  >
                    + {addedSubstituded.modifier_name}
                    {addedSubstituded.quantity > 1
                      ? ` (${addedSubstituded.quantity})`
                      : ''}
                  </p>{' '}
                  <p
                    className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                  >
                    - {removedSubstituded.modifier_name}
                    {removedSubstituded.quantity > 1
                      ? ` (${removedSubstituded.quantity})`
                      : ''}
                  </p>
                </>
              ) : (
                ''
              )}
            </h6>
            <p className={`${styleClasses.inn_title_wrap} d-md-none f-s11`}>
              <strong className="f-s14">{title}</strong>
              <br />
              {currentItemName}
              {/* {reduxModifiers.find((modifier) => modifier.item == id)?.map &&
                reduxModifiers
                  .find((modifier) => modifier.item == id)
                  ?.map((modifier) =>
                    handleModifierCode(modifier.code, modifier),
                  )} */}
              {modifiersList.addModifiers.map((modifier) => (
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  + {modifier.name}
                  {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
                </p>
              ))}
              {modifiersList.removeModifers.map((modifier) => (
                <p
                  className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                >
                  - {modifier.modifier_name}
                  {modifier.quantity > 1 ? ` (${modifier.quantity})` : ''}
                </p>
              ))}
              {addedSubstituded && removedSubstituded ? (
                <>
                  {' '}
                  <p
                    className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                  >
                    + {addedSubstituded.modifier_name}
                    {addedSubstituded.quantity > 1
                      ? ` (${addedSubstituded.quantity})`
                      : ''}
                  </p>{' '}
                  <p
                    className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styleClasses.boxLunchesModifiers}`}
                  >
                    - {removedSubstituded.modifier_name}
                    {removedSubstituded.quantity > 1
                      ? ` (${removedSubstituded.quantity})`
                      : ''}
                  </p>
                </>
              ) : (
                ''
              )}
            </p>
          </div>
        )}
      </div>
      {currentItemName !== defaultItemName && (
        <div
          className={`${styleClasses.edit_btn}  f-s14 d-none d-md-block text-uppercase f-s16 font-plex f-w5`}
        >
          {selectedtitle ? selectedtitle : 'click to edit'}
        </div>
      )}
    </label>
  );
};

export default SectionDetailTry2Combo;

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { brazeLogCustomEventCheckoutStarted } from 'src/helper/brazeHelper';

import {
  DELIVERY_ORDER_TYPE,
  PICK_UP_METHOD_GOOD_LIFE_LANE,
  PICK_UP_METHOD_IN_STORE,
  ROUTE_CHECKOUT,
  ROUTE_LOCATIONS,
  ROUTE_STEP_2,
  ROUTE_STEP_5,
  STEP2,
} from '../../../../constants';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import {
  setShowCart,
  updatePickUpMethod,
} from '../../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/store';
import styleClasses from '../../itemOrder.module.scss';

// src / components / ItemOrderModal / itemOrder.module.scss
import CurbSideVehicleDetails from './Curbside/CurbSideVehicleDetails';
import PickUpSlots from './PickupSlots';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
import { onStepClick } from 'src/redux/slices/wizardSlice';

interface IOrderType {
  subTotal?: number;
  tax?: number;
  total?: number;
  closePickUpMethodModal?: (boolean) => void;
  editPickupType?: (boolean) => void;
  handleChangeOrderType?: boolean;
  isEditSlot?: boolean;
}

export const OrderType = (props: IOrderType) => {
  const {
    tax,
    subTotal,
    total,
    closePickUpMethodModal,
    editPickupType,
    handleChangeOrderType,
    isEditSlot,
  } = props;
  const locationInfo = useAppSelector((state) => state.location);
  // const {
  //   order_types: { Pickup },
  // } = locationInfo.selectedStore;

  const { redirect } = useRedirectToSpecificStep();
  const dispatch = useAppDispatch();

  const redirectToSpecificStep = (id) => {
    dispatch(onStepClick({ stepId: id }));
    redirect(id);
  };

  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const cart = useAppSelector((state) => state.cart);
  const isItMobile = useCheckMobileScreen();
  const pickUpMethodDispatch = useAppDispatch();
  const history = useHistory();
  const isShownVehicleDetails = (isShown: boolean) => {
    setShowVehicleDetails(isShown);
  };
  const closeShowVehicleModal = () => {
    closePickUpMethodModal(true);
    pickUpMethodDispatch(setShowCart(false));
    const slotDetails = cart?.orderDetails?.PickupSlotDetails;
    brazeLogCustomEventCheckoutStarted(
      cart,
      { tax, subTotal, total },
      isItMobile,
      slotDetails,
      locationInfo.selectedStore,
    );
    history.push(ROUTE_STEP_5);
  };
  const selectedPickupMethod = useAppSelector((state) =>
    state.cart.orderDetails?.PickupSlotDetails?.pickupMethod !=
    DELIVERY_ORDER_TYPE
      ? state.cart.orderDetails?.PickupSlotDetails?.pickupMethod
      : PICK_UP_METHOD_IN_STORE,
  );

  useEffect(() => {
    handlePickupMethod(selectedPickupMethod);
  }, [selectedPickupMethod]);

  const handlePickupMethod = (pickUpMethod: string) => {
    pickUpMethodDispatch(updatePickUpMethod(pickUpMethod));
  };
  const handleChangeToDeliveryModal = () => {
    editPickupType(true);
    closePickUpMethodModal(true);
  };

  const handleLocationSelect = () => {
    closePickUpMethodModal(true);
    pickUpMethodDispatch(setShowCart(false));
    redirectToSpecificStep(STEP2);
  };

  return (
    <div className={` order-pickup-wrapper  ${styleClasses.itemOrderWrapper}`}>
      {!showVehicleDetails && (
        <>
          <PickUpSlots
            tax={tax}
            subTotal={subTotal}
            total={total}
            isEditSlot={isEditSlot}
            closePickUpMethodModal={closePickUpMethodModal}
            isShownVehicleDetails={isShownVehicleDetails}
            pickupMethod={PICK_UP_METHOD_IN_STORE}
            renderChangeToComponent={() => {
              return (
                !handleChangeOrderType && (
                  <div className="d-flex flex-column">
                    <button
                      type="button"
                      className={`btn p-0 f-w6 p-16 clr-dark-red font-plex mb-2`}
                      onClick={handleChangeToDeliveryModal}
                    >
                      Change To Delivery
                    </button>
                    <button
                      type="button"
                      className={`btn  p-0 f-w6 p-16 clr-dark-red font-plex`}
                      onClick={() => handleLocationSelect()}
                    >
                      Change Location
                    </button>
                  </div>
                )
              );
            }}
          />
        </>
      )}

      {showVehicleDetails && (
        <CurbSideVehicleDetails
          closeShowVehicleModal={closeShowVehicleModal}
          isShownVehicleDetails={isShownVehicleDetails}
        />
      )}
    </div>
  );
};

import { Col, Row } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import FavoriteBtn from 'src/components/FavoriteButton/FavoriteBtn';
import TagSymbol from 'src/components/Molecules/Symbols/Symbol';
import { isGuestUser } from 'src/helper/helperMethods';
import { ItemDetails } from 'src/models/item.model';
import { useAppSelector } from 'src/redux/store/store';
import styleClasses from '../itemDetails.module.scss';
import { DescInfoIcon } from 'src/assets/images/Svgs/svg';
interface ItemDescriptionProps {
  item: ItemDetails;
  isItemActive: boolean;
}

const index = ({ item, isItemActive }: ItemDescriptionProps) => {
  const user = useAppSelector((state) => state.user);
  return (
    <div className={`${item?.is_box_lunch ? 'pt-md-0 pt-3' : 'pt-0'}`}>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-4 pb-2">
          <h2 className={`f-s20 f-w5 pt-md-0 opr-heading-clr font-plex ${styleClasses.itemTitleSize}`}>
            {item?.name}
          </h2>
        {!!item?.description && <div className={styleClasses.DescInfoIcon}>
         <DescInfoIcon/>
         <span className={`${styleClasses.tooltiptext}`}>{item?.description}</span>
         </div>
        }
          <div className="py-1">
            {item?.is_salad_tray ? <TagSymbol tags={item.tags} /> : null}
          </div>
        </div>
        {!isGuestUser(user) && (
          <FavoriteBtn item={item} disableItem={!isItemActive} />
        )}
      </div>
    </div>
  );
};

export default index;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SINGLE_ITEM_SELECTED_SECTION } from 'src/constants';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { IItemCustomization } from 'src/models/item.model';
import { itemBuckets } from 'src/priceCalculation/buckets';
import {
  removeTemporarilyAddedPriceForItem,
  resetModifiers,
} from 'src/redux/slices/itemCustomizationSlice';
import { useAppDispatch } from 'src/redux/store/store';

import AddIngredients from './AddIngredients';
import CustomizationModal from './CustomizationModal';
import styleClasses from './order.module.scss';

const Customization: React.FC<IItemCustomization> = (props) => {
  const {
    item,
    showCustomization,
    showCustomizedOptions,
    handleIngredientSelection,
    autoScrollDivRef,
    firstTimeModal,
    cardColSize,
    boxLunchesModal,
    itemAsModifierState,
    isCreateYourOwnItem,
    CYOIScrollToMissingRequiredArea,
    hideFirstTimeModal,
    showFirstTimeModal,
    modifiersRef,
    dbId,
    itemsAsModifiersToHandleScroll,
  } = props;

  const order = useSelector((state: any) => state.itemCustomization);
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (item && !isMobile && isCreateYourOwnItem && !dbId) {
      showFirstTimeModal();
      modifiersRef.current = [...order.modifiers];
    }
  }, [item]);

  const onModalCancel = () => {
    dispatch(resetModifiers({ modifiers: modifiersRef.current }));
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.discardBucketChanges('1'),
        item: '1',
      }),
    );
    hideFirstTimeModal();
  };

  const applyClickHandler = () => {
    itemBuckets.resetCopyComboBucket();
    hideFirstTimeModal();
  };
  // console.log(firstTimeModal, isCreateYourOwnSalad);
  return (
    <>
      {/* reate your own salad */}

      {isCreateYourOwnItem && !isMobile && (
        <CustomizationModal
          modalShow={firstTimeModal}
          onModalCancel={onModalCancel}
          item={item}
          handleIngredientSelection={handleIngredientSelection}
          styleClasses={styleClasses}
          applyClickHandler={applyClickHandler}
          // getOrderButtonState={getOrderButtonState}
          showCustomization={showCustomization}
          order={order}
          cardColSize={cardColSize}
          itemAsModifierState={itemAsModifierState}
          isCreateYourOwnItem={isCreateYourOwnItem}
        />
      )}
      {/* ccreate your own salad end */}
      {item?.is_active &&
        verifyStatus(item, 'item_location') &&
        (showCustomizedOptions ||
          (showCustomization && item.is_customizable)) && (
          <div
            ref={autoScrollDivRef}
            className={`${styleClasses.customize_content_wrapper}`}
          >
            {!firstTimeModal && (
              <div
                className={`${styleClasses.customize_card_header} d-flex align-items-center card_title_wrap my-3`}
              >
                <h2 className="text-start text-uppercase f-s22 f-sm-s20 text-style-none mb-0">
                  Customize
                </h2>
              </div>
            )}
            <div className={`px-0 ${boxLunchesModal}`}>
              <AddIngredients
                modifierGroups={item?.items_modifier_groups}
                coreModifiers={item?.core_modifiers}
                requiredModifiers={item?.items_required_modifier_groups}
                itemsAsModifiers={item?.sub_items_required_modifier_groups}
                handleIngredientSelection={(data: any) =>
                  handleIngredientSelection(data, SINGLE_ITEM_SELECTED_SECTION)
                }
                selectedItem={SINGLE_ITEM_SELECTED_SECTION}
                itemName={item?.name}
                showCustomization={showCustomization}
                cardColSize={cardColSize}
                itemAsModifierState={itemAsModifierState}
                isCreateYourOwnItem={isCreateYourOwnItem}
                CYOIScrollToMissingRequiredArea={
                  CYOIScrollToMissingRequiredArea
                }
                item={item}
                itemsAsModifiersToHandleScroll={itemsAsModifiersToHandleScroll}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default Customization;

//TODO: replace the axios import with the local axios file
import { changeUser as brazeSetUser } from '@braze/web-sdk';
import * as CONSTANT from 'src/constants';
// import axios from "axios";
import {
  removeUserData,
  updateUserData,
} from 'src/Features/AccountSetup/redux/slices';
import {
  brazeLogCustomEvent,
  brazeLogCustomEventLoyaltyAccountCreated,
} from 'src/helper/brazeHelper';
import { syncCartOnLogin } from 'src/helper/cartHelperMethods/syncCart';
import { getUser, getVisitorId, setOperator } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import { isLogoutAction, resetCart } from 'src/redux/slices/cartSlice';
import { resetCheckout } from 'src/redux/slices/checkoutSlice';
import { resetPrimaryCapacity } from 'src/redux/slices/handleStatesForSpecificFlows';
import { customerBusinessService } from 'src/services';

export async function login(
  dispatch,
  loginPayload,
  cartDispatch,
  updateAccountStatusModal,
  isCheckoutLogin = false,
) {
  try {
    const visitorId = getVisitorId();
    const newLoginPayload = { ...loginPayload };
    newLoginPayload['visitor_id'] = visitorId;
    const { data: response } = await customerBusinessService.login(
      newLoginPayload,
    );

    if (response.status) {
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify({ ...response.data, visitor_id: visitorId }),
      );
      if (!isCheckoutLogin) syncCartOnLogin(cartDispatch);
      dispatch(updateUserData(response.data));
    } else {
      Toast_Func({ status: false, message: response.data.message });
    }
  } catch (error) {
    const errorsObject = error.response.data.errors;
    const errorCode = errorsObject?.code;
    if (errorCode) {
      const buttonText = CONSTANT.API_CODE_MESSAGES[errorCode];
      updateAccountStatusModal(
        errorsObject.header,
        errorsObject.text,
        errorCode,
        buttonText,
        loginPayload.email,
        true,
      );
    } else
      Toast_Func({
        status: false,
        message: error.response.data.errors[0],
      });
  }
}

export function logout(dispatch) {
  dispatch(removeUserData());
  dispatch(resetCart());
  dispatch(resetCheckout());
  dispatch(resetPrimaryCapacity());
  localStorage.removeItem(CONSTANT.SELECTED_STORE);
  const operator = JSON.parse(localStorage.getItem(CONSTANT.OPERATOR));
  setOperator(operator?.token, null);
  localStorage.setItem(
    CONSTANT.CURRENT_USER,
    JSON.stringify({ visitor_id: getVisitorId(), logged_out: true }),
  );
}

export function unauthorizeLogout(dispatch) {
  dispatch(resetCart());
  dispatch(isLogoutAction());
  dispatch(resetCheckout());
  localStorage.setItem(
    CONSTANT.CURRENT_USER,
    JSON.stringify({ visitor_id: getVisitorId() }),
  );
}

export async function socialSignIn(
  loginDispatch,
  loginPayload,
  signInName,
  cartDispatch,
  setLocationCallback,
) {
  try {
    let response = null;
    switch (signInName) {
      case 'GOOGLE_SIGNIN':
        response = await customerBusinessService.googleSignIn(loginPayload);
        break;
      case 'FACEBOOK_SIGNIN':
        response = await customerBusinessService.facebookSignIn(loginPayload);
        break;
      case 'APPLE_SIGNIN':
        response = await customerBusinessService.appleSignIn(loginPayload);
    }
    if (response.data.status) {
      loginDispatch(updateUserData(response.data.data));
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify({
          visitor_id: getVisitorId(),
          ...response.data.data,
        }),
      );
      if (response.data.data.is_new_user)
        brazeLogCustomEventLoyaltyAccountCreated(response.data.data);
    }
  } catch (error) {
    Toast_Func({
      status: false,
      message: error.response?.data?.message?.length
        ? error.response?.data?.message[0]
        : 'Something went wrong',
    });
  }
}

export async function guestLogin(dispatch, loginPayload) {
  try {
    const response = await customerBusinessService.guestLogin(loginPayload);
    if (response.data.status) {
      dispatch(updateUserData(response?.data?.data));
      brazeSetUser(response?.data?.data?.id);
      if (!getUser()?.logged_out) brazeLogCustomEvent(CONSTANT.NEW_GUEST_USER);
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify(response?.data?.data),
      );
      // syncCartOnLogin(cartDispatch);
      return true;
    }
  } catch (error) {
    dispatch({
      type: 'LOGIN_ERROR',
      error: error?.response?.data?.data?.errors[0],
    });
    return false;
  }
}

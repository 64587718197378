import { CATERING_OPERATOR_SERVICE } from '../constants';
import { SendaQouteInstanceHTTP } from './instance.service';

class SendQuoteMicroService extends SendaQouteInstanceHTTP {
  baseURL = CATERING_OPERATOR_SERVICE;

  sendAQuote = (data: any, options?: any): Promise<any> =>
    this.post(`orders/send-a-quote`, data, options);
    
}

export const sendQuoteMicroService = new SendQuoteMicroService();

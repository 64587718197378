import { createSlice } from '@reduxjs/toolkit';
import {
  ROUTE_STEP_1,
  ROUTE_STEP_2,
  ROUTE_STEP_3,
  ROUTE_STEP_4,
  ROUTE_STEP_5,
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
} from 'src/constants';
import { deleteAfterDigit } from 'src/helper/helperMethods';

const initialState = {
  currentStep: 0,
  activeStep: 1,
  lastActiveStep: 1,
  activeLineWidth: 0,
  steps: [
    {
      route: ROUTE_STEP_1,
      id: '1',
      title: 'customer',
    },
    {
      route: ROUTE_STEP_2,
      id: '2',
      title: 'Order Type',
    },
    { route: ROUTE_STEP_3, id: '3', title: 'menu' },
    { route: ROUTE_STEP_4, id: '4', title: 'cart' },
    { route: ROUTE_STEP_5, id: '5', title: 'checkout' },
  ],
  revisitAbleSteps: [],
  disableClick: false,
};

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState: initialState,
  reducers: {
    nextStep: (state: any) => {
      if (state.currentStep < state.steps.length - 1) {
        const index = state.revisitAbleSteps.indexOf(state.activeStep);
        if (index === -1) {
          const newRevisitAbleSteps = [
            ...state.revisitAbleSteps,
            state.activeStep,
          ];
          state.lastActiveStep += 1;
          state.revisitAbleSteps = newRevisitAbleSteps;
          state.activeLineWidth =
            (state.currentStep + 1) * (100 / (state.steps.length - 1));
        }
        state.currentStep += 1;
        state.activeStep += 1;
      }
    },
    previousStep: (state) => {
      if (state.currentStep > 0) {
        state.currentStep -= 1;
        state.activeStep -= 1;
      }
    },
    reOrderWizardState: (state) => {
      state.activeLineWidth = 1 * (100 / (state.steps.length - 1));
      state.currentStep = 1;
      state.activeStep = 2;
      state.lastActiveStep = 2;
      state.revisitAbleSteps = [1];
    },

    editOrderWizardState: (state) => {
      state.activeLineWidth = 4 * (100 / (state.steps.length - 1));
      state.currentStep = 2;
      state.activeStep = 3;
      state.lastActiveStep = 5;
      state.revisitAbleSteps = [1, 2, 3, 4, 5];
    },
    activeStepsOnDirectAccess: (state, action: any) => {
      // const value = action.payload.path.split('/')[1];
      const value = action.payload;

      switch (value) {
        case STEP1:
          if (state.currentStep === 0) return;
          state.activeLineWidth = 100 / (state.steps.length - 1);
          state.currentStep = 0;
          state.activeStep = 1;
          break;
        case STEP2:
          if (state.currentStep === 1) return;
          state.activeLineWidth = 1 * (100 / (state.steps.length - 1));
          state.currentStep = 1;
          state.activeStep = 2;
          break;
        case STEP3:
          if (state.currentStep === 2) return;
          state.activeLineWidth = 2 * (100 / (state.steps.length - 1));
          state.currentStep = 2;
          state.activeStep = 3;
          break;
        case STEP4:
          if (state.currentStep === 3) return;
          state.activeLineWidth = 3 * (100 / (state.steps.length - 1));
          state.currentStep = 3;
          state.activeStep = 4;
          break;
        case STEP5:
          if (state.currentStep === 4) return;
          state.activeLineWidth = 4 * (100 / (state.steps.length - 1));
          state.currentStep = 4;
          state.activeStep = 5;
          break;

        default:
          state.activeLineWidth = 100 / (state.steps.length - 1);
          state.currentStep = 0;
          state.activeStep = 1;
          break;
      }
    },
    onStepClick: (state, action) => {
      const { stepId } = action.payload;
      state.currentStep = stepId - 1;
      state.activeStep = Number(stepId);
    },
    jumbToASpecificStep: (state, action) => {
      let { stepId } = action.payload;
      stepId = Number(stepId);
      state.currentStep = stepId - 1;
      state.activeStep = stepId;
      state.lastActiveStep = stepId;
      state.activeLineWidth = (stepId - 1) * (100 / (state.steps.length - 1));
      const newRevisitAbleSteps = [];
      for (let index = 1; index <= stepId; index++) {
        newRevisitAbleSteps.push(index);
      }
      state.revisitAbleSteps = newRevisitAbleSteps;
    },
    resetNextSteps: (state) => {
      const newRevisitAbleSteps = deleteAfterDigit(
        [...state.revisitAbleSteps],
        state.currentStep,
      );
      state.activeLineWidth =
        state.currentStep * (100 / (state.steps.length - 1));
      state.lastActiveStep = state.activeStep;
      state.revisitAbleSteps = newRevisitAbleSteps;
    },
    setDraftSteps: (state, action: any) => {
      const {
        current_step,
        active_step,
        revisitable_steps,
        last_active_step,
        active_line_width,
      } = action.payload;
      state.currentStep = Number(current_step);
      state.activeStep = Number(active_step);
      state.revisitAbleSteps = revisitable_steps;
      state.lastActiveStep = Number(last_active_step);
      state.activeLineWidth = Number(active_line_width);
    },
    resetSteps: (state) => {
      state.currentStep = 0;
      state.activeStep = 1;
      state.revisitAbleSteps = [];
      state.lastActiveStep = 1;
      state.activeLineWidth = 0;
    },

    handleDisableStepperClick: (state, action) => {
      state.disableClick = action.payload;
    },
  },
});

export const {
  nextStep,
  previousStep,
  onStepClick,
  resetNextSteps,
  resetSteps,
  setDraftSteps,
  activeStepsOnDirectAccess,
  editOrderWizardState,
  reOrderWizardState,
  jumbToASpecificStep,
  handleDisableStepperClick
} = wizardSlice.actions;

export const wizardSliceReducer = wizardSlice.reducer;

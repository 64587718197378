import axios, { AxiosRequestConfig, CancelTokenSource, CancelTokenStatic  } from 'axios';
import { SERVER_URL } from 'src/constants';
import { getOperatorToken, getUser } from 'src/helper/helperMethods';
import { successResponseHandler } from '../axios/interceptors';

import { errorResponseHandler } from './interceptor';

const instance = axios.create({
  // Set the base URL and any other default configurations
  baseURL: SERVER_URL,
});

// Define the requestHandler function
const requestHandler = (request: AxiosRequestConfig) => {
	const OPERATORTOKEN = getOperatorToken();
	if (OPERATORTOKEN) {
		if (request.url.includes('operator')) {
		request.headers['Authorization'] = 'Bearer ' + `${OPERATORTOKEN}`;
    request.headers['token'] = OPERATORTOKEN;
		}
		request.headers['Authorization'] = 'Bearer ' + `${OPERATORTOKEN}`;
    request.headers['token'] = OPERATORTOKEN;
	}
  return request;
};

// Apply the requestHandler only to the instance

export class SendaQouteInstanceHTTP {
	CancelToken: CancelTokenStatic;
  source: CancelTokenSource;
  baseURL: string;
  // private authToken: string;

  constructor() {
		this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
    this.baseURL = SERVER_URL;
		// this.source = this.CancelToken.source();
		instance.interceptors.request.use(requestHandler);
		(axios as any).interceptors.response.use(
      successResponseHandler,
      errorResponseHandler,
    );
  }
	
	protected get = async (url: string, params?: any): Promise<any> => {
		return await instance.get(`${this.baseURL}/${url}`, {
      params: { ...params, is_catering: 1 },
      cancelToken: this.source.token,
    })
	};
  protected getFile = async (url: string, params?: any): Promise<any> =>
    await instance.get(`${this.baseURL}/${url}`, {
      params: { ...params, is_catering: 1 },
      cancelToken: this.source.token,
      responseType: 'arraybuffer',
    });

  /**
   * Write data over server
   * @param url Endpoint link
   * @param body Data to send over server
   * @return Promise
   */
  protected post = async (url: string, body: any, options?: any): Promise<any> =>
    await instance.post(
      `${this.baseURL}/${url}`,
      { ...body, is_catering: true },
      {
        ...options,
        cancelToken: this.source.token,
      },
    );

  /**
   * Delete Data From Server
   * @param url Endpoint link
   * @param params Embed as query params
   * @return Promise
   */
  protected delete = (url: string, params?: any, data?: any): Promise<any> =>
		instance.delete(`${this.baseURL}/${url}`, {
      params: { ...params, is_catering: 1 },
      data,
    });

  /**
   * Update data on server
   * @param url Endpoint link
   * @param body Data to send over server
   * @param params Embed as query params
   * @return Promise
   */
  protected put = (url: string, body?: any, params?: any): Promise<any> =>
		instance.put(
      `${this.baseURL}/${url}`,
      { ...body, is_catering: true },
      {
        ...params,
        cancelToken: this.source.token,
      },
    );

  /**
   * Update data over server
   * @param url Endpoint link
   * @param body Data to send over server
   * @return Promise
   */
  protected patch = async (
    url: string,
    body: any,
    options = {},
  ): Promise<any> =>
    await instance.patch(
      `${this.baseURL}/${url}`,
      { ...body, is_catering: true },
      {
        ...options,
        cancelToken: this.source.token,
      },
    );

  private updateCancelToken() {
    this.source = this.CancelToken.source();
  }
	
	

	// protected post = async (url: string, body: any, options?: any): Promise<any> => {
  //   try {	
  //     return await instance.post(
  //       `${this.baseURL}/${url}`,
  //       { ...body, is_catering: true },
  //       {
  //         ...options,
  //       }
  //     );
  //   } catch (error) {
  //     // Handle errors
  //     console.error('Error in post request:', error);
  //     throw error;
  //   }
  // }
}

import { useAppSelector } from 'src/redux/store/store';

import StepperHeader from '../StepperHeader/stepperHeader';

const Stepper = () => {
  const wizard: any = useAppSelector((state) => state.wizard);

  return (
    <StepperHeader
      activeStep={wizard.activeStep}
      steps={wizard.steps}
      totalSteps={wizard.steps.length - 1}
      activeLineWidth={wizard.activeLineWidth}
      lastVisitedRoute={wizard.lastActiveStep - 1}
      disableClick={wizard.disableClick}
    />
  );
};

export default Stepper;

import { s3BaseUrl } from 'src/constants';

export const awsAssetsFetcher = (name: string, type = '.png') => {
  return `${s3BaseUrl}/${name}${type ? '.' + type : '.png'}`;
};
export const formatImageName = (name: string): string => {
  return name
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '');
};
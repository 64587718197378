import React from 'react';
import RequiredModifiers from 'src/containers/Modifiers/ItemRequiredModifiers';
import { IItemsRequiredModifierGroups } from 'src/models/item.model';

type propTypes = {
  ItemReqModifierGroup: IItemsRequiredModifierGroups[];
  handleIngredientSelection: (data, id) => void;
  bucketkey: any;
  isBoxLunch: boolean;
  editCartItem?: any;
  requiredModifiersAtItemDetailSectionToHandleScroll?: {
    refToScrollToMissingRequiredModifiers: any[];
    isRequiredModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsRequiredModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  };
};

const ItemRequiredModifiers: React.FC<propTypes> = (props) => {
  const {
    ItemReqModifierGroup,
    requiredModifiersAtItemDetailSectionToHandleScroll,
    handleIngredientSelection,
    bucketkey,
    isBoxLunch,
    editCartItem,
  } = props;
  const showRedBorderAnimation = (index) => {
    return requiredModifiersAtItemDetailSectionToHandleScroll
      ? !requiredModifiersAtItemDetailSectionToHandleScroll
          .isRequiredModifierSelected.status &&
          requiredModifiersAtItemDetailSectionToHandleScroll
            .isRequiredModifierSelected.highLightArea === index
      : false;
  };
  return (
    <React.Fragment>
      {ItemReqModifierGroup?.map(
        (group: IItemsRequiredModifierGroups, index: number) => (
          <div key={group.id} className="mt-4">
            <div
              className={`pt-3 pb-2`}
              ref={
                requiredModifiersAtItemDetailSectionToHandleScroll
                  ? requiredModifiersAtItemDetailSectionToHandleScroll
                      .refToScrollToMissingRequiredModifiers[index]
                  : null
              }
            >
              <h5 className="f-s16 mb-0 f-w6 font-plex">
                {group.label.toLowerCase().includes('add')
                  ? `${group.label || group.name}`
                  : `Choose Your ${group.label || group.name}`}
              </h5>
            </div>
            <div className={`select_size_box_dressing`}>
              <RequiredModifiers
                requiredModifierGroup={group}
                handleIngredientSelection={handleIngredientSelection}
                bucketkey={bucketkey}
                isBoxLunch={isBoxLunch}
                editCartItem={editCartItem}
                showRedBorderAnimation={showRedBorderAnimation(index)}
              />
            </div>
          </div>
        ),
      )}
    </React.Fragment>
  );
};

export default ItemRequiredModifiers;

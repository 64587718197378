import { useMutation } from 'react-query';

import { IPromoCode } from '../../models/order.model';
import { customerBusinessService } from '../../services';

const applyPromoCode = async (payload: IPromoCode) => {
  const response = await customerBusinessService.applyPromoCode(payload);
  return response.data;
};

export default function usePromoCode() {
  return useMutation((payload: IPromoCode) => applyPromoCode(payload));
}

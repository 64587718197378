import { QuantityMinus, QuantityPlus } from 'src/assets/images/Svgs/svg';
import { MAX_BOX_LUNCH_QUANTITY } from 'src/constants';
import { ItemDetailForm } from 'src/models/item.model';

import FormField from '../FormFields/FormField';
import MultiTagSelect from '../MultiTagSelect/MultiTagSelect';
type propTypes = {
  formState: ItemDetailForm;
  handleFromFields: (formState: ItemDetailForm) => void;
  isBoxLunch?: boolean;
};

const OrderNameForm: React.FC<propTypes> = ({
  formState,
  handleFromFields,
}) => {
  const incrementChangeHandler = () => {
    formState.quantity = Number(formState.quantity) + 1;
    handleFromFields(formState);
  };

  const decrementChangeHandler = () => {
    if (formState.quantity > 1) {
      formState.quantity = formState.quantity - 1;
      handleFromFields(formState);
    }
  };

  return (
    <div className="new_form_design d-flex gap-3">
      <div className='w-50'>
        <div className="d-flex justify-content-between user-select-none">
          <h3 className="f-s16 f-w6 opr-heading-clr mb-2 font-plex">Name(s)</h3>
          <p className="f-s14 mb-0 font-plex">(Optional)</p>
        </div>
        <MultiTagSelect
          formState={formState}
          handleFromFields={handleFromFields}
          fontSizeLabel={'f-s16'}
          placeholder={'Name(s)'}
        />
      </div>
      <div className='w-50'>
      <div className="d-flex justify-content-between">
        <h3 className="f-s16 f-w6 opr-heading-clr mb-2 font-plex">
          Add special instructions
        </h3>
        {/* <p className="f-s14 mb-0">(Optional)</p> */}
      </div>

      <FormField
        inputFieldClass={
          formState?.instructions.length > 150 ? 'input_error' : ''
        }
        type="text"
        placeholder="150 Characters"
        value={formState?.instructions}
        onChange={(e) => {
          if (e?.target?.value.length > 150) return;
          formState.instructions = e?.target?.value;
          handleFromFields(formState);
        }}
        name="name"
      />
      </div>
    </div>
  );
};
export default OrderNameForm;

import {
  Breadcrumb,
  Col,
  Container,
  Dropdown,
  NavLink,
  Row,
} from 'react-bootstrap';
import { CZLogo, UserIconBoy } from 'src/assets/images/Svgs/svg';
import Cart from 'src/Features/Cart/Cart';
import { useAppSelector } from 'src/redux/store/store';
import styles from './header.module.scss';
import {
  CONTROL_CENTER_OPERATOR_URL,
  INDIVIDUAL_OPERATOR_APP_URL,
} from 'src/constants';
import { useEffect } from 'react';
import { updateCartRedeemedOfferItem } from 'src/redux/slices/cartSlice';
import { useDispatch } from 'react-redux';

const Header = () => {
  const { operator } = useAppSelector((state) => state?.user);
  const dispatch = useDispatch();
  const { activeStep } = useAppSelector((state) => state.wizard);
  const url = window.location.href;
  const cart = useAppSelector((state) => state.cart);
  const show =
    cart.showCart && (url.includes('menu') || url.includes('item-detail'));
  const handleOrderClickRedirection = () => {
    window.open(`${CONTROL_CENTER_OPERATOR_URL}`, '_blank');
  };
  const token = JSON.parse(localStorage.getItem('OPERATOR'))?.token || '';

  const handleIndividualClick = () => {
    if (!token) return;
    window.open(
      `${INDIVIDUAL_OPERATOR_APP_URL}?token=${token}&isOrderListing=true`,
      '_blank',
    );
  };

  return (
    <>
      <div className={`${styles.headerWrapper} px-5`}>
        <Container fluid className={styles.headerContainer}>
          <Row className="align-items-center">
            <Col xs={12} lg={6} md={6} className="p-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between gap-5 align-items-center ">
                  <div className="text-center flex-fill">
                    <NavLink
                      className={` ${styles.navLogoLink} d-inline d-md-block`}
                      href="/"
                    >
                      <CZLogo className="sticky-logo" />
                    </NavLink>
                  </div>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <span
                        className="font-plex"
                        onClick={handleOrderClickRedirection}
                      >
                        Operator
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      <span className="font-plex">
                        {cart.is_edit_order
                          ? 'MODIFY EXISTING ORDER'
                          : 'CREATE AN ORDER'}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} md={6} className="p-0">
              <div
                className={`${styles.app_user_links} app_user_links d-flex justify-content-end align-items-center gap-5`}
              >
                <div className={styles.btn_container}>
                  <label className={styles.switch}>
                    <input type="checkbox" checked />
                    <div className={styles.slider}>
                      <span
                        className={`font-plex ${styles.indvidualBtn}`}
                        onClick={handleIndividualClick}
                      >
                        Individual
                      </span>
                      <span className="font-plex">Catering</span>
                    </div>
                  </label>
                </div>
                <Dropdown className={styles.typeLinkDropdown}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className={`${styles.dropDownToggle} text-start gap-3 d-inline-flex align-items-center border-0`}
                  >
                    <UserIconBoy />
                    <div>
                      <p className="mb-0 font-plex f-s14 f-w5">
                        {operator?.name}
                      </p>
                      <span className="f-s12 f-w4 font-plex">
                        {operator?.role}
                      </span>
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {show ? <Cart /> : null}
    </>
  );
};

export default Header;

import { useQuery } from 'react-query';
import { GUEST_USER } from '../constants';
import { Menu } from '../models/Menu.model';
import { locationMenuService } from '../services';

interface IPayload {
  location_id?: string;
  customer_id?: number | null | undefined;
}

interface IAuthInfo {
  id: number;
  type: string | null;
}

const getCategories = async (
  location_id: string | null | undefined,
  user: IAuthInfo,
) => {
  const payload: IPayload = {};
  if (location_id) payload.location_id = location_id;
  if (user.type !== GUEST_USER) payload.customer_id = user?.id;
  const response = await locationMenuService.getLocationMenu(payload);
  return response.data.data.categories.filter(
    (category) => category.items && category.items.length > 0,
  );
};

export default function useCategories(
  location_id: string | null | undefined,
  user: IAuthInfo,
) {
  return useQuery<Menu[]>(
    ['categories', location_id],
    () => getCategories(location_id, user),
    {
      staleTime: 1000 * 60 * 60,
    },
  );
}

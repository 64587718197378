import { FC } from 'react';

import styles from './loader.module.scss';
interface LoaderProps {
  lightBg?: boolean;
  className?: string;
  modalSpinner?: boolean;
  AlignSpinner?: any;
  isPage?: string;
}

const Loader: FC<LoaderProps> = ({
  lightBg,
  className,
  modalSpinner,
  AlignSpinner,
  isPage,
}) => {
  return (
    <div
      className={
        modalSpinner
          ? `${styles.spinner} ${className} d-flex flex-column align-items-center justify-content-center theme-spinner`
          : `${styles.spinner} ${className} d-flex flex-column align-items-center justify-content-center theme-spinner ${styles.full_page_loader} `
      }
    >
      <div
        className={`${styles.container_img_wave} ${AlignSpinner} d-flex align-items-center justify-content-center`}
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 129.3 127.4"
          xmlSpace="preserve"
        >
          <path
            className={`${styles.cls_1} ${styles.svg_animation}`}
            d="M40.6,118.6c1.7,0.7,3.6-0.8,4.8-3.8c1.2-3,1-5.5-0.7-6.2c-1.7-0.7-3.6,0.9-4.8,3.8
            C38.7,115.4,39,117.9,40.6,118.6z M13.1,93.8c0.1,0.3,0.3,0.5,0.5,0.8l4.4-2.6c-0.1-0.3-0.2-0.5-0.4-0.8C17,90,15.6,89.5,14.5,90
            c-0.1,0.1-0.3,0.2-0.4,0.2c-1.2,0.5-1.7,1.9-1.2,3.2C12.9,93.6,13,93.7,13.1,93.8z M97.4,12.8c-1.5-1-3.6,0.2-5.3,3
            s-1.8,5.3-0.3,6.3c1.5,1,3.7-0.3,5.3-3S98.9,13.8,97.4,12.8L97.4,12.8z M102.6,34.5c-2-2.6-0.7-6.2,2.4-8.7s6.9-2.9,8.9-0.3
            s0.8,6.2-2.4,8.7S104.6,37.1,102.6,34.5z M109.6,47c-0.3-0.9-0.5-1.9-0.7-2.9l13.1-4.5c0.4,0.9,0.8,1.8,1.1,2.7
            c1.3,4.2-0.8,7.3-4.6,8.6C114.6,52.3,110.9,51.2,109.6,47L109.6,47z M56.9,110.3l5.4,9.4l0.6-8.8l2.1,0.2l-1,14.4h-0.7l-5.4-9.2
            l-0.6,8.7l-2.1-0.2l1-14.4L56.9,110.3z M45.6,106.8c2.9,1.3,3.5,5,2,8.9s-4.7,6-7.7,4.7c-3-1.3-3.5-5-2-8.9
            C39.4,107.7,42.6,105.5,45.6,106.8z M32.6,98.3c2,1.8,2.2,4.1,0.2,6.5l-6.4,7.3l-1.6-1.5l6.4-7.4c1.2-1.4,1.1-2.6,0.1-3.5
            c-1-0.9-2.2-0.9-3.4,0.5l-6.4,7.4l-1.6-1.5l6.4-7.4C28.2,96.5,30.6,96.5,32.6,98.3L32.6,98.3z M22.2,85l-3.4,4.7
            c0.1,0.2,0.2,0.4,0.3,0.6s0.3,0.5,0.4,0.8l4.5-2.7l1,1.9l-12,7.2c-0.6-0.8-1.1-1.6-1.6-2.4c-1.8-3.1-0.8-5.5,1.3-6.7
            c1.4-0.9,3.1-0.9,4.4,0l3.6-5.5L22.2,85z M19.2,77.2L5.6,80.9l-0.6-2.1L18.5,75L19.2,77.2z M17.5,65.2c0,1.4-0.4,2.7-1.1,3.9
            l-1.9-0.7c0.7-0.9,1.1-2,1.1-3.1c0.1-0.9-0.6-1.7-1.4-1.7c-0.1,0-0.2,0-0.3,0c-1,0-1.7,0.6-2.9,2.4s-2.4,2.9-4,2.9
            c-2,0.1-3.7-1.6-3.8-3.7c0-0.1,0-0.1,0-0.2c0-1.1,0.3-2.2,0.9-3.2l1.9,0.7c-0.5,0.7-0.8,1.5-0.8,2.4c-0.1,0.9,0.6,1.6,1.4,1.7
            c0.1,0,0.2,0,0.3,0c0.9,0,1.6-0.7,2.5-2.2c1.4-2.1,2.5-3,4.3-3.1c2-0.1,3.8,1.5,3.9,3.6C17.5,65,17.5,65.1,17.5,65.2z M19.7,48.8
            L13.8,47l-1.3,4.3l5.9,1.8l-0.6,2.1L4.4,51.2l0.6-2.1l5.7,1.7l1.3-4.4l-5.7-1.7l0.6-2.1l13.4,4.1L19.7,48.8z M22.3,22.1l8.6,8.5
            l-1.5,1.5l-8.6-8.5L18.6,26l-1.4-1.4l5.9-6.3l1.5,1.4L22.3,22.1z M43.4,21.4l-3-5.5l-3.9,2.2l3,5.5l-1.9,1.1L30.9,12l1.9-1l2.9,5.3
            l3.9-2.2l-2.9-5.4l1.9-1.1l6.8,12.6L43.4,21.4z M51.5,18.1l-3-14l6.7-1.6l0.4,2l-4.6,1.1l0.8,3.9l4.2-1l0.4,1.9l-4.1,0.9l0.9,4.2
            l4.6-1l0.4,2L51.5,18.1z M82.9,10.9l-1.6,6.8c-1.2,0.5-2.6,0.5-3.9,0.2c-3.1-0.8-4.9-4-3.8-8.5s4.1-6.6,7.2-5.8
            c1.3,0.3,2.4,1,3.2,2.1L82.7,7c-0.6-0.8-1.4-1.3-2.3-1.6c-2.1-0.5-3.9,1.2-4.6,4.4C75,13.2,76,15.5,78,16c0.5,0.1,1.1,0.1,1.6,0
            l0.9-3.7l-2.2-0.5l0.4-1.9L82.9,10.9z M90.8,23.7c-2.7-1.8-2.7-5.6-0.6-9.1s5.6-5.1,8.3-3.4s2.7,5.5,0.6,9.1S93.5,25.4,90.8,23.7
            L90.8,23.7z M112.4,26.7c-1.1-1.5-3.5-1-6,1s-3.5,4.3-2.3,5.8s3.5,1,6-1S113.6,28.1,112.4,26.7L112.4,26.7z M121.3,43.4
            c-0.1-0.3-0.2-0.6-0.4-0.9l-9.7,3.3c0.1,0.3,0.2,0.7,0.3,1c0.8,2.5,3.1,3.1,6.2,2.1C120.6,47.8,122.1,45.8,121.3,43.4L121.3,43.4z
            M112.3,67.7l13.8,2.3l-0.3,2.2l-11.8-2.1l-0.7,4.5l-2-0.3L112.3,67.7z M109.2,80.2l12.9,5.6l-0.8,2.1l-12.8-5.7L109.2,80.2z
            M113.5,97.5l-3.3-2.5l-2.4,3.3l-1.5-1.2l2.4-3.3l-4.7-3.6l1.3-1.8l11.2,8.6l-4,5.6l-1.6-1.2L113.5,97.5z M96.9,98.3l8.6,11.4
            l-5.4,4.3l-1.2-1.6l3.7-3l-2.4-3.1l-3.3,2.7l-1.2-1.6l3.3-2.7l-2.6-3.4l-3.7,3l-1.2-1.6L96.9,98.3z M77.5,113.7
            c1.4-0.4,2.8,0.4,3.2,1.9c0.4,1.4-0.4,2.9-1.8,3.3s-2.8-0.4-3.2-1.9c0,0,0,0,0-0.1C75.2,115.6,76,114.1,77.5,113.7
            C77.4,113.7,77.5,113.7,77.5,113.7L77.5,113.7z"
          />
          <path
            className={styles.cls_1}
            d="M89.2,54.6C89,54,88.4,53.8,87.8,54h-0.1c-3.1-6-9.9-9.1-16.5-7.5l0,0c3.3-2.2,4.1-4.5,5.1-6.5
            c-5.8-1.4-9.3-0.7-10.8,5.8c0.8-7.3,1.8-8.7-3.1-12.3c-2.1,4.1-4.2,6.7-3.2,9.5c0.5,1.6,1.1,3.1,1.8,4.6c-1.5-1.5-2.9-3.2-4-5.1
            c-2.3-3.9-7.1-4.2-9.7-4.5c0.6,4.5,0.7,6,2.8,8.2c1.5,1.6,4.9,2.6,4.9,2.6s-6,1.9-10.1-2.3c-0.9,2.9,0.5,5.9,3.1,7.2
            c-6.3,2.9-9.6,10-7.7,16.7c0.3,1,0.7,1.9,1.1,2.8c1.2,2.6,2.9,4.9,5,6.8l0,0c-0.4,0.4-0.5,1.1-0.1,1.5c0,0,0,0,0.1,0.1
            c1.8,1.6,3.8,3.1,5.9,4.4l2.3,1.3c8.5,4.7,16.6,6.4,18.4,6.3c3.6-1.1,15.6-16.5,16.9-31.7c0-0.3,0-0.5,0-0.8c0.2-1.9,0-3.7-0.5-5.5
            C89.5,55.4,89.4,55,89.2,54.6z"
          />
          <path
            className={styles.cls_2}
            d="M45.3,73c-0.4-0.8-0.7-1.6-1-2.4c-1.8-6.4,1.9-13.1,8.4-14.9c0.1,0,0.1,0,0.2-0.1c3.2-0.8,6.6-0.4,9.4,1.3
            c1.5-3.3,4.5-5.8,8-6.7c5.3-1.4,10.8,0.8,13.7,5.4c-6.4,3.2-12,7.9-16.3,13.6c-4.1,5.6-10.2,9.3-17,10.6C48.4,78,46.6,75.6,45.3,73z
            "
          />
          <path
            className={styles.cls_1}
            d="M49.3,64.8c-0.9,0.2-1.5-0.9-1.3-2.5c0.1-0.6,0.3-1.2,0.5-1.8c-0.8,0.9-1.4,2.1-1.5,3.4c-0.2,2,0.5,3.4,1.7,3.1
            s2.2-2,2.5-4c0-0.2,0-0.5,0-0.7C50.8,63.5,50.1,64.6,49.3,64.8z"
          />
          <path
            className={styles.cls_1}
            d="M57.4,64.8c-1,0.3-2-0.8-2.3-2.4c-0.1-0.6-0.1-1.3,0.1-1.9c-0.7,1.1-1,2.4-0.8,3.7c0.3,2,1.5,3.4,2.8,3
            s2-2.4,1.7-4.4c0-0.3-0.1-0.5-0.2-0.8C58.8,63.3,58.2,64.5,57.4,64.8z"
          />
          <path
            className={styles.cls_1}
            d="M54.3,72.4c-1.1,0.5-2.5-0.6-3.1-2.3c-0.2-0.7-0.3-1.4-0.3-2.1c-0.6,1.3-0.6,2.7-0.1,4c0.8,2.1,2.5,3.4,3.9,2.8
            s1.8-2.8,1-4.9c-0.1-0.3-0.2-0.5-0.4-0.8C55.6,70.6,55.2,72,54.3,72.4z"
          />
          <polygon
            className={styles.cls_1}
            points="82.1,87.6 82.5,89 82.1,89.1 81.7,87.7 81.2,87.9 81.2,87.6 82.5,87.2 82.6,87.5 "
          />
          <polygon
            className={styles.cls_1}
            points="84.7,88.3 84.4,87.4 84.1,88.2 84.1,88.2 83.4,87.7 83.6,88.6 83.3,88.7 82.9,87 82.9,87 84,87.8 
            84.4,86.6 84.4,86.6 85,88.2 "
          />
        </svg>
      </div>
      {/* <div className={modalSpinner ? " d-none" : " "}>
        <div className={styles.footer_img_wrap}>
          <Image
            className="w-100"
            source={
              moboImages
                ? isDeviceIos
                  ? `${s3BaseUrl}/images/Footer/Footer.jpeg`
                  : `${s3BaseUrl}/images/Footer/Footer.webp`
                : isDeviceIos
                ? `${s3BaseUrl}/images/Footer/footer-art-mobo.jpeg`
                : `${s3BaseUrl}/images/Footer/footer-art-mobo.webp`
            }
            alt="image"
          />
        </div>
      </div> */}
    </div>
  );
};

export default Loader;

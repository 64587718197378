import React from 'react';
import { QuantityDecrement, QuantityDelete } from 'src/assets/images/Svgs/svg';
import { MAX_BOX_LUNCH_QUANTITY } from 'src/constants';

import { quantityCounterOperations as operations } from '../../../models/cart.model';

import styles from './CartItems.module.scss';

interface IQuantityCounter {
  quantity?: number;
  editQuantity?: (operation: operations) => void;
  disabled?: boolean;
  itemDetailCounterStyle?: string;
}

const QuantityCounter: React.FC<IQuantityCounter> = (props) => {
  const {
    quantity,
    editQuantity,
    disabled = false,
    itemDetailCounterStyle,
  } = props;

  const increaseQuantity = () => editQuantity(operations.increase);
  const decreaseQuantity = () => editQuantity(operations.decrease);

  return (
    <div className="text-center me-3">
      {/* <span className="clr-text-grey f-s14 ls-normal lh-normal font-Visby-cf f-w5">
        Quantity
      </span> */}
      <div
        className={`${
          styles.card_increments
        } d-flex align-items-center ${itemDetailCounterStyle} ${
          disabled && styles.disabled
        }`}
      >
        <button
          type="button"
          className=" f-s18 f-w4 font-rale lh-normal"
          onClick={decreaseQuantity}
          disabled={disabled}
        >
          {quantity === 1 ? <QuantityDelete /> : <QuantityDecrement />}
        </button>
        <span className="d-inline-block px-4 f-s16 font-plex">{quantity}</span>
        <button
          type="button"
          className=" f-s18 f-w4 font-rale lh-normal"
          onClick={increaseQuantity}
          disabled={disabled || quantity > MAX_BOX_LUNCH_QUANTITY - 1}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default QuantityCounter;

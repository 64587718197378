import React from 'react';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import { selectedBtnStyle } from '../../components/Molecules/Card/cardStyle';

import styleClasses from './try2.module.scss';

const NoDressing: React.FC<any> = ({
  label = '',
  groupId = 0,
  itemNo,
  noDressingHandler,
  isNoDressing,
  noDressingCardBorderRadius,
  showRedBorderAnimation,
}) => {
  const noDressingClickHandler = () => {
    const payload: any = {
      modifier_id: 0,
      modifier_name: `No ${label}`,
      display_price: 0,
      modifier_calories: '0',
      type: 'selected',
      modifier_group_id: groupId,
      modifier_group_max: 0,
      code: 'NO',
      modifier_type: 'TRY_2_COMBO_REQUIRED_MODIFIERS',
      in_slide: true,
      quantity: isNoDressing ? 0 : 1,
      additionalPrice: 0,
      groupId: groupId,
    };
    noDressingHandler(payload, itemNo);
  };
  return (
    <div
      className={`${styleClasses.add_on_item_wrap} ${styleClasses.no_dressing_card
        } ${noDressingCardBorderRadius} cursor-pointer shadow-box d-flex flex-column ${isNoDressing && styleClasses.selected
        } ${showRedBorderAnimation ? 'border-size-select' : ''}`}
      onClick={noDressingClickHandler}
    >
      <div className={`${styleClasses.items_image} mb-2`}>
        <img
          src={awsAssetsFetcher('static/Prohibit', 'webp')}
          className="img-fluid no-dressing-img"
          alt="ingredient"
        />
      </div>
      <div>
        <h3 className={`${styleClasses.item_title} font-plex`}>
          {`No ${label}`}
        </h3>
        <div className={`${styleClasses.buttons_wraper}`}>
          <button
            type="button"
            className={`  item-select-btn mx-auto mw-auto mb-1`}
            style={{
              ...(isNoDressing && {
                ...selectedBtnStyle,
              }),
            }}
            onClick={noDressingClickHandler}
          >
            {isNoDressing ? 'Selected' : 'Select'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoDressing;

import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import { AddPlusIcon } from 'src/assets/images/Svgs/svg';
import { STEP5 } from 'src/constants';
import { ItemDetailForm } from 'src/models/item.model';
import styles from './MultiTagSelect.module.scss';
import { useAppSelector } from 'src/redux/store/store';
import { checkTheStepNo, formatPhoneNumber } from 'src/helper/helperMethods';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: number;
}

const createOption = (label: string, value: number) => ({
  label,
  value: value,
});

interface MultiTagSelectProps {
  formState: ItemDetailForm;
  handleFromFields: any;
  fontSizeLabel?: string;
  readonly placeholder: string;
  limitAdditionalValue?: boolean;
  isPhoneNumberInput?: boolean;
  isSetInitializeValue?: boolean;
}

const MultiTagSelect: React.FC<MultiTagSelectProps> = ({
  formState,
  handleFromFields,
  fontSizeLabel,
  placeholder,
  limitAdditionalValue,
  isPhoneNumberInput = false,
  isSetInitializeValue = false,
}) => {
  const { search } = useLocation();

  const wizardSteps = useAppSelector((state) => state.wizard);

  const [showEmailErroMessage, setShowEmailErroMessage] =
    useState<boolean>(false);
  const isThisStep4 = checkTheStepNo(wizardSteps, STEP5);  

  const regeNamePtrn = isThisStep4 && !isPhoneNumberInput
    ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i :
    isPhoneNumberInput && isThisStep4 ? 
    /^\d{10}$/
    : /^[a-zA-Z0-9\s]+$/;
  const query = new URLSearchParams(search);
  const dbId = query.get('id');

  const updateTagsFirstTime = useRef(false);

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<any>(isSetInitializeValue && formState?.name? formState?.name : []);
  const [maxLimitExceed, setMaxLimitExceed] = React.useState(false);
  const idCounter = useRef(0);

  useEffect(() => {
    if (formState.reset) {
      setValue([]);
      idCounter.current = 0;
    }
    if (dbId && formState.name.length > 0 && !updateTagsFirstTime.current) {
      updateTagsFirstTime.current = true;
      const options = [];
      for (let index = 0; index < formState.name.length; index++) {
        options.push(createOption(formState.name[index], idCounter.current));
        idCounter.current = idCounter.current + 1;
      }
      setValue(options);
    }
  }, [dbId, formState]);

  useEffect(() => {
    if (formState.reset) {
      idCounter.current = 0;
      return;
    }
    const newState = [];
    for (let index = 0; index < value.length; index++) {
      newState.push(value[index].label);
    }
    formState.name = newState;
    if (newState.length > 5 && limitAdditionalValue) {
      setMaxLimitExceed(true);
    }
    handleFromFields(formState);
  }, [value]);
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setShowEmailErroMessage(true);
        if (!regeNamePtrn.test(inputValue)) break;
        if (value.length > 4) {
          setMaxLimitExceed(true);
        }
        formState.reset = false;
        setShowEmailErroMessage(false);
        setValue((prev) => [
          ...prev,
          createOption(inputValue, idCounter.current),
        ]);
        idCounter.current = idCounter.current + 1;

        setInputValue('');
        event.preventDefault();
    }
  };

  const onChange = (newValue) => {
    setValue(newValue);
    setMaxLimitExceed(false);
    setShowEmailErroMessage(false);
  };

  const onInputChange = (newValue, action) => {
    if (action === 'input-change') {
      setInputValue(newValue);
    }
  };
  const handleSubmit = () => {
    setShowEmailErroMessage(true);
    if (!regeNamePtrn.test(inputValue) || !inputValue) return;
    if (value.length > 4) {
      setMaxLimitExceed(true);
    }
    formState.reset = false;
    setShowEmailErroMessage(false);
    setValue((prev) => [...prev, createOption(inputValue, idCounter.current)]);
    idCounter.current = idCounter.current + 1;
    setInputValue('');
  };

  const errorMessage = () => {
    if (isThisStep4 && !isPhoneNumberInput) {
      return showEmailErroMessage ? 'Invalid email address. ' : null;
    }
    else if(isThisStep4 && isPhoneNumberInput) {
      return showEmailErroMessage ? 'Invalid phone number. ' : null;
    }
    return 'Name must not include any special chracters';
  };
  
  const limitExceedsMessage = () => {
    if(value.length <= 4) {
      setMaxLimitExceed(false) 
    return undefined};
    if (limitAdditionalValue) {
      return 'Max limit to additional is 5';
    } 
    return undefined;
  }

  return (
    <>
      <div className="form-group mb-0 position-relative">
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isMulti
          isClearable={false}
          menuIsOpen={false}
          onChange={(newValue) => onChange(newValue)}
          onInputChange={(newValue, { action }) =>
            onInputChange(newValue, action)
          }
          onKeyDown={handleKeyDown}
          // value={value}
          value={isPhoneNumberInput ? formatPhoneNumber(value) : value}
          onBlur={handleSubmit}
          placeholder={placeholder}
          className={`form-control custom_multi_select name_select_feild ${fontSizeLabel}`}
          classNamePrefix="custom-creatable"
        />
        <div className={`${styles.addPlusIcon}`} onClick={handleSubmit}>
          <AddPlusIcon />
        </div>
        <span className={'clr-dark-red f-s14 font-plex mt-1 d-inline-block'}>
          {!regeNamePtrn.test(inputValue) && inputValue && errorMessage()}
          {limitAdditionalValue && maxLimitExceed && limitExceedsMessage()}
        </span>
      </div>
    </>
  );
};
export default MultiTagSelect;
import { useQuery } from "react-query";
import { locationMenuService } from "../services";

interface IRequestPayload {
  timeZone: string
}

const getTimeZoneTime = async ({timeZone}: IRequestPayload) => {
  const response = await locationMenuService.getTimeZoneTime(timeZone);
  return response?.data?.data
};

export default function useTimeZone(payload: IRequestPayload) {
  return useQuery(["serverDateTime", payload.timeZone], () => getTimeZoneTime(payload), {
    staleTime: 1000 * 60 * 3
  });
}

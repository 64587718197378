import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from '../../components/Button/Button';
// import { Scrollbars } from 'react-custom-scrollbars';
import RecommendedButton from '../../components/Button/RecommendedButton';
import CounterCard from '../../components/Molecules/Card/CounterCard/CounterCard';
import styleClasses from '../../components/Molecules/Card/CounterCard/counterCard.module.scss';
import SelectCard from '../../components/Molecules/Card/SelectCard/SelectCard';
import * as Constants from '../../constants';
import { REQUIRED_MODIFIERS } from '../../constants';
import {
  isItSubstituteGroupFromMultipleRequired,
  verifyStatus,
} from '../../helper/customizedItem/customizedItem.helper';
import {
  IRequiredModifier,
  IRequiredModifiersGroupsModifiers,
} from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';

import NoDressing from './cards/NoDressing';

const OtherDressing: React.FC<IRequiredModifier> = (props) => {
  const {
    setOtherDressingData,
    requiredModifierGroup,
    selectedIngredients,
    handleIngredientSelection,
    noDressingHandler,
    selectedAddedIngredients,
    isNoDressing,
    sectionSelected,
    showRecommendedButton = true,
    addSelectedIngredients,
    selectedOtherDressingData,
    label,
  } = props;

  const handleClick = (data, in_item, in_slide) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: requiredModifierGroup.id,
        modifier_group_max: requiredModifierGroup.max,
        modifier_group_base: requiredModifierGroup.base,
        brink_modifier_group_id: requiredModifierGroup.brink_modifier_group_id,
        in_item,
        modifier_type: Constants.REQUIRED_MODIFIERS,
        in_slide,
      },
    });
  };

  const showModifier = (modifier, selected) => {
    if (!modifier?.in_item || selected?.in_slide || modifier?.in_item) {
      return true;
    }
    return false;
  };

  const isLimitExceed = (extendable_limit: number) => {
    if (extendable_limit === 1) {
      return false;
    }
    const isNoDressingExist = selectedAddedIngredients.find(
      (mod: any) => mod.modifier_name === Constants.NO_DRESSING_CAPITALIZE,
    );
    if (isNoDressingExist) {
      extendable_limit += 1;
    }
    if (selectedAddedIngredients.length >= extendable_limit) {
      return true;
    } else {
      return false;
    }
  };

  const selectedIngredient = (modifier) => {
    return selectedIngredients.find(
      (selected) => selected.modifier_id === modifier.id && !selected.core,
    );
  };

  const noDressing = (function () {
    const requiredModifiersBucket = itemBuckets.getSingleBucket({
      name: REQUIRED_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
    if (requiredModifiersBucket?.noDressings) {
      const noDressingState = requiredModifiersBucket?.noDressings.find(
        (e) => e.id === requiredModifierGroup?.id,
      );
      return noDressingState.noDressing;
    } else {
      return false;
    }
  })();

  const name = label ?? 'Modifiers';

  const decideCard = (modifier: IRequiredModifiersGroupsModifiers) => {
    return (
      selectedIngredients?.length > 0 &&
      !isItSubstituteGroupFromMultipleRequired(requiredModifierGroup) &&
      modifier?.max > 1
    );
  };

  return (
    <>
      <div
        className={`${styleClasses.try2_all_dressing} other-dressing-view try2_dressing_modal px-4 p-4`}
      >
        <RecommendedButton
          showRecommendedButton={showRecommendedButton}
          handleClick={setOtherDressingData}
          text={label}
        />
        <hr className="mb-4 mt-2 mt-md-3 d-md-none" />
        {/* <Scrollbars className="Modal_inner_scrollbar"> */}
        {requiredModifierGroup && (
          <Row
            className={`${styleClasses.item_other_dressing_scroll} mb-3 mx-0`}
            key={requiredModifierGroup.id}
          >
            {requiredModifierGroup?.required_modifiers_groups_modifiers?.map(
              (modifier) => {
                const selected = selectedIngredient(modifier);
                return (
                  showModifier(modifier, selected) && (
                    <Col xs="4" sm="3" className="py-2 px-2">
                      {decideCard(modifier) ? (
                        <CounterCard
                          id={modifier?.id}
                          brink_id={modifier?.brink_modifier_id}
                          image={`${Constants.s3BaseUrl}${modifier?.image}`}
                          title={modifier.name}
                          price={modifier.price}
                          calories={modifier.calories}
                          max={modifier.max}
                          is_active={verifyStatus(
                            modifier,
                            Constants.MODIFIER_LOCATION,
                          )}
                          handleClick={(data) => {
                            handleClick(data, modifier?.in_item, true);
                          }}
                          priceAlternateLabel={selected ? ' ' : ' '}
                          groupMin={requiredModifierGroup.min}
                          count={selected?.quantity || 0}
                          from={Constants.TRY_2_COMBO}
                          additionalPrice={selected?.additionalPrice}
                          extendableLimit={isLimitExceed(
                            requiredModifierGroup.extendable_limit,
                          )}
                          extendableLimitValue={
                            requiredModifierGroup.extendable_limit
                          }
                          showRedBorderAnimation={
                            props.isRequiredModifierSelected
                          }
                          counterBtnSpace={'mx-md-0'}
                        />
                      ) : (
                        <SelectCard
                          id={modifier?.id}
                          brink_id={modifier?.brink_modifier_id}
                          image={`${Constants.s3BaseUrl}${modifier?.image}`}
                          title={modifier.name}
                          price={modifier.price}
                          calories={modifier.calories}
                          is_active={verifyStatus(
                            modifier,
                            Constants.MODIFIER_LOCATION,
                          )}
                          priceAlternateLabel={selected ? ' ' : ' '}
                          handleClick={(data) => {
                            handleClick(data, modifier?.in_item, true);
                          }}
                          groupMin={requiredModifierGroup.min}
                          selected={Boolean(selected)}
                          default_selected={modifier.is_selected}
                          modifierAdditionalPrice={selected?.additionalPrice}
                          extendableLimit={isLimitExceed(
                            requiredModifierGroup.extendable_limit,
                          )}
                          extendableLimitValue={
                            requiredModifierGroup.extendable_limit
                          }
                          showRedBorderAnimation={
                            props.isRequiredModifierSelected
                          }
                          selectBtnSpace={'px-lg-5'}
                        />
                      )}
                    </Col>
                  )
                );
              },
            )}
            {requiredModifierGroup.min === 0 && (
              <Col xs="4" sm="3" className="p-2">
                <NoDressing
                  label={requiredModifierGroup.label}
                  groupId={requiredModifierGroup.id}
                  noDressingHandler={noDressingHandler}
                  isNoDressing={noDressing}
                  itemNo={sectionSelected}
                  showRedBorderAnimation={props.isRequiredModifierSelected}
                />
              </Col>
            )}
          </Row>
        )}
        {/* </Scrollbars> */}
      </div>
      <hr className="customHr" />
      <div className="text-center me-4">
        <Button
          disabled={
            !(selectedIngredients.length > 0) &&
            selectedIngredients.length <= requiredModifierGroup?.min &&
            !noDressing
          }
          onClick={addSelectedIngredients}
          className="w-25 mb-3"
        >
          Apply
        </Button>
      </div>
      {/* <ApplyButton
        isDisabled={
          !(selectedIngredients.length > 0) &&
          selectedIngredients.length <= requiredModifierGroups?.min &&
          !noDressing
        }
        selectedOtherDressingData={selectedOtherDressingData}
        clickHandle={addSelectedIngredients}
        btnClass={buttonStyleClasses.large_btn}
      /> */}
    </>
  );
};

export default OtherDressing;

import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { persistZero } from 'src/helper/helperMethods';

import styles from './radioselect.module.scss';

interface RadioProps {
  text?: string;
  amount?: any | number;
  img?: any;
  id?: string;
  menu?: string;
  calories?: string;
  counter?: string;
  grouplabel?: string;
  className?: string;
  selected?: boolean;
  onChange?: (e: any) => void;
  isSize?: boolean;
  orignalPrice?: number;
  servingNumber?: string;
  servingText?: string;
  imageSize?: string;
  isBoxLunch?: boolean;
}

const RadioSelect: FC<RadioProps> = (props) => {
  const {
    text,
    amount,
    img,
    id,
    menu,
    calories,
    counter,
    grouplabel,
    className,
    selected,
    onChange,
    servingNumber,
    orignalPrice,
    servingText,
    imageSize,
    isBoxLunch,
  } = props;

  const displayPrice = () => {
    let itemPrice = 0;
    if (selected) {
      itemPrice = amount;
    } else {
      itemPrice = orignalPrice;
    }
    return itemPrice
  };
  return (
    <Col lg="3" className="px-2">
      <div className={className} key={id} onClick={onChange}>
        <input
          type="radio"
          id={id}
          data-testid="6-count"
          name="radio-group"
          checked={selected}
        />
        <label
          htmlFor={id}
          className={`f-s16 w-100 shadow-box optional-selector size-selector h-100 ${grouplabel}  ${
            props.isSize ? 'border-size-select' : ''
          }`}
        >
          {img && (
            <div className="h-100 d-flex justify-content-between flex-column">
              {img && <img src={img} alt="img" className={`${imageSize}`} />}
              <span>{menu}</span>
            </div>
          )}

          <div>
            <h3
              className={`
               f-s14  d-block opr-heading-clr font-plex ${
                 isBoxLunch ? 'my-1' : ''
               }`}
            >
              {text}
            </h3>

            {!!isBoxLunch && !!amount && (
              <span
              className='f-s14 f-w5 opr-heading-clr cz-mb-12'
                style={{
                  color: selected && amount > orignalPrice ? 'darkred' : '',
                }}
              >
                {` ${`($${persistZero(displayPrice())})`}`}
              </span>
            )}

            {calories && (
              <p className="font-plex f-s16 f-w5 mb-0">{calories}</p>
            )}
          </div>
          {!!servingNumber && (
            <p className="f-s13 font-plex f-w5 mb-0 mt-2 text-center">
              {servingNumber}
            </p>
          )}
          {!!counter && (
            <div
              className={`${styles.rounded_box} rounded-box clr-sec-white mx-auto mx-md-0`}
            >
              <span className="font-plex">{counter}</span>
            </div>
          )}
          {!isBoxLunch && !!servingText && (
            <p className="f-s14 f-w5 opr-heading-clr cz-mb-12">
            {`${servingText}`}
            <span style={{
                color: selected && amount > orignalPrice ? 'darkred' : '',
              }}>
                {` ${`($${persistZero(displayPrice())})`}`}
              </span>
          </p>
          )}
          {!isBoxLunch ? (
            <button
              type="button"
              className={`${selected ? styles.darkBg : ''} ${
                styles.selected_kids_combo
              }  item-select-btn w-md-50 mb-2 f-w5 font-plex`}
            >
              {selected ? 'Selected' : 'Select'}
            </button>
          ) : null}
        </label>
      </div>
    </Col>
  );
};

export default RadioSelect;

import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Button from 'src/components/Button/Button';
import CustomModal from 'src/components/customModal/CustomModal';
import FavoriteBtn from 'src/components/FavoriteButton/FavoriteBtn';
import ItemOrderModal from 'src/components/ItemOrderModal/ItemOrderModal';
import TagSymbol from 'src/components/Molecules/Symbols/Symbol';
import * as CONSTANT from 'src/constants';
import { brazeLogCustomEventViewPage } from 'src/helper/brazeHelper';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { IItemPathToRoute, ItemDetails } from 'src/models/item.model';
import { useAppSelector } from 'src/redux/store/store';

import styles from '../menu.module.scss';

import { ItemDetailsModal } from './ItemDetailsModal';
import IMenuItems from './Types';
import { toFixedNumber } from 'src/priceCalculation/helper';

type IitemGrid = {
  itemTitle?: string;
  itemImage?: any;
  symbolIcon?: any;
  favIcon?: string;
  itemDescription?: string;
  itemCal?: string;
  itemPrice?: string;
  selectButton?: string;
  customizeButton?: string;
  handleChooseDressing?: any;
  itemInfoCard?: string;
  errorButton?: boolean;
  item?: ItemDetails;
  handleCustomize?: (item: any) => void;
  disableItem?: boolean;
  filteredIitems?: [];
  showDetailsHandler?: any;
  full_item_short_description?: string;
  activeKey?: string;
  addToComboClickHandler?: (item: ItemDetails) => void;
  hideCustomize?:boolean
};

const ItemGrid: FC<IitemGrid> = ({
  itemTitle,
  itemImage,
  symbolIcon,
  itemDescription,
  itemCal,
  itemPrice,
  selectButton,
  customizeButton,
  itemInfoCard,
  errorButton,
  item,
  disableItem,
  handleCustomize,
  filteredIitems,
  addToComboClickHandler,
  hideCustomize
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get('category_id');
  const isBoxLunch = queryParams.get('is_box_lunch');

  const [showMore, setShowMore] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState<IMenuItems>({} as IMenuItems);
  const cart = useAppSelector((state) => state.cart);
  const showDetailsHandler = (e: React.MouseEvent<HTMLButtonElement>, item) => {
    if (disableItem) {
      return;
    }
    setShowMore(true);
    setItemDetails(item);
    e.stopPropagation();
  };

  const closeDetailsModal = () => {
    setShowMore(false);
  };
  const history = useHistory();
  const isItMobile = useCheckMobileScreen();
  const [openOrderModal, setOpenOrderModal] = useState<boolean>();
  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
  };
  const [divHeight, setDivHeight] = useState<number>(0);
  const itemHeight = useRef(null);

  const [itemToOrder, setItemToOrder] = useState<IItemPathToRoute | null>(null);
  const { user } = useAppSelector((state) => state.user);
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected,
  );
  const isPrimaryCapacityFormFilled = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity.formFilled,
  );
  const locationInfo = useAppSelector((state) => state.location);
  const isGuest = user.type === CONSTANT.GUEST_USER;
  const initiator = item.is_tray_item
    ? CONSTANT.INITIATOR_TRAY
    : item.is_package
      ? CONSTANT.INITIATOR_PACKAGE
      : CONSTANT.INITIATOR_BOX;

  function handleClick(isCustomize = false) {
    if (disableItem) {
      return;
    }
    closeOpenOrderModal();
    const flags = item.is_package
    ? `is_package=true`
    : `is_box_lunch=${item.is_box_lunch ? true : false}`;
    const locationDescriptor: IItemPathToRoute = {
      pathname: `${`${CONSTANT.ROUTE_OTHER_ITEM}/${item.id}`}`,
      search: `category_id=${item?.category_id}&${flags}`,
      state: {
        isPremiumPackage: item?.is_premium ?? false,
        ...(isCustomize
          ? {
              myProp: 'customise',
            }
          : {}),
      },
    };
    if (isOrderTypeSelected && locationInfo.selectedStore) {
      history.push(locationDescriptor);
      brazeLogCustomEventViewPage(item.name, isItMobile);
    } else {
      if (item.cart_directly) setItemToOrder(null);
      else setItemToOrder(locationDescriptor);
      setOpenOrderModal(true);
    }
  }

  useEffect(() => {
    if ((!divHeight || divHeight <= 0) && itemHeight?.current?.scrollHeight) {
      setDivHeight(itemHeight?.current?.scrollHeight);
    }
  }, [
    filteredIitems,
    history.location.pathname,
    itemHeight?.current?.scrollHeight,
  ]);

  return (
    <>
      <div
        className={`${styles.item_grid_wraper} ${disableItem && styles.item_disabled
          } ${itemInfoCard}`}
      >
        <div
          className={`px-0 pb-1 position-relative ${styles.item_grid_col}`}
          onClick={() => {
            if (addToComboClickHandler) {
              if (disableItem) {
                return;
              }
              addToComboClickHandler(item);
            } else {
              handleClick();
            }
          }}
        >
          <div className="px-3">
            <div
              className={`pt-4 pb-2 d-flex gap-2  align-items-start  ${styles.item_title} d-none d-md-flex`}
            >
              <div>
              <h3 className="f-s14 f-sm-s18 font-plex mb-0 clr-dark-gray text-center">
                  {itemTitle +
                    `${
                      isBoxLunch
                        ? item?.extra_amount === 0
                          ? ''
                          : ` ($${toFixedNumber(item?.extra_amount)})`
                        : ` ($${toFixedNumber(item.price)})`
                    }`}
                </h3>
              </div>
            </div>
            <div className={`d-md-none ${styles.item_title}`}>
              <div
                className={`d-flex justify-content-between align-items-center ${item.is_box_lunch ? 'pt-4' : 'pt-md-4'
                  }`}
              >
                <h3 className="f-s14 f-sm-s18 font-Visby-cf fw-bold mb-0 clr-dark-gray">
                  {itemTitle + ` ($${toFixedNumber(item.price)})`}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div>
          {errorButton ? (
            <p className="font-rale f-w5 text-center f-s16 f-sm-s14 clr-dark-red pb-4">
              This item is currently not available at this location. Please
              select a different item or location.
            </p>
          ) : (
            <div className={`pb-4 px-3 ${styles.item_btns}`}>
              {customizeButton && (
                <Button
                  className={`btn-large outline w-100 f-sm-s14 ${styles.select_btn} ${isBoxLunch && styles.customizeBtn}`}
                  onClick={() => {
                    handleCustomize(item);
                  }}
                  disabled={disableItem}
                >
                  {customizeButton}
                </Button>
              )}
              {!disableItem ?
                <>
                    {!hideCustomize && (
                    <Button
                      onClick={() => {
                        if (addToComboClickHandler) {
                          addToComboClickHandler(item);
                        } else {
                          handleClick(true);
                        }
                      }}
                      className={`w-100 wborder-0 f-sm-s14 ${
                        styles.select_btn
                      } ${isBoxLunch ? '' : styles.customizeBtn}`}
                      type="button"
                      disabled={disableItem}
                    >
                      {disableItem ? 'Unavailable' : selectButton}
                    </Button>
                  )}
                  {/* button added to match design will make it functional later */}
                  {!isBoxLunch ? (
                    <Button
                      onClick={() => {
                        if (addToComboClickHandler) {
                          addToComboClickHandler(item);
                        } else {
                          handleClick();
                        }
                      }}
                      className={`w-100 wborder-0 f-sm-s14 ${styles.select_btn}`}
                      type="button"
                      disabled={disableItem}
                    >
                      {disableItem ? 'Unavailable' : 'Order'}
                    </Button>
                  ) : null}
                </>
                : <Button
                  className={`w-100 wborder-0 f-sm-s14 ${styles.select_btn}`}
                  type="button"
                  disabled={disableItem}
                >
                  {'Unavailable'}
                </Button>
              }
            </div>
          )}
        </div>
      </div>
      <CustomModal
        showModal={openOrderModal}
        title={'Select Your Order Type'}
        closeModal={closeOpenOrderModal}
        modalDialogClasses={`${styles.itemOrderModal} itemOrderModal`}
        modalBodyClass={'overflow-visible'}
      >
        <ItemOrderModal
          itemPath={itemToOrder}
          closeOpenOrderModal={closeOpenOrderModal}
          routeTo={CONSTANT.ROUTE_START_CATERING_ORDER}
          initiator={initiator}
        />
      </CustomModal>
      <ItemDetailsModal
        showModal={showMore}
        closeModal={closeDetailsModal}
        itemDetials={itemDetails}
      />
    </>
  );
};

export default ItemGrid;

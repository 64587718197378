import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import CustomModal from 'src/components/customModal/CustomModal';
import RedeemDollarsModal from 'src/Features/Rewards/Redeem/redeemDollarsModal';
import { checkTheStepNo, getUser } from 'src/helper/helperMethods';

import * as CONSTANT from '../../../constants';
import { s3BaseUrl } from '../../../constants';
import useRedeemOffer from '../../../hooks/useRedeemOffer';
import { IOfferItem } from '../../../models/rewards.model';
import { useAppSelector } from '../../../redux/store/store';

import styleClasses from './offer.module.scss';
import OfferEligibilityModal from './OfferEligibilityModal';

interface IOfferItemCardProps {
  offerItem: IOfferItem;
  selectedOfferItem: IOfferItem;
  btnText: string;
  subTotal: number;
}

const OfferItemCard: React.FC<IOfferItemCardProps> = ({
  offerItem,
  selectedOfferItem,
  btnText,
  subTotal,
}) => {
  const wizardSteps = useAppSelector((state) => state.wizard);
  const isThisStep4 = checkTheStepNo(wizardSteps, CONSTANT.STEP4);

  const { name, in_cart } = offerItem;
  const [offerEligibilityModal, setOfferEligibilityModal] = useState<{
    show: boolean;
    failedOfferCriteria: string;
  }>({
    show: false,
    failedOfferCriteria: '',
  });
  const cartItems = useAppSelector((state) => state.cart.items);
  const { redeemOffer } = useRedeemOffer();
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const redeemOfferItem = () => {
    const { showModalOrCart, failedOfferCriteria } = redeemOffer({
      offerItem,
      cartItems,
      selectedOfferItem,
      subTotal,
      isOfferClicked: true,
    });
    if (
      showModalOrCart &&
      failedOfferCriteria === CONSTANT.OFFER_IN_STORE_REDEMPTION
    ) {
      setShowRedeemModal(true);
      return;
    }

    if (showModalOrCart) {
      setOfferEligibilityModal({
        show: true,
        failedOfferCriteria,
      });
    }
  };
  const { loyalty_user_id } = getUser();
  const closeShowRedeemModal = () => {
    setShowRedeemModal(false);
  };

  const offerCardPartation = (colMd, colStyle) => {
    return (
      <Col md={colMd} className={colStyle}>
        <div
          className={`${styleClasses.other_products_item} text-center ${
            btnText === 'Redeemed' && styleClasses.active
          }`}
        >
          <h5 className={`f-s14 lh-18 font-plex light-blue f-w4`}>{name}</h5>
          <button
            type="button"
            className={`f-sm-s12 ${styleClasses.itemSelectBtn} ${
              !in_cart && 'outline'
            } mb-2`}
            onClick={() => {
              redeemOfferItem();
            }}
          >
            {btnText}
          </button>
        </div>
      </Col>
    );
  };

  return (
    <>
      <OfferEligibilityModal
        show={offerEligibilityModal.show}
        offerFailedCriteria={offerEligibilityModal.failedOfferCriteria}
        offerDetails={offerItem.data}
        closeModal={() =>
          setOfferEligibilityModal({
            show: false,
            failedOfferCriteria: '',
          })
        }
      />
      {isThisStep4
        ? offerCardPartation('', styleClasses.summery_suggestion_column)
        : offerCardPartation('4', styleClasses.suggestion_column)}
      <CustomModal
        showModal={showRedeemModal}
        closeModal={closeShowRedeemModal}
        title={offerItem?.name}
        modalDialogClasses='defaultModalwrapper'
      >
        <RedeemDollarsModal
          header={''}
          title={offerItem?.expiration}
          description={'Scan this code at the counter to redeem your offer'}
          qrCodeValue={loyalty_user_id}
        />
        <div className="text-end me-4">
        <button onClick={closeShowRedeemModal} type="button" className=" mb-3 cancel-filled-btn text-uppercase">
          Cancel
        </button>
      </div>
      </CustomModal>
    </>
  );
};

export default OfferItemCard;

import { FC } from 'react';
import { Veg } from 'src/assets/images/Svgs/svg';
import styles from 'src/Features/Menu/menu.module.scss';
import { ItemDetails } from 'src/models/item.model';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import ItemGrid from '../ItemGrid/ItemGrid';

interface NavProps {
  item: ItemDetails;
}
const ItemInfoModal: FC<NavProps> = ({ item }) => {
  return (
    <>
      {item ? (
        <>
          <h3 className="f-s22 font-Cls text-center mb-0">Item Detail</h3>
          <ItemGrid
            favIcon={`${styles.fav_icon_fill}`}
            itemTitle={item.name}
            itemImage={awsAssetsFetcher(
              `Catering/Items/${item?.id}/item-${item?.id}`,
              'jpeg',
            )}
            itemDescription={item.description}
            symbolIcon={<Veg />}
            selectButton="select"
            errorButton={!item.is_active}
            item={item}
            filteredIitems={[]}
            itemInfoCard="shadow-none"
          />
        </>
      ) : undefined}
    </>
  );
};

export default ItemInfoModal;

import React from 'react';
// import styleClasses from "../../../components/ItemGrid/ItemGrid.module.scss";
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { s3BaseUrl } from '../../../constants';
import { Tag } from '../../../models/Menu.model';
import styles from './symbol.module.scss';

interface SymbolsProps {
  tags: Tag[];
}
const TagSymbol: React.FunctionComponent<SymbolsProps> = ({ tags }) => {
  // Function to render tooltip according to provided class name respective to symbol
  const renderTooltip = (tag, styleClass) => {
    return (
      <Tooltip className={styleClass} id="tooltip-vegeterian">
        {tag.name}
      </Tooltip>
    );
  };

  // Function to return icon and class name according to symbol name
  const getSymbolDetail = (tag: Tag) => {
    const iconMatch = tag.logo.slice(tag.logo.lastIndexOf('/') + 1);
    const tooltipClass = `tooltip-${iconMatch
      .split('-')[0]
      .toLocaleLowerCase()}`;
    return {
      icon: `${s3BaseUrl}/images/Menu/symbol-icons/${iconMatch}`,
      className: tooltipClass,
    };
  };

  return (
    <span className={styles.item_icon_wrap + ' d-flex gap-2 flex-wrap'}>
      {tags?.map((tag) => {
        const detail = getSymbolDetail(tag);
        if (!detail) return '';
        return (
          <OverlayTrigger
            key={tag.id}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(tag, detail.className)}
          >
            <Image src={detail.icon} className="tooltip-icon" />
          </OverlayTrigger>
        );
      })}
    </span>
  );
};

export default TagSymbol;

import { useMutation } from 'react-query';

import { IApplyOfferOnCart } from '../../models/order.model';
import { orderMicroService } from '../../services';

const applyOfferOnCart = async (payload: IApplyOfferOnCart) => {
  const response = await orderMicroService.applyOfferOnCart(payload);
  return response.data;
};

export default function useApplyOfferOnCart() {
  return useMutation((payload: IApplyOfferOnCart) => applyOfferOnCart(payload));
}

export const COMPLIMENTARY_MODIFIERS = 'complementary_modifiers';
export const ADDITIONALPRICE = 'additionalPrice';
export const ORIGINALPRICE = 'originalPrice';
export const REQUIRED_MODIFIERS = 'required_modifiers';
export const CORE_MODIFIERS = 'core_modifiers';
export const ITEM_AS_MODIFIERS = 'sub_item_required_modifiers';
export const ADD_ONS = 'add_ons';
export const BY_DEFAULT_ADDED = 'byDefaultAdded';
export const BY_DEFAULT_ADDED_V2 = 'byDefaultAddedV2';
export const ADDITIONAL_ADDED = 'additionalAdded';
export const ADDITIONAL_REMOVED = 'additionalRemoved';
export const MODIFIERS = 'modifiers';
export const CORE_RELATED = 'core_related';
export const PICK_UP_ORDER_TYPE = 'PICK_UP_ORDER';
export const DELIVERY_ORDER_TYPE = 'DELIVERY_ORDER';
export const SINGLE_ITEM_SELECTED_SECTION = 1;
export const SINGLE_ITEM_SELECTED_SECTION_WITH_COMLEMENTARY = 2;
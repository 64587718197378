import { useMutation } from 'react-query';

import { orderMicroService } from '../services';
import { getUser } from 'src/helper/helperMethods';

const exitEditOrderHelper = async (id: number) => {
  const user = getUser();
  if (!user.token) return;
  const response = await orderMicroService.exitEditOrder(id);
  return response.data.data;
};

export default function useExitEditOrder() {
  return useMutation((data: { id }) => exitEditOrderHelper(data.id));
}

import React, { useState } from 'react';

import * as CONSTANT from '../../../constants';
interface IItemModifiers {
  modifiers: any[];
  paragraphClassName?: string;
}

const ItemModifiers: React.FC<IItemModifiers> = ({
  modifiers,
  paragraphClassName,
}) => {
  const [showMore, setShowMore] = useState(false);
  if (modifiers.length === 0) return null;

  const handleModifierCode = (code: string, modifier: any) => {
    const quantity = modifier.quantity > 1 ? `(${modifier.quantity})` : '';
    switch (code) {
      case CONSTANT.ADD:
        return (
          <div className="d-flex justify-content-between w-100">
            <span className="ms-0  clr-dark-red f-s12 font-plex">{`${
              modifier.is_item ? '' : '+'
            } ${modifier?.modifier_name} ${quantity}`}</span>
            {modifier?.total_price > 0 && (
              <span className="ms-0  clr-dark-red f-s12 font-plex">
                {' '}
                {`$${modifier?.total_price.toFixed(2)}`}{' '}
              </span>
            )}
          </div>
        );
      case CONSTANT.NO:
        return (
          <>
            <span className="ms-0  clr-dark-red f-s12 font-plex">{`${
              modifier.is_item ? '' : '-'
            } ${modifier?.modifier_name} ${quantity}`}</span>
          </>
        );
      case CONSTANT.SUB:
        return (
          <div className="d-flex justify-content-between w-100">
            <div>
              <span className="ms-0  clr-dark-red f-s12 font-plex">{`${
                modifier.is_item ? '' : '+'
              } ${modifier?.substituted_modifier?.modifier_name}`}</span>
              <span className="ms-0  clr-dark-red f-s12 font-plex">{`${
                modifier.is_item ? '' : '-'
              }${modifier?.modifier_name}`}</span>
            </div>
            {modifier?.total_price > 0 && (
              <span className="ms-0  clr-dark-red f-s12 font-plex">
                {' '}
                {`$${modifier?.total_price.toFixed(2)}`}{' '}
              </span>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const handleShowMoreOrLess = () => {
    if (showMore) setShowMore(false);
    else setShowMore(true);
  };

  const sliceTill = showMore ? modifiers.length : 1;

  return (
    modifiers.length > 0 && (
      <div className={paragraphClassName ? paragraphClassName : ''}>
        {modifiers.slice(0, sliceTill).map((modifier: any, index: number) => {
          return (
            <div key={index}>
              {modifier.is_item &&
              modifier.code === CONSTANT.NO &&
              modifier.quantity === 0
                ? null
                : handleModifierCode(modifier.code, modifier)}
              {/* <br /> */}
            </div>
          );
        })}
        {modifiers.length > 1 && (
          <span
            className="cursor-pointer clr-dark-red font-plex f-s14"
            onClick={handleShowMoreOrLess}
          >
            {!showMore ? 'see more' : 'see less'}
          </span>
        )}
      </div>
    )
  );
};

export default ItemModifiers;

import { createSlice } from '@reduxjs/toolkit';

const initialStateValues = {
  futureOrder: {
    activeOrders: true,
  },
};

export const orderDetailSlice = createSlice({
  name: 'orderDetails',
  initialState: initialStateValues,
  reducers: {
    updateActiveFutureOrders: (state, action) => {
      state.futureOrder.activeOrders = action.payload;
    },
    resetOrderDetails: (state) => {
      state.futureOrder = {
        activeOrders: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetOrderDetails, updateActiveFutureOrders } =
  orderDetailSlice.actions;

export const orderDetailSliceReducer = orderDetailSlice.reducer;

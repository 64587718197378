import { FC, ReactElement, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import ButtonComponent from 'src/components/Button/Button';
import CustomModal from 'src/components/customModal/CustomModal';
import FormField from 'src/components/FormFields/FormField';
import Loader from 'src/components/Loader/Loader';
import { ROUTE_STEP_1, STEP3, STEP5 } from 'src/constants';
import { logout } from 'src/Features/AccountSetup/redux/actions';
import { resetLocation } from 'src/Features/Location/redux/slice';
import { checkTheStepNo } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import { saveDraftMapper } from 'src/helper/utils';
import useSaveDraft from 'src/react-query-hooks/useDraft';
import useProfile from 'src/react-query-hooks/useProfile';
import {
  resetCart,
  resetCartItems,
  resetCartOffer,
} from 'src/redux/slices/cartSlice';
import { resetCheckout } from 'src/redux/slices/checkoutSlice';
import { resetPrimaryCapacity } from 'src/redux/slices/handleStatesForSpecificFlows';
import {
  nextStep,
  onStepClick,
  previousStep,
  resetSteps,
} from 'src/redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import styles from './footer.module.scss';
import {
  removeBookingUserData,
  removeRecievingUserData,
  removeSearchQuery,
} from 'src/Features/AccountSetup/redux/slices';
import Button from 'src/components/Button/Button';
import useExitEditOrder from 'src/react-query-hooks/useExistEditOrder';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';

type Footer = {
  renderPlaceOrderButton?: () => ReactElement<any, any>;
  resetNoUserFound?: React.Dispatch<React.SetStateAction<boolean>>;
  validateRequiredSelection?: any;
  nextButtonProps?: {
    nextLoading?: boolean;
    nextButtonText?: string;
    nextButtonDisabled?: boolean;
  };
  disablePrev?: boolean;
};

const Footer: FC<Footer> = ({
  renderPlaceOrderButton,
  resetNoUserFound,
  validateRequiredSelection,
  nextButtonProps,
  disablePrev,
}) => {
  const { validate } = useValidateRequiredSelection(moveNext);
  const [draftModal, setDraftModal] = useState(false);
  const [draftName, setDraftName] = useState('');
  const { mutateAsync: saveAsDraft, isLoading } = useSaveDraft();
  const userDispatch = useAppDispatch();
  const [fromNewOrder, setFromNewOrder] = useState(false);

  const wizardSteps = useAppSelector((state) => state.wizard);

  const queryClient = useQueryClient();

  const { nextLoading, nextButtonText = 'Next' } = nextButtonProps || {};
  const history = useHistory();
  const isThisStep5 = checkTheStepNo(wizardSteps, STEP5);
  const { currentStep, activeStep, steps } = useAppSelector(
    (state) => state.wizard,
  );
  const { redirect } = useRedirectToSpecificStep();
  const dispatch = useAppDispatch();
  const handleNext = () => {
    if (validateRequiredSelection) {
      validateRequiredSelection();
    } else {
      validate();
    }
  };

  function moveNext() {
    dispatch(nextStep());
    history.push(steps[currentStep + 1]?.route);
  }

  const handlePrevious = () => {
    dispatch(previousStep());
    history.push(steps[currentStep - 1]?.route);
  };
  const [startNewOrderModal, setStartNewOrderModal] = useState<boolean>(false);
  const handleStartNewOrderModal = () => {
    setStartNewOrderModal(true);
  };
  const closeStartNewOrderModal = () => {
    setStartNewOrderModal(false);
  };
  const { mutateAsync: exitOrder, isLoading: isExitOrderLoading } =
    useExitEditOrder();

  const handleExitEditOrder = async () => {
    await exitOrder(
      { id: cart.orderId },
      {
        onSuccess: (data) => {
          dispatch(resetCart());
          Toast_Func({
            status: true,
            message: 'Closed edit order successfully',
          });
        },
        onError: (error) => {
          console.log(error);
          Toast_Func({
            status: false,
            message: 'An error occurred',
          });
        },
      },
    );
  };
  const startNewOrder = async () => {
    if (cart.is_edit_order) {
      await handleExitEditOrder();
    }

    dispatch(resetCartItems());
    dispatch(resetCartOffer());
    dispatch(resetCheckout());
    dispatch(resetLocation());
    dispatch(removeBookingUserData());
    dispatch(removeRecievingUserData());
    dispatch(resetPrimaryCapacity());
    dispatch(removeSearchQuery());
    resetNoUserFound && resetNoUserFound(false);
    dispatch(resetSteps());
    closeStartNewOrderModal();
    logout(userDispatch);
    queryClient.removeQueries();
    Toast_Func({ status: true, message: 'A new order has been initiated.' });
    history.push(ROUTE_STEP_1);
  };
  const { user: authInfo } = useAppSelector((state) => state.user);
  const user = authInfo.id;
  const { data } = useProfile(user, authInfo.type);
  const [quoteSentModal, setQuoteSentModal] = useState(false);
  const location = useAppSelector((state) => state.location);
  const wizard = useAppSelector((state) => state.wizard);
  const checkout = useAppSelector((state) => state.checkout);
  const { recievingUser } = useAppSelector((state) => state.user);

  const specificFlowState = useAppSelector((state) => state.specificFlowStates);

  const cart = useAppSelector((state) => state.cart);

  const saveDraftObject = saveDraftMapper({
    customer: data,
    receiver: recievingUser,
    location,
    cart,
    wizard,
    checkout,
    specificFlowState,
    user,
  });
  const onDraftHandler = async (draftObject) => {
    const response = await saveAsDraft(draftObject);
    if (response.id) {
      Toast_Func({ status: true, message: 'A Draft has been saved' });
      setDraftModal(false);
      setDraftName('');
      if (fromNewOrder) {
        setFromNewOrder(false);
        setStartNewOrderModal(true);
      }
      if (!cart.is_edit_order) {
        redirect(STEP3);
        dispatch(onStepClick({ stepId: STEP3 }));
      }
      return;
    } else {
      Toast_Func({
        status: false,
        message: 'An error occurred while saving information as draft',
      });
    }
  };
  return (
    <>
      <footer className={`${styles.footerWrap}`}>
        <div className={styles.footerContent}>
          <Container fluid className="px-4 py-3">
            <Row>
              <Col lg="6" md="6" className="d-flex">
                <button
                  type="button"
                  className="global-filled-btn f-w6 f-s14 font-plex text-uppercase"
                  onClick={handleStartNewOrderModal}
                >
                  Start A New Order
                </button>
              </Col>
              <Col lg="6" md="6">
                <div className="d-flex justify-content-end gap-3">
                  <button
                    type="button"
                    className={`solid-border-btn text-color f-w6 f-s14 font-plex text-uppercase ${
                      (location.selectedStore === '' ||
                        location?.selectedStore === null) &&
                      styles.disabledButton
                    }`}
                    onClick={() => setDraftModal(true)}
                    disabled={
                      location.selectedStore === '' ||
                      location?.selectedStore === null
                    }
                  >
                    save as draft
                  </button>
                  <button
                    type="button"
                    className={`solid-border-btn text-color f-w6 f-s14 font-plex text-uppercase ${
                      currentStep === 0 && styles.disabledButton
                    }`}
                    onClick={handlePrevious}
                    disabled={currentStep === 0 || disablePrev}
                  >
                    Previous
                  </button>
                  {isThisStep5 ? (
                    renderPlaceOrderButton?.()
                  ) : (
                    <ButtonComponent
                      type="button"
                      className={`global-filled-btn f-w6 f-s14 font-plex text-uppercase`}
                      onClick={handleNext}
                      disabled={currentStep === 4 || nextButtonProps?.nextButtonDisabled}
                      loading={nextLoading}
                    >
                      {nextButtonText}
                    </ButtonComponent>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      <CustomModal
        showModal={startNewOrderModal}
        title={'Start A New Order'}
        closeModal={closeStartNewOrderModal}
        modalDialogClasses={'defaultModalwrapper'}
        showClose={true}
        scrollable={false}
        titletextAlign={'text-start'}
        crossBtnIcom={'light-blue-icon'}
      >
        <div className="p-4">
          <h3 className="f-s18 light-blue font-plex f-w4">
            Are you sure you want to start new order?
          </h3>
          <div className={`pt-2 ${styles.redAlertBar}`}>
            <p className="mb-0 font-plex f-s14 f-w4 p-2">
              Any unsaved changes will be lost.
            </p>
          </div>
        </div>
        <hr className="my-0" />
        <div className="d-flex justify-content-end gap-3 px-4 py-3">
          <button
            onClick={closeStartNewOrderModal}
            className="cancel-filled-btn text-uppercase font-plex f-w5"
            type="button"
          >
            Cancel
          </button>
          <button
            type="button"
            className={`solid-border-btn text-color f-w5 font-plex f-s16 text-uppercase ${
              (location.selectedStore === '' ||
                location?.selectedStore === null) &&
              styles.disabledButton
            }`}
            onClick={() => {
              setDraftModal(true);
              setFromNewOrder(true);
              setStartNewOrderModal(false);
            }}
            disabled={
              location.selectedStore === '' || location?.selectedStore === null
            }
          >
            save as draft
          </button>
          <button
            className="global-filled-btn text-uppercase font-plex f-w5"
            type="button"
            onClick={startNewOrder}
            disabled={isExitOrderLoading}
          >
            Continue
          </button>
        </div>
      </CustomModal>
      <CustomModal
        showModal={draftModal}
        title={'Name your draft'}
        closeModal={() => {
          setDraftModal(false);
          if (fromNewOrder) {
            setStartNewOrderModal(true);
            setFromNewOrder(false);
          }
        }}
        modalDialogClasses={'defaultModalwrapper'}
      >
        <div className="border-bottom pt-2 pb-3 px-4">
          {/* <p className="f-s18 light-blue font-plex f-w4 mb-3">
                Please enter draft name
              </p> */}
          <div className="">
            <FormField
              name="draft_name"
              labelText="Draft Name"
              placeholder="Please enter draft name"
              type="text"
              onChange={(event) => setDraftName(event.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-3 px-4 py-3">
          <button
            className="cancel-filled-btn"
            type="button"
            onClick={() => {
              setDraftModal(false);
              if (fromNewOrder) {
                setStartNewOrderModal(true);
                setFromNewOrder(false);
              }
            }}
          >
            {' '}
            Cancel
          </button>
          <Button
            className={`global-filled-btn ${
              (location.selectedStore === '' ||
                location?.selectedStore === null) &&
              styles.disabledButton
            }`}
            type="button"
            disabled={
              !draftName.length ||
              location.selectedStore === '' ||
              location?.selectedStore === null
            }
            onClick={() => {
              saveDraftObject.draft_name = draftName;
              onDraftHandler(saveDraftObject);
            }}
            loading={isLoading}
          >
            Save as Draft
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

const ITEMS = 'items';
const requiredSelectionsForEachSteps = {
  3: [ITEMS],
};

const useValidateRequiredSelection = (moveNext: () => void) => {
  const { activeStep } = useAppSelector((state) => state.wizard);
  const requiredSelectionForActiveStep =
    requiredSelectionsForEachSteps[activeStep] || [];
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.items;
  const validate = () => {
    if (requiredSelectionForActiveStep.length === 0) {
      moveNext();
      return;
    }
    for (
      let index = 0;
      index < requiredSelectionForActiveStep.length;
      index++
    ) {
      const currentRequiredSelectionForActiveStep =
        requiredSelectionForActiveStep[index];
      switch (currentRequiredSelectionForActiveStep) {
        case ITEMS:
          if (cartItems.length <= 0) {
            Toast_Func({
              status: false,
              message: 'Cart is empty',
            });
          } else {
            moveNext();
          }
          break;
        default:
          moveNext();
          break;
      }
    }
  };

  return { validate };
};

export default Footer;

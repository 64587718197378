import { useQuery } from 'react-query';

import { IGetItemFromCartPayload } from '../../models/order.model';
import { orderMicroService } from '../../services';

export const getCartItem = async (payload: IGetItemFromCartPayload) => {
  const response = await orderMicroService.getCartItem({
    ...payload,
  });
  return response.data.data;
};

export default function useGetCartItem(payload: IGetItemFromCartPayload) {
  return useQuery('get-cart-item', () => getCartItem(payload), {
    // enabled: !!payload.id,
    retry: false,
    cacheTime: 0,
    initialData: undefined,
    staleTime: 0,
    enabled: !!(
      payload.id &&
      payload.location_id &&
      (payload.customer_id || payload.visitor_id)
    ),
  });
}

import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout/Layout';
import { brazeInititalize } from './helper/brazeHelper';
import { setVisitorId } from './helper/helperMethods';
import store, { persistor } from './redux/store/store';
import Routes from './routes/Routes/Routes';
import { PUBLISHABLEKEY } from './constants';

import './App.scss';

import 'react-toastify/dist/ReactToastify.css';

brazeInititalize();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query: any) => {
      const queryKey = query.queryKey[0];
      // logErrorMessage(queryKey)
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
declare module 'react-query/types/react/QueryClientProvider' {
  export interface QueryClientProviderProps {
    children?: React.ReactNode;
  }
}

function App() {
  const stripePromise = loadStripe(PUBLISHABLEKEY);
  setVisitorId();

  return (
    <Provider store={store}>
      <PersistGate
        loading={<>loading...</>}
        persistor={persistor}
      ></PersistGate>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            {/* <ErrorBoundary> */}
            <Elements stripe={stripePromise}>
              <Layout>
                <Routes />
              </Layout>
            </Elements>
            {/* </ErrorBoundary> */}
            <ToastContainer />
            <ReactQueryDevtools />
          </BrowserRouter>
        </QueryClientProvider>
      </div>
    </Provider>
  );
}

export default App;

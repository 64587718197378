import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import * as CONSTANT from 'src/constants';
import { isItCreateYourOwnItem,persistZero } from 'src/helper/helperMethods';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import {
  INoDressingModifier,
  ISubCategories,
  ItemDetails,
  ItemModal,
} from 'src/models/item.model';
import { ISelectedItem } from 'src/models/try2Combo';
import { itemBuckets } from 'src/priceCalculation/buckets';
import {
  removeTry2ComboIngredients,
  resetBoxLunchesSize,
  try2ComboCategorySelection,
} from 'src/redux/slices/itemCustomizationSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import ComboSection from './ComboSection/comboSection';
import Details from './ComboSection/details';
import BoxLunchesCustomization from './Items/boxLunchCustomization';
import Items from './Items/items';
import ItemsCustomization from './Items/itemsCustomization';
import styles from './boxlunches.module.scss';
type BoxLunchesTypes = {
  subCategories: ISubCategories[];
  togglePersonalize: () => void;
  sectionSelected: string;
  addItemtoCombo: (item: ItemDetails) => void;
  handleSyncIngredients: (item) => void;
  noDressingHandler: (modifier: INoDressingModifier, itemNo: string) => void;
  emptySelectedItemSelectedModifiers: () => void;
  handleClickCustomize: (item: ItemDetails) => void;
  hideCustomizationModalHandler: () => void;
  requiredModalShow: boolean;
  selectedItem: ItemDetails;
  ingredientsModal: boolean;
  toggleIngrediatsModal: (state: boolean) => void;
  handleIngredientSelection: (data, id) => void;
  isShowingRequiredModalOnCustomization: boolean;
  changeSelection: (section: string) => void;
  boxLunchCombo?: ItemModal[];
  findSelectedItemAvailability?: (item_id: number | string) => any;
  Item: (selected: ISelectedItem, id: string) => any;
  Category?: (selected: ISelectedItem, id: string) => any;
  itemDetails: boolean;
  setSectionSelected: (id, scroll?: boolean) => void;
  selectedItemCount: string;
  onRemoveClickHandler: (id: string) => void;
  apiItem: ItemDetails;
  showCustomization?: boolean;
  setSelectedItem: any;
  addToComboClickHandler: (item: ItemDetails) => void;
  DescriptionScrollRef?: React.MutableRefObject<HTMLDivElement>;
  flushMemory: () => void;
  isBothItemsSelected: () => boolean;
  isEdit: boolean;
  callRadioChange: boolean;
  setCallRadioChange: any;
  loadValueForModal: any;
  setLoadValueForModal: any;
  editIngredientModal: any;
  setEditIngredientModal: any;
  showIngredientsModalHandler: any;
  onEditClickHandler: any;
  personalize: any;
  editfromDetails: any;
  isCreateYourOwnItem: boolean;
};

const BoxLunches: React.FC<BoxLunchesTypes> = (props) => {
  const {
    subCategories,
    togglePersonalize,
    sectionSelected,
    addItemtoCombo,
    handleSyncIngredients,
    noDressingHandler,
    emptySelectedItemSelectedModifiers,
    handleClickCustomize,
    hideCustomizationModalHandler,
    requiredModalShow,
    selectedItem,
    ingredientsModal,
    toggleIngrediatsModal,
    handleIngredientSelection,
    isShowingRequiredModalOnCustomization,
    changeSelection,
    boxLunchCombo,
    findSelectedItemAvailability,
    Item,
    Category,
    itemDetails,
    setSectionSelected,
    selectedItemCount,
    onRemoveClickHandler,
    apiItem,
    showCustomization,
    setSelectedItem,
    addToComboClickHandler,
    DescriptionScrollRef,
    flushMemory,
    isBothItemsSelected,
    isEdit,
    callRadioChange,
    setCallRadioChange,
    loadValueForModal,
    setLoadValueForModal,
    editIngredientModal,
    setEditIngredientModal,
    showIngredientsModalHandler,
    onEditClickHandler,
    personalize,
    editfromDetails,
    isCreateYourOwnItem
  } = props;
  const [items, setItems] = useState(isEdit ? undefined : subCategories[0]);
  const [tabs, setTabs] = useState(true);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [firstTimeModal, setFirstTimeModal] = useState(false);
  const autoScrollDivRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  //samran start
  const order = useAppSelector((state: any) => state.itemCustomization);
  useEffect(() => {
    if (callRadioChange) {
      handleChangeTab(1);
      setCallRadioChange(false);
    }
  }, [callRadioChange]);
  const selectedOrder = () => {
    let result = [];
    order.modifiers.map((itemModifier) => {
      if (itemModifier.item === '1') {
        result = itemModifier;
      }
    });
    return result;
  };
  const findSelectedItem = () => {
    const id = loadValueForModal?.name;

    const orderItem = order?.modifiers?.filter((item) => item?.name === id);
    const category: any = apiItem?.sub_categories?.filter(
      (category) => category.id === parseInt(orderItem[0]?.category_id),
    );
    const a = category[0]?.items.filter((obj) => obj.id === orderItem[0].id);
    return a[0];
  };
  const selectedItems = () => {
    const result = [];
    order.modifiers.map((itemModifier) => {
      if (itemModifier.is_selected) {
        result.push(itemModifier);
      }
    });
    return result;
  };

  const selectedIngredients = (orderItem) => {
    return orderItem.modifiers;
  };
  /**
   * We'll use this hook after
   * @method {componentDidMount} and {componentWillUnMount}
   * @requires [This order must be followed]
   */

  /**
   * End
   */

  //Box Lunches Implementation

  //samran end
  const hideModifierModal = () => {
    setModalShow(false);
    if (firstTimeModal) {
      setFirstTimeModal(false);
    }
  };

  const selectedModifier: any = itemBuckets.getSingleBucket({
    name: CONSTANT.REQUIRED_MODIFIERS,
    fromItem: Number(sectionSelected),
  });

  const handleChooseDressing = () => {
    // setChooseDressing(true);
  };
  // useEffect(() => {
  //   if (isEdit) {
  //     setItems(undefined);
  //   }
  // }, []);

  const handleChangeTab = (id) => {
    const chanege = id - 1;
    if (!apiItem.is_single_item) {
      changeSelection(chanege.toString());
      dispatch(
        try2ComboCategorySelection({
          selected: { ...subCategories[id - 1] },
          sectionSelected: id,
        }),
      );
      setTabs(!tabs);
    } else {
      dispatch(
        try2ComboCategorySelection({
          selected: { ...subCategories[id - 1] },
          sectionSelected: id,
        }),
      );
    }
    setItems({ ...subCategories[id - 1] });
  };

  // const handleCustomize = (item: ItemDetails) => {
  //   setSelectedItem(item);
  //   setModalShow(true);
  // };
  const unrepeatedIngredients = selectedItem
    ? [
        ...selectedItem.core_modifiers,
        ...selectedItem.items_required_modifier_groups
          .map((group) => group.required_modifiers_groups_modifiers)
          .flat(),
      ]
    : [];
  const isItMobile = useCheckMobileScreen();
  const makeASelection = isItMobile
    ? CONSTANT.SELECT_AN_ITEM
    : CONSTANT.MAKE_A_SELECTION;

  const hideIngredientsModalHandler = () => {
    setEditIngredientModal(false);
    setLoadValueForModal({ load: false, name: null, id: null });
  };
  const addCustomizeItemtoComboClick = () => {
    //
  };
  const removeSelectedIngredients = (selectedCustomizeData, id) => {
    dispatch(
      removeTry2ComboIngredients({
        modifiers: selectedCustomizeData,
        sectionSelected: id,
      }),
    );
  };

  const goBack = () => {
    togglePersonalize();
    if (!isBothItemsSelected()) {
      flushMemory();
      dispatch(resetBoxLunchesSize());
    }

    // dispatch(
    //   resetTry2ComboItemContext({
    //     category_id: items?.category_id,
    //   }),
    // );
  };
  //  personalize,
  //     editfromDetails,
  return (
    <>
      <div className="custom_w">
        <div className={styles.boxWrap}>
          <Container fluid className="">
            {personalize && (
              <>
                <div
                  onClick={() => {
                    goBack();
                  }}
                  className="btn btn-custom btn-custom-transparent back-arrow-btn f-s14 px-0 mb-0 start-0 mt-3"
                >
                  Back
                </div>

                {/* {!apiItem.is_single_item && ( */}
                <>
                  <div className="row">
                    {!apiItem.is_single_item && (
                      <div className="col-lg-3 col-md-3 col-sm-0"></div>
                    )}
                    <div
                      className={`${styles.tryComboSelector} ${
                        apiItem.is_single_item
                          ? 'col-lg-12 col-md-12 col-sm-12 align-items-center'
                          : 'col-lg-7 col-md-7 col-sm-12'
                      } d-flex flex-column mt-4 mb-5 gap-4`}
                    >
                      <div className="d-flex gap-3">
                        {boxLunchCombo.map((section, index) => {
                          const selected = findSelectedItemAvailability(
                            section.id,
                          );
                          // Get selected Item
                          const item = Item(selected, section.id);
                          // Get selected category
                          const category = Category(selected, section.id);
                          // const isDisabled = item?.is_customizable !== undefined && !item?.is_customizable
                          // To bypass this check for CYOS, replace the upper condition with the commented one

                          const isDisabled =
                            item?.is_customizable !== undefined &&
                            !item?.is_customizable &&
                            !isItCreateYourOwnItem(item?.is_create_your_own);
                          return (
                            <ComboSection
                              id={section.id}
                              title={
                                isItMobile ? section.sm_title : section.title
                              }
                              onChange={(id, scroll) => {
                                handleChangeTab(id);
                                setSectionSelected(id, scroll);
                              }}
                              image={
                                item
                                  ? `${CONSTANT.s3BaseUrl}${item.image}?version=1`
                                  : ''
                              }
                              itemName={
                                category?.name ? item.name : makeASelection
                              }
                              addedItem={category?.name && item}
                              changeItemSize={itemDetails ? '' : 'f-s18'}
                              selectedItem={selectedItemCount}
                              isItemSelected={Boolean(item)}
                              sectionSelected={sectionSelected}
                              onRemoveClickHandler={onRemoveClickHandler}
                              onEditClickHandler={onEditClickHandler}
                              comingFromItem={CONSTANT.TRY_2_COMBO}
                              checked={section.id === sectionSelected}
                              textStart={''}
                              isCustomizeable={isDisabled}
                              handleChangeTab={handleChangeTab}
                            />
                          );
                        })}
                      </div>

                      <p className="p-18 text-start mb-0 f-sm-s14">
                        <span className="d-inline-block font-plex">
                          {apiItem.name} -{' '}
                          <strong
                            className={` ${
                              order?.display_price > 0 &&
                              'text-danger'
                            }`}
                          >
                            Total: $
                            {persistZero(order?.display_price + apiItem.price)}
                          </strong>
                        </span>
                      </p>
                    </div>
                    <hr className="mb-4 mb-md-10" />
                  </div>
                </>
                <Items
                  items={items}
                  styles={styles}
                  handleChooseDressing={handleChooseDressing}
                  handleCustomize={handleClickCustomize}
                  addToComboClickHandler={addToComboClickHandler}
                  isEdit={isEdit}
                />
                <ItemsCustomization
                  modalShow={modalShow}
                  setModalShow={setModalShow}
                  selectedItem={selectedItem}
                  styles={styles}
                  selectedModifier={selectedModifier}
                  unrepeatedIngredients={unrepeatedIngredients}
                  handleIngredientSelection={handleIngredientSelection}
                  hideModifierModal={hideModifierModal}
                  autoScrollDivRef={autoScrollDivRef}
                  selectedIngredients={selectedIngredients(selectedOrder())}
                  sectionSelected={sectionSelected}
                  addItemtoCombo={addItemtoCombo}
                  handleSyncIngredients={handleSyncIngredients}
                  noDressingHandler={noDressingHandler}
                  emptySelectedItemSelectedModifiers={
                    emptySelectedItemSelectedModifiers
                  }
                  hideCustomizationModalHandler={hideCustomizationModalHandler}
                  requiredModalShow={requiredModalShow}
                  ingredientsModal={ingredientsModal}
                  toggleIngrediatsModal={toggleIngrediatsModal}
                  isShowingRequiredModalOnCustomization={
                    isShowingRequiredModalOnCustomization
                  }
                  setSelectedItem={setSelectedItem}
                  showCustomization={showCustomization}
                  Item={apiItem}
                  isCreateYourOwnItem={isCreateYourOwnItem}
                />
              </>
            )}
            {personalize || editfromDetails ? (
              <>
                {loadValueForModal?.load && (
                  <BoxLunchesCustomization
                    showCustomizationModal={editIngredientModal}
                    closeCustomizationModal={hideIngredientsModalHandler}
                    modifierGroups={findSelectedItem()?.items_modifier_groups}
                    coreModifiers={findSelectedItem()?.core_modifiers}
                    requiredModifiers={
                      findSelectedItem()?.items_required_modifier_groups
                    }
                    handleIngredientSelection={(modifier) =>
                      handleIngredientSelection(modifier, loadValueForModal?.id)
                    }
                    itemName={findSelectedItem()?.name}
                    addItemtoComboClick={addCustomizeItemtoComboClick}
                    handleSyncIngredients={handleSyncIngredients}
                    selectedItem={findSelectedItem()}
                    sectionSelected={sectionSelected}
                    selectedIngredients={selectedIngredients(selectedOrder())}
                    removeSelectedIngredients={removeSelectedIngredients}
                    currentEditItem={loadValueForModal?.id}
                    noDressingHandler={noDressingHandler}
                    isItEdit={true}
                    styles={styles}
                    setSelectedItem={setSelectedItem}
                    Item={apiItem}
                    isCreateYourOwnItem={isCreateYourOwnItem}
                  />
                )}
              </>
            ) : undefined}
          </Container>
        </div>
      </div>
    </>
  );
};

export default BoxLunches;

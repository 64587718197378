import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import * as Constants from 'src/constants';

import { filterArraysBasedOnGroupId } from '../../helper/customizedItem/customizedItem.helper';
import {
  ICoreModifiers,
  IModifierGroups,
  IParams,
} from '../../models/item.model';
import {
  IBucketSelectedIngredients,
  IOptionalModifier,
} from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';
import { ADDITIONAL_ADDED } from '../../priceCalculation/constants';

import CoreModifiers from './ingredients/CoreModifiers';
import ModifierGroups from './ingredients/ModifierGroups';
import RequiredModifier from './ingredients/RequiredModifier';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
const AddBoxLunchIngredients: React.FC<IOptionalModifier> = (props) => {
  const {
    coreModifiers,
    modifierGroups,
    requiredModifiers,
    handleIngredientSelection,
    sectionSelected,
    noDressingHandler,
    itemName,
    isItEdit,
    Item,
    isCYOIColSize,
    isCreateYourOwnItem,
  } = props;

  const {
    refToScrollToMissingRequiredAreaForCYOI,
    isCYOIRequiredModifierSelected,
    setIsCYOIRequiredModifierSelected,
  } = useScrollToMissingRequiredArea(requiredModifiers);

  const CYOIScrollToMissingRequiredArea = {
    refToScrollToMissingRequiredArea: refToScrollToMissingRequiredAreaForCYOI,
    isCYOIRequiredModifierSelected,
    setIsCYOIRequiredModifierSelected,
  };

  const {
    missingRequiredRef,
    modifierGroupScrolling,
    scrollToNextGroupOnExtendableSelection,
  } = useScrolling(
    requiredModifiers,
    modifierGroups,
    CYOIScrollToMissingRequiredArea
  );

  const handleCoreModifierClick = (
    data: IParams,
    modifier: ICoreModifiers,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifier?.modifier_group_id,
        modifier_type: type,
      },
    });
  };

  const handleClick = (
    data: IParams,
    modifierGroup: IModifierGroups,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifierGroup?.id,
        modifier_group_max: modifierGroup?.max,
        modifier_group_base: modifierGroup?.base,
        modifier_type: type,
      },
    });
  };

  const selectedAddOns = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.ADD_ONS,
      sectionSelected,
    );
  })();

  const selectedCore: IBucketSelectedIngredients[] = (function () {
    const result = itemBuckets.specificItemBucketSelectedModifiers(
      Constants.CORE_MODIFIERS,
      sectionSelected,
    );
    return result;
  })();

  const selectedAddedAddOns: IBucketSelectedIngredients[] = (function () {
    const fromItem: 0 | 1 = (parseInt(sectionSelected) - 1) as 0 | 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.ADD_ONS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const coreModifierBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: Constants.CORE_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
  })();

  const isItCoreRelated = (groupId: number) => {
    if (coreModifierBucket?.memoryChip) {
      const isExist = coreModifierBucket['memoryChip'][groupId];
      if (isExist === undefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const isLimitExceed = (
    groupId: number,
    extendable_limit: number,
    groupMin,
  ) => {
    if (extendable_limit === 1 && groupMin === 1) {
      return false;
    } else {
      if (!isItCoreRelated(groupId)) {
        const selectedModifierForCurrentGroup: IBucketSelectedIngredients[] =
          filterArraysBasedOnGroupId([selectedAddedAddOns], groupId);
        return selectedModifierForCurrentGroup.length >= extendable_limit
          ? true
          : false;
      } else {
        if (coreModifierBucket['memoryChip'][groupId]?.changes) {
          return coreModifierBucket['memoryChip'][groupId]?.changes?.quantity >=
            extendable_limit
            ? true
            : false;
        } else {
          return coreModifierBucket['memoryChip'][groupId]?.quantity >=
            extendable_limit
            ? true
            : false;
        }
      }
    }
  };

  const handleAddOnsModifierClickHandler = (
    currentModifier: IParams,
    modifiers: IModifierGroups,
    type: string
  ) => {
    handleClick(currentModifier, modifiers, type);
    if (
      isCreateYourOwnItem &&
      (currentModifier.type === Constants.INCREASE ||
        currentModifier.type === Constants.SELECTED)
    ) {
      scrollToNextGroupOnExtendableSelection(currentModifier);
    }
  };

  const handleRequiredModifierClickHandler = (currentModifier) => {
    handleIngredientSelection(currentModifier);
    if (
      isCreateYourOwnItem &&
      (currentModifier.type === Constants.INCREASE ||
        currentModifier.type === Constants.SELECTED)
    ) {
      scrollToNextGroupOnExtendableSelection(currentModifier);
    }
  };


  return (
    <div className="other-dressing-view try2_dressing_modal box-lunches-modal-inner">
      {/* <Scrollbars className="Modal_inner_scrollbar customization_inner_scrollbar apply-btn-view"> */}
      {coreModifiers?.length ? (
        <CoreModifiers
          coreModifiers={coreModifiers}
          selectedCore={selectedCore}
          handleCoreModifierClick={handleCoreModifierClick}
          isLimitExceed={isLimitExceed}
          Item={Item}
        />
      ) : null}
      {requiredModifiers?.length ? (
        <RequiredModifier
          sectionSelected={sectionSelected}
          itemIngredients={requiredModifiers}
          handleIngredientSelection={handleRequiredModifierClickHandler}
          noDressingHandler={noDressingHandler}
          showMaxSelection={isCreateYourOwnItem}
          // customizationModule={true}
          showAdditionalPrice={true}
          Item={Item}
          isCYOIColSize={isCYOIColSize}
          CYOIScrollToMissingRequiredArea={CYOIScrollToMissingRequiredArea}
          isCreateYourOwnItem={isCreateYourOwnItem}
          missingRequiredRef={missingRequiredRef}
        />
      ) : null}
      {modifierGroups?.length ? (
        <ModifierGroups
          modifierGroups={modifierGroups}
          selectedAddOns={selectedAddOns}
          selectedCore={selectedCore}
          handleClick={handleAddOnsModifierClickHandler}
          isLimitExceed={isLimitExceed}
          isItEdit={isItEdit}
          isBoxLunch={true}
          Item={Item}
          isCreateYourOwnItem={isCreateYourOwnItem}
          modifierGroupScrolling={modifierGroupScrolling}
        />
      ) : null}
      {/* </Scrollbars> */}
      {/* <hr className="d-md-none" /> */}
    </div>
  );
};

const useScrollToMissingRequiredArea = (requiredModifiers) => {
  const [
    requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
    setRequiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
  ] = useState([]);

  const [isCYOIRequiredModifierSelected, setIsCYOIRequiredModifierSelected] =
    useState({ status: false, highLightArea: null });
  useEffect(() => {
    if (requiredModifiers) {
      assignRefForScroll({
        requiredSection: requiredModifiers,
        refState: requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
        setRefState:
          setRequiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
      });
    }
  }, [requiredModifiers]);

  function assignRefForScroll({ requiredSection, refState, setRefState }) {
    const divRefsNew = refState;
    divRefsNew.length = requiredSection?.length;
    // Initialize the refs
    for (let i = 0; i < divRefsNew.length; i++) {
      divRefsNew[i] = divRefsNew[i] || React.createRef();
    }
    setRefState(divRefsNew);
  }

  return {
    refToScrollToMissingRequiredAreaForCYOI:
      requiredModifiersRefsForScrollToMissingSelectedGroupInCYOI,
    isCYOIRequiredModifierSelected,
    setIsCYOIRequiredModifierSelected,
  };
};

const useScrolling = (
  requiredModifiers,
  modifierGroups,
  CYOIScrollToMissingRequiredArea
) => {
  const isItMobile = useCheckMobileScreen();

  const missingRequiredRef: MutableRefObject<{
    valForAccordian: string[];
    valForSearch: { [key: number]: boolean };
  }> = useRef({ valForAccordian: ['0'], valForSearch: {} });

  const modifierGroupScrolling: MutableRefObject<{
    valForAccordian: string[];
    valForSearch: { [key: number]: boolean };
    currentMissingIndex: number;
    refsToCYOIAddOnsModifier: any;
  }> = useRef({
    valForAccordian: [],
    valForSearch: {},
    currentMissingIndex: null,
    refsToCYOIAddOnsModifier: [],
  });

  const scrollToNextGroupOnExtendableSelection = (currentModifier) => {
    const selectedRequiredModifier = itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: Constants.SINGLE_ITEM_SELECTED_SECTION,
    });
    const selectedAddOnsModifier = itemBuckets.getSingleBucket({
      name: Constants.ADD_ONS,
      fromItem: Constants.SINGLE_ITEM_SELECTED_SECTION,
    });
    const selectedModifier = [
      ...selectedRequiredModifier.modifiers.concat(
        selectedAddOnsModifier.modifiers
      ),
    ];
    const itemIngredients = [...requiredModifiers.concat(modifierGroups)];

    const commulativeItems = [];
    let missingGroupIndex = null;
    selectedModifier?.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.modifier_group_id
      );
      if (index !== -1) {
        commulativeItems[index].quantity =
          commulativeItems[index].quantity + element?.quantity;
        commulativeItems[index].noDressing = false;
      } else {
        const trackObj = {
          groupId: element?.modifier_group_id,
          groupMax: element?.modifier_group_max,
          groupMin: element?.modifier_group_min,
          extendableLimitValue: element?.extendableLimitValue,
          quantity: element?.quantity,
          noDressing: false,
        };
        commulativeItems.push(trackObj);
      }
    });
    const data = commulativeItems.find(
      (item) => item.groupId === currentModifier.modifier_group_id
    );
    const startScrollingFunctionality =
      data?.extendableLimitValue === data?.quantity;
    if (!startScrollingFunctionality) {
      return;
    }

    let isMinExeceeded = null;
    let limitForCompare: { type: 'min' | 'extendable_limit'; value: number } = {
      type: null,
      value: null,
    };
    const currentModifierGroupIndex = itemIngredients.findIndex(
      (itemIngredient) =>
        itemIngredient.id === currentModifier.modifier_group_id
    );
    for (let i = 0; i < itemIngredients.length; i++) {
      const itemIndex = commulativeItems.findIndex(
        (e) => e.groupId === itemIngredients[i].id
      );
      missingGroupIndex = i;
      limitForCompare = { type: null, value: null };
      let minReached: boolean = false;
      if (i < currentModifierGroupIndex) {
        limitForCompare.value = itemIngredients[i].min;
        limitForCompare.type = 'min';
      } else {
        limitForCompare.value = itemIngredients[i].extendable_limit;
        limitForCompare.type = 'extendable_limit';
      }
      if (commulativeItems[itemIndex]?.noDressing === true) {
        minReached = true;
      }
      if (
        (commulativeItems[itemIndex]?.noDressing === false &&
          commulativeItems[itemIndex]?.quantity > 0 &&
          commulativeItems[itemIndex]?.quantity >= limitForCompare.value) ||
        limitForCompare.value === 0
      ) {
        minReached = true;
      }

      if ((itemIndex === -1 || !minReached) && limitForCompare.value != 0) {
        isMinExeceeded = false;
        break;
      } else {
        isMinExeceeded = true;
      }
    }
    if (isMinExeceeded) {
      return { status: false };
    } else {
      const requiredModifiersIndexLength = requiredModifiers.length;
      let startScrollingFor: 'required' | 'addons' = 'required';
      if (missingGroupIndex >= requiredModifiersIndexLength) {
        missingGroupIndex = missingGroupIndex - requiredModifiersIndexLength;
        startScrollingFor = 'addons';
      }
      console.log({
        status: true,
        missingGroupIndex: missingGroupIndex,
      });
      handleScrolling({
        status: true,
        missingGroupIndex: missingGroupIndex,
        startScrollingFor,
        limitForCompareType: limitForCompare.type,
      });
    }
  };

  function handleScrolling(data: {
    status: boolean;
    missingGroupIndex: number;
    startScrollingFor: 'required' | 'addons';
    limitForCompareType: 'min' | 'extendable_limit';
  }) {
    let scrollTimeoutId;
    clearTimeout(scrollTimeoutId);
    if (!data.status) return;
    if (data.startScrollingFor === 'addons') {
      modifierGroupScrolling.current.valForAccordian.push(
        `${data.missingGroupIndex}`
      );
      modifierGroupScrolling.current.valForSearch[data.missingGroupIndex] =
        true;
      modifierGroupScrolling.current.currentMissingIndex =
        data.missingGroupIndex;
      scrollTimeoutId = setTimeout(() => {
        const modalContainer = document.getElementById(
          'modal_handle_for_byo_items'
        ); // Replace with the actual modal content class or ref
        if (modalContainer) {
          modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
            data.missingGroupIndex
          ]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });

          // Ensure modal itself scrolls to the top
          modalContainer.scrollTop =
            modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
              data.missingGroupIndex
            ].offsetTop;
        } else {
          modifierGroupScrolling.current.refsToCYOIAddOnsModifier[
            data.missingGroupIndex
          ]?.scrollIntoView({
            behavior: 'smooth',
            block: `${isItMobile ? 'start' : 'center'}`,
          });
        }
      }, 400);
      return;
    }
    const {
      refToScrollToMissingRequiredArea,
      setIsCYOIRequiredModifierSelected,
    } = CYOIScrollToMissingRequiredArea;
    if (data.limitForCompareType === 'min') {
      setIsCYOIRequiredModifierSelected({
        status: data.status,
        highLightArea: data.missingGroupIndex,
      });
      let animatedOnScrollId;
      clearTimeout(animatedOnScrollId);
      animatedOnScrollId = setTimeout(() => {
        setIsCYOIRequiredModifierSelected({
          status: false,
          highLightArea: null,
        });
      }, 3000);
    }
    for (let i = 0; i < refToScrollToMissingRequiredArea.length; i++) {
      if (i === data.missingGroupIndex) {
        if (!missingRequiredRef.current.valForSearch[data.missingGroupIndex]) {
          missingRequiredRef.current.valForAccordian.push(
            `${data.missingGroupIndex}`
          );
          missingRequiredRef.current.valForSearch[data.missingGroupIndex] =
            true;
        }
        scrollTimeoutId = setTimeout(() => {
          const modalContainer = document.getElementById(
            'modal_handle_for_byo_items'
          );
          if (modalContainer) {
            refToScrollToMissingRequiredArea[i].current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });

            // Ensure modal itself scrolls to the top
            modalContainer.scrollTop =
              refToScrollToMissingRequiredArea[i].current.offsetTop;
          } else {
            refToScrollToMissingRequiredArea[i].current?.scrollIntoView({
              behavior: 'smooth',
              block: `${isItMobile ? 'start' : 'center'}`,
            });
          }
        }, 400);
        break;
      }
    }
  }

  return {
    missingRequiredRef,
    modifierGroupScrolling,
    scrollToNextGroupOnExtendableSelection,
  };
};
export default AddBoxLunchIngredients;

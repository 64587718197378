import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import {
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  ROUTE_START_CATERING_ORDER,
} from 'src/constants';
import {
  setLocation,
  unSetIsDeliveryToggle,
} from 'src/Features/Location/redux/slice';
import * as CONSTANT from 'src/constants';
import { handleIsLiveLocationRedirection } from 'src/helper/checkout/LocationHelper';
import {
  getVisitorId,
  isGuestUser,
  validateCount,
} from 'src/helper/helperMethods';
import { ISelectedAddress } from 'src/models/forms.model';
import { IItemPathToRoute } from 'src/models/item.model';
import {
  INearestLocationQueryParams,
  LocationModel,
} from 'src/models/location.model';
import { IGetCartPayload } from 'src/models/order.model';
import useProfile from 'src/react-query-hooks/useProfile';
import {
  clearCartOfferAuxData,
  resetCartOffer,
  updateCartRedeemedOfferItem,
  updateIsOrderTypeSelected,
  updateOrderDetails,
  updateOrderType,
} from 'src/redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { locationMenuService } from 'src/services';
import { selectDeliveryAddressSchema } from 'src/validationSchemas/selectDeliveryAddressSchema';

import Button from '../Button/Button';
import FormField from '../FormFields/FormField';

import styles from './orderType.module.scss';
import VisitorDeliveryForm from './VisitorDeliveryForm';
import { useDispatch } from 'react-redux';
import { resetNextSteps } from 'src/redux/slices/wizardSlice';
import useGetOffers from 'src/react-query-hooks/Loyalty/useGetOffers';
import { IOfferItem } from 'src/models/rewards.model';
import { roundToTwo } from 'src/priceCalculation/helper';
import useRedeemOffer from 'src/hooks/useRedeemOffer';
import useSyncCart from 'src/hooks/useSyncCart';
import { Toast_Func } from 'src/helper/toast.helper';
import useApplyOfferOnCart from 'src/react-query-hooks/Cart/useApplyOfferOnCart';

interface IItemDelivery {
  itemPath: IItemPathToRoute;
  shownDeilveryLocation: (isShown) => void;
  closeOpenOrderModal: () => void;
  nearestLocation: (isNearest) => void;
  isNearestStoreAvailable: boolean;
  showItemDeliveryDateTime?: boolean;
  handleShowDeliveryMethodModal?: (boolean) => void;
  saveSelectedOrderType?: string;
  handleSavedOrderType?: () => void;
  handleOrderType?: (addressType: string) => void;
  isItMobile: boolean;
  isCheckout: boolean;
  routeTo?: string;
  initiator?: string;
  setLocations: any;
  setLatLongs?: (val: any) => void;
  deliveryLocationProps?: any;
  editInModal?: boolean;
  showAddNewAddress: boolean;
  setShowAddNewAddress: any;
  isPickup?: boolean;
  isDeliveryToggleSearch?: boolean;
  setDeliveryToggleButton?: (boolean) => void;
  isFound?: boolean;
  setIsFound?: (boolean) => void;
  setDisableToggle?: any;
}
function ItemDelivery({
  shownDeilveryLocation,
  closeOpenOrderModal,
  nearestLocation,
  isNearestStoreAvailable,
  showItemDeliveryDateTime,
  handleShowDeliveryMethodModal,
  saveSelectedOrderType,
  // handleSavedOrderType,
  handleOrderType,
  isItMobile,
  isCheckout,
  itemPath,
  initiator,
  setLocations,
  deliveryLocationProps,
  setLatLongs,
  editInModal = false,
  showAddNewAddress,
  setShowAddNewAddress,
  isPickup,
  isDeliveryToggleSearch,
  setDeliveryToggleButton,
  isFound,
  setIsFound,
  setDisableToggle,
}: IItemDelivery) {
  const dispatch = useDispatch();

  const { isFindingNearest, setIsFindingNearest } = deliveryLocationProps;
  const [runOnSubmit, setRunOnSubmit] = useState(false);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = useAppSelector((state) => state.cart.items);
  const { syncCart, loading: syncingCart } = useSyncCart();
  const cartDispatch = useAppDispatch();

  // useEffect(() => {

  // },[])
  const queryClient = useQueryClient();
  const orderTypeDispatch = useAppDispatch();
  const locationDispatch = useAppDispatch();
  const isPrimaryCapacityFormFilled = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity.formFilled,
  );
  const deliveryAddress = useAppSelector(
    (state) => state.cart?.orderDetails?.delivery_address || '',
  );
  const locationData = useAppSelector(
    (state) => state.location.selectedStore || '',
  );
  const { steps, activeStep, lastActiveStep } = useAppSelector(
    (state) => state.wizard,
  );
  const {
    refetch: refetchOffers,
    isRefetching: isRefetchingOffers,
  } = useGetOffers(false, 'CartModal');
  const history = useHistory();
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { reVerifyCartItemsOfferEligibility, validateOffers, redeemOffer } = useRedeemOffer();
  const wizardSteps = useAppSelector((state) => state.wizard);
  const { currentStep } = wizardSteps;

  const selectedDeliveryAddress = useAppSelector((state) =>
    Object.keys(state.cart?.orderDetails?.delivery_address || {})?.length > 0
      ? state.cart?.orderDetails?.delivery_address
      : '',
  );
  const cartData = useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.user);
  const [newAddedAddressForDelivery, setNewAddedAddressForDelivery] = useState(
    selectedDeliveryAddress
      ? {
          value: selectedDeliveryAddress.address_name,
          label: selectedDeliveryAddress.address_name,
          id: selectedDeliveryAddress.id,
          isDefault: selectedDeliveryAddress.is_default,
          street: selectedDeliveryAddress.street_name,
          ...selectedDeliveryAddress,
        }
      : null,
  );
  const operatorDetail = useAppSelector((state) => state.user.operator);

  const OPManager =
    operatorDetail?.role === 'operating partners' ||
    operatorDetail?.role === 'manager';
  useEffect(() => {
    if (!selectedDeliveryAddress) {
      setNewAddedAddressForDelivery(null);
    }
  }, [selectedDeliveryAddress]);
  const showNewAddressForm = () => {
    if (selectedDeliveryAddress) return !selectedDeliveryAddress.id;
    else return isGuestUser(user);
  };
  const visitor_id = getVisitorId();
  const {
    isLoading,
    isFetching,
    data: userProfileData,
    error,
  } = useProfile(user.id, user.type);

  useEffect(() => {
    if (
      isNearestStoreAvailable &&
      !isFindingNearest &&
      runOnSubmit &&
      !editInModal
    ) {
      handleAutoFormSubmission();
      setRunOnSubmit(false);
      if (setIsFound) setIsFound(true);
    }
  }, [isNearestStoreAvailable, isFindingNearest, runOnSubmit]);

  useEffect(() => {
    if (
      !isDeliveryToggleSearch &&
      selectedAddress &&
      saveSelectedOrderType === CONSTANT.DELIVERY_ORDER_TYPE &&
      !isFound
    ) {
      handleAutoFormSubmission();
    }
  }, [isDeliveryToggleSearch]);
  useEffect(() => {
    setDisableToggle?.(isRefetchingOffers);
    setIsFindingNearest?.(isRefetchingOffers)
  }, [isRefetchingOffers]);
  useEffect(() => {
    if (
      !editInModal &&
      selectedAddress &&
      saveSelectedOrderType === CONSTANT.DELIVERY_ORDER_TYPE &&
      !deliveryAddress.full_address &&
      !isPickup &&
      !isDeliveryToggleSearch
    ) {
      handleAutoFormSubmission();
      return;
    } else if (deliveryAddress.full_address && !isPickup) {
      setLatLongs?.([
        {
          id: locationData.id,
          latitude: locationData.lat,
          longitude: locationData.long,
          name: locationData.name,
          data: locationData,
        },
      ]);
      setLocations([locationData]);
      nearestLocation(true);
      setIsFindingNearest(false);
      return;
    }
  }, [saveSelectedOrderType, selectedAddress]);

  const checkNearestStoreLocation = async (address) => {
    setIsFindingNearest(true);
    if (!address) {
      setIsFindingNearest(false);
      closeOpenOrderModal();
      return;
    }
    const payload: INearestLocationQueryParams = {
      delivery: true,
      city: address.city,
      state: address.state,
      street: address.street,
      zipcode: address.zipcode,
      delivery_address:
        user.type === GUEST_USER ? address?.fullAddress : address?.full_address,
    };
    if (isCheckout) payload.is_checkout = 1;
    const response = await locationMenuService.getNearbyLocations(payload);
    if (response?.data?.data[0] && !OPManager) {
      const array = [];
      response.data.data &&
        response.data.data.map(function (data) {
          if (
            typeof data.lat !== 'undefined' &&
            data.lat !== null &&
            typeof data.long !== 'undefined' &&
            data.long !== null
          ) {
            array.push({
              id: data.id,
              latitude: data.lat,
              longitude: data.long,
              name: data.name,
              data: data,
            });
          }
        });
      setLatLongs?.(array);
      setLocations([response.data.data[0]]);
      nearestLocation(true);
      // await refetchOffers();
      setIsFindingNearest(false);
      setDisableToggle?.(false);
      return response.data.data[0];
    } else if (response?.data?.data[0] && OPManager) {
      const locationExist = operatorDetail.locations.find(
        (obj) => response?.data?.data[0]?.id === obj?.id,
      );
      if (locationExist?.id) {
        const array = [];
        response.data.data &&
          response.data.data.map(function (data) {
            if (
              typeof data.lat !== 'undefined' &&
              data.lat !== null &&
              typeof data.long !== 'undefined' &&
              data.long !== null
            ) {
              array.push({
                id: data.id,
                latitude: data.lat,
                longitude: data.long,
                name: data.name,
                data: data,
              });
            }
          });
        setLatLongs?.(array);
        setLocations([response.data.data[0]]);
        // await refetchOffers();
        nearestLocation(true);
        setIsFindingNearest(false);
        setDisableToggle?.(false);
        return response.data.data[0];
      } else {
        setLocations([]);
        nearestLocation(false);
        setIsFindingNearest(false);
        closeNewAddressForm();
        setDisableToggle?.(true);
        setDeliveryToggleButton?.(false);
        return null;
      }
    } else {
      setLocations([]);
      nearestLocation(false);
      setIsFindingNearest(false);
      setDisableToggle?.(true);
      setDeliveryToggleButton?.(false);
      closeNewAddressForm();
      return null;
    }
  };

  const closeNewAddressForm = () => {
    shownDeilveryLocation(false);
    setShowAddNewAddress?.(false);
  };
  const setAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleLocation = async (nearestLoc, deliveryAddress) => {
    if (!nearestLoc?.is_live) {
      handleIsLiveLocationRedirection(
        saveSelectedOrderType,
        nearestLoc,
        isItMobile,
      );
    } else {
      if (cartData.orderType === CONSTANT.DELIVERY_ORDER_TYPE) {
        orderTypeDispatch(
          updateOrderDetails({
            delivery_address: deliveryAddress,
            ...(cartData.orderDetails?.PickupSlotDetails && {
              PickupSlotDetails: cartData.orderDetails.PickupSlotDetails,
            }),
          }),
        );
      } else {
        orderTypeDispatch(
          updateOrderDetails({
            delivery_address: deliveryAddress,
            ...(cartData.orderDetails?.PickupSlotDetails && {
              PickupSlotDetails: {
                ...cartData.orderDetails?.PickupSlotDetails,
                pickupMethod: CONSTANT.DELIVERY_ORDER_TYPE,
              },
            }),
          }),
        );
      }
      dispatch(resetNextSteps());
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      locationDispatch(setLocation({ currentLocation: nearestLoc }));
      const response = await refetchOffers();
      validateOffers(nearestLoc.id, CONSTANT.DELIVERY_ORDER_TYPE, response?.data);
      locationDispatch(unSetIsDeliveryToggle());
      setDeliveryToggleButton?.(false);
      const payload: IGetCartPayload = {
        location_id: nearestLoc.id.toString(),
      };
      if (user.id) payload.customer_id = user.id;
      else payload.visitor_id = visitor_id;
      queryClient.refetchQueries(['get-cart', nearestLoc.id]);
      closeOpenOrderModal && closeOpenOrderModal();
      if (saveSelectedOrderType) {
        orderTypeDispatch(updateOrderType(saveSelectedOrderType));
      }
      if (
        showItemDeliveryDateTime &&
        !cartData.orderDetails?.PickupSlotDetails?.time
      ) {
        handleShowDeliveryMethodModal(true);
      }
    }
  };

  const handleAutoFormSubmission = async () => {
    const response = await checkNearestStoreLocation(selectedAddress);
    if (response) {
      if (!response.is_live) {
        handleIsLiveLocationRedirection(
          saveSelectedOrderType,
          response,
          isItMobile,
        );
      } else {
        handleLocation(response, selectedAddress);
      }
    }
  };

  const handleFormSubmission = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await checkNearestStoreLocation(selectedAddress);
    if (!response) {
      setSubmitting(false);
    } else {
      if (response && !response.is_live) {
        handleIsLiveLocationRedirection(
          saveSelectedOrderType,
          response,
          isItMobile,
        );
      } else {
        handleLocation(response, selectedAddress);
        setSubmitting(false);
      }
    }
  };

  const selectedDelieveryAddress = (allAddressArray: any) => {
    let address = newAddedAddressForDelivery;

    if (newAddedAddressForDelivery) {
      address = allAddressArray?.find(
        (address) => address?.id === newAddedAddressForDelivery?.id,
      );
    }
    return address;
  };

  useEffect(() => {
    if (!isGuestUser(user)) {
      if (!isFetching && userProfileData?.addresses?.length > 0) {
        const allAddressArray = userProfileData.addresses.map((address) => ({
          value: address.address_name,
          label: address.address_name,
          id: address.id,
          isDefault: address.is_default,
          street: address.street_name,
          ...address,
        }));
        setUserAddresses(allAddressArray);
        setSelectedAddress(
          newAddedAddressForDelivery
            ? selectedDelieveryAddress(allAddressArray)
            : allAddressArray.find((address) => address.isDefault == 1),
        );
      }
    } else if (newAddedAddressForDelivery)
      setSelectedAddress(newAddedAddressForDelivery);
  }, [userProfileData, newAddedAddressForDelivery]);

  const initialFormState: ISelectedAddress = {
    selected_address: newAddedAddressForDelivery
      ? newAddedAddressForDelivery
      : userAddresses.find((address) => address.isDefault == 1),
  };

  return (
    <div>
      <div className=" gap-24">
        {!showAddNewAddress &&
        initialFormState?.selected_address?.hasOwnProperty('isDefault') ? (
          <>
            <Formik
              initialValues={initialFormState}
              validationSchema={selectDeliveryAddressSchema}
              onSubmit={handleFormSubmission}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className={styles.newFormDesign}>
                  <Row
                    className={`justify-content-center ${
                      editInModal && ' px-5'
                    }`}
                  >
                    <Col md="12">
                      <div className="form-group">
                        <FormField
                          isSearchable={true}
                          options={userAddresses}
                          labelText={'Delivery Location'}
                          name="selected_address"
                          type="select"
                          placeholder={'Select a delivery address'}
                          value={values.selected_address}
                          onChange={(address) => {
                            setFieldValue('selected_address', address);
                            setAddress(address);
                            checkNearestStoreLocation(address);
                            setRunOnSubmit(true);
                          }}
                          disabled={isFindingNearest}
                          errors={errors}
                          touched={touched}
                          inputFieldClass="custom_select"
                        />

                        {!isFindingNearest && !OPManager && (
                          <p
                            className={`clr-red-dark f-s14`}
                            hidden={isNearestStoreAvailable}
                          >
                            Option unavailable. Unfortunately, you’re too far
                            from the nearest Cafe Zupas location.
                          </p>
                        )}
                        {!isFindingNearest && OPManager && (
                          <p
                            className={`clr-red-dark f-s14`}
                            hidden={isNearestStoreAvailable}
                          >
                            The address does not lie in your store's delivery
                            zone. Please contact IT to update your delivery
                            zone.
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {selectedAddress && (
                    <div className={`mt-1 text-start ${editInModal && 'px-5'}`}>
                      <span className="d-block f-s14 font-plex light-blue f-w5 text-break">
                        {selectedAddress?.address_name}
                      </span>
                      <span className="d-block f-s14 font-plex light-blue f-w5 text-break">
                        {selectedAddress?.street_name},{' '}
                        {selectedAddress?.apartment_no}
                      </span>
                      <span className="d-block f-s14 font-plex light-blue f-w5 text-break">
                        {selectedAddress?.city}, {selectedAddress?.state}{' '}
                        {selectedAddress?.zipcode}
                      </span>
                    </div>
                  )}

                  {!selectedAddress && newAddedAddressForDelivery && (
                    <div className={`mt-1 text-start ${editInModal && 'px-5'}`}>
                      <span className="d-block f-s14 font-plex light-blue f-w5 text-break">
                        {newAddedAddressForDelivery?.address_name}
                      </span>
                      <span className="d-block f-s14 font-plex light-blue f-w5 text-break">
                        {newAddedAddressForDelivery?.street_name},{' '}
                        {newAddedAddressForDelivery?.apartment_no}
                      </span>
                      <span className="d-block f-s14 font-plex light-blue f-w5 text-break">
                        {newAddedAddressForDelivery?.city},{' '}
                        {newAddedAddressForDelivery?.state}{' '}
                        {newAddedAddressForDelivery?.zipcode}
                      </span>
                    </div>
                  )}
                  <div
                    className={`d-flex justify-start-center ${
                      editInModal && 'px-5'
                    }`}
                  >
                    <button
                      type="button"
                      className={`btn p-0 f-w6 clr-dark-red font-plex mt-3`}
                      onClick={() => {
                        // if (validateCount(userAddresses)) {
                        shownDeilveryLocation(true);
                        nearestLocation(true);
                        setShowAddNewAddress?.(true);
                        // }
                      }}
                    >
                      Add a New Address
                    </button>
                  </div>
                  {editInModal && <hr className="mb-0" />}
                  <div className="d-flex justify-content-end">
                    {editInModal && (
                      <button
                        type="submit"
                        className="btn-large lh-lg py-2 mt-4 f-s16 px-4 me-4"
                        disabled={
                          isSubmitting ||
                          !isNearestStoreAvailable ||
                          isFindingNearest
                        }
                      >
                        {isFindingNearest
                          ? 'Searching for nearest store...'
                          : 'Continue'}
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <VisitorDeliveryForm
            handleLocation={handleLocation}
            isItMobile={isItMobile}
            handleShowDeliveryMethodModal={handleShowDeliveryMethodModal}
            showItemDeliveryDateTime={showItemDeliveryDateTime}
            checkNearestStoreLocation={checkNearestStoreLocation}
            setAddress={setAddress}
            selectedAddress={selectedAddress}
            saveSelectedOrderType={saveSelectedOrderType}
            handleOrderType={handleOrderType}
            isFindingNearest={isFindingNearest}
            isNearestStoreAvailable={isNearestStoreAvailable}
            newAddedAddressForDelivery={newAddedAddressForDelivery}
            setNewAddedAddressForDelivery={setNewAddedAddressForDelivery}
            closeNewAddressForm={closeNewAddressForm}
            userAddresses={userAddresses}
            addNewAddress={`${editInModal && 'px-5'}`}
            editInModal={editInModal}
          />
        )}
      </div>
    </div>
  );
}

export default ItemDelivery;

import {
  DELIVERY_ORDER_TYPE,
  PICK_UP_METHOD_CURBSIDE,
  PICK_UP_METHOD_IN_STORE,
  PICK_UP_ORDER_TYPE,
} from '../../constants';

export const handleIsLiveLocationRedirection = (
  type: string,
  location: any,
  isMobileMode = false,
) => {
  let url = '';
  switch (type) {
    case PICK_UP_ORDER_TYPE:
      if (isMobileMode) url = location.olo_individual_order_url_mobile;
      else url = location.olo_individual_order_url;
      break;
    case DELIVERY_ORDER_TYPE:
      if (isMobileMode) url = location.olo_individual_order_url_mobile;
      else url = location.olo_individual_order_url;
      break;
    // case PICK_UP_METHOD_IN_STORE:
    // 	if( isMobileMode ) url = location.olo_take_out_order_url_mobile
    // 	else url = location.olo_take_out_order_url
    //     break;
    // case PICK_UP_METHOD_CURBSIDE:
    // 	if( isMobileMode ) url = location.olo_take_out_order_url_mobile
    // 	else url = location.olo_take_out_order_url
    //     break;
    default:
      if (isMobileMode) url = location.olo_individual_order_url_mobile;
      else url = location.olo_individual_order_url;
      break;
  }
  window.location.href = url;
};

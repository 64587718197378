import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userSliceReducer } from 'src/Features/AccountSetup/redux/slices';
import { locationSliceReducer } from 'src/Features/Location/redux/slice';

import { reducer } from 'src/redux/slices/cartSlice';
import { checkoutReducer } from 'src/redux/slices/checkoutSlice';
import { footerSliceReducer } from 'src/redux/slices/footerSlice';
import { specificFlowStatesReducer } from 'src/redux/slices/handleStatesForSpecificFlows';
import { itemCustomizationReducer } from 'src/redux/slices/itemCustomizationSlice';
import { itemSliceReducer } from 'src/redux/slices/itemSlice';
import { orderDetailSliceReducer } from 'src/redux/slices/orderDetailSlice';
import { wizardSliceReducer } from 'src/redux/slices/wizardSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'cart',
    'user',
    'checkout',
    'location',
    'specificFlowStates',
    'footer',
    'wizard',
  ],
};
const reducers = combineReducers({
  location: locationSliceReducer,
  cart: reducer,
  user: userSliceReducer,
  itemCustomization: itemCustomizationReducer,
  Items: itemSliceReducer,
  checkout: checkoutReducer,
  specificFlowStates: specificFlowStatesReducer,
  orderDetails: orderDetailSliceReducer,
  wizard: wizardSliceReducer,
  footer: footerSliceReducer,
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;

import { useQuery } from 'react-query';
import { UserProfile } from 'src/types';

import { AUTH_USER, GUEST_USER } from '../constants';
import { customerBusinessService } from '../services';

const getProfile = async (userId: string | number): Promise<UserProfile> => {
  if (!userId) return;
  try {
    const response = await customerBusinessService.getCustomerProfile(userId, {
      details: '[addresses,locations,vehicles]',
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export default function useProfile(
  userId: string | number,
  userType: string = AUTH_USER,
) {
  return useQuery(['profile', userId], () => getProfile(userId), {
    retry: false,
    staleTime: Infinity,
    enabled: !!userId && userType !== GUEST_USER,
  });
}

import { useQuery } from 'react-query';
import { locationMenuService } from '../services';

const getAllStates = async () => {
  const response = await locationMenuService.getAllStates();
  return response.data.data;
};

export default function useGetAllStates() {
  return useQuery('allStates', () => getAllStates(), {
    retry: false,
    staleTime: Infinity,
  });
}

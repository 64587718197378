import { useQuery } from 'react-query';
import { operatorService } from 'src/services';

const getCateringOperatorDetails = async () => {
  const response = await operatorService.getOperatorDetail();
  return response.data.data;
};

export default function useGetCateringOperatorDetails() {
  return useQuery(
    ['cateringOperatorDetails'],
    () => getCateringOperatorDetails(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
}

import { useQuery } from 'react-query';
import { operatorService } from '../../services';
const getOperatorCustomerList = async (query) => {
  const response = await operatorService.searchOperatorCustomers(query);
  return response.data.data;
};

export default function useOperatorCustomerList({ query }) {
  return useQuery(
    ['operatorCustomerLists', query],
    () => getOperatorCustomerList(query),
    {
      retry: false,
      staleTime: 0,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
}

import { useMutation } from 'react-query';
import { brazeLogCustomEventAddToFavorites } from 'src/helper/brazeHelper';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { AddFavourite, ItemFields } from 'src/models/Menu.model';
import { orderMicroService } from 'src/services';

const addFavorite = async (item, isItMobile): Promise<AddFavourite> => {
  const payload = {
    customer_id: item.customer_id,
    brink_id: item.brink_id || item.full_brink_item_id || 5,
    item_id: item.item_id || item.id,
    display_price: item.display_price || item.price,
    item_description: item.description || 'Description',
    modifiers: item.modifiers || [],
  };
  const response = await orderMicroService.addToFavorites(payload);
  brazeLogCustomEventAddToFavorites(item, isItMobile);
  return response.data.data;
};

export default function useAddFavorites() {
  const isItMobile = useCheckMobileScreen();
  return useMutation((item: ItemFields) => addFavorite(item, isItMobile));
}

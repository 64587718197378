import { useQuery } from 'react-query';
import { operatorService } from 'src/services';

const loadDraft = async (id: string) => {
  const response = await operatorService.loadDraft(id);
  return response.data.data;
};

export default function useLoadDraft() {
  const draftOrderId = localStorage.getItem('draftOrderId');
  return useQuery(
    ['draftOrderId', draftOrderId],
    () => loadDraft(draftOrderId),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: draftOrderId ? true : false,
    },
  );
}

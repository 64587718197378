import React from 'react';
import { Col, Row } from 'react-bootstrap';

import CounterCard from '../../../../components/Molecules/Card/CounterCard/CounterCard';
import styleClasses from '../../../../components/Molecules/Card/CounterCard/counterCard.module.scss';
import SelectCard from '../../../../components/Molecules/Card/SelectCard/SelectCard';
import * as Constants from '../../../../constants';
import NoDressing from '../../../../containers/Modifiers/cards/NoDressing';
import OtherDressing from '../../../../containers/Modifiers/cards/OtherDressing';
import {
  isItSubstituteGroup,
  verifyStatus,
} from '../../../../helper/customizedItem/customizedItem.helper';
import { IRequiredModifiersGroupsModifiers } from '../../../../models/item.model';
import { ISlider } from '../../../../models/try2Combo';
import { itemBuckets } from '../../../../priceCalculation/buckets';

const VerticalSlider: React.FC<ISlider> = (props) => {
  const {
    otherDressingHandler,
    items_required_modifier_groups,
    selectedIngredients,
    handleIngredientSelection,
    isInItemPresent,
    selectedAddedIngredients,
    sectionSelected,
    noDressingHandler,
    isNoDressing,
    label,
  } = props;
  let otherRequiredModifiers = false;

  const requiredModifierGroups =
    items_required_modifier_groups &&
    items_required_modifier_groups.length > 0 &&
    items_required_modifier_groups[0];

  const handleClick = (data, in_item) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: items_required_modifier_groups[0]?.id,
        modifier_group_max: items_required_modifier_groups[0]?.max,
        modifier_group_base: items_required_modifier_groups[0]?.base,
        brink_modifier_group_id:
          items_required_modifier_groups[0]?.brink_modifier_group_id,
        in_item,
        modifier_type: Constants.REQUIRED_MODIFIERS,
        in_slide: true,
      },
    });
  };

  const showModifier = (modifier, selected) => {
    const additionalAdded = requiredModifiersBucket?.additionalAdded?.modifiers;
    const additionalRemoved =
      requiredModifiersBucket?.additionalRemoved?.modifiers;
    const modifierExistence = checkModifierExistence(
      additionalAdded,
      additionalRemoved,
      modifier,
    );

    if (isInItemPresent) {
      if (modifier?.in_item || selected?.in_slide || modifierExistence) {
        return true;
      } else {
        if (!otherRequiredModifiers) {
          otherRequiredModifiers = true;
        }
        return false;
      }
    } else {
      return true;
    }
  };

  const isShowNoDressing = (): boolean => {
    if (!otherRequiredModifiers && requiredModifierGroups?.min === 0) {
      return true;
    }
    const noDressingExists =
      // eslint-disable-next-line no-prototype-builtins
      requiredModifiersBucket?.hasOwnProperty('noDressings');
    return noDressingExists;
  };

  const checkModifierExistence = (array1, array2, modifier) => {
    let modifierExistence = array1?.find(
      (mod) => mod.modifier_id === modifier.id,
    );
    if (!modifierExistence) {
      modifierExistence = array2?.find(
        (mod) => mod.modifier_id === modifier.id,
      );
    }
    if (modifierExistence) {
      return true;
    } else {
      return false;
    }
  };

  const isLimitExceed = (extendable_limit) => {
    if (extendable_limit === 1) {
      return false;
    }
    const isNoDressingExist = selectedAddedIngredients?.find(
      (mod: any) => mod.modifier_name === Constants.NO_DRESSING_CAPITALIZE,
    );
    if (isNoDressingExist) {
      extendable_limit += 1;
    }
    if (selectedAddedIngredients?.length >= extendable_limit) {
      return true;
    } else {
      return false;
    }
  };

  const requiredModifiersBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
  })();

  const selectedRequiredModifier = (modifier) => {
    return selectedIngredients?.find(
      (selected) => selected.modifier_id === modifier.id && !selected.core,
    );
  };

  const decideCard = (modifier: IRequiredModifiersGroupsModifiers) => {
    return (
      selectedIngredients?.length > 0 &&
      !isItSubstituteGroup(items_required_modifier_groups) &&
      modifier?.max > 1
    );
  };

  return (
    <div
      className={`${styleClasses.dressing_mobile_view} dressing-mobile-view d-md-none`}
    >
      <hr className="mb-4 mt-2 mt-md-3" />
      <Row className="mb-5">
        {requiredModifierGroups.required_modifiers_groups_modifiers?.map(
          (modifier) => {
            const selected = selectedRequiredModifier(modifier);
            return (
              showModifier(modifier, selected) && (
                <Col xs="4" className="py-mb-0 mb-0 px-2" key={modifier?.id}>
                  {decideCard(modifier) ? (
                    <CounterCard
                      id={modifier?.id}
                      brink_id={modifier?.brink_modifier_id}
                      image={`${Constants.s3BaseUrl}${modifier?.image}`}
                      title={modifier.name}
                      price={modifier.price}
                      calories={modifier.calories}
                      max={modifier.max}
                      groupMin={requiredModifierGroups.min}
                      is_active={verifyStatus(
                        modifier,
                        Constants.MODIFIER_LOCATION,
                      )}
                      priceAlternateLabel={selected ? ' ' : ' '}
                      handleClick={(data) => {
                        handleClick(data, modifier?.in_item);
                      }}
                      count={selected?.quantity || 0}
                      from={Constants.TRY_2_COMBO}
                      additionalPrice={selected?.additionalPrice}
                      extendableLimit={isLimitExceed(
                        requiredModifierGroups.extendable_limit,
                      )}
                      extendableLimitValue={
                        requiredModifierGroups.extendable_limit
                      }
                    />
                  ) : (
                    <SelectCard
                      id={modifier?.id}
                      brink_id={modifier?.brink_modifier_id}
                      image={`${Constants.s3BaseUrl}${modifier?.image}`}
                      title={modifier.name}
                      groupMin={requiredModifierGroups.min}
                      price={modifier.price}
                      calories={modifier.calories}
                      is_active={verifyStatus(
                        modifier,
                        Constants.MODIFIER_LOCATION,
                      )}
                      priceAlternateLabel={selected ? ' ' : ' '}
                      handleClick={(data) => {
                        handleClick(data, modifier?.in_item);
                      }}
                      selected={Boolean(selected)}
                      default_selected={modifier.is_selected}
                      modifierAdditionalPrice={selected?.additionalPrice}
                      extendableLimit={isLimitExceed(
                        requiredModifierGroups.extendable_limit,
                      )}
                      extendableLimitValue={
                        requiredModifierGroups.extendable_limit
                      }
                    />
                  )}
                </Col>
              )
            );
          },
        )}
        {isShowNoDressing() && (
          <Col xs="4" className="mb-0">
            <NoDressing
              label={requiredModifierGroups?.label}
              groupId={requiredModifierGroups?.id}
              noDressingHandler={noDressingHandler}
              isNoDressing={isNoDressing}
              itemNo={sectionSelected}
              noDressingCardBorderRadius={
                styleClasses.no_Dressing_Card_Border_radius
              }
            />
          </Col>
        )}
        {otherRequiredModifiers && (
          <Col xs="4" className="py-mb-0 mb-0 px-2">
            <OtherDressing
              groupMin={requiredModifierGroups?.min}
              otherDressingHandler={otherDressingHandler}
              styleClass={'other_card_style'}
              label={label}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default VerticalSlider;

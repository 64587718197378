import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SERVER_URL } from 'src/constants';

export const requestHandler = (request: AxiosRequestConfig) => {
  const url = request.baseURL + request.url;
  if (request.method === 'get') {
    request.url = '/server.php?url=' + url;
  } else {
    request.url = '/server.php?url=' + url;
    if (typeof request.data === 'undefined') {
      request.data = { url: url };
    } else {
      request.data['url'] = url;
    }
  }
  request.baseURL = SERVER_URL;

  return request;
};

export const successResponseHandler = (response: AxiosResponse) => {
  return {
    ...response,
    data: response.data,
  };
};

export const errorResponseHandler = (error: AxiosError) => {
  // window.location.assign(window.location.origin + '/error')
  return Promise.reject(error);
};

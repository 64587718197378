import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getSizeName, getSizeNameInCaseOfHalf } from 'src/helper/utils';
import { IOfferItem } from 'src/models/rewards.model';

import { FULL_SIZE, ROUTE_OTHER_ITEM, STEP3, STEP5 } from '../../../constants';
import {
  checkTheStepNo,
  substitutedAddedModifiers,
} from '../../../helper/helperMethods';
import useRedeemOffer from '../../../hooks/useRedeemOffer';
import {
  cartItem,
  quantityCounterOperations,
} from '../../../models/cart.model';
import { setShowCart } from '../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';

import ActionButtons from './ActionButtons';
import styles from './CartItems.module.scss';
import ItemModifiers from './ItemModifiers';
import { onStepClick } from 'src/redux/slices/wizardSlice';
import ItemPrice from './ItemPrice';
import ItemRemoveModal from './itemRemoveModal';
import QuantityCounter from './QuantityCounter';
interface ICartItemCard {
  item: cartItem;
  selectedOfferItem: IOfferItem;
  subTotal: number;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number,
  ) => void;
  iteratingIndex: number;
  isActive: boolean;
  loading: boolean;
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: () => void,
  ) => void;
}

const CartItemCard: React.FC<ICartItemCard> = ({
  item,
  selectedOfferItem,
  subTotal,
  handleItemRemove,
  loading,
  handleQuantityChange,
  iteratingIndex,
}) => {
  const history = useHistory();
  const wizardSteps = useAppSelector((state) => state.wizard);
  const isThisStep5 = checkTheStepNo(wizardSteps, STEP5);

  const [removeModal, setRemoveModal] = useState(false);
  const dispatch = useAppDispatch();
  const {
    item_size,
    name,
    id,
    quantity,
    recipient_name,
    special_instructions,
  } = item;

  const [nameDivHeight, setNameDivHeight] = useState<number>(0);
  const [instructionDivHeight, setInstructionDivHeight] = useState<number>(0);
  const itemHeight = useRef(null);
  const instructionHeight = useRef(null);
  const [showMoreNames, setShowMoreNames] = useState(false);

  const [showMoreIntructuons, setShowMoreIntructuons] = useState(false);
  const handleshowMoreNames = () => setShowMoreNames(!showMoreNames);

  const handleShowMoreIntructuons = () =>
    setShowMoreIntructuons(!showMoreIntructuons);
  const [itemQuantity, setItemQuantity] = useState<number>(quantity ?? 1);
  const handleQuantityChangeWrapper = async (
    operation: quantityCounterOperations,
  ) => {
    let quantity = itemQuantity;
    if (
      itemQuantity === 1 &&
      operation === quantityCounterOperations.decrease
    ) {
      showItemRemoveModal();
      return;
    }
    if (operation === quantityCounterOperations.increase) {
      quantity = itemQuantity + 1;
    } else {
      quantity = itemQuantity > 1 ? itemQuantity - 1 : 1;
    }
    setItemQuantity(quantity);
    handleQuantityChange(id, iteratingIndex, quantity);
  };

  const { reVerifyOfferEligibility } = useRedeemOffer();
  useEffect(() => {
    reVerifyOfferEligibility(selectedOfferItem, item, subTotal);
  }, [subTotal]);

  useEffect(() => {
    if (quantity) setItemQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    if (
      (!nameDivHeight || nameDivHeight <= 0) &&
      itemHeight?.current?.scrollHeight
    ) {
      setNameDivHeight(itemHeight?.current?.scrollHeight);
    }
  }, [item?.recipient_name]);

  useEffect(() => {
    if (
      (!instructionDivHeight || instructionDivHeight <= 0) &&
      instructionHeight?.current?.scrollHeight
    ) {
      setInstructionDivHeight(instructionHeight?.current?.scrollHeight);
    }
  }, [item?.special_instructions]);

  const redirectToSpecificStep = (id) => {
    dispatch(onStepClick({ stepId: id }));
  };

  const handleItemEdit = () => {
    const route = {
      pathname: `${`${ROUTE_OTHER_ITEM}/${item.item_id}`}`,
      search: `?category_id=${item?.category_id}&&is_box_lunch=${item.is_box_lunch ? true : false
        }&id=${item.id}`,
      state: {
        showCustomization: true,
        isSingleItem: item.is_single_item,
      },
    };
    redirectToSpecificStep(STEP3);
    history.push(route);
    dispatch(setShowCart(false));
  };

  const displayPrice = () => {
    const price = item.gross_price ?? item.display_price;
    return parseFloat((price * itemQuantity).toFixed(2));
  };

  const showItemRemoveModal = () => {
    setRemoveModal(true);
  };

  const hideItemRemoveModal = () => {
    setRemoveModal(false);
  };

  const unavailableModifiers: Array<string> = [];
  item?.modifiers?.filter((e: any) => {
    if (item?.unavailable_modifiers?.includes(e?.modifier_name)) {
      unavailableModifiers.push(e.modifier_name);
    }
  });

  const unavailableModifiersExist = unavailableModifiers?.length > 0;
  const showSize = item_size && !item.single_size;
  const size: string =
    item_size === FULL_SIZE.id
      ? getSizeName(item?.category_name).upper_case
      : getSizeNameInCaseOfHalf(item?.category_name, item_size);
  const recipientNameArr = item?.recipient_name?.split(',');
  return (
    <>
      <li className="text-center mb-3">
        <div
          className={`${styles.list_item} text-center mb-0 w-100 ${(!item.available || unavailableModifiersExist) && styles.error_state
            }`}
        >
          <h1 className="d-flex justify-content-between m-0 text-start align-items-start">
            <div className="d-flex w-100">
              {itemQuantity > 1 && isThisStep5 ? <p className={`${styles.quantityOnCheckout} clr-white f-s14 font-plex mb-0 me-1`}>{itemQuantity + "x"}</p> : ''}
              <p className='mb-0 f-s16 f-w5 light-blue font-plex lh-normal'> {name}</p>
            </div>
            <ActionButtons
              onEdit={handleItemEdit}
              onRemove={showItemRemoveModal}
              isFree={item.free}
              notEditable={item.cart_directly || !item.available}
            />
            <ItemRemoveModal
              showModal={removeModal}
              handleClose={hideItemRemoveModal}
              removeItem={() =>
                handleItemRemove(id, iteratingIndex, hideItemRemoveModal)
              }
              loading={loading}
            />
          </h1>
          <span className="d-block f-s14 font-plex f-w4 text-start mt-1">
                {showSize && size}
              </span>
          <ItemModifiers
            modifiers={substitutedAddedModifiers(item)}
            paragraphClassName={'text-start mb-0 indent-first'}
          />
          <div className={styles.nameSection}>
            <span
              className={`d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize ${showMoreNames && styles.nameText
                }`}
              ref={itemHeight}
            >
              {recipientNameArr?.map((item, index) => {
                return (
                  <span className={`f-w6 mb-0`}>
                    {item}
                    {index < recipientNameArr?.length - 1 ? ',' : ''}{' '}
                  </span>
                );
              })}
            </span>
            <div className="d-flex justify-content-start">
              {nameDivHeight > 21 && (
                <button onClick={handleshowMoreNames} className='font-plex f-s14'>
                  {' '}
                  {showMoreNames ? 'show more' : 'show less'}
                </button>
              )}
            </div>
          </div>

          <div className={styles.nameSection}>
            <span
              className={`d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize ${showMoreIntructuons && styles.nameText
                }`}
              ref={instructionHeight}
            >
              {item?.special_instructions}
            </span>
            <div className="d-flex justify-content-start">
              {instructionDivHeight > 21 && (
                <button onClick={handleShowMoreIntructuons} className="font-plex f-s14">
                  {' '}
                  {showMoreIntructuons ? 'show more' : 'show less'}
                </button>
              )}
            </div>
          </div>

          <span
            className={`d-flex align-items-center mt-1 ${isThisStep5 ? 'justify-content-end' : 'justify-content-between'
              }`}
          >
            {!isThisStep5 && (
              <QuantityCounter
                quantity={itemQuantity}
                editQuantity={handleQuantityChangeWrapper}
                disabled={!item.available || unavailableModifiersExist}
              />
            )}
            <ItemPrice price={displayPrice()} />
          </span>
        </div>
        {!item.available ? (
          <span
            className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer"
            onClick={showItemRemoveModal}
          >
            This item is no longer available. Please remove from your order.
          </span>
        ) : (
          unavailableModifiersExist && (
            <span
              className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer"
              onClick={showItemRemoveModal}
            >
              Unfortunately, we are currently out of{' '}
              {unavailableModifiers?.join(', ')} Today.
            </span>
          )
        )}
      </li>
    </>
  );
};

export default CartItemCard;

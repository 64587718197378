import * as Yup from "yup";

export const contactInfoFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(
      /^[a-zA-Z ]+$/,
      "First name should not contain special characters or numbers"
    ),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(
      /^[a-zA-Z ]+$/,
      "Last name should not contain special characters or numbers"
    ),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email address is required"),
    phone: Yup.string().required("Phone number is required").matches(/^[0-9]{10}$/, "Phone number must be 10 digits long"),
  locations:  Yup.array()
      .nullable()
      .min(1, "Favorite location is required"),
});

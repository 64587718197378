import { useQuery } from 'react-query';

import { IGetCartPayload } from '../../models/order.model';
import { orderMicroService } from '../../services';

export const getCart = async (payload: IGetCartPayload) => {
  const response = await orderMicroService.getCart(payload);
  return response.data.data;
};

export default function useGetCartItems(payload: IGetCartPayload) {
  return useQuery('get-cart', () => getCart(payload), {
    retry: false,
  });
}

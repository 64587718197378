import React from 'react';
import IMenuItems from './Types';
interface ItemPricesProps {
  getItem: IMenuItems;
}
const ItemPrices: React.FunctionComponent<ItemPricesProps> = ({ getItem }) => {
  return (
    <>
      {!getItem.half_brink_item_id ? (
        `$${getItem.price}`
      ) : (
        <>
          {`Half $${getItem.half_brink_item_price}`}
          <span className="ms-2">FULL ${getItem.price}</span>
        </>
      )}
    </>
  );
};

export default ItemPrices;

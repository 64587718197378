import { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import RadioSelect from 'src/components/RadioSelect/RadioSelect';
import * as CONSTANT from 'src/constants';
import { getCustomizedItemPrice } from 'src/helper/getCombinePrice/getCombinePrice.helper';
import { ItemDetails, Order } from 'src/models/item.model';
import { toFixedNumber } from 'src/priceCalculation/helper';

interface propTypes {
  item: ItemDetails; // true if the item is selected for a specific size or false otherwise
  handleSelection: (e: any, size: string, isHalf?: boolean) => void;
  order?: Order;
  isSize: boolean;
  price?: any;
  state?: any;
  customizationMenu?: any;
  setCustomizationMenu?: any;
}

const index: React.FC<propTypes> = (props) => {
  const { item, handleSelection, order, isSize, price, state, customizationMenu, setCustomizationMenu } = props;

  const getItemTotal = (itemPrice, size) => {
    const isSelected =
      order.size === CONSTANT.FULL_SIZE.id ||
      order.size === CONSTANT.HALF_SIZE.id;
    let HalfSizeOrder = order;
    let totalPrice = 0;
    if (size == CONSTANT.HALF_SIZE.id) {
      HalfSizeOrder = {
        ...order,
        display_price: item.half_brink_item_price,
      };
    }
    totalPrice = getCustomizedItemPrice(HalfSizeOrder, itemPrice);
    if (isSelected) {
      totalPrice = toFixedNumber(totalPrice + order?.display_price);
    }

    return totalPrice;
  };

  useEffect(() => {
    if(state?.myProp === 'customise' && item.half_brink_item_id && item?.is_box_lunch && !customizationMenu) {
      
      setCustomizationMenu(true);
      handleSelection(null, CONSTANT.HALF_SIZE.id, true)
    }
  }, [state?.myProp, item.half_brink_item_id, item?.is_box_lunch]);
  return (
    <>
      {/* {item.is_salad_tray ? (
        <h4 className="f-s25 font-Cls mb-0 lh-normal">Select Size</h4>
      ) : null} */}
      <div
        className={`select_item_size`}
      >
        <h3 className="f-s16 mb-3 f-w6 font-plex">Select Size</h3>
        <Row className="gap-2 gap-lg-0">
          {item.half_brink_item_id && (
            <RadioSelect
              text={item.half_item_label}
              amount={
                item.is_box_lunch
                  ? price
                  : getItemTotal(
                      item.half_brink_item_price,
                      CONSTANT.HALF_SIZE.id,
                    )
              }
              orignalPrice={item.half_brink_item_price}
              img={undefined}
              id={item.half_item_label}
              className={item.half_brink_item_id ? '' : 'w-100'}
              onChange={(e) => handleSelection(e, CONSTANT.HALF_SIZE.id, true)}
              selected={order?.size === CONSTANT.HALF_SIZE.id}
              isSize={isSize}
              servingText={item?.half_item_short_description}
              isBoxLunch={item?.is_box_lunch}
            />
          )}
          <RadioSelect
            text={item.full_item_label}
            amount={
              item.is_box_lunch
                ? price
                : getItemTotal(item.price, CONSTANT.LARGE_SIZE.id)
            }
            orignalPrice={item.price}
            img={undefined}
            id={item.full_item_label}
            className={item.half_brink_item_id ? '' : 'w-100'}
            onChange={(e) => handleSelection(e, CONSTANT.LARGE_SIZE.id)}
            selected={order?.size === CONSTANT.LARGE_SIZE.id}
            isSize={isSize}
            servingText={item?.full_item_short_description}
            isBoxLunch={item?.is_box_lunch}
          />
        </Row>
      </div>
    </>
  );
};

export default index;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { IOfferItemDetails } from 'src/models/rewards.model';

import {
  OFFER_DISCOUNTED_SUBTOTAL_ZERO,
  OFFER_ELIGIBLE_LOCATIONS,
  OFFER_ELIGIBLE_SUBTOTAL_RANGE,
  PICK_UP_ORDER_TYPE,
} from '../../../constants';
import useMarketLocations from '../../../react-query-hooks/useLocations';
import styles from './offer.module.scss';

interface IOfferEligibilityModalProps {
  show: boolean;
  offerFailedCriteria: string;
  offerDetails: IOfferItemDetails;
  closeModal: () => void;
}

const OfferEligibilityModal: React.FC<IOfferEligibilityModalProps> = ({
  show,
  closeModal,
  offerFailedCriteria,
  offerDetails,
}) => {
  const {
    offer_redemption_restrictions: {
      check_amount_restriction: {
        maximum_check_amount: maxAmount = 0,
        minimum_check_amount: minAmount = 0,
      } = {},
    },
    eligible_locations,
    eligible_items,
  } = offerDetails;

  const shouldCheckAmountRestriction = !!minAmount || !!maxAmount;

  const { data: markets } = useMarketLocations();
  const showEligibleLocations =
    markets
      ?.filter((location) => eligible_locations.includes(location.id))
      .map((market) => market.name) || [];
  const showEligibleItems =
    Object.values(eligible_items)?.map((eligibleItem) => eligibleItem?.name) ||
    [];

  return (
    <Modal
      show={show}
      centered
      className={`theme-modal-style app_container_wrap  border-radius-15  order_type_modal ${styles.offerEligibalModalWrapper}`}
      onHide={closeModal}
    >
      <Modal.Header className="py-3 px-5">
        <button
          type="button"
          className="btn modal-close-button pe-0 light-blue-icon"
          onClick={closeModal}
        >
          Close
        </button>
        <h4 className="f-s22 font-plex text-start f-w5 light-blue lh-normal mb-0 text-capitalize">
          Eligibility Criteria
        </h4>
      </Modal.Header>
      <Modal.Body className="mb-0 px-md-5">
        <>
          {showEligibleItems.length ? (
            <div className="mb-3">
              <h5
                className={`f-s18 font-plex light-blue fw-normal text-capitalize`}
              >
                Eligible Items
              </h5>
              {showEligibleItems.map((eligibleItem) => (
                <div className="w-100" key={eligibleItem}>
                  <div className="mb-1">
                    <span className="d-block f-s14 f-sm-s12 font-Visby-cf f-w5 text-capitalize clr-dark-blue">
                      - {eligibleItem}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {showEligibleLocations.length ? (
            <div className="mb-3">
              <h5
                className={`f-s18 font-Cls fw-normal lh-22 mb-0 text-capitalize ${
                  offerFailedCriteria === OFFER_ELIGIBLE_LOCATIONS
                    ? 'clr-red-dark'
                    : ''
                }`}
              >
                Eligible Locations
              </h5>
              {showEligibleLocations.map((eligibleLocation) => (
                <div className="w-100" key={eligibleLocation}>
                  <div className="mb-1">
                    <span className="d-block f-s14 f-sm-s12 font-Visby-cf f-w5 clr-dark-blue ms-1">
                      - {eligibleLocation}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {offerFailedCriteria === OFFER_DISCOUNTED_SUBTOTAL_ZERO && (
            <>
              <span className="d-block f-s16 font-Visby-cf f-w5 clr-red-dark">
                Cart Is Already Free.
              </span>
            </>
          )}
          {offerFailedCriteria === PICK_UP_ORDER_TYPE && (
            <>
              <span className="d-block f-s16 font-Visby-cf f-w5 clr-red-dark">
                You can only apply this offer on delivery orders.
              </span>
            </>
          )}
          {!offerFailedCriteria ? (
            <>
              <span className="d-block f-s18 font-Visby-cf f-w5 clr-red-dark">
                Your Cart Is Empty.
              </span>
            </>
          ) : null}
        </>
      </Modal.Body>
      <Modal.Footer className="justify-content-start px-5">
        {shouldCheckAmountRestriction && (
          <div className="mb-3">
            <h5
              className={`f-s18 font-plex fw-normal lh-22 mb-0 text-capitalize  ${
                offerFailedCriteria === OFFER_ELIGIBLE_SUBTOTAL_RANGE
                  ? 'clr-red-dark'
                  : ''
              }`}
            >
              Amount Restriction:{' '}
            </h5>
            <div className="mb-1">
              <span className="d-block f-s16 font-plex f-w4 light-blue ">
                {(minAmount &&
                  maxAmount &&
                  `Amount should be greater than $${minAmount} and smaller than $${maxAmount}`) ||
                  (minAmount &&
                    `Amount should be greater than $${minAmount}`) ||
                  (maxAmount && `Amount should be less than $${maxAmount}`)}
              </span>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default OfferEligibilityModal;

import { useQuery } from 'react-query';
import { getOperatorCustomerId, getUser } from 'src/helper/helperMethods';
import { operatorService } from 'src/services';

const getCateringOperatorCustomer = async (id: string,clear_edit_cart:boolean) => {
  if (id) {
    const response = await operatorService.getOperatorCustomer({id,clear_edit_cart});
    return response.data.data;
  }
};

export default function useGetCateringOperatorCustomer() {
  const customerId = getOperatorCustomerId();
  const clear_edit_cart = getUser()?.token?false:true

  return useQuery(
    ['cateringOperatorCustomer', customerId,clear_edit_cart],
    () => getCateringOperatorCustomer(customerId,clear_edit_cart),
    {
      staleTime: Infinity,
      cacheTime: customerId ? 50000 : 0,
      enabled: customerId ? true : false,
    },
  );
}

import { toast } from 'react-toastify';

export interface Toast {
  status: boolean;
  message: any;
}
export function Toast_Func(data: Toast) {
  if (data.status == true) {
    toast.success(data.message, {
      position: 'top-center',
      className: 'custom-success-toast',
    });
  } else {
    if (typeof data.message === 'object') {
      for (const k in data.message) {
        if (Array.isArray(data.message[k])) {
          for (const j in data.message[k]) {
            toast.error(data.message[k][j], {
              position: 'top-center',
            });
          }
        } else {
          toast.error(data.message, {
            position: 'top-center',
            className: 'custom-error-toast',
          });
        }
      }
    } else {
      toast.error(data.message, {
        position: 'top-center',
        className: 'custom-error-toast',
      });
    }
  }
}

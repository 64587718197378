import { PAYMENT_METHOD_SERIVCE } from '../constants';

import { HttpService } from './base.service';

class PaymentService extends HttpService {
  //   private readonly prefix: string = ORDER_SERVICE;
  baseURL = PAYMENT_METHOD_SERIVCE;

  addPaymentMethod = (data: any): Promise<any> =>
    this.post('payment-methods', data);
  getCustomerPaymentMethods = (customer_id: string | number): Promise<any> =>
    this.get(`customer-payment-methods/${customer_id}`);
  removePaymentMethod = (
    methodId: string | number,
    customer_id,
  ): Promise<any> =>
    this.delete(`payment-methods/${methodId}?customer_id=${customer_id}`);
  updatePaymentMethod = (recordID: string | number, data): Promise<any> =>
    this.patch(`payment-methods/${recordID}`, data);
  addGiftCard = (data: any): Promise<any> => this.post('add/gift-card', data);
  removeGiftCard = (data: any): Promise<any> =>
    this.post('remove/gift-card', data);
  createPaymentIntent = (data: any): Promise<any> =>
    this.post('create/payment-intent', data);
}

export const paymentService = new PaymentService();

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { guestLogin } from 'src/Features/AccountSetup/redux/actions';
import { brazeLogCustomEventAddedToCart } from 'src/helper/brazeHelper';
import {
  getAddToOrderPayloadToSync,
  getUpdateOrderPayloadToSync,
} from 'src/helper/cartHelperMethods/dataTransformation';
import { createOrderPayloadModifiers } from 'src/helper/customizedItem/customizedItem.helper';
import { getUser, getVisitorId } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useAddToCart from 'src/react-query-hooks/Cart/useAddToCart';
import useUpdateItem from 'src/react-query-hooks/Cart/useUpdateItem';
import { resetContext } from 'src/redux/slices/itemCustomizationSlice';

import { IRouteParams } from '../models/order';
import {
  IAddToOrderPayload,
  IUpdateOrderItemPayload,
} from '../models/order.model';
import {
  addItemToOrder,
  setOrderId,
  updateItemToOrder,
} from '../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../redux/store/store';

import useCheckMobileScreen from './useCheckMobileScreen';
import { useRedirectToSpecificStep } from './useRedirectToSpecificStep';
import { STEP3 } from 'src/constants';

interface IAddToOrder {
  addToOrder: (
    structuredOrder,
    isCombo?: boolean,
    quantity?: number,
  ) => Promise<boolean>;
  updateOrder: (
    structuredOrder,
    isCombo?: boolean,
    quantity?: number,
  ) => Promise<boolean>;
  loading: boolean;
}

interface ILocation {
  search: string;
  state: any;
}

interface IResponse {
  success: boolean;
  status?: string;
  items?: any[];
  message: string;
  response?: any;
}

const useAddToOrder = (): IAddToOrder => {
  const { mutateAsync: addToCart } = useAddToCart();
  const cart = useAppSelector((state) => state.cart);
  const { redirect } = useRedirectToSpecificStep();

  const isItMobile = useCheckMobileScreen();
  const { mutateAsync: updateOrderToCart } = useUpdateItem();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const locationInfo = useAppSelector((state) => state.location);
  const location_id = locationInfo.selectedStore?.id;
  const { user: authInfo } = useAppSelector((state) => state.user);
  let { id: item_id } = useParams<IRouteParams>();
  if (item_id && item_id.includes('?')) {
    item_id = item_id.split('?')[0];
  }

  const order = useSelector((state: any) => state.itemCustomization);
  const { search, state }: ILocation = useLocation();
  const query = new URLSearchParams(search);
  const category_id = query.get('category_id');

  const showCustomization = state?.showCustomization ?? false;

  const createOrderObj = (order: any, isCombo = false) => {
    if (isCombo) return order;
    const finalOrder = {
      ...order,
      id: order.item_id,
      price: order.display_price ? order.display_price : order.price,
      category_id,
      free: state?.reward_size ? true : false,
    };

    return finalOrder;
  };
  const authenticateGuest = async () => {
    try {
      const payload = { visitor_id: getVisitorId() };
      return await guestLogin(dispatch, payload);
    } catch (error) {
      return false;
    }
  };
  const addToOrder = async (structuredOrder: any, isCombo: false, quantity) => {
    setLoading(true);
    const user = getUser();
    if (!user.token) {
      const response = await authenticateGuest();
      if (!response) {
        setLoading(false);
        return false;
      }
    }

    const isUpsellItem = structuredOrder.isUpsellItem;
    delete structuredOrder.isUpsellItem;

    if (!isUpsellItem && showCustomization && order.id)
      return updateOrder(structuredOrder, isCombo, quantity);
    const finalOrder = createOrderObj(structuredOrder, isCombo);
    const dataForBackend = await dataToSyncBackendRequest(
      finalOrder,
      isCombo,
      quantity,
    );
    const isSynced = await syncOrderWithBackend(dataForBackend);
    // if( isSynced.success ){
    //   Toast_Func({
    //     status: true,
    //     message: "Added to Cart!"
    //   })
    // }
    setLoading(false);
    return isSynced.success;
  };

  const updateOrder = async (
    structuredOrder: any,
    isCombo: false,
    quantity,
  ) => {
    const dataForBackend = await dataToSyncUpdateOrderBackendRequest(
      structuredOrder,
      isCombo,
      quantity,
    );
    const isSynced = await syncOrderWithBackend(dataForBackend, true);
    Toast_Func({ status: isSynced.success, message: isSynced.message });
    if (isSynced.success) {
      dispatch(resetContext());
      redirect(STEP3)
    }
    setLoading(false);
    return isSynced.success;
  };

  const syncOrderWithBackend = async (
    payload,
    isUpdate = false,
  ): Promise<IResponse> => {
    try {
      let response;
      if (isUpdate) {
        response = await updateOrderToCart({
          item_id: order.id,
          item: payload,
        });
      } else {
        response = await addToCart(payload);
      }
      if (response) {
        !isUpdate &&
          brazeLogCustomEventAddedToCart(
            cart,
            payload.items,
            isItMobile,
            locationInfo.selectedStore,
          );
        if (response.id) dispatch(setOrderId(response.id));
        !isUpdate
          ? dispatch(addItemToOrder(response.items))
          : dispatch(updateItemToOrder(response));
        return {
          success: true,
          status: 'complete',
          message: 'Item updated successfuly',
          response: response,
        };
      } else {
        return {
          success: false,
          status: 'failed',
          message: 'Either item or any modifier is unavailable!',
        };
      }
    } catch (error) {
      return {
        success: false,
        status: 'failed',
        message: 'Either item or any modifier is unavailable!',
      };
    }
  };
  const dataToSyncBackendRequest = async (finalOrder, isCombo, quantity?) => {
    const OrderData = {
      ...finalOrder,
      set_id: 1,
      quantity: quantity ?? 1,
    };
    const payload: IAddToOrderPayload = getAddToOrderPayloadToSync(
      authInfo,
      location_id,
    );
    payload.items = [OrderData];
    payload.items.forEach((element) => {
      element = createOrderPayloadModifiers(element, isCombo);
      if (element.is_package) {
        element.modifiers.forEach((nestedElements) => {
          nestedElements = createOrderPayloadModifiers(nestedElements, false);
        });
      }
    });
    return payload;
  };

  const dataToSyncUpdateOrderBackendRequest = async (
    finalOrder,
    isCombo,
    quantity: number,
  ) => {
    let payload: IUpdateOrderItemPayload[] =
      getUpdateOrderPayloadToSync(authInfo);
      payload = [
        {
          ...payload,
          ...finalOrder,
          id: order.id,
          quantity: quantity ?? 1,
        },
      ];
      payload.forEach((element) => {
        element = createOrderPayloadModifiers(element, isCombo);
        if (element.is_package) {
          element.modifiers.forEach((nestedElements) => {
            nestedElements = createOrderPayloadModifiers(nestedElements, false);
          });
        }
      });
      const finalPayload = payload[0];
    return finalPayload;
  };

  return { addToOrder, updateOrder, loading };
};

export default useAddToOrder;

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getOperatorToken, getUser } from 'src/helper/helperMethods';

// const {dispatch} = store;

export const requestHandler = (request: AxiosRequestConfig) => {
  const authInfo = getUser();
  const token = authInfo.token || authInfo?.access_token;
    if (token) {
      request.headers['Authorization'] = 'Bearer ' + token;
      request.headers['token'] = token;
    }

  return request;
};

export const successResponseHandler = (response: AxiosResponse) => {
  return response;
};

export const errorResponseHandler = (error: AxiosError) => {
  const authInfo = getUser();
  if (error?.response?.status === 401 && authInfo.token) {
    // unauthorizeLogout(dispatch)
  }
  return Promise.reject(error);
};

import { FC, MouseEvent } from 'react';

import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { ItemDetails } from 'src/models/item.model';
import useAddFavorites from 'src/react-query-hooks/useAddFavorite';
import useFavorites from 'src/react-query-hooks/useFavorites';
import useRemoveFavorite from 'src/react-query-hooks/useRemoveFavorite';
import { useAppSelector } from 'src/redux/store/store';
import styles from '/src/Features/Menu/menu.module.scss';

interface FavoriteBtnProps {
  positionIcon?: string;
  widthIcon?: string;
  item?: ItemDetails;
  disableItem?: boolean;
}
const FavoriteBtn: FC<FavoriteBtnProps> = (props) => {
  function handleClick(e) {
    e.stopPropagation();
  }
  const { item, widthIcon, positionIcon, disableItem } = props;
  const { user } = useAppSelector((state) => state.user);
  const { data: favorites = [] } = useFavorites(user.id, user.type);

  const [favoriteItems, setFavoriteItems] = useState<any>({});

  let { mutateAsync: removeFavorite } = useRemoveFavorite();
  let { mutateAsync: addFavorite } = useAddFavorites();

  useEffect(() => {
    if (favorites.length > 0) {
      const favouriteItem = favorites?.find((fvrt) => fvrt.item_id === item.id);
      setFavoriteItems(favouriteItem ? favouriteItem : {});
    }
  }, [favorites]);

  const removeFavoriteItem = (favoriteItemId: any, item: any) => {
    const userId = user.id;
    removeFavorite(
      { itemId: favoriteItemId, userId },
      {
        onError: (error, variables, context) => {
          setFavoriteItems(item);
        },
      },
    );
  };

  const addFavoriteItem = (item: any) => {
    addFavorite(
      { ...item, customer_id: user.id },
      {
        onError: (error, variables, context) => {
          setFavoriteItems({});
        },
      },
    );
  };

  const debouncedAddFavourite = useCallback(
    _debounce(addFavoriteItem, 500),
    [],
  );
  const debouncedRemoveFavourite = useCallback(
    _debounce(removeFavoriteItem, 500),
    [],
  );

  const favoriteButtonHandler = (e: React.MouseEvent<HTMLDivElement>, item) => {
    if (Object.keys(favoriteItems)?.length <= 0) {
      setFavoriteItems(item);
      debouncedAddFavourite(item);
    } else {
      setFavoriteItems({});
      debouncedRemoveFavourite(item?.id, item);
    }
  };
  const favourite =
    favoriteItems && Object.keys(favoriteItems)?.length > 0 ? true : false;
  return (
    <>
      <div
        onClick={handleClick}
        className={`${styles.fav_btn_wrap} ${positionIcon} ${widthIcon}`}
      >
        <div
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            !disableItem && favoriteButtonHandler(e, item);
          }}
          className={favourite ? styles.fav_icon_fill : ''}
        >
          <svg
            className={`${styles.fav_icon} `}
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="28"
            viewBox="0 0 31 28"
            fill="none"
          >
            <path
              d="M15.3643 26.2812C15.3643 26.2812 1 18.2372 1 8.46947C1.00029 6.74307 1.59849 5.07005 2.6929 3.73486C3.78731 2.39967 5.31037 1.48473 7.00313 1.14558C8.69589 0.806437 10.4539 1.06403 11.9782 1.87456C13.5025 2.68509 14.699 3.99853 15.3644 5.59157L15.3643 5.59158C16.0297 3.99854 17.2262 2.6851 18.7505 1.87457C20.2748 1.06403 22.0328 0.806438 23.7255 1.14558C25.4183 1.48472 26.9414 2.39967 28.0358 3.73486C29.1302 5.07005 29.7284 6.74307 29.7287 8.46947C29.7287 18.2372 15.3643 26.2812 15.3643 26.2812Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
};

export default FavoriteBtn;

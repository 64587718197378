import { useState } from 'react';
import * as CONSTANT from 'src/constants';
import AddAndRemoveModal from 'src/Features/BoxLunches/ComboSection/AddAndRemoveModal';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { ItemModal, Order } from 'src/models/item.model';
import { groupBy } from 'lodash';
import styles from '../itemDetails.module.scss';
import { useAppSelector } from 'src/redux/store/store';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { isItCreateYourOwnItem } from 'src/helper/helperMethods';
interface propTypes {
  handleSelection: (e: any, size: string) => void;
  order?: Order;
  boxLunchCombo: ItemModal[];
  findSelectedItemAvailability: any;
  Item: any;
  Category: any;
  isSingleItem?: boolean;
  onRemoveClickHandler?: (id: string) => void;
  onEditClickHandler: any;
  setEditFromDetails: any;
}

const index: React.FC<propTypes> = (props) => {
  const {
    order,
    boxLunchCombo,
    findSelectedItemAvailability,
    Item,
    Category,
    handleSelection,
    isSingleItem,
    onRemoveClickHandler,
    onEditClickHandler,
    setEditFromDetails,
  } = props;
  const isSelected =
    order.size === CONSTANT.FULL_SIZE.id ||
    order.size === CONSTANT.HALF_SIZE.id;
  const [AddAndRemoveModalState, setAddAndRemoveModalState] = useState([
    false,
    false,
  ]);
  const substituted = {
    1: { added: null, removed: null },
    2: {
      added: null,
      removed: null,
    },
  };
  const modifiersList = {
    1: {
      addModifiers: [],
      removeModifers: [],
    },
    2: { addModifiers: [], removeModifers: [] },
  };
  const reduxModifiers = useAppSelector(
    (state) => state.itemCustomization.modifiers,
  );
  const buckets = itemBuckets.getBuckets();
  const handleShowCustomization = (id) => {
    if (reduxModifiers[Number(id) - 1].id === null) {
      return;
    }
    const currentBucket = buckets[Number(id) - 1].bucket;
    const bucketKeys = Object.keys(currentBucket);
    bucketKeys.map((bucketKey) => {
      const currentBucetModifiers = [];
      for (
        let index = 0;
        index < currentBucket[bucketKey].additionalAdded.modifiers.length;
        index++
      ) {
        const currentModifier =
          currentBucket[bucketKey].additionalAdded.modifiers[index];
        if (
          (currentModifier?.modifier_group_extendable_limit === 1 ||
            currentModifier?.extendableLimitValue === 1) &&
          currentModifier?.modifier_group_max === 1 &&
          currentModifier?.modifier_group_min === 1 &&
          currentModifier.treat_as === CONSTANT.CORE_RELATED
        ) {
          continue;
        }

        currentBucetModifiers.push(currentModifier);
      }
      const modifiersGrouped = groupBy(currentBucetModifiers, 'modifier_name');

      Object.keys(modifiersGrouped).map((modifierName) => {
        const arrvalue = modifiersGrouped[modifierName];
        modifiersList[id].addModifiers.push({
          name: modifierName,
          quantity: arrvalue[arrvalue.length - 1].quantity,
        });
      });
      if (bucketKey == 'core_modifiers') {
        const filteredModifiers = currentBucket[
          bucketKey
        ].byDefaultAddedV2.modifiers.filter(
          (originalModifier) =>
            !currentBucket[bucketKey].byDefaultAdded.modifiers.some(
              (copyModifier) =>
                originalModifier.modifier_id === copyModifier.modifier_id,
            ),
        );
        modifiersList[id].removeModifers = [...filteredModifiers];
      }
    });
  };
  const handleSubstitutedModifierCustomization = (id) => {
    if (reduxModifiers[Number(id) - 1].id === null) {
      return;
    }
    const currentBucket = buckets[Number(id) - 1].bucket;
    currentBucket.core_modifiers.byDefaultAddedV2.modifiers.map((modifier) => {
      if (
        modifier?.modifier_group_extendable_limit &&
        modifier?.modifier_group_max &&
        modifier?.modifier_group_min
      ) {
        substituted[id].added = modifier;
      }
    });
    if (substituted[id].added === null) return;
    const modifierExist = currentBucket.core_modifiers.modifiers.find(
      (modifier) => {
        return (
          modifier.modifier_group_id === substituted[id].added.modifier_group_id
        );
      },
    );
    if (modifierExist.modifier_id === substituted[id].added.modifier_id) {
      return;
    }

    if (modifierExist) {
      substituted[id].removed = substituted[id].added;
      substituted[id].added = modifierExist;
    }
    return;
  };
  const AndRemoveModalCloseHandler = (index) => {
    AddAndRemoveModalState[index] = false;
    setAddAndRemoveModalState([...AddAndRemoveModalState]);
    // setEditFromDetails(false);
  };
  const AddAndRemoveModalHandler = (index) => {
    setEditFromDetails(true);
    AddAndRemoveModalState[index] = true;
    setAddAndRemoveModalState([...AddAndRemoveModalState]);
  };
  const isItMobile = useCheckMobileScreen();
  const makeASelection = isItMobile
    ? CONSTANT.SELECT_AN_ITEM
    : CONSTANT.MAKE_A_SELECTION;
  const handleModifierCode = (code: string, modifier: any) => {
    const quantity = modifier.quantity > 1 ? `(${modifier.quantity})` : '';
    switch (code.toLocaleLowerCase()) {
      case CONSTANT.ADD.toLocaleLowerCase():
        return (
          <p
            className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
          >
            + {modifier?.modifier_name}
            {quantity}
          </p>
        );
      case CONSTANT.NO.toLocaleLowerCase():
        return (
          <p
            className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
          >
            - {modifier?.modifier_name}
            {quantity}
          </p>
        );
      case CONSTANT.SUB.toLocaleLowerCase():
        return (
          <>
            <span className="ms-0 mb-2 clr-dark-red">{`+ ${modifier?.substituted_modifier?.modifier_name}`}</span>
            <p
              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
            >
              + {modifier?.substituted_modifier?.modifier_name}
            </p>
            <p
              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
            >
              - {modifier?.modifier_name}
            </p>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div
        className={`${
          isSingleItem
            ? 'd-flex align-items-center'
            : 'row justify-content-center'
        }   select_item_size ${styles.radioInputFields}`}
      >
        {boxLunchCombo?.map((section, index) => {
          handleShowCustomization(section.id);
          handleSubstitutedModifierCustomization(section.id);

          const selected = findSelectedItemAvailability(section.id);
          // Get selected Item
          const item = Item(selected, section.id);
          // Get selected category
          const category = Category(selected, section.id);
          const isDisabled =
            item?.is_customizable !== undefined &&
            !item?.is_customizable &&
            !isItCreateYourOwnItem(item?.is_create_your_own);
          return (
            <>
              {item ? (
                <div
                  className={`${isSingleItem ? 'w-50 mx-auto' : 'col-4'}`}
                  key={section.id}
                >
                  <div
                    onClick={() => {
                      AddAndRemoveModalHandler(index);
                    }}
                    className={`${styles.boxlunxhSelectedItems}`}
                    key={section.id}
                  >
                    <div className="">
                      {/* <img
                        src={
                          item
                            ? `${CONSTANT.s3BaseUrl}${item.image}?version=1`
                            : ''
                        }
                        alt="img"
                        className=" m-auto"
                      /> */}
                      <div className="cz-pb-32 px-2">
                        <p
                          className={`${styles.categoryName} f-s20 f-w4 font-plex light-blue`}
                        >
                          {category?.name ? item.name : ''}
                        </p>
                        {modifiersList[section.id].addModifiers.map(
                          (modifier) => (
                            <p
                              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
                            >
                              + {modifier.name}
                              {modifier.quantity > 1
                                ? ` (${modifier.quantity})`
                                : ''}
                            </p>
                          ),
                        )}
                        {modifiersList[section.id].removeModifers.map(
                          (modifier) => (
                            <p
                              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
                            >
                              - {modifier.modifier_name}
                              {modifier.quantity > 1
                                ? ` (${modifier.quantity})`
                                : ''}
                            </p>
                          ),
                        )}
                        {substituted[section.id].added &&
                        substituted[section.id].removed ? (
                          <>
                            {' '}
                            <p
                              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
                            >
                              + {substituted[section.id].added.modifier_name}
                              {substituted[section.id].added.quantity > 1
                                ? ` (${substituted[section.id].added.quantity})`
                                : ''}
                            </p>{' '}
                            <p
                              className={`clr-dark-red f-s14 font-plex lh-normal mb-0 d-inline-block px-2 ${styles.boxLunchesModifiers}`}
                            >
                              - {substituted[section.id].removed.modifier_name}
                              {substituted[section.id].removed.quantity > 1
                                ? ` (${
                                    substituted[section.id].removed.quantity
                                  })`
                                : ''}
                            </p>
                          </>
                        ) : (
                          ''
                        )}
                        {/* <p
                          className={styles.calories}
                        >{`${category?.calories} cal`}</p> */}
                      </div>
                    </div>

                    <div
                      className={`${styles.clickToEdit} font-plex`}
                      key={section.id}
                    >
                      Click to Change
                    </div>
                  </div>
                  <AddAndRemoveModal
                    onRemoveClickHandler={onRemoveClickHandler}
                    onEditClickHandler={onEditClickHandler}
                    showAddAndRemoveModal={AddAndRemoveModalState[index]}
                    hideAddAndRemoveModal={() => {
                      AndRemoveModalCloseHandler(index);
                    }}
                    name={category?.name ? item.name : makeASelection}
                    id={section.id}
                    isCustomizeable={isDisabled}
                  />
                </div>
              ) : undefined}
            </>
          );
        })}
      </div>
    </>
  );
};

export default index;

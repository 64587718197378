import * as Yup from 'yup';

export const selectDeliveryAddressSchema = Yup.object().shape({
  selected_address: Yup.object()
    .shape({
      value: Yup.string().required('Please Select Address').nullable(),
    })
    .nullable()
    .required('Please Select Address'),
});

import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

import { ButtonProps } from './types';

const Button: FC<ButtonProps> = ({
  children,
  className,
  type,
  disabled,
  loading,
  onClick,
  LoaderPosition,
}) => {
  const onButtonClick = () => {
    if (loading) return;
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      type={type}
      className={`btn-large position-relative font-plex  ${className ?? ''}`}
      disabled={disabled}
      onClick={onButtonClick}
    >
      {children}
      {loading && (
        <Spinner
          animation={'border'}
          size="sm"
          className={`ms-2 ${LoaderPosition}`}
        />
      )}
    </button>
  );
};

export default Button;

import CounterCard from 'src/components/Molecules/Card/CounterCard/CounterCard';
import SelectCard from 'src/components/Molecules/Card/SelectCard/SelectCard';
import {
  MODIFIER_LOCATION,
  NO_DRESSING_CAPITALIZE,
  REQUIRED_MODIFIERS,
  TRY_2_COMBO,
  s3BaseUrl,
} from 'src/constants';
import NoDressing from 'src/containers/Modifiers/cards/NoDressing';
import OtherDressing from 'src/containers/Modifiers/cards/OtherDressing';
import {
  isItSubstituteGroup,
  verifyStatus,
} from 'src/helper/customizedItem/customizedItem.helper';
import {
  ICurrentModifier,
  IRequiredModifiersGroupsModifiers,
} from 'src/models/item.model';
import { ISlider } from 'src/models/try2Combo';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const HorizentalSlider: React.FC<ISlider> = (props) => {
  const {
    otherDressingHandler,
    items_required_modifier_groups,
    selectedIngredients,
    handleIngredientSelection,
    isInItemPresent,
    sectionSelected,
    selectedAddedIngredients,
    noDressingHandler,
    isNoDressing,
    noDressingModifiers,
    label,
  } = props;
  let otherRequiredModifiers = false;

  const requiredModifierGroups =
    items_required_modifier_groups &&
    items_required_modifier_groups.length > 0 &&
    items_required_modifier_groups[0];

  const handleClick = (data, in_item) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: items_required_modifier_groups[0]?.id,
        modifier_group_max: items_required_modifier_groups[0]?.max,
        modifier_group_base: items_required_modifier_groups[0]?.base,
        brink_modifier_group_id:
          items_required_modifier_groups[0]?.brink_modifier_group_id,
        in_item,
        modifier_type: REQUIRED_MODIFIERS,
        in_slide: true,
      },
    });
  };

  const showModifier = (modifier, selected) => {
    const additionalAdded = requiredModifiersBucket?.additionalAdded?.modifiers;
    const additionalRemoved =
      requiredModifiersBucket?.additionalRemoved?.modifiers;
    const modifierExistence = checkModifierExistence(
      additionalAdded,
      additionalRemoved,
      modifier,
    );
    if (isInItemPresent) {
      if (modifier?.in_item || selected?.in_slide || modifierExistence) {
        return true;
      } else {
        if (!otherRequiredModifiers) {
          otherRequiredModifiers = true;
        }
        return false;
      }
    } else {
      return true;
    }
  };

  const isShowNoDressing = (): boolean => {
    if (!otherRequiredModifiers && requiredModifierGroups?.min === 0) {
      return true;
    }
    const noDressingExists =
      // eslint-disable-next-line no-prototype-builtins
      requiredModifiersBucket?.hasOwnProperty('noDressings');
    return noDressingExists;
  };

  const checkModifierExistence = (
    additionalAdded: ICurrentModifier[],
    additionalRemoved: ICurrentModifier[],
    modifier,
  ) => {
    let modifierExistence = additionalAdded?.find(
      (mod) => mod.modifier_id === modifier.id,
    );
    if (!modifierExistence) {
      modifierExistence = additionalRemoved?.find(
        (mod) => mod.modifier_id === modifier.id,
      );
    }
    if (!modifierExistence && noDressingModifiers) {
      modifierExistence = noDressingModifiers?.find(
        (mod) => mod.modifier_id === modifier.id,
      );
    }
    if (modifierExistence) {
      return true;
    } else {
      return false;
    }
  };

  const isLimitExceed = (extendable_limit) => {
    if (extendable_limit === 1) {
      return false;
    }
    const isNoDressingExist = selectedAddedIngredients.find(
      (mod: any) => mod.modifier_name === NO_DRESSING_CAPITALIZE,
    );
    if (isNoDressingExist) {
      extendable_limit += 1;
    }
    if (selectedAddedIngredients.length >= extendable_limit) {
      return true;
    } else {
      return false;
    }
  };

  const requiredModifiersBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: REQUIRED_MODIFIERS,
      fromItem: parseInt(sectionSelected),
    });
  })();

  const selectedRequiredModifier = (modifier) => {
    return selectedIngredients?.find(
      (selected) => selected.modifier_id === modifier.id && !selected.core,
    );
  };

  const decideCard = (modifier: IRequiredModifiersGroupsModifiers) => {
    return (
      selectedIngredients?.length > 0 &&
      !isItSubstituteGroup(items_required_modifier_groups) &&
      modifier?.max > 1
    );
  };

  return (
    <>
    <Swiper
      modules={[Navigation, Pagination, Scrollbar]}
      spaceBetween={34}
      slidesPerView={4}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
        1199: {
          slidesPerView: 4,
          spaceBetween: 34,
        },
      }}
      className={`w-100 mb-5`}
    >
      {requiredModifierGroups.required_modifiers_groups_modifiers?.map(
        (modifier, i: number) => {
          const selected = selectedRequiredModifier(modifier);
          return (
            showModifier(modifier, selected) && (
              <SwiperSlide key={i}>
                {decideCard(modifier) ? (
                  <CounterCard
                    id={modifier?.id}
                    brink_id={modifier?.brink_modifier_id}
                    image={`${s3BaseUrl}${modifier?.image}`}
                    title={modifier.name}
                    price={modifier.price}
                    calories={modifier.calories}
                    max={modifier.max}
                    groupMin={requiredModifierGroups.min}
                    is_active={verifyStatus(modifier, MODIFIER_LOCATION)}
                    priceAlternateLabel={selected ? ' ' : ' '}
                    handleClick={(data) => {
                      handleClick(data, modifier?.in_item);
                    }}
                    count={selected?.quantity || 0}
                    from={TRY_2_COMBO}
                    additionalPrice={selected?.additionalPrice}
                    extendableLimit={isLimitExceed(
                      requiredModifierGroups.extendable_limit,
                    )}
                    extendableLimitValue={
                      requiredModifierGroups.extendable_limit
                    }
                  />
                ) : (
                  <SelectCard
                    id={modifier?.id}
                    brink_id={modifier?.brink_modifier_id}
                    image={`${s3BaseUrl}${modifier?.image}`}
                    title={modifier.name}
                    price={modifier.price}
                    groupMin={requiredModifierGroups.min}
                    calories={modifier.calories}
                    is_active={verifyStatus(modifier, MODIFIER_LOCATION)}
                    priceAlternateLabel={selected ? ' ' : ' '}
                    handleClick={(data) => {
                      handleClick(data, modifier?.in_item);
                    }}
                    selected={Boolean(selected)}
                    default_selected={modifier.is_selected}
                    modifierAdditionalPrice={selected?.additionalPrice}
                    extendableLimit={isLimitExceed(
                      requiredModifierGroups.extendable_limit,
                    )}
                    extendableLimitValue={
                      requiredModifierGroups.extendable_limit
                    }
                  />
                )}
              </SwiperSlide>
            )
          );
        },
      )}
      {isShowNoDressing() && (
        <SwiperSlide>
          <NoDressing
            label={requiredModifierGroups?.label}
            groupId={requiredModifierGroups?.id}
            noDressingHandler={noDressingHandler}
            isNoDressing={isNoDressing}
            itemNo={sectionSelected}
          />
        </SwiperSlide>
      )}
      {otherRequiredModifiers && (
        <SwiperSlide>
          <OtherDressing
            groupMin={requiredModifierGroups?.min}
            otherDressingHandler={otherDressingHandler}
            label={label}
          />
        </SwiperSlide>
      )}
    </Swiper>
          {/* {!otherRequiredModifiers && <> <hr/>
          <div className='text-end pb-2 me-4'>
          <button type='button' className='cancel-filled-btn f-s14 f-w5 font-plex text-uppercase'>Cancel</button>
          </div>
          </>
          } */}
          </>
  );
};

export default HorizentalSlider;

import { FC } from 'react';

import CheckMark from '../../../public/assets/checkmark.svg';

import styles from './stepperHeader.module.scss';
import { onStepClick } from 'src/redux/slices/wizardSlice';
import { useAppDispatch } from 'src/redux/store/store';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
interface StepperHeaderProps {
  activeStep: number;
  steps: { id: number | string; title: string }[];
  activeLineWidth: number;
  totalSteps: number;
  lastVisitedRoute: number;
  disableClick?: boolean;
  // setActiveLineWidth: Dispatch<SetStateAction<number>>;
}

type TStep = {
  route: any;
  id: '1' | '2' | '3' | '4' | '5';
  title: string;
};

const StepperHeader: FC<StepperHeaderProps> = ({
  activeStep,
  steps,
  activeLineWidth,
  lastVisitedRoute,
  disableClick,
}) => {
  const dispatch = useAppDispatch();
  const { redirect } = useRedirectToSpecificStep();

  const isItSelected = (index) => {
    if (lastVisitedRoute > index) return <img src={CheckMark} />;
  };

  const stepClickHandler = (step: TStep) => {
    if (disableClick) return;
    const visitAbleRoutes = lastVisitedRoute + 1;
    const id: '1' | '2' | '3' | '4' | '5' = step.id;
    if (Number(id) <= visitAbleRoutes) {
      redirect(id);
      dispatch(onStepClick({ stepId: id }));
    }
  };

  return (
    <div className={styles.stepperHeader}>
      <div className={`${styles.steps} ${styles.headerContent}`}>
        <>
          {steps.map((currentSteps: any, index: any) => (
            <div
              key={currentSteps.id}
              className={`text-center ${styles.minWid} ${
                lastVisitedRoute >= index ? 'cursor-pointer' : 'cursor-auto'
              }`}
              onClick={() => stepClickHandler(currentSteps)}
            >
              <span
                className={`${styles.circle1} ${
                  lastVisitedRoute >= index ? styles.active : ''
                }`}
              >
                {isItSelected(index)}
              </span>
              <p
                className={`${styles.title} ${
                  currentSteps.id == activeStep && styles.activeStepper
                } mb-0 text-capitalize text-nowrap`}
              >
                {currentSteps.title}
              </p>
            </div>
          ))}
          {steps.slice(0, -1).map((step: any, index: any) => (
            <>
              <div className={`${styles.straightLine} `}></div>
              <span
                style={{
                  width: `${activeLineWidth}%`,
                  backgroundColor: '#aa182c',
                }}
                className={`${
                  lastVisitedRoute > index ? styles.activeLine : ''
                }`}
              ></span>
            </>
          ))}
        </>
      </div>
    </div>
  );
};

export default StepperHeader;

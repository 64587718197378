import { createSlice } from '@reduxjs/toolkit';
import { SELECTED_STORE } from 'src/constants';

const selectedStore =
  localStorage.getItem(SELECTED_STORE) &&
  'undefined' !== localStorage.getItem(SELECTED_STORE)
    ? JSON.parse(localStorage.getItem(SELECTED_STORE))
    : '';

export const initialState = {
  selectedStore: '' || selectedStore,
  error: '',
  searchByValue: '',
  isDeliveryToggle: false,
  fromURL: false,
};
export const locationSlice = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: {
    setLocation: (state, action) => {
      const { currentLocation, zipCode } = action.payload;
      state.selectedStore = currentLocation;
      state.searchByValue = zipCode;
    },
    setFromURLTrue: (state) => {
      state.fromURL = true;
    },
    setIsDeliveryToggle: (state) => {
      state.isDeliveryToggle = true;
    },

    unSetIsDeliveryToggle: (state) => {
      state.isDeliveryToggle = false;
    },

    setLocationOrderTypes: (state, action) => {
      const { delivery, pickUp } = action.payload;
      state.selectedStore = state?.selectedStore || {};
      state.selectedStore.order_types = state.selectedStore?.order_types || {};
      state.selectedStore.order_types.Delivery = delivery;
      state.selectedStore.order_types.Pickup = pickUp;
    },
    setLocationError: (state, action) => {
      state.selectedStore = null;
      state.error = action.payload;
    },
    resetLocation: () => initialState,
  },
});

export const {
  setLocation,
  setIsDeliveryToggle,
  unSetIsDeliveryToggle,
  setLocationError,
  resetLocation,
  setLocationOrderTypes,
  setFromURLTrue
} = locationSlice.actions;

export const locationSliceReducer = locationSlice.reducer;

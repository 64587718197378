import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ROUTE_STEP_2, ROUTE_STEP_3, s3BaseUrl } from '../../../constants';
import { ISelectedItemDetailsForModal } from '../../../models/try2Combo';
import { setShowCart } from '../../../redux/slices/cartSlice';
import { useAppDispatch } from '../../../redux/store/store';

import styleClasses from './order.module.scss';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  name: string;
  image: string;
  comboItems?: ISelectedItemDetailsForModal[];
  styleTitle?: string;
  styleName?: string;
  isSingleItem?: boolean;
  isCombo?: boolean;
  item?: any;
}
const AddedModal: React.FunctionComponent<ModalProps> = (props) => {
  const {
    showModal,
    closeModal,
    name,
    image,
    comboItems,
    styleTitle,
    styleName,
    isSingleItem,
    item,
  } = props;
  const dispatch = useAppDispatch();
  const itemImage = comboItems ? (
    <div className="row pt-2">
      {comboItems?.map((item) => {
        return item?.is_selected ? (
          <div className={`${isSingleItem ? 'd-flex flex-column' : 'col-12'}`}>
            <p className="text-start font-plex my-0 f-w5 f-s16">
             {item?.itemName}
            </p>
          </div>
        ) : null;
      })}
    </div>
  ) : null;
  useEffect(() => {
    const isOpenCart = sessionStorage.getItem('recently_item_added_to_cart');
    parseInt(isOpenCart) && openCartAfterAddingAndItem();
  }, []);

  const openCartAfterAddingAndItem = () => {
    dispatch(setShowCart(true));
    sessionStorage.setItem('recently_item_added_to_cart', '0');
  };

  const onContinueClick = () => {
    closeModal();
    // to={`/menu/${menu.name}`}
    // window.location.reload();
  };

  const onViewCartClick = () => {
    window.location.reload();
    sessionStorage.setItem('recently_item_added_to_cart', '1');
  };
  return (
    <>
      <Modal
        show={showModal}
        centered
        // className="theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom added-modal-size"
        className={`theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom ${styleClasses.addedModalSize}`}
        // onHide={closeModal}
      >
        <Modal.Header className="p-4">
          {/* <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={closeModal}
          >
            Close
          </button> */}
          <div className={`${styleClasses.item_title_wrap} menu-title`}>
            <h6
              className={`sub_title1 text-center text-uppercase f-s18 light-blue f-w5 mb-0 font-plex`}
            >
              Added to your cart!
            </h6>
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 py-4">
          <div className="d-flex flex-column h-100 no-shadow">
            <div
              className={`${styleClasses.item_calor_wrap} menu-calories d-flex px-4`}
            >
              <p
                className={`f-s18 light-blue font-plex text-start f-w5 text-start mb-0 ${styleName}`}
              >
                {name}
              </p>
            </div>
            <div className="px-md-4 pt-0 pb-3">{itemImage}</div>
            <hr className="my-2" />
            <div className="d-flex gap-4 justify-content-end px-4 pt-3">
              <button
                type="button"
                className={`cancel-filled-btn text-uppercase`}
                onClick={() => onViewCartClick()}
              >
                View Cart
              </button>
              <Link to={`${ROUTE_STEP_3}/${item}`}>
                <div
                  // type="button"
                  className={` btn-large position-relative py-2 btn-disabled-bg  ${styleClasses.added_modal_btn}`}
                  // onClick={() => onContinueClick()}
                >
                  Continue Ordering
                </div>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddedModal;

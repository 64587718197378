import React from 'react';
import CounterCard from 'src/components/Molecules/Card/CounterCard/CounterCard';
import SelectCard from 'src/components/Molecules/Card/SelectCard/SelectCard';
import * as Constants from 'src/constants';
import {
  isItSubstituteGroup,
  verifyStatus,
} from 'src/helper/customizedItem/customizedItem.helper';
import { selectedIngredient } from 'src/helper/helperMethods';
import { IRequiredModifiersGroupsModifiers } from 'src/models/item.model';

const ItemModifierRequiredGroups: React.FC<any> = (props) => {
  const {
    ingredients,
    selectedIngredients,
    handleClick,
    isLimitExceed,
    customizedItemSize,
    remainingBase,
    showAdditionalPrice,
    itemBase,
    itemModifierItemModalWidth,
    showRedBorderAnimation,
    Item,
    isCreateYourOwnItem,
    isCYOIColSize,
  } = props;

  const decideCard = (modifier: IRequiredModifiersGroupsModifiers) => {
    return (
      selectedIngredientsOfAGroup(ingredients?.id)?.length > 0 &&
      !isItSubstituteGroup(ingredients) &&
      modifier?.max > 1
    );
  };

  const selectedIngredientsOfAGroup = (groupId: number) => {
    return selectedIngredients?.filter(
      (ingredient) => ingredient?.modifier_group_id === groupId,
    );
  };

  return (
    <>
      {ingredients?.required_modifiers_groups_modifiers?.map(
        (modifier, index: number) => {
          const selectedSide = selectedIngredient(
            modifier,
            selectedIngredients,
          );
          return (
            <React.Fragment key={index}>
              <div
                className={`${
                  customizedItemSize
                    ? 'item__modifier_item dressing_items'
                    : 'py-1 py-md-3 col-md-3 col-lg-2 col-4 px-2'
                } ${itemModifierItemModalWidth} ${
                  Item?.is_box_lunch && 'col-lg-3 px-2'
                }
                  ${isCYOIColSize}
                  
                   `}
              >
                {decideCard(modifier) ? (
                  <CounterCard
                    id={modifier?.id}
                    brink_id={modifier?.brink_modifier_id}
                    image={`${Constants.s3BaseUrl}${modifier?.image}`}
                    title={modifier.name}
                    price={modifier.price}
                    calories={modifier.calories}
                    max={modifier.max}
                    groupMin={ingredients.min}
                    is_active={verifyStatus(
                      modifier,
                      Constants.MODIFIER_LOCATION,
                    )}
                    priceAlternateLabel={selectedSide ? ' ' : ' '}
                    handleClick={(data) => {
                      handleClick(data, modifier?.in_item);
                    }}
                    count={selectedSide?.quantity || 0}
                    from={Constants.TRY_2_COMBO}
                    additionalPrice={selectedSide?.additionalPrice}
                    extendableLimit={isLimitExceed(
                      ingredients.extendable_limit,
                    )}
                    extendableLimitValue={ingredients.extendable_limit}
                    itemBase={itemBase}
                    remainingBase={remainingBase}
                    showAdditionalPrice={showAdditionalPrice}
                    showRedBorderAnimation={showRedBorderAnimation}
                    isCreateYourOwnItem={isCreateYourOwnItem}
                  />
                ) : (
                  <SelectCard
                    id={modifier?.id}
                    brink_id={modifier?.brink_modifier_id}
                    image={`${Constants.s3BaseUrl}${modifier?.image}`}
                    title={modifier.name}
                    price={modifier.price}
                    calories={modifier.calories}
                    groupMin={ingredients.min}
                    is_active={verifyStatus(
                      modifier,
                      Constants.MODIFIER_LOCATION,
                    )}
                    handleClick={(data) => {
                      handleClick(data, modifier?.in_item);
                    }}
                    count={selectedSide?.quantity || 0}
                    priceAlternateLabel={selectedSide ? ' ' : ' '}
                    selected={Boolean(selectedSide)}
                    modifierAdditionalPrice={selectedSide?.additionalPrice}
                    extendableLimit={isLimitExceed(
                      ingredients.extendable_limit,
                    )}
                    extendableLimitValue={ingredients.extendable_limit}
                    showRedBorderAnimation={showRedBorderAnimation}
                    isCreateYourOwnItem={isCreateYourOwnItem}
                  />
                )}
              </div>
            </React.Fragment>
          );
        },
      )}
    </>
  );
};

export default ItemModifierRequiredGroups;

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setShowCart } from "../../redux/slices/cartSlice";
import ContactInfoForm from '../../components/Forms/ContactInfoForm';
import { IUserStatusData } from "../../models/customer.modal";
interface IContinueToCheckoutFormProps {
    closeModal?: (boolean) => void;
    userId?: number;
    currentGuestUserInfo?: any;
    showContactInfo?: boolean;
    setShowContactInfo: (value: boolean) => void;
    handleSubmitAsGuest: () => void;
    userStatusData?: IUserStatusData;
}

const ContinueToCheckoutForm: React.FC<IContinueToCheckoutFormProps> = ({
    closeModal,
    userId,
    currentGuestUserInfo,
    showContactInfo,
    setShowContactInfo,
    handleSubmitAsGuest
}) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const proceedToLogin = () => {
        closeModal(true)
        dispatch(setShowCart(false))
        history.push('/login')
    }
    return (
        <div className="justify-content-center app_container_wrap px-1 py-2 text-center">
            <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22">  {showContactInfo ? "Contact Info" : "Don't have an account?"} </h6>
            <div className="text-center">
                {showContactInfo && <ContactInfoForm closeModal={closeModal} currentGuestUserInfo={currentGuestUserInfo} isGuest handleSubmitAsGuest={handleSubmitAsGuest} />}
                {!showContactInfo &&
                    <button
                        type="button"
                        className=" btn-large mb-sm-4 mb-3 d-block mx-auto"
                        onClick={() => setShowContactInfo(true)}
                    >
                        Proceed as Guest
                    </button>
                }
                <button
                    type="button"
                    onClick={proceedToLogin}
                    className="btn btn-custom f-s16 font-Vcf clr-dark-red p-0 ls-05"
                >
                    Login
                </button>
            </div>
        </div>
    );
};

export default ContinueToCheckoutForm;

import {
  CANCELLED,
  DELIVERY,
  DELIVERY_DESTINATION_ID,
  FAILED,
  IN_STORE,
  IN_STORE_DESTINATION_ID,
} from '../../constants';

import { formatTime } from './orderTracking';

export const PickUpMethods = {
  [DELIVERY_DESTINATION_ID]: DELIVERY,
  [IN_STORE_DESTINATION_ID]: IN_STORE,
};

export const OrderStatusHeadline = {
  [CANCELLED]: 'Your Order has been Cancelled',
  [FAILED]: 'Your Order is Failed',
};

export const formatOrderTime = (orderDateTime) => {
  const time = orderDateTime?.split(' ')?.[1];
  return formatTime(time);
};

export const formatOrderHeadline = (orderSatus: string) => {
  if (isOrderCancelledOrFailed(orderSatus))
    return OrderStatusHeadline[orderSatus];
  return null;
};

export const isOrderCancelledOrFailed = (orderStatus: string) => {
  return [CANCELLED, FAILED].includes(orderStatus);
};

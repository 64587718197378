import { useMutation } from 'react-query';
import { sendQuoteMicroService } from '../services';

const sendAQuoteHelper = async (item): Promise<any> => {
    const response = await sendQuoteMicroService.sendAQuote(item);
    return response.data.data;
};

export default function useSendQuote() {
    return useMutation((item: any) => sendAQuoteHelper(item));
}
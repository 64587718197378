import React from 'react';
import { useHistory } from 'react-router';
import { DeleteIcon, EditPencil } from 'src/assets/images/Svgs/svg';
import { STEP5 } from 'src/constants';
import { checkTheStepNo } from 'src/helper/helperMethods';
import { useAppSelector } from 'src/redux/store/store';

interface IActionButtons {
  onEdit: () => void;
  onRemove: () => void;
  isFree?: boolean;
  notEditable?: boolean;
  isCombo?: boolean;
}

const ActionButtons: React.FC<IActionButtons> = (props) => {
  const { onEdit, onRemove, isFree, isCombo, notEditable } = props;
  const wizardSteps = useAppSelector((state) => state.wizard);
  const isThisStep5 = checkTheStepNo(wizardSteps, STEP5);

  return (
    <span className="w-25 d-flex justify-content-end gap-3">
      <button
        type="button"
        className="btn border-0 text-decoration-underline p-0 f-s14 font-rale clr-dark-gray f-w5"
        onClick={onEdit}
        disabled={isFree || isCombo || notEditable ? true : false}
        // disabled
      >
        <EditPencil />
      </button>
      {!isThisStep5 && (
        <button
          type="button"
          className="btn border-0 text-decoration-underline clr-dark-gray p-0 f-s14 font-rale f-w5"
          onClick={onRemove}
        >
          <DeleteIcon />
        </button>
      )}
    </span>
  );
};

export default ActionButtons;

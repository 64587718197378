import React from 'react';
import { INotAvailable } from 'src/models/button'

const NotAvailable: React.FC<INotAvailable> = ({ selectedBtnStyle }) => {
  return (
    <button
      type="button"
      className={`btn item-select-btn mb-2`}
      style={{
        ...selectedBtnStyle,
      }}
      disabled={true}
    >
      Unavailable
    </button>
  );
};

export default NotAvailable;
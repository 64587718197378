import { useMutation } from 'react-query';
import { operatorService } from 'src/services';

const registerCateringOperatorCustomer = async (data: any) => {
  const response = await operatorService.registerOperatorCustomer(data);
  return response.data.data;
};

export default function userRegisterCateringOperatorCustomer() {
  return useMutation(
    (data: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
      is_catering: number;
    }) => registerCateringOperatorCustomer(data),
  );
}

import Button from 'src/components/Button/Button';
import FormField from 'src/components/FormFields/FormField';

import styles from '../shareLink.module.scss';
import { Form } from 'react-bootstrap';

const ShareLinkModal = ({ title, desc, isCsv }) => {
  return (
    <div className="d-flex flex-wrap gap-3 pb-4">
      <div className="text-center">
        <h4 className="f-s18 fw-bold">{title}</h4>
        <p className="f-s16 mb-0">{desc}</p>
      </div>
      <div className="new_form_dsign w-100">
        <div className={`${styles.customShareInput} form-group`}>
          {/* <FormField
            labelText={''}
            name="favorite_location"
            isSearchable={true}
            type={'textarea'}
            placeholder={''}
            inputFieldClass={styles.custom_select_link}
            onChange={undefined}
          /> */}
          <Form.Control
            as="textarea"
            rows={8}
            style={{ resize: 'none' }}
            // placeholder="Provide feedback here..."
          />
        </div>
      </div>
      {isCsv && <Button className="w-100">import</Button>}
      <Button className="w-100">Share group order</Button>
    </div>
  );
};
export default ShareLinkModal;

import { SELECTED_STORE } from "src/constants";
import { setLocation } from "./slice";


export async function setLocationAction(dispatch, locationPayload) {
    try {
    dispatch(setLocation({ currentLocation: locationPayload }))
    localStorage.setItem(SELECTED_STORE, JSON.stringify(locationPayload));
  } catch (error) {
    dispatch({
      type: "LOCATION_ERROR",
      error: error,
    });
  }
}

const ToggleButton = ({
  text,
  setSplitPayment,
  isSplitPayment,
  checked,
  isFound,
  setIsFound,
  disabled = false,
}: {
  text: string;
  setSplitPayment?: (value: boolean) => void;
  isSplitPayment?: boolean;
  checked?: boolean;
  isFound?: boolean;
  setIsFound?: (value: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <>
      <div className="form-group theme_check_boxes Switch_btn rounded">
        <input
          type="checkbox"
          id={`radiobox_cart`}
          name="address_group"
          onChange={() => {
            setSplitPayment(!isSplitPayment);
            // if (!isSplitPayment) handleSplitToggle?.();
            if (isFound) {
              setIsFound(false);
            }
          }}
          disabled={disabled}
          checked={isSplitPayment}
        />
        <label
          htmlFor={`radiobox_cart`}
          className="d-flex align-items-center f-s18 f-sm-s16"
        >
          <span className="box_wrap mt-1"></span>
          <span className={`d-flex justify-content-between info_label`}>
            {text}
          </span>
        </label>
      </div>
    </>
  );
};

export default ToggleButton;

import axios from 'axios';
import { CATERING_OPERATOR_SERVICE } from 'src/constants';

const httpClientCC = axios.create({
  baseURL: CATERING_OPERATOR_SERVICE,
});

httpClientCC.interceptors.request.use(
  (config) => {
    const OPERATOR = JSON.parse(localStorage.getItem('OPERATOR'));
    config.headers['Authorization'] = 'Bearer ' + OPERATOR.token;

    return config;
  },
  (error) => {
    Promise.reject(error);
    console.log(error);
  },
);
export default httpClientCC;

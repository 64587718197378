import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { CalenderDropDown, CalenderIcon } from 'src/assets/images/Svgs/svg';
import Button from 'src/components/Button/Button';
import FormField from 'src/components/FormFields/FormField';
import { brazeLogCustomEventCheckoutStarted } from 'src/helper/brazeHelper';
import { timeSlotPayload } from 'src/helper/checkout/PlaceOrderHelper';
import { formatTime } from 'src/helper/utils';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import useAddPickupSlotMethod from 'src/react-query-hooks/usePickupSlotMethod';
import useTimeZone from 'src/react-query-hooks/useTimeZone';
import { selectPickupSlotSchema } from 'src/validationSchemas/selectPickUpSlotSchema';

// import styleClasses from '../../../../../src/components/ItemGrid/ItemGrid.module.scss';
import styles from '../../../../components/ItemOrderModal/itemOrder.module.scss';
import {
  ASAP_TYPE,
  DELIVERY_ORDER_TYPE,
  NO_TIME_SLOTS_PRESENT,
  PICK_UP_METHOD_CURBSIDE,
  SELECTED_STORE,
  STEP3,
  STEP5,
} from '../../../../constants';
import { ISelectInputState } from '../../../../models/forms.model';
import {
  setShowCart,
  updateOrderDetails,
} from '../../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/store';
import { activeStepsOnDirectAccess, jumbToASpecificStep, nextStep, onStepClick } from 'src/redux/slices/wizardSlice';
import { checkTheStepNo } from 'src/helper/helperMethods';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
import { setPrimaryCapacity } from 'src/redux/slices/handleStatesForSpecificFlows';

interface PickUpSlotsProps {
  subTotal?: number;
  tax?: number;
  total?: number;
  isShownVehicleDetails?: (boolean) => void;
  closePickUpMethodModal?: (boolean) => void;
  closeDeliveryMethodModal?: (boolean) => void;
  pickupMethod?: string;
  isEditSlot?: boolean;
  renderChangeToComponent: () => any;
}

const PickUpSlots = (props: PickUpSlotsProps) => {
  const {
    tax,
    subTotal,
    total,
    isShownVehicleDetails,
    closePickUpMethodModal,
    closeDeliveryMethodModal,
    pickupMethod,
    isEditSlot,
    renderChangeToComponent,
  } = props;

  const queryClient = useQueryClient();

  const wizardSteps = useAppSelector((state) => state.wizard);
  const { currentStep, steps } = wizardSteps;
  const isThisStep3 = checkTheStepNo(wizardSteps, STEP3);

  const primaryCapacityDetails = useAppSelector(state => state.specificFlowStates.primaryCapacity)
  const { redirect } = useRedirectToSpecificStep();

  const { mutateAsync: addPickupSlotMethod, isLoading: isFetchingSlots } =
    useAddPickupSlotMethod();
  const isItMobile = useCheckMobileScreen();
  const [timeSlotValidation, setTimeSlotValidation] = useState<string>('');
  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const [handleContinueButton, setHandleContinueButton] =
    useState<boolean>(true);
  const [asapMinutes, setAsapMinutes] = useState<string>(null);
  const { user: authInfo } = useAppSelector((state) => state.user);
  const primaryCapacity = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity,
  );
  const [dates, setDates] = useState({
    startDate: '',
    lastDate: '',
  });
  const [availablePickUpSlots, setAvailablePickUpSlots] = useState({
    availableTimeSlot: [],
    autoSlectedTimeSlot: '',
  });
  const [pickupSlotValue, setPickupSlotValue] = useState('');
  const [stateOfSlotsFetching, setStateOfSlotsFetching] =
    useState<ISelectInputState>({ state: false, text: null });

  const history = useHistory();
  const locationInfo = useAppSelector((state) => state.location);
  const cart = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const primaryCapacityDispatch = useAppDispatch();

  const orderTypeDispatch = useAppDispatch();
  const selectedPickupSlots = cart?.orderDetails?.PickupSlotDetails || '';
  const orderDetailsDeliveryAddress =
    cart?.orderDetails?.delivery_address || '';
  const vehicleDetails = cart?.orderDetails?.vehicleDetails || '';
  const orderType = cart?.orderType || '';
  const alreadySelectedDate = new Date(selectedPickupSlots?.date);
  const selectedLocationTimezone = JSON.parse(
    localStorage.getItem(SELECTED_STORE),
  )?.time_zone;
  const { data: dateTime, isFetching: isFetchingDateTime } = useTimeZone({
    timeZone: selectedLocationTimezone || '',
  });
  const currentDate = dateTime?.split(' ')?.[0];
  const currentDateTime = new Date(currentDate);
  const primaryDateTime = new Date(primaryCapacity.date);
  const isSelectDateInPast = alreadySelectedDate < currentDateTime;
  const isPrimarySelectedDateInPast = primaryDateTime < currentDateTime;
  const isApiFetching = isFetchingDateTime || isFetchingSlots;
  useEffect(() => {
    if (!selectedPickupSlots?.time?.length) {
      addPickUpSlotsToCart(null);
    }
  }, [availablePickUpSlots]);
  useEffect(() => {
    if ((isSelectDateInPast && !isEditSlot) || !calenderDate) {
      setCalenderDate(currentDate ?? '');
      const pickupSlotObj = {
        date: currentDate,
        time: '',
        pickupMethod:
          orderType == DELIVERY_ORDER_TYPE ? DELIVERY_ORDER_TYPE : pickupMethod,
        isAsapOrder: false,
        asapMinutes: null,
      };
      orderTypeDispatch(
        updateOrderDetails({
          delivery_address: orderDetailsDeliveryAddress,
          vehicleDetails: vehicleDetails,
          PickupSlotDetails: pickupSlotObj,
        }),
      );
    }
  }, [dateTime]);
  const [calenderDate, setCalenderDate] = useState(
    selectedPickupSlots
      ? selectedPickupSlots?.date
      : isPrimarySelectedDateInPast
        ? currentDate
        : primaryCapacity.date,
  );
  let initialFormState;
  initialFormState = {
    selected_slot: selectedPickupSlots?.time
      ? availablePickUpSlots?.availableTimeSlot.filter((slot) => {
          return slot.value === selectedPickupSlots.time;
        }).length
        ? {
            label: formatTime(selectedPickupSlots.time, {
              time: selectedPickupSlots.time,
            }),
            value: selectedPickupSlots.time,
          }
        : availablePickUpSlots?.autoSlectedTimeSlot?.length
        ? {
            label: formatTime(availablePickUpSlots?.autoSlectedTimeSlot, {
              time: availablePickUpSlots?.autoSlectedTimeSlot,
            }),
            value: availablePickUpSlots?.autoSlectedTimeSlot,
          }
        : { label: 'Select Time', value: null }
      : { label: 'Select Time', value: null },
  };

  useEffect(() => {
    const startDate = currentDate;
    const lastDate = getLastDate();
    if (startDate && lastDate) {
      setDates({
        startDate: startDate,
        lastDate: lastDate,
      });

      if (!selectedPickupSlots && dates) {
        addPickUpSlotsToCart(null);
      }
    }
  }, [dates.startDate, dates.lastDate, dateTime]);

  useEffect(() => {
    if (dates.startDate) {
      handlePickUpSlots(
        selectedPickupSlots ? selectedPickupSlots.date : dates.startDate,
      );
    }
  }, [dates.startDate, dateTime]);

  const getLastDate = () => {
    const week = currentDateTime;
    const updated_date = week.setDate(week.getDate() + 180);
    const dateAfterWeek = new Date(updated_date);
    const day = dateAfterWeek.getUTCDate();
    const month = dateAfterWeek.getUTCMonth() + 1;
    const year = dateAfterWeek.getUTCFullYear();
    const lastdateStr =
      year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);

    return lastdateStr;
  };

  const handleCalenderChange = (value: any) => {
    setCalenderDate(value);
    handlePickUpSlots(value);
  };

  const addPickUpSlotsToCart = (value) => {
    const pickupSlotObj = {
      date: value ? value : calenderDate ? calenderDate : dates?.startDate,
      time:
        pickupSlotValue ||
        (availablePickUpSlots.autoSlectedTimeSlot
          ? availablePickUpSlots.autoSlectedTimeSlot
          : selectedPickupSlots.time || ''),
      pickupMethod:
        orderType == DELIVERY_ORDER_TYPE ? DELIVERY_ORDER_TYPE : pickupMethod,
      isAsapOrder: pickupSlotValue
        ? pickupSlotValue?.includes(ASAP_TYPE)
        : availablePickUpSlots?.autoSlectedTimeSlot?.includes(ASAP_TYPE),
      asapMinutes: asapMinutes,
    };
    orderTypeDispatch(
      updateOrderDetails({
        delivery_address: orderDetailsDeliveryAddress,
        PickupSlotDetails: pickupSlotObj,
        vehicleDetails: vehicleDetails,
      }),
    );
    primaryCapacityDispatch(setPrimaryCapacity({
      ...primaryCapacityDetails,
      date: pickupSlotObj.date,
      selected_slot: {
        label: formatTime(pickupSlotObj.time, { time: pickupSlotObj.time }),
        value: pickupSlotObj.time
      }
    }))


    return pickupSlotObj;
  };

  const handleFormSubmission = async () => {
    return addPickUpSlotsToCart(null);
  };

  const toggleContinueButton = (value) => {
    if (value) {
      setTimeSlotValidation('No Time Slots Available');
      setDisableContinue(true);
    } else {
      setTimeSlotValidation('');
      setDisableContinue(false);
    }
  };

  const byDefaultSelectInputState = () => {
    setStateOfSlotsFetching({ state: true, text: 'No Option' });
  };

  const modifyAvailableSlots = (asapTime, slots) => {
    const availableSlots = [];
    const today = new Date().toLocaleDateString('en-US', {
      timeZone: selectedLocationTimezone,
    });
    const currentDate = new Date(today);
    currentDate.setSeconds(0);
    currentDate.setMinutes(currentDate.getMinutes() + asapTime);
    slots.map((item, i) => {
      if (i === 0) availableSlots.push(item);
      else {
        const launchDate = new Date(today + ' ' + item);
        if (new Date(currentDate) <= new Date(launchDate)) {
          availableSlots.push(item);
        }
      }
    });
    setAvailablePickUpSlots({
      availableTimeSlot: availableSlots?.map((item) => {
        return { label: formatTime(item, { time: item }), value: item };
      }),
      autoSlectedTimeSlot: availableSlots[0],
    });
  };

  const handlePickUpSlots = async (value: any) => {
    setStateOfSlotsFetching({ state: true, text: 'Loading...' });
    setHandleContinueButton(true);

    const modifiedValues = {
      location_id: locationInfo.selectedStore?.id?.toString(),
      date: value || dates?.startDate,
      customer_id: authInfo.id,
      catering: 1,
      ...timeSlotPayload(cart),
    };
    const mutate = addPickupSlotMethod;
    return mutate(
      {
        newPickupSlotMethod: modifiedValues,
      },
      {
        onSuccess: (data, variables, context) => {
          if (
            (data?.available_slots != '' &&
              data?.available_slots != NO_TIME_SLOTS_PRESENT) ||
            data?.asap_time
          ) {
            byDefaultSelectInputState();
            if (data?.asap_time) {
              setAsapMinutes(data.asap_time);
              data?.available_slots.unshift(
                'ASAP: ' + data?.asap_time + ' minutes',
              );
            }
            if (selectedPickupSlots.time) {
              if (
                data?.available_slots.filter(
                  (slot) => slot === selectedPickupSlots.time,
                ).length
              ) {
                toggleContinueButton(false);
              } else {
                setTimeSlotValidation(
                  'The time you selected is not available anymore. Please select a different time slot for your order',
                );
                setDisableContinue(true);
              }
            }

            if (data?.asap_time && orderType == DELIVERY_ORDER_TYPE) {
              modifyAvailableSlots(data?.asap_time, data?.available_slots);
            } else {
              setAvailablePickUpSlots({
                availableTimeSlot: data?.available_slots?.map((item) => {
                  return {
                    label: formatTime(item, { time: item }),
                    value: item,
                  };
                }),
                autoSlectedTimeSlot: primaryCapacity?.selected_slot
                  ? data?.available_slots.filter(
                    (slot) => slot === primaryCapacity?.selected_slot?.value,
                  ).length
                    ? selectedPickupSlots.time
                      ? selectedPickupSlots.time
                      : primaryCapacity?.selected_slot?.value
                    : data?.available_slots[0]
                  : data?.available_slots[0],
              });
            }
            if (timeSlotValidation === '') {
              toggleContinueButton(false);
            }

            queryClient.invalidateQueries('pickupSlotMethod');
          } else {
            setAvailablePickUpSlots({
              availableTimeSlot: [],
              autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
            });
            setStateOfSlotsFetching({
              state: true,
              text: 'No Time Slots Available!',
            });
            toggleContinueButton(true);
          }
        },
        onError: (error: any, variables, context) => {
          setAvailablePickUpSlots({
            availableTimeSlot: [],
            autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
          });
          setStateOfSlotsFetching({
            state: true,
            text: 'No Time Slots Available!',
          });
          toggleContinueButton(true);
        },
      },
    );
  };

  const redirectToNextStep = () => {
    if (isThisStep3) {
      dispatch(jumbToASpecificStep({ stepId: STEP5 }));
      redirect(STEP5);
    } else {
      dispatch(nextStep());
      history.push(steps[currentStep + 1]?.route);
    }
  }

  const handleNextButton = async () => {
    // queryClient.refetchQueries(["get-cart", selectedLocation.id]);
    // if (timeSlotValidation == '') {
    if (timeSlotValidation == '') {
      if (orderType == DELIVERY_ORDER_TYPE) {
        //Case: Delievery Method
        const slotDetails = await handleFormSubmission();
        closeDeliveryMethodModal?.(true);
        orderTypeDispatch(setShowCart(false));
        brazeLogCustomEventCheckoutStarted(
          cart,
          { tax, subTotal, total },
          isItMobile,
          slotDetails,
          locationInfo.selectedStore,
        );
        redirectToNextStep()
      } else {
        if (pickupMethod === PICK_UP_METHOD_CURBSIDE) {
          // Case: CurbSide Pickup Method
          isShownVehicleDetails(true);
          handleFormSubmission();
        } else {
          //Case: InStore Pickup Method
          const slotDetails = await handleFormSubmission();

          closePickUpMethodModal(true);
          orderTypeDispatch(setShowCart(false));
          brazeLogCustomEventCheckoutStarted(
            cart,
            { tax, subTotal, total },
            isItMobile,
            slotDetails,
            locationInfo.selectedStore,
          );
          redirectToNextStep()
        }
      }
    } else {
      setAvailablePickUpSlots({
        availableTimeSlot: [],
        autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
      });
      toggleContinueButton(true);
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialFormState}
        onSubmit={handleFormSubmission}
        validationSchema={selectPickupSlotSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitForm,
        }) => (
          <>
            <form
              className="new_form_design pb-5 delivery_location_select full_width_mob show_datepick_calender"
              onSubmit={handleSubmit}
            >
              <Row className="modalBottmBorder m-0">
                <Col lg="8" md="8" className="py-4 ps-4 pe-2">
                  <div className="form-group mb-0">
                    <label className="d-flex justify-content-between f-w4 font-plex light-blue f-s16">
                      Order Date
                    </label>

                    <div className="position-relative" >
                      <input
                        name="date"
                        type="date"
                        required
                        disabled={isApiFetching}
                        min={dates?.startDate}
                        max={dates?.lastDate}
                        value={calenderDate}
                        className={` form-control no-style type-data position-relative`}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(e) => {
                          setFieldValue('selected_slot', NO_TIME_SLOTS_PRESENT);
                          setPickupSlotValue('');
                          handleCalenderChange(e.target.value);
                        }}
                      />
                      <div
                        // className={styleClasses.date_icon_container}
                        data-testid="icon-container "
                      >
                        <CalenderDropDown className="calenderIcon pointerNone" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  lg="4"
                  md="4"
                  className={`py-4 ps-2 pe-4 ${styles.customShareInput}`}
                >
                  <FormField
                    options={availablePickUpSlots.availableTimeSlot}
                    type={'select'}
                    name="selected_slot"
                    labelText={
                      orderType == DELIVERY_ORDER_TYPE
                        ? 'Delivery Time'
                        : 'Pick-Up Time'
                    }
                    placeholder={
                      timeSlotValidation || isApiFetching
                        ? NO_TIME_SLOTS_PRESENT
                        : availablePickUpSlots?.autoSlectedTimeSlot
                          ? formatTime(
                            availablePickUpSlots?.autoSlectedTimeSlot,
                            {
                              time: availablePickUpSlots?.autoSlectedTimeSlot,
                            },
                          )
                          : NO_TIME_SLOTS_PRESENT
                    }
                    errors={errors}
                    touched={touched}
                    value={
                      timeSlotValidation && timeSlotValidation === ''
                        ? '00:00:00'
                        : values.selected_slot
                    }
                    onChange={(slot) => {
                      setFieldValue('selected_slot', slot);
                      toggleContinueButton(false);
                      if (slot) {
                        setPickupSlotValue(slot.value);
                      }
                    }}
                    inputFieldClass={'custom_select mb-0'}
                    handleLoading={{
                      enable: stateOfSlotsFetching.state,
                      inputValue: stateOfSlotsFetching.text,
                    }}
                  />
                  {/* <CalenderDropDown className="calenderIcon pointerNone" /> */}
                </Col>
                <div className="justify-start-center ms-3 mb-4">
                  <span className="d-block f-s14 font-plex light-blue f-w5">
                    {locationInfo.selectedStore.name}
                  </span>
                  <span className="d-block f-s14 font-plex light-blue f-w5">
                    {locationInfo.selectedStore.address}
                  </span>
                  <span className="d-block f-s14 font-plex light-blue f-w5">
                    {locationInfo.selectedStore.city},{' '}
                    {locationInfo.selectedStore.state}{' '}
                    {locationInfo.selectedStore.zip}
                  </span>
                </div>
              </Row>
              {timeSlotValidation && (
                <p className="text-center my-3 f-s14 w-75 mx-auto full_width_mob px-4 clr-dark-red">
                  {timeSlotValidation}
                </p>
              )}
              <div className="text-center">
                <div className="p-3">
                  <Button
                    disabled={
                      disableContinue || isApiFetching || !!timeSlotValidation
                    }
                    loading={
                      handleContinueButton &&
                      !timeSlotValidation &&
                      isApiFetching
                    }
                    type="button"
                    className="global-filled-btn w-75"
                    onClick={() => {
                      handleNextButton();
                    }}
                  >
                    Continue
                  </Button>
                </div>
                {renderChangeToComponent()}
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PickUpSlots;

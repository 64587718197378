import {
  UPPERCASE_ADD,
  UPPERCASE_SUB,
  UPPERCASE_MULTI,
  UPPERCASE_DVISION,
} from 'src/constants';

export enum quantityCounterOperations {
  increase = 'INCREASE',
  decrease = 'DECREASE',
}

export interface suggestedItem {
  name: string;
  full_calories: string;
  id: number;
  price: number;
  image: string;
  title?: string;
  image_url?: string;
  is_active_in_cart?: boolean;
}

export interface rewardItem {
  name: string;
  image: string;
  dueDate: string;
  id: number;
  menu_id: number;
  item_id: string | number;
  category_id?: number | string;
}

export interface cartItem {
  name: string;
  id: string | number;
  item_id: string | number;
  price: number;
  display_price?: number;
  base_price?: number;
  discount_amount: number;
  discount: number;
  gross_price?: number;
  size?: string;
  item_size?: string;
  isRedeemed?: boolean;
  set_id?: string | number;
  category_id?: number | string;
  free?: boolean;
  quantity?: number;
  index?: number;
  available?: boolean;
  unavailable_modifiers?: string[];
  item_type: string;
  isComboItem?: boolean;
  modifiers?: ICartModifier[];
  reward_id?: string;
  cart_directly?: boolean;
  category_name?: string;
  single_size: boolean;
  recipient_name?: string;
  is_redeemed_by_offer?: boolean;
  redeemed_by_offer_discount?: number;
  is_box_lunch: boolean;
  special_instructions: string;
  is_single_item?: boolean;
  is_package?: boolean;
  is_premium?: boolean;
}

export interface ICartModifier {
  id: number;
  modifier_name: string;
  order_item_id: number;
  modifier_id: number;
  modifier_group_id: number;
  code: string;
  sub_with_id: string;
  brink_modifier_group_id: number;
  parent_id: number;
  brink_id: number;
  display_price: number;
  gross_price: number;
  is_seasonal_item: any;
  created_at: Date;
  updated_at: Date;
  modifiers: any;
  size?: string;
  quantity?: number;
  is_item?: boolean;
  name?: string;
  available?: boolean;
  unavailable_modifiers?: string[];
  modifier_type?: string;
}

export interface IItemTreeItem {
  id: number[];
  order_id: number;
  item_id: number;
  brink_id: number;
  display_price: number;
  gross_price: number;
  is_seasonal_item: any;
  created_at: Date;
  updated_at: Date;
  quantity: number;
  set_id: number;
  category_id: number;
  name: string;
  special_request: any;
  free: number;
  reward_id: number;
  disabled: number;
  size: string;
  points: any;
  category_name: string;
  from_category: number;
  modifiers: ICartModifier[];
  price: number;
  isUnavailable: boolean;
}

export interface IItemTree {
  [key: string]: IItemTreeItem[];
}

export interface ICartTree {
  itemTree: IItemTree;
  comboItemTree: any;
  index: number;
}

export type dmasOperationType =
  | typeof UPPERCASE_ADD
  | typeof UPPERCASE_SUB
  | typeof UPPERCASE_DVISION
  | typeof UPPERCASE_MULTI;

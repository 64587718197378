import { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  DELIVERY_ORDER_TYPE,
  PICK_UP_ORDER_TYPE,
  ROUTE_CHECKOUT,
} from 'src/constants';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { IItemPathToRoute } from 'src/models/item.model';
import { useAppSelector } from 'src/redux/store/store';

import ItemDelivery from '../OrderPrerequisite/ItemDelivery';

import styles from './itemOrder.module.scss';
import ItemPickup from './ItemPickup';
import { LocationModel } from 'src/models/location.model';

interface IItemOrderPickupProps {
  itemPath?: IItemPathToRoute;
  closeOpenOrderModal?: () => void;
  editChangeToDelivery?: boolean;
  handleShowDeliveryMethodModal?: (boolean) => void;
  editChangeToPickup?: boolean;
  handleShowPickupMethodModal?: (boolean) => void;
  searchLocations?: boolean;
  editAddressOnCheckout?: any;
  goBack?: boolean;
  showModal?: boolean;
  routeTo?: string;
  initiator?: string;
}
const ItemOrderModal = (props: IItemOrderPickupProps) => {
  const {
    itemPath,
    closeOpenOrderModal,
    editChangeToDelivery,
    handleShowDeliveryMethodModal,
    editChangeToPickup,
    handleShowPickupMethodModal,
    searchLocations,
    editAddressOnCheckout,
    goBack,
    showModal,
    routeTo,
    initiator,
  } = props;

  const [isFindingNearest, setIsFindingNearest] = useState(false);
  const [locations, setLocations] = useState<LocationModel[]>([]);

  const selectedOrderType = useAppSelector(
    (state) => state.cart?.orderType || '',
  );
  const [isShownDeilveryLocation, setIsShownDeilveryLocation] =
    useState<boolean>(false);
  const [isNearestStoreAvailable, setIsNearestStoreAvailable] =
    useState<boolean>(true);
  const [showItemDeliveryDateTime, setShowItemDeliveryDateTime] =
    useState(false);
  const [showItemPickupDateTime, setShowItemPickupDateTime] = useState(false);
  const [saveSelectedOrderType, setSelectedOrderType] = useState<string>(
    selectedOrderType || PICK_UP_ORDER_TYPE,
  );
  const isItMobile = useCheckMobileScreen();
  const location = useLocation();

  const handleOrderType = (orderType: string) => {
    setSelectedOrderType(orderType);
  };

  const shownDeilveryLocation = (isShown) => {
    setIsShownDeilveryLocation(isShown);
  };

  const nearestLocation = (isNearest) => {
    setIsNearestStoreAvailable(isNearest);
  };

  const showItemDeliveryDateTimeModalMethod = () => {
    handleShowDeliveryMethodModal(true);
  };
  const showItemPickupDateTimeModalMethod = () => {
    handleShowPickupMethodModal(true);
  };
  useEffect(() => {
    if (editChangeToDelivery) {
      setShowItemDeliveryDateTime(true);
      handleOrderType(DELIVERY_ORDER_TYPE);
    }
  }, [editChangeToDelivery]);

  useEffect(() => {
    if (editChangeToPickup) {
      setShowItemPickupDateTime(true);
      handleOrderType(PICK_UP_ORDER_TYPE);
    }
  }, [editChangeToPickup]);

  const getDefaultKey = () => {
    return editChangeToPickup
      ? PICK_UP_ORDER_TYPE
      : editChangeToDelivery
        ? DELIVERY_ORDER_TYPE
        : saveSelectedOrderType;
  };
  const [showAddNewAddress, setShowAddNewAddress] = useState<boolean>(false);
  return (
    <div className={`${styles.itemOrderWrapper} itemOrderModal pt-5 pb-4`}>
      {/* {!isShownDeilveryLocation && (
        <>
          <h5 className="f-s22 font-plex light-blue mb-2 text-capitalize full_width_mob py-3 f-w5">
            Select Your Order Type
          </h5>
          <p className="mb-4">Is this order for pick-up or delivery?</p>
        </>
      )} */}
      <Tab.Container defaultActiveKey={getDefaultKey()}>
        {!isShownDeilveryLocation && (
          <Nav variant="pills" className="d-flex justify-content-center gap-4">
            <Nav.Item>
              <Nav.Link
                eventKey={PICK_UP_ORDER_TYPE}
                onClick={() => handleOrderType(PICK_UP_ORDER_TYPE)}
                disabled={editAddressOnCheckout}
              >
                Pick-Up
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={DELIVERY_ORDER_TYPE}
                onClick={() => handleOrderType(DELIVERY_ORDER_TYPE)}
              >
                Delivery
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}

        <Tab.Content className="mx-auto">
          <Tab.Pane eventKey={PICK_UP_ORDER_TYPE}>
            <ItemPickup
              itemPath={itemPath}
              closeOpenOrderModal={closeOpenOrderModal}
              showItemPickupDateTime={showItemPickupDateTime}
              handleShowPickupMethodModal={showItemPickupDateTimeModalMethod}
              saveSelectedOrderType={saveSelectedOrderType}
              isItMobile={isItMobile}
              isCheckout={location.pathname.includes(ROUTE_CHECKOUT)}
              goBack={goBack}
              showModal={showModal}
              routeTo={routeTo}
              initiator={initiator}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={DELIVERY_ORDER_TYPE}>
            <div>
              {!isShownDeilveryLocation && (
                <p className="f-s14 mt-3 light-blue font-plex">
                  Some items cannot be delivered.
                </p>
              )}
              <ItemDelivery
                setShowAddNewAddress={setShowAddNewAddress}
                showAddNewAddress={showAddNewAddress}
                itemPath={itemPath}
                routeTo={routeTo}
                initiator={initiator}
                shownDeilveryLocation={shownDeilveryLocation}
                closeOpenOrderModal={closeOpenOrderModal}
                isNearestStoreAvailable={isNearestStoreAvailable}
                nearestLocation={nearestLocation}
                showItemDeliveryDateTime={showItemDeliveryDateTime}
                handleShowDeliveryMethodModal={
                  showItemDeliveryDateTimeModalMethod
                }
                saveSelectedOrderType={saveSelectedOrderType}
                handleOrderType={handleOrderType}
                isItMobile={isItMobile}
                isCheckout={location.pathname.includes(ROUTE_CHECKOUT)}
                deliveryLocationProps={{
                  setIsFindingNearest,
                  isFindingNearest,
                }}
                setLocations={setLocations}
                editInModal={true}
              />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
export default ItemOrderModal;

import { Col, Row } from 'react-bootstrap';
import { LocationIconBlue } from 'src/assets/images/Svgs/svg';
import { STEP3 } from 'src/constants';

import { CalenderIconBlue } from '../../assets/images/Svgs/svg';
import CartBtn from '../cartBtn/CartBtn';

import styles from './dateLocationSelect.module.scss';
import { checkTheStepNo } from 'src/helper/helperMethods';
import { useAppSelector } from 'src/redux/store/store';
interface DateLocationSelectProps {
  date?: string;
  address?: string;
  dateReadonly?: boolean;
  dateSelectCol?: string;
  adressSelectCol?: string;
  cardBtnCol?: string;
}

const DateLocationSelect = ({
  date,
  address,
  dateReadonly,
  dateSelectCol,
  adressSelectCol,
  cardBtnCol,
}: DateLocationSelectProps) => {
  const wizardSteps = useAppSelector((state) => state.wizard);
  const isThisStep3 = checkTheStepNo(wizardSteps, STEP3);
  return (
    <div className={styles.dateLocationWrap}>
      <Row className={`${styles.dateLocationRow} g-0`}>
        <Col className={dateSelectCol}>
          <div className="form-group h-100">
            <div className="position-relative h-100 d-flex align-items-center ps-4">
              <CalenderIconBlue />
              <input
                name="date"
                type="date"
                placeholder="mm/dd/yy"
                className="form-control no-style type-data light-blue f-w4 font-plex f-s16 border-0 h-100"
                value={date}
                readOnly={dateReadonly}
              />
            </div>
          </div>
        </Col>
        <Col className={adressSelectCol}>
          <div className={`${styles.locationInput} form-group h-100`}>
            <div className="position-relative h-100 d-flex align-items-center ps-3">
              <LocationIconBlue />
              <input
                readOnly
                name="date"
                type="text"
                placeholder="Address"
                className="form-control no-style type-data light-blue f-w4 font-plex f-s16 border-top-0 border-bottom-0 h-100"
                value={address}
              />
            </div>
          </div>
        </Col>
        <Col md="1" className={cardBtnCol}>
          {isThisStep3 && <CartBtn />}
        </Col>
      </Row>
    </div>
  );
};

export default DateLocationSelect;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ITEM_LOCATION, s3BaseUrl } from 'src/constants';

import SelectCard from '../../../../components/Cards/SelectCard/SelectCard';
import {
  IRefStatesForScrolling,
  ISubCategories,
  ItemDetails,
} from '../../../../models/item.model';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';
import styles from './Packeges.module.scss';

type proTypes = {
  trays: ISubCategories[];
  handleSelect: (item: ItemDetails) => void;
  selectedItem: string | number;
  openItemInfoModal: (item: ItemDetails) => void;
  requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
};

const PackegeItemSelect: React.FC<proTypes> = (props) => {
  const {
    trays,
    handleSelect,
    selectedItem,
    openItemInfoModal,
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
  } = props;

  const showRedBorderAnimation = (index) => {
    return requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
      ? !requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
        .isRequiredModifierSelected.status &&
      requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
        .isRequiredModifierSelected.highLightArea === index
      : false;
  };
  const requiredTrays = useGetRequiredTrays(trays);

  return (
    <React.Fragment>
      {requiredTrays?.map((tray, index) => (
        <Container fluid className="px-0">
          <div className="single-item-modifiers row">
            <h3
              className="f-s16 mb-3 f-w6 font-plex"
              ref={
                requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
                  ? requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
                    .refToScrollToMissingRequiredModifiers[index]
                  : null
              }
            >
              {tray.name}
            </h3>
            <Row>

              {tray.items?.map((modifier) => {
                return (
                  <Col lg="3" md="4" sm="4" xs="4" className={`${styles.packegesSaladCard} px-2 mb-3`} 
                    key={modifier?.id.toString()}>
                    <SelectCard
                      id={modifier.id.toString()}
                      name={modifier.name}
                      // clories={modifier.calories}
                      img={`${s3BaseUrl}${modifier?.image}`}
                      modifier={modifier}
                      handleSelect={handleSelect}
                      selectedItem={selectedItem?.toString()}
                      openItemInfoModal={() => {
                        openItemInfoModal(modifier);
                      }}
                      showRedBorderAnimation={showRedBorderAnimation(index)}
                      isActive={verifyStatus(modifier, ITEM_LOCATION)}
                    />

                  </Col>
                );
              })}




            </Row>

          </div>
        </Container>
      ))}
    </React.Fragment>
  );
};

export default PackegeItemSelect;

const useGetRequiredTrays = (trays: ISubCategories[]) => {
  const requriedTrays = trays.filter(
    (tray: ISubCategories) => tray.items.length > 1,
  );
  return requriedTrays;
};

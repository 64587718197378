import { useHistory } from 'react-router';
import {
  ROUTE_STEP_1,
  ROUTE_STEP_2,
  ROUTE_STEP_3,
  ROUTE_STEP_4,
  ROUTE_STEP_5,
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
} from 'src/constants';

export const useRedirectToSpecificStep = () => {
  const history = useHistory();
  const redirect = (
    step:
      | typeof STEP1
      | typeof STEP2
      | typeof STEP3
      | typeof STEP4
      | typeof STEP5,
  ) => {
    switch (step) {
      case STEP1:
        history.push(ROUTE_STEP_1);
        break;
      case STEP2:
        history.push(ROUTE_STEP_2);
        break;
      case STEP3:
        history.push(ROUTE_STEP_3);
        break;
      case STEP4:
        history.push(ROUTE_STEP_4);
        break;
      case STEP5:
        history.push(ROUTE_STEP_5);
        break;
      default:
        break;
    }
  };
  return { redirect };
};

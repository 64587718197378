import React from 'react';
import { Modal } from 'react-bootstrap';
import IMenuItems from './Types';
import styles from '../menu.module.scss';
import ItemPrices from './ItemPrices';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  itemDetials: IMenuItems;
  getClories?: (itemDetials: IMenuItems) => string;
  location?: any;
}
export const ItemDetailsModal: React.FunctionComponent<ModalProps> = (
  props,
) => {
  const { showModal, closeModal, itemDetials, getClories, location } = props;
  return (
    <Modal
      show={showModal}
      centered
      className="theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom"
      onHide={closeModal}
    >
      <Modal.Header className="pt-0">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="px-md-0 px-5">
        <div
          className={`${styles.item_main_div} d-flex flex-column h-100 no-shadow`}
        >
          <div
            className={`${styles.item_title_wrap} menu-title d-flex align-items-start justify-content-start text-start`}
          >
            <h6 className="sub_title1 f-s22 f-sm-s18 mb-0">
              {itemDetials.name}
            </h6>
          </div>

          <div
            className={`${styles.item_calor_wrap} menu-calories d-flex justify-content-start`}
          >
            <h6 className="h-14 clr-text-grey font-rale mb-3"></h6>
          </div>
          <p
            className={`${styles.item_subtitle_wrap_} text-start mb-3 p-16 f-s16`}
          >
            {itemDetials.description}
          </p>
          <h6 className="text-start h-16 font-Vcf ls-normal text-uppercase">
            {location?.selectedStore && <ItemPrices getItem={itemDetials} />}
          </h6>
        </div>
      </Modal.Body>
    </Modal>
  );
};

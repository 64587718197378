import { FC } from 'react';

import Stepper from '../Stepper/Stepper';

import Header from './Header/Header';
import styles from './layout.module.scss';
import { LayoutProps } from './types';

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Stepper />
      <main className={styles.main}>{children}</main>
    </>
  );
};

export default Layout;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CartBucket } from 'src/assets/images/Svgs/svg';
import * as CONSTANT from 'src/constants';
import { guestLogin } from 'src/Features/AccountSetup/redux/actions';
import { getVisitorId } from 'src/helper/helperMethods';
import useGetCustomerPlacedOrders from 'src/react-query-hooks/useGetCustomerPlacedOrders';
import { setShowCart } from 'src/redux/slices/cartSlice';
import { useAppSelector } from 'src/redux/store/store';

import styles from './cartBtn.module.scss';

const CartBtn = () => {
  const { user } = useAppSelector((state) => state?.user);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = cart.items;
  const show = cart.showCart;
  const setShow = (show) => {
    dispatch(setShowCart(show));
  };

  const locationInfo = useAppSelector((state) => state.location);
  const isActiveOrders = useAppSelector(
    (state) => state.orderDetails.futureOrder.activeOrders,
  );
  const location = useLocation();
  const [showLocationSearch, setShowLocationSearch] = useState<boolean>(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [multiOrderModal, setMultiOrderModal] = useState(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const history = useHistory();
  const [addressConfirmationModal, setAddressConfirmationModal] = useState<{
    showModal: boolean;
    showCart: boolean;
  }>({ showModal: false, showCart: false });

  const {
    refetch: refetchGetCustomerPlacedOrders,
    data: { data: placedOrders = [] } = [],
    isFetching: isFetchingPlacedOrders,
    refetch: fetchItem,
  } = useGetCustomerPlacedOrders({ userId: user?.id });
  const isPublicTrackingRoute = location.pathname.includes(
    CONSTANT.ROUTE_ORDER_TRACKING,
  );

  useEffect(() => {
    if (multiOrderModal || location.pathname === '/thankYou') {
      fetchItem();
    }
  }, [multiOrderModal, location]);

  const shouldShowLiveTracker =
    !isFetchingPlacedOrders &&
    placedOrders?.length &&
    !isPublicTrackingRoute &&
    isActiveOrders;
  const authenticateGuest = async () => {
    try {
      setAuthLoading(true);
      const payload = {
        visitor_id: getVisitorId(),
      };
      await guestLogin(dispatch, payload);
      setAuthLoading(false);
      return true;
    } catch (error) {
      setAuthLoading(false);
      return false;
    }
  };

  const verifyAddress = () => {
    return (
      cart.orderType === CONSTANT.DELIVERY_ORDER_TYPE &&
      user.type !== CONSTANT.GUEST_USER &&
      cart?.orderDetails?.delivery_address?.isGuestAddress
    );
  };
  const handleLocationButton = () => {
    if (verifyAddress()) {
      setAddressConfirmationModal({ showModal: true, showCart: false });
      return;
    }
    setShowLocationSearch(true);
    setOpenOrderModal(true);
  };
  const viewCartHandler = async () => {
    if (location.pathname.includes(CONSTANT.ROUTE_CHECKOUT)) {
      history.push(CONSTANT.ROUTE_HOME);
      setShow(true);
    } else {
      if (authLoading) return;
      if (!cart.isOrderTypeSelected) {
        setOpenOrderModal(true);
      } else if (verifyAddress())
        setAddressConfirmationModal({ showModal: true, showCart: true });
      else setShow(true);
    }
  };
  const locationLabel = () => {
    if (cart.isOrderTypeSelected) {
      return cart.orderType == CONSTANT.DELIVERY_ORDER_TYPE
        ? 'Delivery'
        : locationInfo?.selectedStore?.name ?? 'LOCATIONS';
    } else return 'LOCATIONS';
  };
  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
  };

  useEffect(() => {
    if (!user?.id && !authLoading) authenticateGuest();
  }, [user?.id]);
  const dispatch = useDispatch();
  const userId = user?.id ?? '';
  const userType = user?.type ?? '';

  return (
    <>
      <button
        type="button"
        onClick={viewCartHandler}
        className={`${styles.cartBtnWrap}`}
      >
        <CartBucket />
        <span>{cartItems.length} Item</span>
      </button>
    </>
  );
};
export default CartBtn;

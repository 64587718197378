/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOCATION_MENU_SERVICE } from '../constants';

// import { ILocationMenuQueryParams, IMarketListingsQueryParams, INearestLocationQueryParams } from "../models/location.model";
import { HttpService } from './base.service';

class LocationMenuService extends HttpService {
  baseURL = LOCATION_MENU_SERVICE;

  getMarketListings = (data: unknown): Promise<any> =>
    this.get(`markets/listing`, data);
  getNearbyLocations = (data: any): Promise<any> =>
    this.get('locations/nearest/list', data);
  getLocationMenu = (data: any): Promise<any> =>
    this.get('getLocationMenu?is_catering=1', data);
  getLocationMenuItem = (data: any): Promise<any> =>
    this.get('getLocationMenuItem', data);
  getUpsellItems = (data: any): Promise<any> =>
    this.get(
      `upsell/fetch-items?business_date=${data?.business_date}&hour=12&location_id=${data?.location_id}${data?.items}`,
    );
  getAllStates = (): Promise<any> => this.get('getAllStates');
  getRewardCatalog = (customer_id, data): Promise<any> =>
    this.get(`rewardCatalog/${customer_id}`, data);
  getAllLocations = (): Promise<any> => this.get(`getAllLocations`);
  getTags = (): Promise<any> => this.get(`getTags`);
  getTimeZoneTime = (timeZone: string): Promise<any> =>
    this.get(`getCurrentDateTime?timezone=${timeZone}`);
  getMenuCategories = (): Promise<any> => this.get(`categories?is_catering=1`);
  getLocationDetails = (id: number): Promise<any> =>
    this.get(`getLocation/${id}`);
  getFundRaiser = (data: any): Promise<any> =>
    this.get(
      `get-fund-raiser?location_id=${data.location_id}&date=${data.business_date}`,
    );
}

export const locationMenuService = new LocationMenuService();

import React from 'react';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router';
import ItemGrid from 'src/Features/Menu/ItemGrid/ItemGrid';
import { s3BaseUrl } from 'src/constants';
import { checkIsItemActive } from 'src/helper/customizedItem/customizedItem.helper';
import { ISubCategories, ItemDetails } from 'src/models/item.model';
import { useAppSelector } from 'src/redux/store/store';
import { getFilteredItems } from 'src/helper/helperMethods';

type ItemsTypes = {
  items: ISubCategories;
  styles: CSSModuleClasses;
  handleChooseDressing: () => void;
  handleCustomize: (item: any) => void;
  addToComboClickHandler?: (item: ItemDetails) => void;
  isEdit:boolean
};

const Items: React.FC<ItemsTypes> = (props) => {
  const { items, styles, handleChooseDressing, handleCustomize, isEdit } = props;
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected,
  );
  const savedOrderType = useAppSelector((state) => state.cart.orderType);
  const orderType = isOrderTypeSelected ? savedOrderType : '';
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const isBoxLunch = query.get('is_box_lunch');

  const boxFilter = JSON.parse(localStorage.getItem('boxFilter')) || [];
  const getFilteredItemDetails = (): any => {
    let searchArray = [];
    let menuItemsToSearchFrom = items?.items;

    menuItemsToSearchFrom = getFilteredItems(items?.items, boxFilter);
    searchArray = [...menuItemsToSearchFrom];
    return searchArray;
  };
  const ItemsToShow = isBoxLunch && !isEdit ? getFilteredItemDetails() : items?.items;
  return (
    <div className="row">
      {ItemsToShow?.length || isEdit ? (
        ItemsToShow?.map((data, index) => {
          return (
            <Col xl="3" md="6" lg="6" sm="12" className="mb-4" key={index}>
              <ItemGrid
                favIcon={`${styles.fav_icon_fill}`}
                itemTitle={data.name}
                itemImage={`${s3BaseUrl}${data?.image}`}
                itemDescription={data.description}
                selectButton="add to box"
                customizeButton="customize"
                handleChooseDressing={handleChooseDressing}
                handleCustomize={handleCustomize}
                item={data}
                disableItem={!checkIsItemActive(data, orderType)}
                addToComboClickHandler={props.addToComboClickHandler}
              />
            </Col>
          );
        })
      ) : (
        <>
          <h1 className="text-start f-s20 f-w5 fw-normal opr-heading-clr mb-2 font-plex">
            {items.name}
          </h1>
          <div className="text-center mt-4">
            <h2 className="mb-3 f-s30">Items not Found</h2>
            <p className="f-s16 f-w4">
              {' '}
              Sorry! We couldn’t find what you were looking for. Might we
              suggest some Cafe Zupas fan favorites...
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Items;

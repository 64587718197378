import { useMutation } from 'react-query';

import {
  IPersonalInfoFormFields,
  PhoneNumberFormFields,
} from '../models/forms.model';
import { customerBusinessService } from '../services';

const updateProfile = async (
  editedProfile: IPersonalInfoFormFields | PhoneNumberFormFields,
  authentication_token: string,
) => {
  const response = await customerBusinessService.updateCustomerProfile(
    editedProfile,
  );
  return response.data.data;
};

export default function useUpdateProfile() {
  return useMutation(
    (userInfo: {
      editedProfile: IPersonalInfoFormFields | PhoneNumberFormFields;
      authentication_token: string;
    }) => updateProfile(userInfo.editedProfile, userInfo.authentication_token),
  );
}

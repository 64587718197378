import { createSlice } from '@reduxjs/toolkit';

import { ADD, COMPLEMENTARY_ITEM, HALF_SIZE, REQUIRED_ITEM } from '../../constants';
import { Codes } from '../../models/item.model';
import { toFixedNumber } from '../../priceCalculation/helper';
import {
  categoryObject,
  complementaryObject,
  coreIngredientSelectionHelper,
  ingredientSelectionHelper,
  ingredientSubstitutionHelper,
  itemExtraPrice,
  itemExtraPriceWhileAdding,
  itemIngredientSelectionHelper,
  itemSize,
  price,
} from '../utility';
const initialState = {
  size: null,
  modifiers: [],
  item_id: null,
  id: null,
  name: null,
  display_price: null,
  original_price: null,
  item_calories: null,
  reRender: false,
  makeAnItemTry2Combo: {
    bucket: null,
    item: null,
    order: null,
  },
};

export const itemCustomizationSlice = createSlice({
  name: 'itemCustomization',
  initialState,
  reducers: {
    updatePriceForItem: (state, action) => {
      const itemModifiers = [...state.modifiers];
      const calories = 0;
      const { itemId, activeBucket } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === itemId.toString()) {
          toFixedNumber((itemModifiers[i].price -= activeBucket.originalPrice));
          toFixedNumber(
            (itemModifiers[i].price += activeBucket.additionalPrice),
          );
        }
      }
      state.display_price = toFixedNumber(
        state.display_price - activeBucket.originalPrice,
      );
      state.display_price = toFixedNumber(
        state.display_price + activeBucket.additionalPrice,
      );
      activeBucket.originalPrice = activeBucket.additionalPrice;
      console.log(
        `%c$${state.display_price}`,
        'color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold',
      );
    },

    addItemAsModifiersPriceInTotalPrice: (state, action) => {
      const {
        extraPrice,
        extraPriceAction,
      }: { extraPrice: number; extraPriceAction: '+' | '-' } = action.payload;
      switch (extraPriceAction) {
        case '+':
          return {
            ...state,
            display_price: toFixedNumber(state.display_price + extraPrice),
          };
        case '-':
          return {
            ...state,
            display_price: toFixedNumber(state.display_price - extraPrice),
          };
        default:
          return state;
      }
    },
    updatePriceForAllItem: (state, action) => {
      const itemModifiers = [...state.modifiers];
      action.payload.forEach((element) => {
        const { itemId, activeBucket } = element;
        if (activeBucket === undefined) return;

        for (let i = 0; i < itemModifiers?.length; i++) {
          if (itemModifiers[i].item === itemId.toString()) {
            toFixedNumber(
              (itemModifiers[i].price -= activeBucket?.originalPrice),
            );
            toFixedNumber(
              (itemModifiers[i].price += activeBucket?.additionalPrice),
            );
          }
        }

        if (element.isCustomize)
          state.display_price = toFixedNumber(
            state.display_price - activeBucket.originalPrice,
          );
        state.display_price = toFixedNumber(
          state.display_price + activeBucket.additionalPrice,
        );
        activeBucket.originalPrice = activeBucket.additionalPrice;
        console.log(
          `%c$${state.display_price}`,
          'color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold',
        );
      });
    },
    makeAnItemToTry2Combo: (state, action) => {
      const { bucket, item, order } = action.payload;
      state.makeAnItemTry2Combo = {
        bucket,
        item,
        order,
      };
    },
    updateSpecificItemSelectedModifiers: (state, action) => {
      const { itemModifiers, itemId } = action.payload;
      const items = state.modifiers;
      for (let i = 0; i < items.length; i++) {
        if (items[i]?.item === itemId.toString()) {
          items[i].modifiers = itemModifiers;
        }
      }
    },
    updateModifiers: (state, action) => {
      const items = [...state.modifiers];
      action.payload.forEach((item) => {
        const { itemModifiers, itemId } = item;
        for (let i = 0; i < items.length; i++) {
          if (items[i]?.item === itemId.toString()) {
            items[i].modifiers = itemModifiers;
          }
        }
      });
      state.modifiers = [...items];
    },
    resetComboItemModifiersByItemId: (state, action) => {
      const { itemId } = action.payload;
      state.modifiers[itemId].modifiers = [];
    },
    removeTemporarilyAddedPriceForItem: (state, action) => {
      const { price, item } = action.payload;
      //
      const itemModifiers = [...state.modifiers];
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === item.toString()) {
          itemModifiers[i].price = toFixedNumber(
            itemModifiers[i].price - price,
          );
        }
      }
      state.display_price = toFixedNumber(state.display_price - price);
      state.reRender = !state.reRender;
      console.log(
        `%c$${state.display_price}`,
        'color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold',
      );
    },
    addItem: (state, action) => {
      const { id, name, price, calories, half_brink_item_id } = action.payload;
      return {
        ...state,
        modifiers: [
          categoryObject('1'),
          complementaryObject(COMPLEMENTARY_ITEM),
        ],
        item_id: id,
        name: name,
        display_price: 0,
        original_price: 0,
        brink_id: half_brink_item_id,
        item_calories: calories,
      };
    },
    editItem: (state, action) => {
      return {
        ...state,
        ...action.payload,
        size: action.payload.item_size,
        modifiers: state.modifiers,
        display_price: 0,
        original_price: 0,
      };
    },
    addPackagesItem: (state, action) => {
      const { item, isPremium } = action.payload;
      const { id, name, price, half_brink_item_id, is_single_item } = item;
      const mod: any = isPremium
        ? [
            categoryObject('1'),
            categoryObject('2'),
            categoryObject('3'),
            complementaryObject(REQUIRED_ITEM),
          ]
        : [
            categoryObject('1'),
            categoryObject('2'),
            complementaryObject(REQUIRED_ITEM),
          ];
      const byDefaultAddedTrays = item.package_categories
        .filter((tray) => tray.items.length === 1)
        .filter(
          (filteredTray) =>
            filteredTray.items[0].sub_items_required_modifier_groups.length > 0,
        );
      for (let index = 0; index < byDefaultAddedTrays.length; index++) {
        (mod[index].category_id = byDefaultAddedTrays[index].id),
          (mod[index].id = byDefaultAddedTrays[index].items[0].id),
          (mod[index].item_id = byDefaultAddedTrays[index].items[0].id),
          (mod[index].modifier_id = byDefaultAddedTrays[index].items[0].id),
          (mod[index].price = 0),
          (mod[index].basePrice = byDefaultAddedTrays[index].items[0].price),
          (mod[index].code = ADD),
          (mod[index].size = HALF_SIZE.id),
          (mod[index].name = byDefaultAddedTrays[index].items[0].name);
      }
      return {
        ...state,
        modifiers: [...mod],
        item_id: id,
        name: name,
        display_price: 0,
        original_price: 0,
        code: Codes.ADD,
        is_package: true,
        brink_id: half_brink_item_id,
        id: null,
      };
    },
    sizeSelection: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    ingredientSelection: (state, action) => {
      const selectedModifiers = [...state.modifiers];
      const modifiers = ingredientSelectionHelper({
        selectedModifiers,
        payload: action.payload,
      });
      state.modifiers = modifiers;
    },
    substituteSelection: (state, action) => {
      const obj = ingredientSubstitutionHelper({
        initialState: state,
        payload: { ...action.payload },
      });
      //Update All State One By One
      const keys = Object.keys(obj);
      keys.map((key) => {
        state[key] = obj[key];
      });
    },
    syncIngredientSelection: (state, action) => {
      const { modifiers } = action.payload;
      return { ...state, modifiers: [...modifiers] };
    },
    addTry2ComboItem: (state, action) => {
      const { id, name, price, half_brink_item_id, is_single_item } =
        action.payload;
      const mod = is_single_item
        ? [
            categoryObject('1'),
            complementaryObject(COMPLEMENTARY_ITEM),
            complementaryObject(REQUIRED_ITEM),
          ]
        : [
            categoryObject('1'),
            categoryObject('2'),
            complementaryObject(COMPLEMENTARY_ITEM),
            complementaryObject(REQUIRED_ITEM),
          ];
      return {
        ...state,
        modifiers: [...mod],
        item_id: id,
        name: name,
        display_price: 0,
        original_price: 0,
        code: Codes.ADD,
        brink_id: half_brink_item_id,
        id: null,
      };
    },
    addAddChoseForMeSize: (state, action) => {
      const { size, item_id } = action.payload;
      return {
        ...state,
        size: size,
        id: item_id,
      };
    },
    addTry2ComboItemBasePrice: (state, action) => {
      const { price } = action.payload;
      return {
        ...state,
        display_price: price,
        original_price: price,
      };
    },
    resetChooseForMe: (state, action) => {
      const modifier = state.modifiers;
      const { is_single_item } = action.payload;
      const mod = is_single_item
        ? [categoryObject('1'), modifier[1], modifier[2]]
        : [categoryObject('1'), categoryObject('2'), modifier[2], modifier[3]];
      return {
        ...state,
        modifiers: mod,
        // display_price: resetPrice ? 0 : state.display_price,
        // original_price: resetPrice ? 0 : state.original_price,
        display_price: 0,
        original_price: 0,
      };
    },
    test: (state, action) => {
      const { price } = action.payload;
      return {
        ...state,
        display_price: price,
        original_price: price,
      };
    },
    addKidsComboItem: (state, action) => {
      const { id, name, price, half_brink_item_id } = action.payload;
      return {
        ...state,
        modifiers: [
          categoryObject('1'),
          categoryObject('2'),
          categoryObject('3'),
          complementaryObject(COMPLEMENTARY_ITEM),
        ],
        item_id: id,
        name: name,
        display_price: price,
        original_price: price,
        code: Codes.ADD,
        brink_id: half_brink_item_id,
      };
    },
    editTry2ComboItem: (state, action) => {
      const { id, modifiers } = action.payload;
      state.id = id;
      modifiers.forEach((modifier, i: number) => {
        if (modifier.is_item) {
          state.modifiers[i] = {
            item: (i + 1).toString(),
            id: modifier.self_category_id,
            modifier_id: modifier.modifier_id,
            item_id: modifier.self_category_id,
            modifier_name: modifier.self_category_name,
            name: modifier.self_category_name,
            modifier_size: modifier.modifier_size,
            quantity: modifier.quantity,
            category_id: modifier.category_id,
            is_selected: true,
            code: 'Add',
            size: modifier.modifier_size,
          };
        }
      });
    },
    makeAnItemTry2Combo: (state) => {
      const itemModifiers = [...state.modifiers];
      const { bucket: items, order, item } = state.makeAnItemTry2Combo;
      itemModifiers[0].modifiers = order?.modifiers[0]?.modifiers;
      itemModifiers[0].price = order?.modifiers[0]?.price;
      itemModifiers[0].basePrice = item?.basePrice;
      itemModifiers[0].category_id = order?.modifiers[0]?.category_id;
      state.display_price = toFixedNumber(
        state.display_price + order?.modifiers[0]?.price,
      );
      state.modifiers = itemModifiers;
    },
    try2ComboIngredientSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let modifiers = null;
      let selectedModifiers = null;
      const { data, sectionSelected } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedModifiers = itemModifiers[i].modifiers;
          modifiers = itemIngredientSelectionHelper({
            selectedModifiers,
            payload: data,
          });
          selectedModifiers = modifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    try2ComboCoreIngredientSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let modifiers = null;
      let selectedModifiers = null;
      const { data, sectionSelected } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedModifiers = itemModifiers[i].modifiers;
          modifiers = coreIngredientSelectionHelper({
            selectedModifiers,
            payload: data,
          });
          selectedModifiers = modifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    try2ComboComplementaryItemsSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let modifiers = null;
      let selectedModifiers = null;
      const { data } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === COMPLEMENTARY_ITEM) {
          selectedModifiers = itemModifiers[i].modifiers;
          modifiers = itemIngredientSelectionHelper({
            selectedModifiers,
            payload: data,
          });
          selectedModifiers = modifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    addTry2ComboSectionItem: (state, action) => {
      const selectedItemIndex = state.modifiers.findIndex(
        (mod) => mod.section == action.payload.section,
      );
      selectedItemIndex !== -1 && state.modifiers.splice(selectedItemIndex, 1);
      const selectedModifiers = state.modifiers.filter(
        (mod) => mod.parent_id != action.payload.parent_id,
      );

      selectedModifiers.push({
        ...action.payload,
      });
      return {
        ...state,
        modifiers: selectedModifiers,
      };
    },
    try2ComboCategorySelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let selectedItem = null;
      const { selected, sectionSelected } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.name = selected.name;
          selectedItem.basePrice = selected.base;
          selectedItem.category_id = selected.id;
          selectedItem.size = itemSize(selected.half_loyalty_label);
        }
      }
      state.modifiers = itemModifiers;
    },
    editComboItems: (state, action) => {
      state.id = action.payload?.id ?? null;
      const itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if (itemModifiers[2] && itemModifiers[2].price) {
        display_price = state.display_price - itemModifiers[2].price;
      }
      const { sectionSelected, item, category, itemName, size } =
        action.payload;

      const itemPrice = item?.half_brink_item_price
        ? item?.half_brink_item_price
        : item?.price;
      console.log(
        toFixedNumber(
          state.display_price + itemExtraPrice(category?.base, itemPrice),
        ),
        category.base,
        itemPrice,
        state.display_price,
      );
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.category_id = item?.category_id;
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.item_id = item?.id;
          selectedItem.modifier_id = item?.id;
          selectedItem.name = item?.name;
          selectedItem.modifier_name = item?.name;
          selectedItem.size = itemSize(item?.half_brink_item_id);
          selectedItem.code = 'Add';
          selectedItem.price =
            itemModifiers[i]?.item === COMPLEMENTARY_ITEM
              ? 0
              : itemExtraPriceWhileAdding({
                  basePrice: category?.base,
                  selectedItemPrice: itemPrice,
                  item: itemModifiers[i]?.item,
                  itemName,
                });
          state.display_price = toFixedNumber(
            state.display_price + itemExtraPrice(category?.base, itemPrice),
          );
        }
      }
      state.modifiers = itemModifiers;
      state.size = size;
    },
    try2ComboItemSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if (itemModifiers[2] && itemModifiers[2].price) {
        display_price = state.display_price - itemModifiers[2].price;
      }

      const { sectionSelected, item, itemName } = action.payload;
      const itemPrice = item?.half_brink_item_price
        ? item?.half_brink_item_price
        : item?.price;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.item_id = item?.id;
          selectedItem.modifier_id = item?.id;
          selectedItem.category_id = item?.category_id;
          selectedItem.name = item?.name;
          selectedItem.modifier_name = item?.name;
          selectedItem.size = itemSize(item?.half_brink_item_id);
          selectedItem.code = 'Add';
          selectedItem.price =
            itemModifiers[i]?.item === COMPLEMENTARY_ITEM
              ? 0
              : itemExtraPriceWhileAdding({
                  basePrice: itemModifiers[i]?.basePrice,
                  selectedItemPrice: itemPrice,
                  item: itemModifiers[i]?.item,
                  itemName,
                });
          state.display_price = toFixedNumber(
            state.display_price +
              itemExtraPrice(itemModifiers[i]?.basePrice, itemPrice),
          );
        }
      }

      state.modifiers = itemModifiers;
    },
    kidsComboItemSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if (itemModifiers[3] && itemModifiers[3].price) {
        display_price = state.display_price - itemModifiers[3].price;
      }
      const { selectedItemId, item, itemName } = action.payload;
      const itemPrice = item?.half_brink_item_price
        ? item?.half_brink_item_price
        : item?.price;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === selectedItemId) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.item_id = item?.id;
          selectedItem.modifier_id = item?.id;
          selectedItem.name = item?.name;
          selectedItem.modifier_name = item?.name;
          selectedItem.code = Codes.ADD;
          selectedItem.calories = item?.calories;
          selectedItem.price =
            itemModifiers[i]?.item === COMPLEMENTARY_ITEM
              ? 0
              : itemExtraPriceWhileAdding({
                  basePrice: itemModifiers[i]?.basePrice,
                  selectedItemPrice: itemPrice,
                  item: itemModifiers[i]?.item,
                  itemName,
                });
          state.display_price = toFixedNumber(
            state.display_price +
              itemExtraPrice(itemModifiers[i]?.basePrice, itemPrice),
          );
        }
      }
      state.modifiers = itemModifiers;
    },
    editKidsComboItemSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if (itemModifiers[3] && itemModifiers[3].price) {
        display_price = state.display_price - itemModifiers[3].price;
      }
      const { sectionSelected, item } = action.payload;
      state.id = action.payload?.id ?? null;
      const itemPrice = item?.half_brink_item_price
        ? item?.half_brink_item_price
        : item?.price;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.modifier_id;
          selectedItem.item_id = item?.modifier_id;
          selectedItem.modifier_id = item?.modifier_id;
          selectedItem.name = item?.modifier_name;
          selectedItem.modifier_name = item?.name;
          selectedItem.code = Codes.ADD;
          selectedItem.calories = item?.calories;
          selectedItem.price = price(
            item.group_base_price,
            itemPrice,
            state.original_price,
            display_price,
            sectionSelected === '1' ? itemModifiers[1] : itemModifiers[0],
          );
          state.display_price = toFixedNumber(
            state.display_price +
              itemExtraPrice(item.group_base_price, itemPrice),
          );
        }
      }
      state.modifiers = itemModifiers;
    },
    try2ComboSwapItemSelection: (state, action) => {
      const itemModifiers = [...state.modifiers];
      let selectedItem = null;
      const { sectionSelected, item } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.name = item?.name;
        }
      }
      state.modifiers = itemModifiers;
    },
    syncTry2IngredientSelection: (state, action) => {
      const { item_id, modifiers } = action.payload;
      const updatedItems = state.modifiers.map((mod) =>
        mod.modifier_id == item_id
          ? { ...mod, modifiers: [...modifiers] }
          : { ...mod },
      );
      return { ...state, modifiers: updatedItems };
    },
    try2IngredientSelection: (state, action) => {
      const { item_id } = action.payload;
      const selectedItem = state.modifiers.find((mod) => {
        return mod.modifier_id == item_id;
      });
      const selectedModifiers = ingredientSelectionHelper({
        selectedModifiers: [...selectedItem.modifiers],
        payload: action.payload,
      });
      const updatedItems = state.modifiers.map((mod) =>
        mod.modifier_id == item_id
          ? { ...mod, modifiers: selectedModifiers }
          : { ...mod },
      );
      return { ...state, modifiers: updatedItems };
    },
    try2SubstituteSelection: (state, action) => {
      const { item_id } = action.payload;

      const selectedItem = state.modifiers.find(
        (mod) => mod.modifier_id == item_id,
      );
      const cloneItemCustomization = ingredientSubstitutionHelper({
        initialState: selectedItem,
        payload: action.payload,
      });
      const updatedItems = state.modifiers.map((mod) =>
        mod.modifier_id == item_id ? { ...cloneItemCustomization } : { ...mod },
      );
      return { ...state, modifiers: [...updatedItems] };
    },
    removeTry2ComboIngredients: (state, action) => {
      const { modifiers, sectionSelected, order } = action.payload;
      const itemModifiers = [...state.modifiers];
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          for (let j = 0; j < modifiers.length; j++) {
            if (modifiers[j].quantity) {
              const index = itemModifiers[i].modifiers.findIndex((item) => {
                return item.modifier_id === modifiers[j].modifier_id;
              });
              if (index === -1) {
                itemModifiers[i].modifiers.push(modifiers[j]);
              } else {
                itemModifiers[i].modifiers[index].quantity =
                  modifiers[j].oldQuantity;
              }
            } else {
              const index = itemModifiers[i].modifiers.findIndex((item) => {
                return item.modifier_id === modifiers[j].modifier_id;
              });
              if (index === -1) {
                itemModifiers[i].modifiers.push(modifiers[j]);
              } else {
                itemModifiers[i].modifiers.splice(index, 1);
              }
            }
          }
        }
      }
      state.modifiers = itemModifiers;
    },
    removeTry2ComboCustomizeItems: (state, action) => {
      const { modifiers, sectionSelected } = action.payload;
      const itemModifiers = [...state.modifiers];
      let selectedModifiers = null;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          for (let j = 0; j < modifiers.length; j++) {
            const index = itemModifiers[i].modifiers.findIndex(
              (item) => item.modifier_id === modifiers[j].modifier_id,
            );
            itemModifiers[i].modifiers.splice(index, 1);
          }
          selectedModifiers = itemModifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    removeSpecificItem: (state, action) => {
      const { id } = action.payload;
      const items = [...state.modifiers];
      let price = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].item === id) {
          // for (let j = 0; j < items[i].modifiers.length; j++) {
          //  price+=items[i].modifiers[j].additionalPrice
          // }
          price = items[i].price;
          items[i].category_id = null;
          items[i].id = null;
          delete items[i]['is_selected'];
          items[i].modifiers = [];
          items[i].name = null;
          items[i].price = null;
        }
      }
      state.modifiers = items;
      state.display_price = toFixedNumber(state.display_price - price);
    },
    resetTry2ComboContext: (state) => {
      const items = [...state.modifiers];
      let price = 0;
      for (let i = 0; i < 2; i++) {
        price += items[i].price;
        items[i].category_id = null;
        items[i].id = null;
        delete items[i]['is_selected'];
        items[i].modifiers = [];
        items[i].name = null;
        items[i].price = null;
      }
      state.modifiers = items;
      state.display_price = toFixedNumber(state.display_price - price);
    },
    resetTry2ComboItemContext: (state, action) => {
      const items: any = [...state.modifiers];
      const { category_id } = action.payload;
      for (let i = 0; i < items.length - 1; i++) {
        if (items[i].category_id === category_id) {
          items[i].category_id = null;
          items[i].id = null;
          items[i].modifiers = [];
          items[i].name = null;
          items[i].price = null;
        }
      }
      state.modifiers = items;
    },
    resetBoxLunchesPrice: (state, payload) => {
      resetContext();
      state.display_price = payload.payload;
    },
    resetBoxLunchesSize: (state) => {
      state.size = '';
    },
    swapTry2ComboContext: (state) => {
      const swapKeysValue = ['category_id', 'name'];
      const items = [...state.modifiers];
      const firstItem = { ...items[0] };
      for (let i = 0; i < swapKeysValue.length; i++) {
        items[0][swapKeysValue[i]] = items[1][swapKeysValue[i]];
        items[1][swapKeysValue[i]] = firstItem[swapKeysValue[i]];
      }
      state.modifiers = items;
    },
    removeCategory: (state, action) => {
      const { item_id } = action.payload;
      const selectedItemIndex = state.modifiers.findIndex(
        (mod) => mod.modifier_id == item_id,
      );
      state.modifiers.splice(selectedItemIndex, 1);
      return { ...state };
    },
    resetMadedTryTwoComboItemData: (state) => {
      state.makeAnItemTry2Combo = {
        bucket: null,
        item: null,
        order: null,
      };
    },
    resetContext: (state) => {
      state.size = null;
      state.modifiers = [];
      state.item_id = null;
      state.name = null;
      state.display_price = null;
      state.original_price = null;
      state.item_calories = null;
      state.reRender = false;
      state.makeAnItemTry2Combo = {
        bucket: null,
        item: null,
        order: null,
      };
      state.id = null;
    },

    resetOrderInBucket: (state, action) => {
      const { itemNo } = action.payload;
      const itemIndex = state.modifiers.findIndex(
        (currentItem) => currentItem.item === itemNo,
      );
      if (itemIndex !== -1) {
        state.modifiers[itemIndex].modifiers = [];
      }
    },

    resetModifiers: (state, action) => {
      const { modifiers } = action.payload;
      state.modifiers = modifiers;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addItem,
  editItem,
  addPackagesItem,
  sizeSelection,
  ingredientSelection,
  substituteSelection,
  syncIngredientSelection,
  addTry2ComboItem,
  addTry2ComboItemBasePrice,
  addKidsComboItem,
  addAddChoseForMeSize,
  kidsComboItemSelection,
  addTry2ComboSectionItem,
  syncTry2IngredientSelection,
  try2IngredientSelection,
  try2SubstituteSelection,
  try2ComboIngredientSelection,
  try2ComboItemSelection,
  try2ComboCategorySelection,
  removeTry2ComboIngredients,
  removeCategory,
  resetContext,
  try2ComboComplementaryItemsSelection,
  try2ComboCoreIngredientSelection,
  removeTry2ComboCustomizeItems,
  removeSpecificItem,
  resetTry2ComboContext,
  resetTry2ComboItemContext,
  swapTry2ComboContext,
  updatePriceForItem,
  addItemAsModifiersPriceInTotalPrice,
  updateSpecificItemSelectedModifiers,
  removeTemporarilyAddedPriceForItem,
  makeAnItemToTry2Combo,
  makeAnItemTry2Combo,
  resetMadedTryTwoComboItemData,
  resetOrderInBucket,
  resetModifiers,
  updateModifiers,
  resetComboItemModifiersByItemId,
  updatePriceForAllItem,
  editTry2ComboItem,
  editComboItems,
  editKidsComboItemSelection,
  resetBoxLunchesPrice,
  resetChooseForMe,
  resetBoxLunchesSize,
  test,
} = itemCustomizationSlice.actions;

export const itemCustomizationReducer = itemCustomizationSlice.reducer;

/***before unknown save 19july 17:15 */

import { createSlice } from '@reduxjs/toolkit';
import { getUser } from 'src/helper/helperMethods';
const user = getUser();

const userId = user.id ?? '';
const authentication_token = user.authentication_token ?? '';
const access_token = user.access_token ?? '';
const visitor_id = user.visitor_id ?? '';
const type = user.type ?? '';

const initialStateValues = {
  user: {
    id: '' || userId,
    visitor_id: '' || visitor_id,
    authentication_token: '' || authentication_token,
    access_token: '' || access_token,
    loading: false,
    errorMessage: null,
    type: '' || type,
  },
  recievingUser: {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  },
  bookingUser: {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  },
  operator: {
    id: '',
    name: '',
    role: '',
    locations:[],
  },
  searchQuery: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialStateValues,
  reducers: {
    updateUserData: (state, action) => {
      state.user = {
        ...initialStateValues.user,
        id: action.payload.id,
        authentication_token:
          action.payload.authentication_token || action.payload.access_token,
        access_token: action.payload.access_token,
        loading: false,
        type: action.payload.type,
        visitor_id:
          action.payload.visitor_id ?? initialStateValues.user.visitor_id,
        // loyalty_user_id: action?.payload?.loyalty_user_id || '',
      };
    },
    removeUserData: (state) => {
      state.user = {
        id: '',
        visitor_id: '' || visitor_id,
        authentication_token: '',
        access_token: '',
        loading: false,
        errorMessage: null,
        type: '' || type,
      };
    },
    updateRecievingUserData: (state, action) => {
      state.recievingUser = {
        ...initialStateValues.recievingUser,
        id: action.payload.id || '',
        email: action.payload.email,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        phone: action.payload.phone,
      };
    },
    removeRecievingUserData: (state) => {
      state.recievingUser = {
        ...initialStateValues.recievingUser,
      };
    },
    
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    
    removeSearchQuery: (state) => {
      state.searchQuery = '';
    },
    
    updateBookingUserData: (state, action) => {
      state.bookingUser = {
        ...initialStateValues.bookingUser,
        id: action.payload.id,
        email: action.payload.email,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        phone: action.payload.phone,
      };
    },
    
    removeBookingUserData: (state) => {
      state.bookingUser = {
        ...initialStateValues.bookingUser,
      };
    },
    
    updateOperatorData: (state, action) => {
      state.operator = {
        ...initialStateValues.operator,
        id: action.payload.id,
        name: action.payload.name,
        role: action.payload.role,
        locations:action?.payload?.location
      };
    },
    removeOperatorData: (state) => {
      state.operator = {
        ...initialStateValues.operator,
      };
    },
  },
});

export const {
  updateUserData,
  removeUserData,
  updateSearchQuery,
  removeSearchQuery,
  updateRecievingUserData,
  updateBookingUserData,
  removeBookingUserData,
  removeRecievingUserData,
  updateOperatorData,
  removeOperatorData,
} = userSlice.actions;

export const userSliceReducer = userSlice.reducer;

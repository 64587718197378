import { useQuery } from 'react-query';
import { AUTH_USER, GUEST_USER } from '../constants';
import { orderMicroService } from '../services';

interface IGetFavoritesQueryParams {
  size: number;
  location_id?: number;
}
const getFavorites = async (userId, location_id: number = null) => {
  if (!userId) return [];
  const payload: IGetFavoritesQueryParams = { size: -1 };
  if (location_id) payload.location_id = location_id;
  const response = await orderMicroService.getCustomerFavorites(
    userId,
    payload,
  );
  return response.data.data.data;
};

export default function useFavorites(
  userId: number | null | undefined,
  userType: string = AUTH_USER,
  location_id: number = null,
) {
  return useQuery(
    ['Favorites', location_id],
    () => getFavorites(userId, location_id),
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: userType !== GUEST_USER,
    },
  );
}

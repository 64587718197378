import { useEffect, useState } from 'react';
import { Offcanvas, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  CopyLinkIcon,
  EmptyBasket,
  ImportCsvIcon,
  LocationArrow,
  ShareLinkIcon,
  TrashIcon,
} from 'src/assets/images/Svgs/svg';
import CustomModal from 'src/components/customModal/CustomModal';
import FormField from 'src/components/FormFields/FormField';
import { isGuestUser, isSignedInUser } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useRedeemOffer from 'src/hooks/useRedeemOffer';
import useSyncCart from 'src/hooks/useSyncCart';
import {
  IDeleteOrderItemPayload,
  IPromoCode,
  IUpdateOrderQuantityPayload,
} from 'src/models/order.model';
import { IOfferItem } from 'src/models/rewards.model';
import useDeleteItemFromCart from 'src/react-query-hooks/Cart/useDeleteItem';
import usePromoCode from 'src/react-query-hooks/Cart/usePromoCode';
import useUpdateItemQuantity from 'src/react-query-hooks/Cart/useUpdateItemQuantity';
import useGetOffers from 'src/react-query-hooks/Loyalty/useGetOffers';
import useExitEditOrder from 'src/react-query-hooks/useExistEditOrder';
import {
  deleteItemFromOrder,
  resetCartOffer,
  setShowCart,
  updateItemQuantity,
} from 'src/redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import Billing from './Billing/Billing';
import CartItemsList from './CartItems/CartItemsList';
import GroupOrderCartDetailCard from './GroupOrderCartDetailCard/GroupOrderCartDetailCard';
import OfferEligibilityModal from './Offers/OfferEligibilityModal';
import OfferItemsList from './Offers/OfferItemsList';
import ShareLinkModal from './ShareLink/ShareLinkModal/ShareLinkModal';
import styles from './cart.module.scss';
import ShareLink from './ShareLink';
import useEmptyCart from 'src/react-query-hooks/Cart/useEmptyCart';
import Button from 'src/components/Button/Button';

enum ShareModal {
  ShareLink = 'ShareLink',
  ImportCsv = 'ImportCsv',
  close = 'close',
}

const Cart = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const showCart = useAppSelector((state) => state.cart.showCart);
  const cart = useAppSelector((state) => state.cart);
  // const isEditOrder = useAppSelector((state) => state.cart.is_edit_order);
  const cartItems = cart.items;
  const isCartEmpty = !cartItems.length;
  const { user } = useAppSelector((state) => state.user);
  const locationInfo = useAppSelector((state) => state.location);
  const location_id = locationInfo.selectedStore?.id;

  const { syncCart, loading: syncingCart } = useSyncCart();

  const [promoCode, setPromoCode] = useState<string>('');
  const { mutate: applyPromoCode, isLoading: isApplyingCoupon } =
    usePromoCode();

  const { mutate: deleteItem, isLoading: isDeleting } = useDeleteItemFromCart();
  const { mutate: emptyCart, isLoading: isEmptying } = useEmptyCart();

  const { mutate: updateQuantity, isLoading: isUpdatingQuantity } =
    useUpdateItemQuantity();
  const onEmptyCartHandler = () => {
    emptyCart(cartItems[0]?.cart_id, {
      onSuccess: (response) => {
        syncCart(location_id);
        Toast_Func({
          status: true,
          message: 'Your Cart is Empty.',
        });
        setOpenEmptyCartModal(false);
      },
      onError: (error) => {
        Toast_Func({
          status: true,
          message: 'Not allowed to Empty Cart',
        });
        setOpenEmptyCartModal(false);
      },
    });
  };
  useEffect(() => {
    if (user?.id || location_id) {
      syncCart(location_id);
    }
  }, [location_id]);

  useEffect(() => {
    dispatch(resetCartOffer());
  }, []);

  const hideCart = () => {
    dispatch(setShowCart(false));
  };

  const isGroupOrderPage = ['/groupOrder'];

  // console.log('isEditOrder :isEditOrder : ',isEditOrder);
  const showGroupCheckoutBtn = !isGroupOrderPage.includes(location.pathname);

  const [shareLinkModal, setShareLinkModal] = useState<ShareModal>(
    ShareModal.close,
  );
  const isCSv = shareLinkModal === ShareModal.ImportCsv;
  const copyHandler = () => {
    navigator.clipboard.writeText(window.location.href);
    Toast_Func({
      status: true,
      message: 'Link Copied, Link added to clip board',
    });
  };

  const [offerEligibilityModal, setOfferEligibilityModal] = useState<{
    show: boolean;
    failedOfferCriteria: string;
  }>({
    show: false,
    failedOfferCriteria: '',
  });
  const [subTotal, setSubTotal] = useState<number>(0);
  const [isPromoOfferAdded, setIsPromoOfferAdded] = useState<boolean>(false);
  const [onCartOpenSelectOffer, setOnCartOpenSelectOffer] =
    useState<boolean>(true);
  const [previousSelectedOffer, setPreviousSelectedOffer] =
    useState<IOfferItem>(null);
  const { redeemOffer, removeOffer } = useRedeemOffer();
  const {
    data: allOffers = {},
    isFetching,
    refetch: refetchOffers,
    isRefetching: isRefetchingOffers,
  } = useGetOffers(!isGuestUser(user) && showCart, 'CartModal');

  const offers = allOffers?.rewards || [];

  const cartRedirectOfferId = cart.redirect_offer_id;
  // const cartRedirectOfferId = cart?.redirect_offer_id? cart?.redirect_offer_id : offers?.find((offer: any) => offer?.is_selected === true)?.id ;

  const cartRedirectOffer: IOfferItem = offers?.find(
    (offer: IOfferItem) => offer.id === cartRedirectOfferId,
  );


  const latestOffer = offers?.[0];
  const selectedOfferItem: IOfferItem = offers?.find(
    (offer: IOfferItem) => offer.in_cart,
  );

  useEffect(() => {
    if (
      !isFetching &&
      !syncingCart &&
      !isEmptying &&
      (isPromoOfferAdded || onCartOpenSelectOffer)
    ) {
      const offerToBeSelected = cartRedirectOffer || latestOffer;
      if (!offerToBeSelected) return;
      const { showModalOrCart, failedOfferCriteria } = redeemOffer({
        offerItem: offerToBeSelected,
        cartItems,
        selectedOfferItem,
        subTotal,
      });
      if ((!onCartOpenSelectOffer || cartRedirectOffer) && showModalOrCart) {
        const findPreviousOffer = offers?.find(
          (offer: IOfferItem) => offer.id === previousSelectedOffer?.id,
        );
        if (findPreviousOffer) {
          findPreviousOffer.in_cart = true;
          findPreviousOffer.redeemed_by_offer_discount =
            previousSelectedOffer.redeemed_by_offer_discount;
          findPreviousOffer.cart_item_index =
            previousSelectedOffer.cart_item_index;
        }
        setOfferEligibilityModal({
          show: true,
          failedOfferCriteria,
        });
      }
      setPreviousSelectedOffer(null);
      setIsPromoOfferAdded(false);
      setOnCartOpenSelectOffer(false);
    }
  }, [isFetching, syncingCart]);

  const isCouponCodeEntered = promoCode?.toString()?.length > 0 ? false : true;
  const handlePromoCode = async () => {
    const payload: IPromoCode = {
      promo_code: promoCode,
    };
    applyPromoCode(payload, {
      onSuccess: async (res: any) => {
        if (res?.data?.rewards?.length) {
          refetchOffers();
          setIsPromoOfferAdded(true);
          setPreviousSelectedOffer(selectedOfferItem);
        }
      },
      onError: (error: any) => {
        Toast_Func({
          status: false,
          message: error?.response?.data?.errors[0],
        });
      },
    });
  };
  const applyPromoCodeIcon = () => {
    if (isApplyingCoupon || isRefetchingOffers) {
      return (
        <div className={`${styles.loader_wrapper}`}>
          <Spinner
            animation="border"
            variant="dark"
            size="sm"
            className={styles.spinner}
          />
        </div>
      );
    }
    return (
      <button
        onClick={() => handlePromoCode()}
        type="button"
        className={`${styles.arrow_btn} btn btn-link calenderIcon calenderIconCart pointer`}
        disabled={isCouponCodeEntered}
        // disabled={true}
      >
        Apply
      </button>
    );
  };

  const handleItemRemove = async (item_id, iteratingIndex, closeModal) => {
    const payload: IDeleteOrderItemPayload = {
      item_id: item_id,
    };
    payload.customer_id = user.id;
    deleteItem(payload, {
      onSuccess: () => {
        if (cartItems[iteratingIndex].is_redeemed_by_offer) {
          selectedOfferItem.in_cart = false;
          const { failedOfferCriteria } = redeemOffer({
            offerItem: selectedOfferItem,
            cartItems,
            selectedOfferItem,
            subTotal,
            deletedItemIndex: iteratingIndex,
          });
          if (failedOfferCriteria) removeOffer(selectedOfferItem, true);
        }
        dispatch(deleteItemFromOrder(iteratingIndex));
        closeModal();
      },
      onError: (error) => {
        closeModal();
      },
    });
  };

  const handleQuantityChange = async (
    item_id,
    iteratingIndex,
    itemQuantity: number,
  ) => {
    const payload: IUpdateOrderQuantityPayload = {
      item_id,
      quantity: itemQuantity,
    };
    dispatch(
      updateItemQuantity({
        index: iteratingIndex,
        quantity: itemQuantity,
      }),
    );
    payload.customer_id = user.id;
    updateQuantity(payload, {
      onError: (error) => {
        console.log(error, 'ERROR ON quantity change');
      },
    });
  };
  const [openEmptyCartModal, setOpenEmptyCartModal] = useState(false);

  return (
    <>
      {(cartRedirectOffer || latestOffer) && (
        <OfferEligibilityModal
          show={offerEligibilityModal.show}
          offerFailedCriteria={offerEligibilityModal.failedOfferCriteria}
          offerDetails={
            cartRedirectOfferId ? cartRedirectOffer?.data : latestOffer?.data
          }
          closeModal={() =>
            setOfferEligibilityModal({
              show: false,
              failedOfferCriteria: '',
            })
          }
        />
      )}
      <div className={`${styles.cartDetailsWrap} `}>
        <Offcanvas
          show={showCart}
          onHide={hideCart}
          placement="end"
          style={{ width: '510px' }}
          scroll={false}
          className={`cutsom-canvas ${styles.cutsom_canvas}`}
        >
          {/* <a
            className="btn btn-custom back-arrow-btn f-s14 pt-4 ps-4 d-md-none"
            href="/menu"
          >
            Menu
          </a> */}
          <Offcanvas.Header closeButton className="justify-space-between">
            <Offcanvas.Title>
              <h2 className="f-s18 f-w5 font-plex clr-white mb-0">
                Order Basket <span>({cartItems.length} Items)</span>
              </h2>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="px-0 py-3">
            {!!cartItems?.length && !isEmptying && !syncingCart && (
              <div
                className={`font-plex f-w5 f-s14 cursor-pointer clr-white bg-dark-red py-2 px-3 text-center ms-auto d-flex justify-content-center gap-2 align-items-center ${styles.emptyCartBtn}`}
                onClick={() => {
                  setOpenEmptyCartModal(true);
                }}
              >
                Empty Cart
                <TrashIcon />
              </div>
            )}
            <div className={styles.cartContent}>
              {cartItems.length == 0 && (
                <div className="text-center pt-5 mt-5">
                  <EmptyBasket />
                </div>
              )}
            </div>
            <div>
              {!showGroupCheckoutBtn && (
                <div className="d-flex justify-content-between mb-5">
                  <ShareLink
                    icon={<ShareLinkIcon className={'mb-2'} />}
                    text={'share link'}
                    onClick={() => setShareLinkModal(ShareModal.ShareLink)}
                  />

                  <ShareLink
                    icon={<CopyLinkIcon className={'mb-2'} />}
                    text={'copy link'}
                    onClick={copyHandler}
                  />
                  <ShareLink
                    icon={<ImportCsvIcon className={'mb-2'} />}
                    text={'Import (.csv file)'}
                    onClick={() => setShareLinkModal(ShareModal.ImportCsv)}
                  />
                </div>
              )}
              <div className="customCanvas">
                {!showGroupCheckoutBtn ? (
                  <>
                    <h4 className="font-Cls f-sm-s12 f-s22 mb-2 fw-bold font-plex">
                      Group Order Cart
                    </h4>
                    <GroupOrderCartDetailCard
                      cartItem={'Salad & Soup Box Lunch'}
                      ingredient1={'Fresh Herb Vinaigrette'}
                      ingredient2={'Half calefornia cobb'}
                      ingredient3={'Butter Ranch'}
                      ingredient4={'Egg Noodles(+0.69)'}
                      name="Julia’s Order"
                      ingredients={''}
                    />
                    <GroupOrderCartDetailCard
                      cartItem={'Salad & Soup Box Lunch'}
                      ingredient1={'Fresh Herb Vinaigrette'}
                      ingredient2={'Half calefornia cobb'}
                      ingredient3={'Butter Ranch'}
                      ingredient4={'Egg Noodles(+0.69)'}
                      name="Julia’s Order"
                      ingredients={''}
                    />
                  </>
                ) : (
                  <>
                    <CartItemsList
                      syncingCart={syncingCart || isEmptying}
                      selectedOfferItem={selectedOfferItem}
                      subTotal={subTotal}
                      handleItemRemove={handleItemRemove}
                      loading={isDeleting || isUpdatingQuantity}
                      handleQuantityChange={handleQuantityChange}
                      cart={cartItems}
                    />
                  </>
                )}

                {/* {(!isCartEmpty && !syncingCart) || isSignedInUser(user) ? (
                  <div className="shadow-divider mb-4"></div>
                ) : null} */}
                {isSignedInUser(user) && cartItems.length > 0 ? (
                  <>
                    <div className={`${styles.promo_code} promo_code`}>
                      <div className="form-group position-relative">
                        <FormField
                          name="redeem_dollars"
                          labelText="Coupon Code"
                          type="text"
                          onChange={(event) => setPromoCode(event.target.value)}
                        />
                        {applyPromoCodeIcon()}
                      </div>
                    </div>
                    <hr className="mt-4" />
                    <OfferItemsList
                      offers={offers}
                      selectedOfferItem={selectedOfferItem}
                      title="Offers"
                      subTotal={subTotal}
                    />
                    {/* <RedeemDollars /> */}
                  </>
                ) : null}
                {!isCartEmpty ||
                (isSignedInUser(user) && cartItems.length > 0) ? (
                  <Billing
                    cartItems={cartItems}
                    isOrderTypeSelected={cart.isOrderTypeSelected}
                    orderType={cart.orderType}
                    authInfo={user}
                    offerDiscount={cart.offerDiscount}
                    disableCheckout={syncingCart || isEmptying}
                    handleTax={false}
                    setSubTotal={setSubTotal}
                    offerDiscountType={cart.offerDiscountType}
                    isCart={true}
                    deesignAsPermodal={true}
                  />
                ) : null}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <CustomModal
        showModal={openEmptyCartModal}
        title={'Empty Your Cart'}
        closeModal={() => {
          setOpenEmptyCartModal(false);
        }}
        modalDialogClasses={'defaultModalwrapper'}
      >
        <div className="p-4">
          <p className="font-plex f-s16 light-blue f-w5 mb-0">
            Are you sure you want to empty your cart?
          </p>
        </div>
        <hr className="my-0" />
        <div className="d-flex justify-content-end gap-3 px-4 py-3">
          <button
            className="cancel-filled-btn text-uppercase"
            type="button"
            onClick={() => {
              setOpenEmptyCartModal(false);
            }}
          >
            {' '}
            Cancel
          </button>
          <Button
            className="global-filled-btn"
            type="button"
            onClick={onEmptyCartHandler}
            loading={isEmptying}
          >
            Confirm
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        showModal={shareLinkModal !== ShareModal.close}
        title={''}
        closeModal={() => setShareLinkModal(ShareModal.close)}
        modalBodyClass=""
        modalDialogClasses={styles.shareLinkModal}
      >
        <ShareLinkModal
          title={isCSv ? 'Import .csv file' : 'ADDRESS INPUT'}
          desc={
            'Add email addresses and we will send out a message to your group!'
          }
          isCsv={isCSv}
        />
      </CustomModal>
    </>
  );
};

export default Cart;

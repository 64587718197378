import React, { MutableRefObject, useState } from 'react';
import _clonedeep from 'lodash.clonedeep';
import {
  TRY_TO_COMBO_ITEM,
  BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
} from 'src/constants';
import { INoDressingModifier, ItemDetails } from 'src/models/item.model';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { modifiersAgainstAnItem } from 'src/priceCalculation/helper';
import {
  removeTemporarilyAddedPriceForItem,
  removeTry2ComboIngredients,
  updateSpecificItemSelectedModifiers,
} from 'src/redux/slices/itemCustomizationSlice';
import { useAppDispatch } from 'src/redux/store/store';

import AddToBoxLunches from './Customization/AddToBoxLunch';
import BoxLunchesCustomization from './boxLunchCustomization';

type CustomizationTypes = {
  modalShow: boolean;
  selectedItem: ItemDetails;
  setModalShow: (state: boolean) => void;
  styles: CSSModuleClasses;
  selectedModifier: any;
  unrepeatedIngredients: any[];
  handleIngredientSelection: (data: any, id?: 2 | 1 | 3 | 4) => void;
  hideModifierModal: () => void;
  autoScrollDivRef: MutableRefObject<HTMLDivElement>;
  handleSyncIngredients: (item) => void;
  //samran start
  selectedIngredients: any;
  sectionSelected: string;
  addItemtoCombo: any;
  noDressingHandler: (modifier: INoDressingModifier, itemNo: string) => void;
  emptySelectedItemSelectedModifiers: () => void;
  hideCustomizationModalHandler: () => void;
  requiredModalShow?: boolean;
  ingredientsModal: boolean;
  toggleIngrediatsModal: (state: boolean) => void;
  isShowingRequiredModalOnCustomization: boolean;
  setSelectedItem: any;
  showCustomization?: boolean;
  Item?: ItemDetails;
  isCreateYourOwnItem: boolean;
};

const ItemsCustomization: React.FC<CustomizationTypes> = (props) => {
  const {
    modalShow,
    selectedItem,
    setModalShow,
    styles,
    selectedModifier,
    unrepeatedIngredients,
    handleIngredientSelection,
    hideModifierModal,
    autoScrollDivRef,
    //samran start
    selectedIngredients,
    addItemtoCombo,
    sectionSelected,
    handleSyncIngredients,
    noDressingHandler,
    emptySelectedItemSelectedModifiers,
    hideCustomizationModalHandler,
    requiredModalShow,
    ingredientsModal,
    toggleIngrediatsModal,
    isShowingRequiredModalOnCustomization,
    setSelectedItem,
    showCustomization,
    Item,
    isCreateYourOwnItem
  } = props;

  const dispatch = useAppDispatch();
  const [key, setKey] = useState<string>('');

  const [otherDressingData, setOtherDressingData] = useState<boolean>(false);
  const [isInItemPresent, setIsInItemPresent] = useState<boolean>(false);

  const hideIngredientsModalHandler = (
    shouldCleanRequiredModifeirs: boolean,
  ) => {
    shouldCleanRequiredModifeirs && cleaningForRequiredModifiers();
    toggleIngrediatsModal(false);
  };
  const cleaningForRequiredModifiers = () => {
    if (!otherDressingData) {
      setIsInItemPresent(false);
    }
    setOtherDressingData(false);
    itemBuckets.resetCopyComboBucket();
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets?.discardBucketChanges(sectionSelected),
        item: sectionSelected,
      }),
    );
    dispatch(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
        }),
        itemId: '1',
      }),
    ); // you must optimize this function [CAFEZUPAS-5751]
  };

  const backButtonClick = () => {
    // handleScroll();
    // itemState(true);
    setKey('');
  };

  const removeSelectedIngredients = (selectedCustomizeData, id) => {
    dispatch(
      removeTry2ComboIngredients({
        modifiers: selectedCustomizeData,
        sectionSelected: id,
      }),
    );
  };

  const showIngredientsModalHandler = (item: ItemDetails) => {
    itemBuckets.createBucketCopy();
    handleSyncIngredients(item);
    toggleIngrediatsModal(true);
  };
  const addCustomizeItemtoComboClick = () => {
    setOtherDressingData(false);
    hideCustomizationModalHandler();
    backButtonClick();
    addItemtoCombo(selectedItem);
  };

  const addItemtoComboClick = () => {
    if (isShowingRequiredModalOnCustomization) {
      showIngredientsModalHandler(selectedItem);
    } else {
      handleSyncIngredients(selectedItem);
      // addSelectedIngredients(selectedItem)
      hideCustomizationModalHandler();
      backButtonClick();
      addItemtoCombo(selectedItem);
    }
  };
  return (
    <>
      <AddToBoxLunches
        showCustomizationModal={requiredModalShow}
        closeCustomizationModal={hideCustomizationModalHandler}
        requiredModifierGroups={selectedItem?.items_required_modifier_groups}
        selectedIngredients={selectedIngredients}
        handleIngredientSelection={handleIngredientSelection}
        sectionSelected={sectionSelected}
        addItemtoCombo={addItemtoComboClick}
        itemName={selectedItem?.name}
        noDressingHandler={noDressingHandler}
        isShowingRequiredModalOnCustomization={
          isShowingRequiredModalOnCustomization
        }
        otherDreesingState={{ otherDressingData, setOtherDressingData }}
        isInItemPresentState={{ isInItemPresent, setIsInItemPresent }}
        styles={styles}
        cleaningForRequiredModifiers={cleaningForRequiredModifiers}
        setSelectedItem={setSelectedItem}
      />
      <BoxLunchesCustomization
        showCustomizationModal={ingredientsModal}
        closeCustomizationModal={hideIngredientsModalHandler}
        modifierGroups={selectedItem?.items_modifier_groups}
        selectedIngredients={selectedIngredients}
        handleIngredientSelection={handleIngredientSelection}
        coreModifiers={selectedItem?.core_modifiers}
        requiredModifiers={selectedItem?.items_required_modifier_groups}
        addItemtoComboClick={addCustomizeItemtoComboClick}
        handleSyncIngredients={handleSyncIngredients}
        selectedItem={selectedItem}
        itemName={selectedItem?.name}
        sectionSelected={sectionSelected}
        removeSelectedIngredients={removeSelectedIngredients}
        currentEditItem={sectionSelected}
        noDressingHandler={noDressingHandler}
        styles={styles}
        setSelectedItem={setSelectedItem}
        Item={Item}
        categoryName={selectedItem?.is_create_your_own}
        isCreateYourOwnItem={isCreateYourOwnItem}
      />
    </>
  );
};

export default ItemsCustomization;

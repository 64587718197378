import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Loader from 'src/components/Loader/Loader';
import RequireAuth from 'src/components/RequireAuth';
import CustomerInfo from 'src/Features/customerInfo/customerInfo';
import * as ROUTE_CONSTANT from 'src/constants';

const Login = React.lazy(
  () => import('src/Features/AccountSetup/components/Login'),
);
const Page404 = React.lazy(() => import('src/Features/Page404/page404'));

const step3 = React.lazy(() => import('src/Features/Menu/Menu'));
const step4 = React.lazy(
  () => import('src/Features/OrderSummary/OrderSummary'),
);
const step5 = React.lazy(() => import('src/Features/order/checkout'));
const OrderPrerequisite = React.lazy(
  () => import('src/components/OrderPrerequisite/OrderPrerequisite'),
);

import MasterContainer from 'src/containers/ItemDetail/MasterContainer';
import { bugSnagInititalize } from 'src/helper/bugSnagHelper';
const CreateAccount = React.lazy(
  () => import('src/Features/AccountSetup/components/CreateAccount'),
);

const ErrorBoundaryBugSnag = bugSnagInititalize();

const Routes: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Loader modalSpinner={true} />}>
      <ErrorBoundaryBugSnag FallbackComponent={Page404}>
      <Switch>
        <Redirect
          exact
          from={ROUTE_CONSTANT.ROUTE_HOME}
          to={ROUTE_CONSTANT.ROUTE_STEP_1}
        />
        <Route
          path={ROUTE_CONSTANT.ROUTE_STEP_1}
          exact={true}
          component={CustomerInfo}
        ></Route>
        <RequireAuth
          path={ROUTE_CONSTANT.ROUTE_STEP_2}
          exact={true}
          component={OrderPrerequisite}
        ></RequireAuth>
        <RequireAuth
          path={ROUTE_CONSTANT.ROUTE_STEP_3}
          exact={true}
          component={step3}
        ></RequireAuth>
        <RequireAuth
          path={`${ROUTE_CONSTANT.ROUTE_STEP_3}/:selected_category?`}
          exact={true}
          component={step3}
        ></RequireAuth>
        <RequireAuth
          path={`${ROUTE_CONSTANT.ROUTE_OTHER_ITEM}/:id`}
          exact={true}
          component={MasterContainer}
        ></RequireAuth>
        <RequireAuth
          path={ROUTE_CONSTANT.ROUTE_STEP_4}
          exact={true}
          component={step4}
        ></RequireAuth>
        <RequireAuth
          path={ROUTE_CONSTANT.ROUTE_STEP_5}
          exact={true}
          component={step5}
        ></RequireAuth>
        <Route
          path={ROUTE_CONSTANT.ROUTE_LOGIN}
          exact={true}
          component={Login}
        ></Route>
        <Route
          path={ROUTE_CONSTANT.ROUTE_404}
          exact={true}
          component={Page404}
        ></Route>
        <Route
          path={ROUTE_CONSTANT.ROUTE_CREATE_ACCOUNT}
          exact={true}
          component={CreateAccount}
        ></Route>

        <Redirect to={ROUTE_CONSTANT.ROUTE_STEP_1} />
      </Switch>
      </ErrorBoundaryBugSnag>
    </Suspense>
  );
};

export default Routes;

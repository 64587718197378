import {
  CREATE_YOUR_OWN_SALAD_OPTIONALS,
  CREATE_YOUR_OWN_SALAD_REQUIRED,
} from '../../constants';
import { Codes } from '../../models/item.model';
import {
  IAddedItemModifiersObjects,
  IItemsModifierObject,
  IModifiersGroupsModifiers,
} from '../../models/order';
import { nullValueVerify } from '../helperMethods';

/**
 * Calculates the price difference between modifier and its substitute
 * @param modifier
 */
const calculatePriceDifference = (modifier): number => {
  return modifier.display_price < modifier.substituted_modifier.display_price
    ? modifier.substituted_modifier.display_price - modifier.display_price
    : 0;
};

/**
 * Calculates price for core modifiers
 * @param modifier
 */
const coreModifierPrice = (modifier): number => {
  return modifier?.quantity > 1
    ? modifier.display_price * (modifier.quantity - 1)
    : 0;
};

/**
 * Calculates price for added modifiers
 * @param modifier
 */
const addedModifierPrice = (modifier): number => {
  return modifier?.quantity > 1
    ? modifier.display_price * modifier.quantity
    : modifier.display_price;
};

/**
 * Calculates price for added modifiers[Addons] for Create Your Own Salad
 * @param modifier
 */
const optionalModifierPriceForCreateYourOwnSalad = (
  modifierObject,
  place: number,
): number => {
  return place > modifierObject?.modifier_group_max
    ? modifierObject?.display_price
    : 0;
};

/**
 * Calculates price for substituted modifiers
 * @param modifier
 */
const sbstitutedModifierPrice = (modifier): number => {
  const priceDifference = calculatePriceDifference(modifier);
  return modifier?.quantity > 1
    ? priceDifference +
        modifier.substituted_modifier.display_price * (modifier.quantity - 1)
    : priceDifference;
};

/**
 * Calculates price for complimentary modifiers
 * @param modifier
 */
const complimentaryModifierPrice = (modifier): number => {
  return modifier?.quantity > 1
    ? modifier.display_price * (modifier.quantity - 1)
    : 0;
};

/**
 * Calculates the prices for modifiers added to the item
 * @param itemModifiers
 */
const getModifiersCombinedPrice = (itemModifiers) => {
  let totalPrice: number = 0;
  let flag: boolean = true;
  // ? Retrive all objects for the modifiers addes to the item
  const addedModifiersObjects: IAddedItemModifiersObjects =
    getModifiersObjects(itemModifiers);
  for (let modifier of itemModifiers) {
    /**if the modifier is complimentary modifier or removed form item, no price will be added */
    if (modifier.code === Codes.NO) {
      continue;
    }
    if (modifier?.complimentary_modifier) {
      totalPrice += complimentaryModifierPrice(modifier);
    } else if (modifier?.substituted_modifier) {
      totalPrice += sbstitutedModifierPrice(modifier);
    } else if (modifier.core) {
      totalPrice += coreModifierPrice(modifier);
    } else if (
      !modifier.core &&
      (modifier.type === CREATE_YOUR_OWN_SALAD_OPTIONALS ||
        modifier.type === CREATE_YOUR_OWN_SALAD_REQUIRED)
    ) {
      if (flag) {
        flag = false;
        for (const key in addedModifiersObjects) {
          for (let i = 0; i < addedModifiersObjects[key].length; i++) {
            const newAddedPrice: number =
              optionalModifierPriceForCreateYourOwnSalad(
                addedModifiersObjects[key][i],
                i + 1,
              );
            totalPrice += newAddedPrice;
          }
        }
      } else {
        totalPrice += 0;
      }
    } else if (!modifier.core) {
      totalPrice += addedModifierPrice(modifier);
    }

    /**Checking if the modifier also has modifiers and calling the fucntion recursively */
    if (!nullValueVerify(modifier.modifiers)) {
      totalPrice += getModifiersCombinedPrice(modifier.modifiers);
    }
  }

  return totalPrice;
};

/**
 * Create the array of objects for modifiers added to the item
 * @param itemModifiers
 */
const getModifiersObjects = (
  item_modifiers: IModifiersGroupsModifiers[],
): IAddedItemModifiersObjects => {
  // ? To Get The All Items Selected Modifiers ObjectBy Modifier_Group_Id as a key
  const itemsSelectedModifiers = {};
  for (let i = 0; i < item_modifiers.length; i++) {
    const modifier_group_id = item_modifiers[i].modifier_group_id;
    if (itemsSelectedModifiers.hasOwnProperty(modifier_group_id)) {
      itemsSelectedModifiers[modifier_group_id].push(
        ...modifiersObjects(item_modifiers[i]),
      );
    } else {
      itemsSelectedModifiers[modifier_group_id] = [];
      itemsSelectedModifiers[modifier_group_id].push(
        ...modifiersObjects(item_modifiers[i]),
      );
    }
  }
  return itemsSelectedModifiers;
};

/**
 * Return the array of objects for a single modifier
 * @param modifier
 */
const modifiersObjects = (
  item_modifier: IModifiersGroupsModifiers,
): IItemsModifierObject[] => {
  const selectedModifiers = [];
  const itemModifiersObjects = createModifierObjects(item_modifier);
  for (let j = 0; j < itemModifiersObjects.length; j++) {
    selectedModifiers.push(itemModifiersObjects[j]);
  }
  return selectedModifiers;
};

/**
 * Create the array of objects for a single modifier
 * @param modifier
 */
function createModifierObjects(
  modifier: IModifiersGroupsModifiers,
): IItemsModifierObject[] {
  const objectsArray = [];
  if (modifier?.quantity) {
    // ? Create Objects For "Counter Card".
    for (let i = 0; i < modifier.quantity; i++) {
      objectsArray.push(newModifierObject(modifier));
    }
  } else {
    // ? Create Objects For "Selected Card".
    objectsArray.push(newModifierObject(modifier));
  }
  return objectsArray;
}

/**
 * Create a new object for a single modifier [contains only necessary <key,values> ]
 * @param modifier
 */
function newModifierObject(
  modifier: IModifiersGroupsModifiers,
): IItemsModifierObject {
  return {
    id: modifier.modifier_id,
    name: modifier.modifier_name,
    modifier_group_id: modifier.modifier_group_id,
    modifier_group_max: modifier.modifier_group_max,
    display_price: modifier.display_price,
    quantity: 1,
  };
}

export const getCustomizedItemPrice = (customizedItem, itemPrice) => {
  let totalPrice = 0;
  if (customizedItem.item_id) {
    totalPrice += itemPrice
      ? itemPrice
      : customizedItem.display_price
      ? customizedItem.display_price
      : customizedItem.price;
  }
  /**Converting string returned by toFixed() into a foating point number */
  return parseFloat(totalPrice?.toFixed(2));
};

export const getCartItemPrice = (customizedItem, itemPrice) => {
  let totalPrice = 0;
  if (customizedItem.item_id) {
    totalPrice += itemPrice
      ? itemPrice
      : customizedItem.display_price
      ? customizedItem.display_price
      : customizedItem.price;
    if (customizedItem.modifiers.length > 0) {
      const a = getModifiersCombinedPrice(customizedItem.modifiers);
      totalPrice += a;
    }
  }
  /**Converting string returned by toFixed() into a foating point number */
  return parseFloat(totalPrice?.toFixed(2));
};

export const getCustomizedItemPriceForCombo = (customizedItem, itemPrice) => {
  let totalPrice = 0;
  if (customizedItem.item_id) {
    totalPrice += itemPrice ? itemPrice : customizedItem.display_price;
    for (let i = 0; i < customizedItem?.modifiers?.length; i++) {
      if (!nullValueVerify(customizedItem?.modifiers[i]?.modifiers)) {
        const a = getModifiersCombinedPrice(
          customizedItem.modifiers[i].modifiers,
        );
        totalPrice += a;
      }
    }
  }

  /**Converting string returned by toFixed() into a foating point number */
  return parseFloat(totalPrice.toFixed(2));
};

import { cloneDeep } from 'lodash';
import _clonedeep from 'lodash.clonedeep';
import * as Constants from 'src/constants';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';
import { addItemAsModifiersPriceInTotalPrice } from 'src/redux/slices/itemCustomizationSlice';

import { IModifiersAgainstAnItem, mapSizesKey } from '../models/item.model';
import { IBucketSelectedIngredients } from '../models/item.model';

import { itemBuckets } from './buckets';
import {
  ADDITIONAL_ADDED,
  ADDITIONAL_REMOVED,
  ADDITIONALPRICE,
  COMPLIMENTARY_MODIFIERS,
  REQUIRED_MODIFIERS,
} from './constants';
import {
  IAddQuantityToSelectedModifier,
  IBucket,
  ICurrentModifier,
  IItemBucket,
  IMemoryChip,
  ISingleBucket,
  IUpdateItemBucketModifier,
} from './priceCalculation';

export const createBucketsObjects = (keys: string[]): IBucket => {
  const object: IBucket = {};
  keys.forEach((key: string) => {
    object[key] = {
      originalPrice: 0,
      additionalPrice: 0,
      max: 0,
      byDefaultAdded: {
        originalPrice: 0,
        additionalPrice: 0,
        modifiers: [],
      },
      byDefaultAddedV2: {
        modifiers: [],
      },
      additionalAdded: {
        originalPrice: 0,
        additionalPrice: 0,
        modifiers: [],
      },
      additionalRemoved: {
        originalPrice: 0,
        additionalPrice: 0,
        modifiers: [],
      },
      modifiers: [],
      writable: true,
    };
  });
  return object;
};

export const lastIndexOf = (array: any, key: any): number => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i].modifier_id === key.modifier_id) return i;
  }
  return -1;
};

export const isExistAsByDefault = (
  modifiers: IBucketSelectedIngredients[],
  currentModifier: ICurrentModifier,
): boolean => {
  let flag = false;
  for (let index = 0; index < modifiers.length; index++) {
    if (modifiers[index].modifier_id === currentModifier.modifier_id) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const isModifierGroupExistInMemoryChip = (
  modifier_group_id: number,
  fromItem: number,
): boolean => {
  const memoryChip: IMemoryChip = itemBuckets.getSingleBucket({
    name: Constants.CORE_MODIFIERS,
    fromItem,
    isFromItemComingAsAnIndex: true,
  })?.memoryChip;
  if (memoryChip) {
    if (
      memoryChip[modifier_group_id] === undefined ||
      memoryChip[modifier_group_id] === null
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const temporarilyAddedPrice = (
  oldBucket: IItemBucket[],
  newBucket: IItemBucket[],
  item: string,
): number => {
  let oldBucketAdditionalprice = 0;
  let newBucketAdditionalprice = 0;
  oldBucket.forEach((currentItem: IItemBucket) => {
    if (currentItem.item.toString() === item) {
      oldBucketAdditionalprice = itemAdditionalPrice(
        oldBucketAdditionalprice,
        currentItem,
        Constants.ADD_ONS,
        ADDITIONALPRICE,
      );
      oldBucketAdditionalprice = itemAdditionalPrice(
        oldBucketAdditionalprice,
        currentItem,
        Constants.REQUIRED_MODIFIERS,
        ADDITIONALPRICE,
      );
      oldBucketAdditionalprice = itemAdditionalPrice(
        oldBucketAdditionalprice,
        currentItem,
        Constants.CORE_MODIFIERS,
        ADDITIONALPRICE,
      );
      oldBucketAdditionalprice = itemAdditionalPrice(
        oldBucketAdditionalprice,
        currentItem,
        Constants.COMPLIMENTARY_MODIFIER,
        ADDITIONALPRICE,
      );
    }
  });
  newBucket.forEach((currentItem: IItemBucket) => {
    if (currentItem.item.toString() === item) {
      newBucketAdditionalprice = itemAdditionalPrice(
        newBucketAdditionalprice,
        currentItem,
        Constants.ADD_ONS,
        ADDITIONALPRICE,
      );
      newBucketAdditionalprice = itemAdditionalPrice(
        newBucketAdditionalprice,
        currentItem,
        Constants.REQUIRED_MODIFIERS,
        ADDITIONALPRICE,
      );
      newBucketAdditionalprice = itemAdditionalPrice(
        newBucketAdditionalprice,
        currentItem,
        Constants.CORE_MODIFIERS,
        ADDITIONALPRICE,
      );
      newBucketAdditionalprice = itemAdditionalPrice(
        newBucketAdditionalprice,
        currentItem,
        Constants.COMPLIMENTARY_MODIFIER,
        ADDITIONALPRICE,
      );
    }
  });
  return toFixedNumber(newBucketAdditionalprice - oldBucketAdditionalprice);
};

export const itemAdditionalPrice = (
  bucketAdditionalPrice: number,
  currentItem: IItemBucket,
  modifiers_type: string,
  priceType: string,
): number => {
  let price = 0;
  const currentItemAdditionalPrice: number =
    currentItem.bucket[modifiers_type][priceType];
  if (currentItemAdditionalPrice > 0) {
    price = toFixedNumber(bucketAdditionalPrice + currentItemAdditionalPrice);
  } else {
    price = bucketAdditionalPrice;
  }
  return price;
};

export const toFixedNumber = (number: number): number => {
  return Number(number.toFixed(2));
};

export const toFixedNumberDecimal = (number) => {
  return (Math.round(number * 100) / 100).toFixed(2);
};

export const floorNumberToDecimal = (number) => {
  const decimalPoints = number.toString().split('.')[1];
  if (decimalPoints?.length > 2) {
    return Math.floor(number * Math.pow(10, 2)) / Math.pow(10, 2);
  } else {
    return number;
  }
};

export const roundToTwo = (number) => {
  if(number)
  {
    return Number(Math.round(Number(number + 'e' + 2)) + 'e-' + 2);
  }
  else
    return 0;
};
export const assignTypeToSelectedModifier = (
  modifier: ICurrentModifier,
): void => {
  if (modifier.quantity === 0) {
    modifier.type = Constants.DECREASE;
  } else {
    modifier.type = Constants.INCREASE;
  }
};

export const keysOfAnObject = (object) => {
  return Object.keys(object);
};

export const retriveModifiersAgainstAnObjectKeys = (keys, bucket) => {
  const modifiers = [];
  for (let i = 0; i < keys.length; i++) {
    modifiers.push(...bucket[keys[i]].modifiers);
  }
  return modifiers;
};

export const modifiersAgainstAnItem = ({
  itemId,
}: IModifiersAgainstAnItem) => {
  const bucket = cloneDeep(
    itemBuckets.getSingleItem({ itemNo: itemId })?.bucket,
  );
  const objectKeys = keysOfAnObject(bucket);
  return retriveModifiersAgainstAnObjectKeys(objectKeys, bucket);
};

export const updateItemBucketModifier = ({
  modifier_type,
  modifier,
  fromItem,
  treat_as,
}: IUpdateItemBucketModifier) => {
  itemBuckets.updateSpecificItemBucketModifiers({
    name: modifier_type,
    modifier,
    fromItem,
  });
  itemBuckets.updateSpecificItemBucket({
    name: modifier_type,
    modifier,
    fromItem,
    as: treat_as,
  });
};

export const updateItemBucketCoreAndAddonsModifier = (
  modifier: ICurrentModifier,
  fromItem,
  treat_as: string,
) => {
  if (
    modifier?.modifier_type === Constants.ADD_ONS &&
    modifier?.treat_as !== Constants.CORE_RELATED
  ) {
    updateItemBucketModifier({
      modifier_type: Constants.ADD_ONS,
      modifier,
      fromItem,
      treat_as,
    });
  }
  if (
    modifier?.modifier_type === Constants.CORE_MODIFIERS ||
    modifier?.treat_as === Constants.CORE_RELATED
  ) {
    updateItemBucketModifier({
      modifier_type: Constants.CORE_MODIFIERS,
      modifier,
      fromItem,
      treat_as,
    });
  }
};

export const updatingItemBucketModifiers = (
  modifier: ICurrentModifier,
  fromItem: number,
  treat_as: string,
) => {
  if (modifier?.complimentary_modifier) {
    updateItemBucketModifier({
      modifier_type: COMPLIMENTARY_MODIFIERS,
      modifier,
      fromItem,
      treat_as,
    });
  }
  if (modifier?.modifier_type === Constants.REQUIRED_MODIFIERS) {
    updateItemBucketModifier({
      modifier_type: REQUIRED_MODIFIERS,
      modifier,
      fromItem,
      treat_as,
    });
  }
  if (
    modifier?.modifier_type === Constants.CORE_MODIFIERS ||
    modifier?.modifier_type === Constants.ADD_ONS
  ) {
    updateItemBucketCoreAndAddonsModifier(modifier, fromItem, treat_as);
  }
};

export const addQuantityToSelectedModifier = ({
  modifier_type,
  type,
  modifier,
  fromItem,
}: IAddQuantityToSelectedModifier) => {
  if (type === Constants.INCREASE) {
    modifier.quantity = 1;
    updateItemBucketModifier({
      modifier_type,
      modifier,
      fromItem,
      treat_as: ADDITIONAL_ADDED,
    });
  } else {
    modifier.quantity = 0;
    updateItemBucketModifier({
      modifier_type,
      modifier,
      fromItem,
      treat_as: ADDITIONAL_REMOVED,
    });
  }
};

export const updatingItemBucketTypeSelectedCoreAndAddonsModifiers = (
  modifier: ICurrentModifier,
  fromItem,
) => {
  if (
    modifier?.modifier_type === Constants.ADD_ONS &&
    modifier?.treat_as !== Constants.CORE_RELATED
  ) {
    const type = itemBuckets.selectedModifierType(
      Constants.ADD_ONS,
      modifier,
      fromItem,
    );
    addQuantityToSelectedModifier({
      modifier_type: Constants.ADD_ONS,
      type,
      modifier,
      fromItem,
    });
  }
  if (
    modifier?.modifier_type === Constants.CORE_MODIFIERS ||
    modifier?.treat_as === Constants.CORE_RELATED
  ) {
    const type = itemBuckets.selectedModifierType(
      Constants.CORE_MODIFIERS,
      modifier,
      fromItem,
    );
    addQuantityToSelectedModifier({
      modifier_type: Constants.CORE_MODIFIERS,
      type,
      modifier,
      fromItem,
    });
  }
};

export const updatingItemBucketTypeSelectedModifiers = (
  modifier: ICurrentModifier,
  fromItem: number,
) => {
  if (modifier?.modifier_type === Constants.REQUIRED_MODIFIERS) {
    const type = itemBuckets.selectedModifierType(
      REQUIRED_MODIFIERS,
      modifier,
      fromItem,
    );
    addQuantityToSelectedModifier({
      modifier_type: REQUIRED_MODIFIERS,
      type,
      modifier,
      fromItem,
    });
  }
  if (
    modifier?.modifier_type === Constants.CORE_MODIFIERS ||
    modifier?.modifier_type === Constants.ADD_ONS
  ) {
    updatingItemBucketTypeSelectedCoreAndAddonsModifiers(modifier, fromItem);
  }
};

export const basePrice = (
  currentModifier: ICurrentModifier,
  byDefaultSelectedModifiers: ICurrentModifier[],
) => {
  return byDefaultSelectedModifiers.find(
    (modifier: ICurrentModifier) =>
      modifier.modifier_group_id === currentModifier.modifier_group_id,
  )?.display_price;
};

export const addComplementaryPrice = (currentModifier: ICurrentModifier) => {
  if (
    currentModifier.quantity === 1 &&
    currentModifier.type === Constants.INCREASE
  ) {
    return false;
  }
  return true;
};

export const resetAdditionalPrice = (currentModifier: ICurrentModifier) => {
  if (
    currentModifier.additionalPrice === undefined ||
    currentModifier.additionalPrice === null
  ) {
    return true;
  } else {
    return false;
  }
};

export const addAdditionalPriceBasedOnActionType = (
  addedModifier: ICurrentModifier,
  currentModifier: ICurrentModifier,
  type: string,
) => {
  if (type === Constants.INCREASE) {
    return toFixedNumber(
      currentModifier.additionalPrice + addedModifier.display_price,
    );
  }
  if (type === Constants.DECREASE) {
    return toFixedNumber(
      currentModifier.additionalPrice - addedModifier.display_price,
    );
  }
};

export const getSelectedBucketModifiers = (bucket: ISingleBucket) => {
  let modifiers: ICurrentModifier[] = cloneDeep(bucket?.modifiers);
  modifiers = [...modifiers, ...(bucket[ADDITIONAL_REMOVED]?.modifiers || [])];
  return modifiers;
};

export const findModifierIndexBasedOnId = (
  modifiers: ICurrentModifier[],
  modifier: ICurrentModifier,
) => {
  return modifiers.findIndex(
    (currentModifier: ICurrentModifier) =>
      currentModifier.modifier_id === modifier.modifier_id,
  );
};

export function calculateAdditionalPriceForItemsAsModifiers(
  items: { id: number; base: number; selectedItems: any[] }[],
) {
  let extraPrice = 0;
  items.map(
    (
      item: { id: number; base: number; selectedItems: any[] },
      index: number,
    ) => {
      extraPrice = item.selectedItems.reduce(
        (acc: number, item: any) => acc + item?.additionalPrice,
        extraPrice,
      );
    },
  );
  return extraPrice;
}

export function calculateAndUpdateTotalAdditionalPrice(
  itemsGroup: { id: number; base: number; selectedItems: any[] }[],
  extraPriceAction: '+' | '-',
  dispatch,
): void {
  const extraPrice = calculateAdditionalPriceForItemsAsModifiers(itemsGroup);
  dispatch(
    addItemAsModifiersPriceInTotalPrice({ extraPrice, extraPriceAction }),
  );
}

function sortThem(array, item_counter_increment, order) {
  const storage = {};
  array.map((val) => {
    if (
      val.isByDefault &&
      (storage[val.id]
        ? storage[val.id] <=
          item_counter_increment * val[mapSizesKey[order.size]] -
            item_counter_increment
        : true)
    ) {
      storage[val.id] = storage[val.id]
        ? storage[val.id] + item_counter_increment
        : item_counter_increment;
      val.isGoodToGo = true;
    }
  });
  array.sort((a, b) => {
    if (a.isByDefault && a.isGoodToGo) {
      return -1;
    } else {
      return 1;
    }
  });
  return array;
}

export function addAdditionalPrice({ items, currentItemsGroup, order }) {
  const currentItemGroup = _clonedeep(currentItemsGroup);
  const newItemsAsModifiers = sortThem(
    [...items],
    currentItemsGroup.item_counter_increment,
    order,
  );
  for (let index = 0; index < newItemsAsModifiers.length; index++) {
    if (newItemsAsModifiers?.isGoodToGo) delete newItemsAsModifiers.isGoodToGo;
    let itemAdditionalPrice = 0;

    let forLoopCount = 0;
    if (currentItemsGroup.item_counter_increment === 0) {
      forLoopCount = newItemsAsModifiers[index].quantity;
    } else {
      forLoopCount =
        newItemsAsModifiers[index].quantity /
        currentItemsGroup.item_counter_increment;
    }
    if (forLoopCount === 0)
      newItemsAsModifiers[index].additionalPrice = itemAdditionalPrice;
    for (let j = 0; j < forLoopCount; j++) {
      const price =
      newItemsAsModifiers[index]?.sub_item_price ??
      newItemsAsModifiers[index].price;
      currentItemGroup[mapSizesKey.basePrice[order.size]] = toFixedNumber(
        currentItemGroup[mapSizesKey.basePrice[order.size]] -
          price,
      );
      if (currentItemGroup[mapSizesKey.basePrice[order.size]] < 0)
        itemAdditionalPrice = toFixedNumber(
          itemAdditionalPrice +
            Math.abs(currentItemGroup[mapSizesKey.basePrice[order.size]]),
        );
      if (currentItemGroup[mapSizesKey.basePrice[order.size]] <= 0) {
        currentItemGroup[mapSizesKey.basePrice[order.size]] = 0;
      }
      newItemsAsModifiers[index].additionalPrice = itemAdditionalPrice;
    }
  }

  return newItemsAsModifiers;
}

export function byDefaultSeletedItems({
  currentItemGroup,
  items,
  mapSizesKey,
  order,
  currentlySelectedItemsAsModifier, // its update by reference
  parentItem
}) {
  const filteredItems = [...currentItemGroup.items_as_modifier];
  if (items) {
    const selectedItems = [];
    filteredItems
      .filter(
        (item) =>
          item.is_selected_with_count &&
          item[mapSizesKey[order.size]] &&
          verifyStatus(item, Constants.MODIFIER_LOCATION),
      )
      .map((item) => {
        const currentItemWithNewProperty = {
          ...item,
          quantity: currentItemGroup.item_counter_increment,
          actualQuantity:
            currentItemGroup.item_counter_increment *
            item[mapSizesKey[order.size]],
          isByDefault: true,
          additionalPrice: 0,
          isSelected: true,
        };
        for (let index = 0; index < item[mapSizesKey[order.size]]; index++) {
          selectedItems.push({ ...currentItemWithNewProperty });
        }
      });
    currentlySelectedItemsAsModifier.push({
      id: currentItemGroup.id,
      parentItem: parentItem,
      base: currentItemGroup[mapSizesKey.basePrice[order.size]],
      parentIncrementCount: currentItemGroup.item_counter_increment,
      selectedItems,
      byDefaultSelectedItems: _clonedeep(selectedItems),
    });
  }
}

import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as CONSTANT from 'src/constants';
import { ROUTE_LOCATIONS } from 'src/constants';
import {
  setLocation,
  setLocationError,
} from 'src/Features/Location/redux/slice';
import { handleIsLiveLocationRedirection } from 'src/helper/checkout/LocationHelper';
import { getVisitorId, isGuestUser } from 'src/helper/helperMethods';
import { ISelectedLocation } from 'src/models/forms.model';
import { IItemPathToRoute } from 'src/models/item.model';
import { IGetCartPayload } from 'src/models/order.model';
import {
  setShowCart,
  updateCartRedeemedOfferItem,
  updateCartOrderDetailsWithoutSlots,
  updateIsOrderTypeSelected,
  updateOrderDetails,
  updateOrderType,
  resetCartOffer,
  clearCartOfferAuxData,
} from 'src/redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { customerBusinessService, locationMenuService } from 'src/services';
import { selectLocationSchema } from 'src/validationSchemas/selectLocationSchema';

import Button from '../Button/Button';
import FormField from '../FormFields/FormField';
import styles from './itemOrder.module.scss';
import useSyncCart from 'src/hooks/useSyncCart';
import useGetOffers from 'src/react-query-hooks/Loyalty/useGetOffers';
import { roundToTwo } from 'src/priceCalculation/helper';
import { IOfferItem } from 'src/models/rewards.model';
import useRedeemOffer from 'src/hooks/useRedeemOffer';
import useApplyOfferOnCart from 'src/react-query-hooks/Cart/useApplyOfferOnCart';
import { Toast_Func } from 'src/helper/toast.helper';

interface IItemPickup {
  itemPath: IItemPathToRoute;
  closeOpenOrderModal: () => void;
  showItemPickupDateTime?: boolean;
  handleShowPickupMethodModal?: (boolean) => void;
  saveSelectedOrderType?: string;
  handleSavedOrderType?: () => void;
  isItMobile: boolean;
  isCheckout: boolean;
  closeModal?: boolean;
  routeTo?: string;
  initiator?: string;
  goBack?: boolean;
  showModal?: boolean;
  editInModal?: boolean;
}

function ItemPickup(props: IItemPickup) {
  const {
    itemPath,
    closeOpenOrderModal,
    showItemPickupDateTime,
    handleShowPickupMethodModal,
    saveSelectedOrderType,
    isItMobile,
    isCheckout,
    goBack,
    showModal,
    routeTo,
    initiator,
    editInModal = false,
  } = props;
  const history = useHistory();
  const orderTypeDispatch = useAppDispatch();
  const locationDispatch = useAppDispatch();
  const visitor_id = getVisitorId();
  const queryClient = useQueryClient();
  const location = useLocation();
  const locationInfo = useAppSelector((state) => state.location);
  const { user } = useAppSelector((state) => state.user);
  const cart = useAppSelector((state) => state.cart);
  const cartItems = useAppSelector((state) => state.cart.items);
  const [isFetchingLocations, setIsFetchingLocations] = useState<boolean>(
    user.type !== CONSTANT.GUEST_USER,
  );
  const { refetch: refetchOffers, isRefetching: isRefetchingOffers } =
    useGetOffers(false, 'CartModal');
  const { reVerifyCartItemsOfferEligibility, validateOffers } = useRedeemOffer();
  const isPrimaryCapacityFormFilled = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity.formFilled,
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    locationInfo.selectedStore,
  );
  const [locationError, setLocationError] = useState(false);
  const operatorDetail = useAppSelector((state) => state.user.operator);
  const OPManager =
    operatorDetail?.role === 'operating partners' ||
    operatorDetail?.role === 'manager';
  const selectedLocationOption = {
    value: locationInfo.selectedStore?.name,
    label: selectedLocation.name,
    id: selectedLocation.id,
  };
  const initialFormState: ISelectedLocation = {
    selected_location: selectedLocation.id ? selectedLocationOption : null,
  };
  const getLocations = async (payload) => {
    const response = await locationMenuService.getNearbyLocations(payload);
    if (response) setIsFetchingLocations(false);
    const responseLocations = response?.data?.data;
    setMarkets(responseLocations);
    setAllLocations(
      responseLocations?.map((market) => ({
        value: market.name,
        label: market.name,
        id: market.id,
        ...market,
      })),
    );
  };
  const handleLocationSearch = () => {
    closeOpenOrderModal();
    orderTypeDispatch(setShowCart(false));
    history.push({
      pathname: CONSTANT.ROUTE_STEP_2,
    });
  };

  const isEditOrder = useAppSelector((state) => state.cart.is_edit_order);
  const orderId = useAppSelector((state) => state.cart.orderId);

  const handleContinueButton = async () => {
    if (!selectedLocation) {
      history.push({
        pathname: ROUTE_LOCATIONS,
        state: {
          itemPath: itemPath,
          goBack: goBack,
          routeTo: routeTo,
          initiator: initiator,
        },
      });
      orderTypeDispatch(setShowCart(false));
    } else {
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      orderTypeDispatch(updateOrderType(CONSTANT.PICK_UP_ORDER_TYPE));
      if (cart.free_delivery) {
        orderTypeDispatch(
          updateCartRedeemedOfferItem({
            is_redeemed_by_offer: false,
          }),
        );
      }
      if (cart.orderDetails?.PickupSlotDetails?.time) {
        orderTypeDispatch(
          updateCartOrderDetailsWithoutSlots({
            pickupMethod: CONSTANT.PICK_UP_METHOD_IN_STORE,
          }),
        );
      } else {
        orderTypeDispatch(updateOrderDetails(null));
      }
      const params = isEditOrder
        ? {
            order_id: orderId,
          }
        : undefined;
      await refetchOffers();
      const response = await customerBusinessService.getOffers(params);

      validateOffers(
        selectedLocation.id,
        CONSTANT.PICK_UP_ORDER_TYPE,
        response?.data?.data,
      );
    }
    closeOpenOrderModal();
  };
  const getSelectedLocation = (location) => {
    return allLocations.find((loc) => loc.id === location?.id)
      ? location
      : null;
  };
  const getLocationDropdown = () => {
    const payload: any = { user_id: user.id, favorites_only: true };
    getLocations(payload);
  };
  const [loading, setIsLoading] = useState(false);
  const confirmLocationChange = async () => {
    setIsLoading(true);
    if (!selectedLocation.is_live) {
      handleIsLiveLocationRedirection(
        saveSelectedOrderType,
        selectedLocation,
        isItMobile,
      );
      setIsLoading(false);
    } else {
      const locationExist = operatorDetail.locations.find(
        (obj) => selectedLocation.id === obj.id,
      );
      if (!OPManager) {
        orderTypeDispatch(updateIsOrderTypeSelected(true));
        // setLocation(locationDispatch, selectedLocation);
        locationDispatch(setLocation({ currentLocation: selectedLocation }));
        const payload: IGetCartPayload = {
          location_id: selectedLocation.id.toString(),
        };
        if (user.id) payload.customer_id = user.id;
        else payload.visitor_id = visitor_id;
        queryClient.refetchQueries(['get-cart', selectedLocation.id]);
        // orderTypeDispatch(updateOrderDetails(null));
        orderTypeDispatch(
          updateCartOrderDetailsWithoutSlots({
            pickupMethod: CONSTANT.PICK_UP_METHOD_IN_STORE,
          }),
        );
        if (saveSelectedOrderType) {
          handleSaveOrderType(saveSelectedOrderType);
        }
        if (
          showItemPickupDateTime &&
          !cart.orderDetails?.PickupSlotDetails?.time
        ) {
          handleShowPickupMethodModal(true);
        }
        if (showConfirmationModal) {
          setShowConfirmationModal(false);
          history.push(CONSTANT.ROUTE_STEP_3);
        } else if (itemPath) {
          const goto =
            isPrimaryCapacityFormFilled || cart.is_edit_order
              ? itemPath
              : {
                  pathname: CONSTANT.ROUTE_START_CATERING_ORDER,
                  state: { initiator: initiator, itemPath: itemPath },
                };
          history.push(goto);
        }
        if (cart.free_delivery) {
          orderTypeDispatch(
            updateCartRedeemedOfferItem({
              is_redeemed_by_offer: false,
            }),
          );
          orderTypeDispatch(resetCartOffer());
        }
      } else if (locationExist?.id && OPManager) {
        orderTypeDispatch(updateIsOrderTypeSelected(true));
        locationDispatch(setLocation({ currentLocation: selectedLocation }));
        const payload: IGetCartPayload = {
          location_id: selectedLocation.id.toString(),
        };
        if (user.id) payload.customer_id = user.id;
        else payload.visitor_id = visitor_id;
        queryClient.refetchQueries(['get-cart', selectedLocation.id]);
        // orderTypeDispatch(updateOrderDetails(null));
        orderTypeDispatch(
          updateCartOrderDetailsWithoutSlots({
            pickupMethod: CONSTANT.PICK_UP_METHOD_IN_STORE,
          }),
        );
        if (saveSelectedOrderType) {
          handleSaveOrderType(saveSelectedOrderType);
        }
        if (
          showItemPickupDateTime &&
          !cart.orderDetails?.PickupSlotDetails?.time
        ) {
          handleShowPickupMethodModal(true);
        }
        if (showConfirmationModal) {
          setShowConfirmationModal(false);
          history.push(CONSTANT.ROUTE_STEP_3);
        } else if (itemPath) {
          const goto =
            isPrimaryCapacityFormFilled || cart.is_edit_order
              ? itemPath
              : {
                  pathname: CONSTANT.ROUTE_START_CATERING_ORDER,
                  state: { initiator: initiator, itemPath: itemPath },
                };
          history.push(goto);
        }
      } else if (!locationExist?.id && OPManager) {
        setLocationError(true);
      }
      if (cart.free_delivery) {
        orderTypeDispatch(
          updateCartRedeemedOfferItem({
            is_redeemed_by_offer: false,
          }),
        );
      }
      const params = isEditOrder
        ? {
            order_id: orderId,
          }
        : undefined;
      await refetchOffers();
      const response = await customerBusinessService.getOffers(params);
      // const response = await refetchOffers();
      validateOffers(
        selectedLocation.id,
        CONSTANT.PICK_UP_ORDER_TYPE,
        response?.data?.data,
      );
      setIsLoading(false);
    }
    closeOpenOrderModal();
  };

  const handleSaveOrderType = (orderType: string) => {
    orderTypeDispatch(updateOrderType(orderType));
  };

  const routeToItem = () => {
    setIsSubmitting(true);
    confirmLocationChange();
  };
  useEffect(() => {
    // getLocationsWithoutPermission();
    if (user.type !== CONSTANT.GUEST_USER) getLocationDropdown();
  }, []);

  // useEffect(() => {
  //   if (!showModal) {
  //     history.push({
  //       pathname: ROUTE_LOCATIONS,
  //       state: { itemPath: itemPath, goBack: goBack },
  //     });
  //   }
  // }, [showModal]);
  return isFetchingLocations ? (
    <div className="mt-4">
      <Spinner />
    </div>
  ) : (
    <>
      <div className="mt-5">
        {allLocations?.length ? (
          <>
            <Formik
              initialValues={initialFormState}
              onSubmit={routeToItem}
              validationSchema={selectLocationSchema}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <form
                  className={`full_width_mob new_form_design my-0 d-flex flex-column ${styles.selectOrderPickup}`}
                  onSubmit={handleSubmit}
                >
                  <FormField
                    isSearchable={true}
                    options={allLocations?.filter(
                      (location) => location.is_live,
                    )}
                    labelText={'Favorite Locations'}
                    name="selected_location"
                    type={'select'}
                    placeholder={'Select Location'}
                    errors={errors}
                    touched={touched}
                    value={getSelectedLocation(values.selected_location)}
                    onChange={(location) => {
                      setFieldValue('selected_location', location);
                      setSelectedLocation(location);
                    }}
                    inputFieldClass={
                      'custom_select mb-0 mt-2 mb-4 pb-md-0 fav_location_select'
                    }
                  />
                  {selectedLocation && (
                    <div className="text-start mb-4 px-5">
                      <span className="d-block f-s14 font-plex light-blue f-w5">
                        {selectedLocation.name}
                      </span>
                      <span className="d-block f-s14 font-plex light-blue f-w5">
                        {selectedLocation.address}
                      </span>
                      <span className="d-block f-s14 font-plex light-blue f-w5">
                        {selectedLocation.city}, {selectedLocation.state}{' '}
                        {selectedLocation.zip}
                      </span>
                    </div>
                  )}
                  {locationError && (
                    <p className={`clr-red-dark f-s14 text-center`}>
                      The address does not lie in your store's delivery zone.
                      Please contact IT to update your delivery zone.
                    </p>
                  )}
                  <hr className="mt-0" />
                  <Button
                    className="mx-5"
                    // disabled={isSubmitting || !markets}
                    type={'submit'}
                    disabled={loading}
                    loading={loading}
                  >
                    Continue
                  </Button>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="text-start">
              {selectedLocation && (
                <div className="my-4 px-5">
                  <span className="d-block f-s14 font-Visby-cf f-w5">
                    {selectedLocation.name}
                  </span>
                  <span className="d-block f-s14 font-Visby-cf f-w5">
                    {selectedLocation.address}
                  </span>
                  <span className="d-block f-s14 font-Visby-cf f-w5">
                    {selectedLocation.city}, {selectedLocation.state}{' '}
                    {selectedLocation.zip}
                  </span>
                </div>
              )}
              {!editInModal && <hr className="mb-0" />}
              <Button
                className="w-75 d-block mx-auto mt-4"
                onClick={handleContinueButton}
               
              >
                Continue
              </Button>
            </div>
          </>
        )}
        <Button className="btn-text mt-4" onClick={handleLocationSearch}>
          {selectedLocation ? 'Change Location' : 'Search Locations'}
        </Button>
      </div>
    </>
  );
}

export default ItemPickup;

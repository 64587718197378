import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store/store';
import { onStepClick } from 'src/redux/slices/wizardSlice';
import { useAppDispatch } from 'src/redux/store/store';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
import {
  ROUTE_LOGIN,
  GUEST_USER,
  ROUTE_OTHER_ITEM,
  ROUTE_OTHER_ITEM_STEP_ID,
} from '../../constants';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const RequireAuth: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...restOfProps
}) => {
  const { user } = useAppSelector((state) => state.user);
  const { location } = restOfProps;
  const dispatch = useAppDispatch();
  const { redirect } = useRedirectToSpecificStep();
  const query = new URLSearchParams(location.search);
  const category_id = query.get('category_id');
  const currentLocation = useLocation();
  const { steps, lastActiveStep } = useAppSelector((state) => state.wizard);

  useEffect(() => {
    let searchedStep = steps[0];

    if (currentLocation?.pathname.includes(ROUTE_OTHER_ITEM)) {
      searchedStep = steps.find((step) => step.id == ROUTE_OTHER_ITEM_STEP_ID);
    } else {
      searchedStep = steps.find((step) =>
        currentLocation?.pathname.includes(step.route),
      );
    }

    if (Number(searchedStep?.id) > lastActiveStep) {
      dispatch(onStepClick({ stepId: lastActiveStep }));
      redirect(lastActiveStep.toString());
    } else if (Number(searchedStep?.id) <= lastActiveStep) {
      dispatch(onStepClick({ stepId: Number(searchedStep?.id) }));
    }
  }, [currentLocation]);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user?.type !== GUEST_USER ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_LOGIN,
              state: {
                referrer: location.pathname,
                category_id: category_id,
              },
            }}
          />
        )
      }
    />
  );
};

export default RequireAuth;

import React from 'react';
import { MinusCircle, PlusCircle } from 'src/assets/images/Svgs/svg';
import {
  addCode,
  addExtendableLimit,
  give_me_my_classes,
} from 'src/helper/helperMethods';
import { selectedBtnStyle } from '../cardStyle';

import * as Constant from '../../../../constants';
import { ICounterCard } from '../../../../models/card';
// import NotAvailable from '../../../Buttons/NotAvailable';
import Labels from '../CardLabels/Labels';
import NotAvailable from 'src/components/Button/NotAvailable';

import styleClasses from './counterCard.module.scss';

const CounterCard: React.FC<ICounterCard> = (props) => {
  const {
    id,
    image,
    title,
    calories,
    price,
    max,
    count,
    brink_id,
    handleClick,
    is_active,
    priceAlternateLabel,
    default_selected,
    isApplyDisable = true,
    from = '',
    modifierType = '',
    // isAddByDefault = true,
    additionalPrice,
    extendableLimit = false,
    extendableLimitValue,
    groupMin,
    // isItEdit=false,
    remainingBase = 0,
    showAdditionalPrice,
    itemBase,
    titleshow,
    nestedParentId,
    counterMob,
    showRedBorderAnimation,
    counterBtnSpace,
    isCreateYourOwnItem
  } = props;

  const disableIncreaseButton =
    (isApplyDisable &&
      (typeof max === 'string' ? parseInt(max) === count : max === count)) ||
    extendableLimit;

  const handleCounterClick = (type: string, quantity) => {
    let payload: any = {
      modifier_id: id,
      modifier_name: title,
      display_price: price,
      modifier_calories: calories,
      brink_id,
      quantity: quantity,
      modifier_group_min: groupMin,
      max,
      type: type,
      is_selected: default_selected,
    };
    if (type === Constant.BY_DEFAULT) {
      payload.core = true;
    }
    payload = addCode(type, payload);
    payload = addExtendableLimit(payload, type, extendableLimitValue);
    if (nestedParentId) payload.nested_parent_id = nestedParentId;
    handleClick(payload);
  };

  const assignBorderClass = () => {
    if (default_selected || modifierType === 'core_modifier') {
      if (count > 1) {
        return give_me_my_classes(
          ['add_on_item_wrap', 'selected'],
          styleClasses,
        );
      } else if (count < 1) {
        return give_me_my_classes(
          ['add_on_item_wrap', 'exclude'],
          styleClasses,
        );
      } else {
        return '';
      }
    } else {
      if (count > 0) {
        return give_me_my_classes(
          ['add_on_item_wrap', 'selected'],
          styleClasses,
        );
      }
    }
  };

  const onIncreaseButtonClick = (e) => {
    e.stopPropagation();
    handleCounterClick(Constant.INCREASE, count + 1);
  };

  const onDecreaseButtonClick = (e) => {
    e.stopPropagation();
    handleCounterClick(Constant.DECREASE, count - 1);
  };

  return (
    <div
      className={`${assignBorderClass()} ${
        styleClasses.item_wrap
      } add_on_item_wrap shadow-box d-flex flex-column justify-content-center ${
        showRedBorderAnimation && count < 1 ? 'border-size-select' : ''
      }`}
      style={{ ...(!is_active && { opacity: 0.7 }) }}
      onClick={() =>
        count === 0 &&
        !disableIncreaseButton &&
        is_active &&
        handleCounterClick(Constant.INCREASE, count + 1)
      }
    >
      <div
        className={`${styleClasses.items_inn_content} ${counterBtnSpace} items__inn_content`}
      >
        <h3 className={`${styleClasses.item_title} ${titleshow} font-plex light-blue`}>
          {title}
        </h3>
        {is_active ? (
          <div
            className={`${styleClasses.item_increments} mb-2 d-flex justify-content-between align-items-center ${counterMob}`}
          >
            <button
              type="button"
              className={`${styleClasses.decrease_btns} btn btn_custom`}
              data-testid="counter-card-dec"
              disabled={count === 0}
              onClick={(e) => onDecreaseButtonClick(e)}
            >
              <MinusCircle />
            </button>
            <span
              className={`${styleClasses.counts} font-plex`}
              data-testid="counter-card-count"
            >
              {count || 0}
            </span>
            <button
              type="button"
              className={`${styleClasses.increase_btns} btn btn_custom`}
              data-testid="counter-card-inc"
              disabled={disableIncreaseButton}
              onClick={(e) => onIncreaseButtonClick(e)}
            >
              <PlusCircle />
            </button>
          </div>
        ) : (
          <NotAvailable selectedBtnStyle={selectedBtnStyle} />
        )}
        <Labels
          price={price}
          priceAlternateLabel={priceAlternateLabel}
          additionalPrice={additionalPrice}
          calories={calories}
          itemBase={itemBase}
          remainingBase={remainingBase}
          showAdditionalPrice={showAdditionalPrice}
          count={count}
          isItSubstitute={extendableLimitValue === 1 && groupMin === 1}
          isCreateYourOwnItem={isCreateYourOwnItem}
        />
      </div>
    </div>
  );
};

export default CounterCard;

import { useMutation } from 'react-query';
import { operatorService } from 'src/services/operator.service';

const saveDraft = async (data: any) => {
  const response = await operatorService.saveAsDraft(data);
  return response.data.data;
};

export default function useSaveDraft() {
  return useMutation((data: any) => saveDraft(data));
}

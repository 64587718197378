import { useMutation } from 'react-query';
import { IDeleteOrderItemPayload } from '../../models/order.model';
import { orderMicroService } from '../../services';

const EditItem = async (payload: any) => {
  if (payload?.id) {
    const response = await orderMicroService.addItemstoOrder(payload.id);
    return response.data.data;
  }
};

export default function useEditCart() {
  return useMutation((payload) => EditItem(payload));
}

import * as Yup from 'yup';

export const selectLocationSchema = Yup.object().shape({
  selected_location: Yup.object()
    .shape({
      value: Yup.string().required('Please Select Location').nullable(),
    })
    .nullable()
    .required('Please Select Location'),
});

import React from 'react';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation } from 'react-router';
import { verifyStatus } from 'src/helper/customizedItem/customizedItem.helper';

import CounterCard from '../../../components/Molecules/Card/CounterCard/CounterCard';
import SelectCard from '../../../components/Molecules/Card/SelectCard/SelectCard';
import * as Constants from '../../../constants';
import { IParams } from '../../../models/card';
import { ICoreModifiers } from '../../../models/item.model';
import { ICoreModifiersComponent } from '../../../models/try2Combo';

const CoreModifiers: React.FC<ICoreModifiersComponent> = (props) => {
  const {
    coreModifiers,
    selectedCore,
    handleCoreModifierClick,
    isLimitExceed,
    cardColSize,
    Item,
    isPackege = false,
  } = props;

  const selectedCoreModifier = (modifier) => {
    return selectedCore?.find(
      (selected) => selected.modifier_id === modifier.id,
    );
  };

  const handleIngredientSelection = (
    data: IParams,
    modifier: ICoreModifiers,
  ) => {
    handleCoreModifierClick(
      {
        ...data,
        ...{
          brink_modifier_group_id: modifier?.brink_modifier_group_id,
          brink_modifier_id: modifier?.brink_modifier_id,
          core: true,
        },
      },
      modifier,
      Constants.CORE_MODIFIERS,
    );
  };
  const { search, state } = useLocation();
  const query = new URLSearchParams(search);
  const isPackage = Boolean(query.get('is_package'));
  const itemCoreModifiers = () => {
    return (
      <div className="single-item-modifiers row">
        {coreModifiers &&
          coreModifiers.map((modifier) => {
            const selected = selectedCoreModifier(modifier);

            return (
              <div
                className={`${
                  Item?.is_box_lunch || isPackage
                    ? 'col-4 col-md-3 col-lg-3 px-2'
                    : 'col-4 col-md-3 col-lg-2 px-2'
                }`}
              >
                <div
                  key={modifier?.id}
                  className={`item__modifier_item pb-3 ${cardColSize}`}
                >
                  {modifier?.max > 1 ? (
                    <CounterCard
                      id={modifier?.id}
                      brink_id={modifier?.brink_modifier_id}
                      image={`${Constants.s3BaseUrl}${modifier?.image}`}
                      title={modifier.name}
                      price={modifier.price}
                      calories={modifier.calories}
                      max={modifier.max}
                      groupMin={modifier.modifier_group_min}
                      is_active={verifyStatus(
                        modifier,
                        Constants.MODIFIER_LOCATION,
                      )}
                      handleClick={(data) =>
                        handleIngredientSelection(data, modifier)
                      }
                      count={selected?.quantity || 0}
                      from={Constants.TRY_2_COMBO}
                      priceAlternateLabel={
                        selected?.quantity ? 'included' : 'include'
                      }
                      modifierType={'core_modifier'}
                      additionalPrice={selected?.additionalPrice}
                      extendableLimit={isLimitExceed(
                        modifier?.modifier_group_id,
                        modifier?.modifier_group_extendable_limit,
                        modifier.modifier_group_min,
                      )}
                      extendableLimitValue={
                        modifier?.modifier_group_extendable_limit
                      }
                    />
                  ) : (
                    <SelectCard
                      id={modifier?.id}
                      brink_id={modifier?.brink_modifier_id}
                      default_selected={
                        modifier.is_selected ? modifier.is_selected : 1
                      }
                      image={`${Constants.s3BaseUrl}${modifier?.image}`}
                      title={modifier.name}
                      price={modifier.price}
                      calories={modifier.calories}
                      groupMin={modifier.modifier_group_min}
                      is_active={verifyStatus(
                        modifier,
                        Constants.MODIFIER_LOCATION,
                      )}
                      handleClick={(data) =>
                        handleIngredientSelection(data, modifier)
                      }
                      priceAlternateLabel={
                        selected?.quantity ? 'included' : 'include'
                      }
                      selected={Boolean(selected)}
                      modifierAdditionalPrice={selected?.additionalPrice}
                      componentUsedIn={Constants.TRY_2_COMBO}
                      extendableLimit={isLimitExceed(
                        modifier?.modifier_group_id,
                        modifier?.modifier_group_extendable_limit,
                        modifier.modifier_group_min,
                      )}
                      extendableLimitValue={
                        modifier?.modifier_group_extendable_limit
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {coreModifiers?.length > 0 && (
        <Container fluid className="modifiersContainer">
          <Accordion
            defaultActiveKey={['1']}
            alwaysOpen
            className="theme-custom-accordions item-customization-acc modifiers-accordions-wrapper"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className=" f-s20 f-w5 font-plex">
                  Included Ingredients
                </span>
              </Accordion.Header>
              <Accordion.Body>{itemCoreModifiers()}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      )}
    </React.Fragment>
  );
};

export default CoreModifiers;

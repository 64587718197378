import React from 'react';

interface IRecommendedDreesingButton {
  showRecommendedButton: boolean;
  handleClick: () => void;
  text: string;
}

const RecommendedButton: React.FC<IRecommendedDreesingButton> = ({
  handleClick,
  showRecommendedButton,
  text,
}) => {
  return (
    <React.Fragment>
      {showRecommendedButton && (
        <button
          type="button"
          className="btn btn-custom back-arrow-btn f-s14 px-0 mb-3 d-none d-md-flex align-items-center"
          onClick={handleClick}
        >
          Recommended {text}
        </button>
      )}
    </React.Fragment>
  );
};

export default RecommendedButton;

import { useMutation, useQuery } from 'react-query';

import { IGetCartPayload } from '../../models/order.model';
import { orderMicroService } from '../../services';

export const emptyCart = async (payload: string) => {
  const response = await orderMicroService.deleteCart(payload);
  return response.data.data;
};

export default function useEmptyCart() {
  return useMutation((payload: string) => emptyCart(payload));
}

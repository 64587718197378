import React, { useCallback } from 'react';
import { IRequiredModifier } from '../../../../models/try2Combo';
import { Container } from 'react-bootstrap';
import { REQUIRED_MODIFIERS } from '../../../../constants';
import { itemBuckets } from '../../../../priceCalculation/buckets';
import { delayer } from 'src/helper/utils';
import Button from 'src/components/Button/Button';
import HorizentalSlider from './HorizentalSlider';
import VerticalSlider from './VerticalSlider';

const Sliders: React.FC<IRequiredModifier> = (props) => {
  const {
    setOtherDressingData,
    otherDressingHandler,
    items_required_modifier_groups,
    selectedIngredients,
    handleIngredientSelection,
    isInItemPresent,
    setIsInItemPresent,
    sectionSelected,
    selectedAddedIngredients,
    noDressingHandler,
    isNoDressing,
    noDressingModifiers,
    addItemtoCombo,
    selectedOtherDressingData,
    label,
  } = props;

  const delayerFunction = useCallback(delayer(addItemtoCombo, 100), []);

  const requiredModifierDetail = () => {
    if (
      items_required_modifier_groups &&
      items_required_modifier_groups?.length > 0
    ) {
      const { id, name, extendable_limit, max, min, label } =
        items_required_modifier_groups[0];
      return { id, name, extendable_limit, max, min, label };
    }
  };

  const isItSubstitute = () => {
    const { name, extendable_limit, max, min } = requiredModifierDetail();
    if (extendable_limit === 1 && max === 1 && min === 1) {
      return true;
    } else {
      return false;
    }
  };

  const noDressing = (function () {
    if (items_required_modifier_groups) {
      const { id } = requiredModifierDetail();
      const requiredModifiersBucket = itemBuckets.getSingleBucket({
        name: REQUIRED_MODIFIERS,
        fromItem: parseInt(sectionSelected),
      });
      if (requiredModifiersBucket?.noDressings) {
        const noDressingState = requiredModifiersBucket?.noDressings.find(
          (e) => e.id === id,
        );
        return noDressingState.noDressing;
      } else {
        return false;
      }
    }
  })();

  const requiredModifierGroups =
    items_required_modifier_groups &&
    items_required_modifier_groups.length > 0 &&
    items_required_modifier_groups[0];

  const isApplyDisable =
    !(selectedIngredients?.length > 0) &&
    selectedIngredients?.length <= requiredModifierGroups?.min &&
    !noDressing;

  const isShowOtherDreesingCard = () => {
    let isTrue,
      isFalse = false;
    if (
      items_required_modifier_groups &&
      items_required_modifier_groups.length > 0
    ) {
      isTrue =
        items_required_modifier_groups[0]?.required_modifiers_groups_modifiers.every(
          (modifier) => modifier?.in_item,
        );
      isFalse =
        items_required_modifier_groups[0]?.required_modifiers_groups_modifiers.every(
          (modifier) => !modifier?.in_item,
        );
    }
    if (isTrue || isFalse) {
      return false;
    } else {
      return true;
    }
  };

  const handleSelection = (data: any) => {
    handleIngredientSelection(data);
    if (isItSubstitute()) {
      delayerFunction();
    }
  };

  return (
    <>
      <div
        className={` px-lg-5 px-md-0 Dressing-desktop_view d-none d-md-block other-dressing-view try2_dressing_modal`}
      >
        <Container className="ps-0 ms-0">
          <h5 className="text-start mb-sm-4 f-s22 f-sm-s16 pt-4 light-blue text-uppercase">
            {isItSubstitute()
              ? 'choose your bread'
              : isShowOtherDreesingCard() && 'Recommended'}
          </h5>
        </Container>
        <HorizentalSlider
          items_required_modifier_groups={items_required_modifier_groups}
          otherDressingHandler={otherDressingHandler}
          selectedIngredients={selectedIngredients}
          handleIngredientSelection={handleSelection}
          setOtherDressingData={setOtherDressingData}
          isInItemPresent={isInItemPresent}
          sectionSelected={sectionSelected}
          selectedAddedIngredients={selectedAddedIngredients}
          noDressingHandler={noDressingHandler}
          isNoDressing={noDressing}
          noDressingModifiers={noDressingModifiers}
          label={label}
        />
      </div>
      <VerticalSlider
        items_required_modifier_groups={items_required_modifier_groups}
        otherDressingHandler={otherDressingHandler}
        selectedIngredients={selectedIngredients}
        handleIngredientSelection={handleSelection}
        setOtherDressingData={setOtherDressingData}
        isInItemPresent={isInItemPresent}
        sectionSelected={sectionSelected}
        noDressingHandler={noDressingHandler}
        isNoDressing={noDressing}
        selectedAddedIngredients={selectedAddedIngredients}
        noDressingModifiers={noDressingModifiers}
        label={label}
      />
      {!isItSubstitute() ? (
        <>
        <hr/>
        <div className="text-end me-5">
          <Button disabled={isApplyDisable} onClick={() => addItemtoCombo()} className='w-25'>
            Apply
          </Button>
        </div>
        </>
      ) : null}
    </>
  );
};

export default Sliders;

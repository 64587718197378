import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, Container, Row, Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomModal from 'src/components/customModal/CustomModal';
import Footer from 'src/components/Layout/Footer/Footer';
import Loader from 'src/components/Loader/Loader';
import {
  CONTROL_CENTER_OPERATOR_URL,
  ROUTE_STEP_1,
  TOKEN,
} from 'src/constants';
import * as CONSTANT from 'src/constants';
import {
  removeRecievingUserData,
  removeUserData,
  updateRecievingUserData,
  updateUserData,
  updateSearchQuery,
  removeSearchQuery,
  updateBookingUserData,
  removeBookingUserData,
} from 'src/Features/AccountSetup/redux/slices';
import { syncCartOnLogin } from 'src/helper/cartHelperMethods/syncCart';
import {
  convertTimeFormat12to24,
  getOperatorCustomerId,
  getOperatorToken,
  getVisitorId,
  isGuestUser,
  setOperator,
  setOperatorCustomer,
  setOperatorToken,
} from 'src/helper/helperMethods';
import { saveDratLoad } from 'src/helper/saveDraftLoad';
import { Toast_Func } from 'src/helper/toast.helper';
import { PickUpMethods } from 'src/helper/tracking';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
import useEditCart from 'src/react-query-hooks/Cart/useEditCart';
import useLoadDraft from 'src/react-query-hooks/loadDraft';
import useGetCateringOperatorCustomer from 'src/react-query-hooks/Operator/useGetOperatorCustomer';
import useGetCateringOperatorDetails from 'src/react-query-hooks/Operator/useGetOperatorDetails';
import userRegisterCateringOperatorCustomer from 'src/react-query-hooks/Operator/useRegisterOperatorCustomer';
import useSaveDraft from 'src/react-query-hooks/useDraft';
import useOrderDetails from 'src/react-query-hooks/useGetOrderDetails';
import useReorder from 'src/react-query-hooks/useReorder';
import {
  resetCart,
  resetCartItems,
  resetCartOffer,
  setShowCart,
  updateIsOrderTypeSelected,
  updateOrderDetails,
  updateOrderType,
} from 'src/redux/slices/cartSlice';
import {
  activeStepsOnDirectAccess,
  editOrderWizardState,
  handleDisableStepperClick,
  nextStep,
  onStepClick,
  reOrderWizardState,
  resetSteps,
} from 'src/redux/slices/wizardSlice';
import { useAppSelector } from 'src/redux/store/store';
import { useAppDispatch } from 'src/redux/store/store';

import * as ROUTE_CONSTANT from '../../constants';
import { API_ERROR_MESSAGE } from '../../models/error.model';
import { updateOperatorData } from '../AccountSetup/redux/slices';
import { setLocationAction } from '../Location/redux/actions';

import BookingCustomerInfo from './BookingCustomerInfo';
import styles from './customerInfo.module.scss';
import SearchedCustomer from './SearchedCustomer';
import Button from 'src/components/Button/Button';
import useSyncCart from 'src/hooks/useSyncCart';
import {
  addGiftCard,
  resetCheckout,
  updateDeliveryTipObject,
  updateGiftCard,
  updateHouseAccount,
  updateIsSplitPayment,
  updatePayInStoreToggle,
  updatePaymentCard,
  updateSendPaymentRequestEmail,
  updateSendPaymentRequestPhone,
  updateTaxExemptAccount,
  updateTextToPayToggle,
} from 'src/redux/slices/checkoutSlice';
import {
  resetLocation,
  setFromURLTrue,
  setLocation,
} from '../Location/redux/slice';
import {
  resetPrimaryCapacity,
  setPrimaryCapacity,
} from 'src/redux/slices/handleStatesForSpecificFlows';
import { logout } from '../AccountSetup/redux/actions';
import { useQueryClient } from 'react-query';
import useExitEditOrder from 'src/react-query-hooks/useExistEditOrder';
import useOperatorCustomerList from 'src/react-query-hooks/Operator/useOperatorCustomerList';
import {
  customerBusinessService,
  locationMenuService,
  paymentService,
} from 'src/services';
import usePaymentMethods from 'src/hooks/usePaymentMethods';
import useGetHouseAccountsList from 'src/react-query-hooks/useGetHouseAccountsList';
import useGetTaxExemptAccountsList from 'src/react-query-hooks/useGetTaxExemptAccounts';
const customerInfo = () => {
  const searchQueryState = useAppSelector((state) => state.user.searchQuery);
  const [searchQuery, setSearchQuery] = useState<string>(
    searchQueryState || '',
  );
  const [orderIdUrl, setOrderIdUrl] = useState(null);
  const [isReOrder, setIsReOrder] = useState<boolean>(false);
  const [isEditOrder, setIsEditOrder] = useState<boolean>(false);

  const history = useHistory<any>();
  const formIkRef = useRef<any>();
  const receiveFormIkRef = useRef<any>();
  const operatorDispatch = useAppDispatch();
  const checkoutDispatch = useAppDispatch();
  const wizardDispatch = useAppDispatch();
  const cartDispatch = useDispatch();
  const loginDispatch = useDispatch();
  const dispatch = useDispatch();
  const { syncCart, loading } = useSyncCart();
  const { recievingUser, bookingUser, user } = useAppSelector(
    (state) => state.user,
  );
  const operatorDetailData = useAppSelector((state) => state.user.operator);
  const locationData = useAppSelector((state) => state.location.selectedStore);
  const queryClient = useQueryClient();

  const cart = useAppSelector((state) => state.cart);
  const visitorId = getVisitorId();

  const noUserFoundLocalStorage = JSON.stringify(
    sessionStorage.getItem('noUserFound'),
  )
    ? sessionStorage.getItem('noUserFound') === 'true'
      ? true
      : false
    : false;
  const [noUserFound, setNoUserFound] = useState(noUserFoundLocalStorage);
  const [phoneNumberState, setPhoneNumberState] = useState<boolean>();

  const { mutate: reorderCart, isLoading: reorderLoader } = useReorder();

  const { mutateAsync: signUpUser, isLoading: userCreationLoading } =
    userRegisterCateringOperatorCustomer();

  const { mutate: updateItem, isLoading: isEditing } = useEditCart();
  const { mutateAsync, isLoading: isExitOrderLoading } = useExitEditOrder();

  const handleExitEditOrder = () => {
    return mutateAsync(
      { id: cart.orderId },
      {
        onSuccess: (data) => {
          dispatch(resetCart());
          dispatch(resetCheckout());
          Toast_Func({
            status: true,
            message: 'Closed edit order successfully',
          });
        },
        onError: (error) => {
          console.log(error);
          Toast_Func({
            status: false,
            message: 'An error occurred',
          });
        },
      },
    );
  };
  const resetToBlank = () => {
    dispatch(resetCartItems());
    dispatch(resetCartOffer());
    dispatch(resetCheckout());
    dispatch(resetCart());
    dispatch(resetLocation());
    dispatch(removeSearchQuery());
    dispatch(removeBookingUserData());
    dispatch(removeRecievingUserData());
    dispatch(resetPrimaryCapacity());
    dispatch(resetSteps());
    logout(checkoutDispatch);
    queryClient.removeQueries();
  };
  const resetData = () => {
    setOperatorCustomer('');
    localStorage.removeItem('CurrentUser');
    dispatch(resetCartItems());
    dispatch(resetCartOffer());
    dispatch(resetCart());
    dispatch(removeSearchQuery());
    dispatch(resetCheckout());
    dispatch(resetLocation());
    dispatch(removeBookingUserData());
    dispatch(removeRecievingUserData());
    dispatch(resetPrimaryCapacity());
    dispatch(resetSteps());
    queryClient.removeQueries();
  };
  const reorder = async (orderID: number) => {
    reorderCart(orderID, {
      onSuccess: () => {
        loginDispatch(
          updateRecievingUserData({
            email: order?.customer?.email,
            first_name: order?.customer?.first_name,
            last_name: order?.customer?.last_name,
            phone: order?.customer?.phone,
            id: data.id,
          }),
        );
        loginDispatch(
          updateBookingUserData({
            email: data?.email,
            first_name: data?.first_name,
            last_name: data?.last_name,
            phone: data?.phone,
            id: data.id,
          }),
        );
        Toast_Func({ status: true, message: 'Order added to your cart' });
        dispatch(reOrderWizardState());
        redirect(CONSTANT.STEP2);
      },
      onError: (error) => {},
    });
  };
  const handleSearchQuery = (query) => {
    setSearchQuery(query);
  };
  const { data, isFetching, isFetched, refetch } =
    useGetCateringOperatorCustomer();

  const handleSelectCustomer = (id) => {
    setOperatorCustomer(id);
    refetch();
  };

  const {
    data: operatorDetail,
    isLoading: isFetchinngOperatorDetail,
    isError: isFetchOperatorDetailError,
    isSuccess: isFetchOperatorDetailSucccess,
    error,
  } = useGetCateringOperatorDetails();

  const handleOperatorDetailFetchingError = () => {
    window.location.href = `${CONTROL_CENTER_OPERATOR_URL}?isCatering=true&operator=true`;

    Toast_Func({
      status: false,
      message: error instanceof Error && error.message,
    });
  };

  const handleOperatorDetailFetchingSuccess = () => {
    if (
      !operatorDetailData?.id ||
      operatorDetailData?.id !== operatorDetail?.id ||
      !locationData
    ) {
      operatorDispatch(
        updateOperatorData({
          id: operatorDetail?.id,
          name: operatorDetail?.full_name,
          role: operatorDetail?.role.name,
          location: operatorDetail?.accessible_locations,
        }),
      );
      if (
        operatorDetail?.role === 'operating partners' ||
        operatorDetail?.role === 'manager'
      )
        dispatch(
          setLocation({
            currentLocation: operatorDetail?.accessible_locations[0],
            zipCode: '',
          }),
        );
    }
  };

  const handleCloseModal = () => {
    sessionStorage.setItem('noUserFound', String(false));
    setNoUserFound(false);
  };

  useEffect(() => {
    setSearchQuery(searchQueryState);
  }, [searchQueryState]);

  const { validate }: { validate: () => void } = useValidateRequiredSelection(
    formIkRef,
    receiveFormIkRef,
  );

  const {
    data: customersList,
    refetch: searchRefetch,
    isError: isFetchCustomerDetailError,
    isLoading: isSearchLoading,
  } = useOperatorCustomerList({
    query: searchQuery,
  });

  const [searchUser, setSearchedUser] = useState(false);
  const handleSearch = async () => {
    setSearchedUser(true);
    loginDispatch(updateSearchQuery(searchQuery));

    const { data } = await searchRefetch();
    if (data?.length == 1) {
      handleSelectCustomer(data[0].id);
      sessionStorage.setItem('noUserFound', String(false));
      setSearchedUser(false);
      loginDispatch(removeSearchQuery());
    }

    if (data?.length == 0) {
      setNoUserFound(true);
      Toast_Func({ status: true, message: 'No user found.' });
      sessionStorage.setItem('noUserFound', String(true));
      setSearchedUser(false);
    }
    if (data?.length > 0) {
      setSearchedUser(false);
    }
    setPhoneNumberState(false);
    setSearchedUser(false);
  };

  useEffect(() => {
    if (isFetchOperatorDetailSucccess) {
      handleOperatorDetailFetchingSuccess();
    }
    if (isFetchOperatorDetailError || isFetchCustomerDetailError)
      handleOperatorDetailFetchingError();
  }, [
    isFetchOperatorDetailSucccess,
    isFetchOperatorDetailError,
    isFetchCustomerDetailError,
  ]);

  useEffect(() => {
    if (data) {
      sessionStorage.setItem('noUserFound', String(false));
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify({ ...data, visitor_id: visitorId }),
      );
      dispatch(updateUserData(data));
    }
  }, [data]);
  const { user: authInfo } = useAppSelector((state) => state.user);

  const checkout = useAppSelector((state) => state.checkout);
  const addedCards = checkout?.giftCard;
  const { data: paymentRecords = [], isFetching: isFetchingPayments } =
    usePaymentMethods(authInfo.id, authInfo.type);

  const {
    data: order,
    isFetching: isFetchingEditOrder,
    refetch: refetchGetCustomerPlacedOrders,
  } = useOrderDetails({ orderId: Number(orderIdUrl) });
  const EditCart = async (order) => {
    updateItem(order, {
      onSuccess: async () => {
        const pickUpMethod = PickUpMethods[order?.pickup_details?.id];
        const orderType = [CONSTANT.IN_STORE].includes(pickUpMethod)
          ? CONSTANT.PICK_UP_ORDER_TYPE
          : CONSTANT.DELIVERY_ORDER_TYPE;
        dispatch(updateOrderType(orderType));
        if (orderType === CONSTANT.DELIVERY_ORDER_TYPE) {
          checkoutDispatch(
            updateOrderDetails({
              ...cart?.orderDetails,
              PickupSlotDetails: {
                date: order.promise_time.split(' ')[0],
                time: order.promise_time.split(' ')[1],
                pickupMethod: orderType,
              },
              delivery_address: {
                address_name:
                  order?.customer?.address_name || order?.customer?.city,
                street_name: order?.customer?.street_1,
                apartment_no: order?.customer?.street_2,
                city: order?.customer?.city,
                zipcode: order?.customer?.zipcode,
                state: order?.customer?.state,
              },
            }),
          );
        } else
          checkoutDispatch(
            updateOrderDetails({
              ...cart?.orderDetails,
              PickupSlotDetails: {
                date: order.promise_time.split(' ')[0],
                time: order.promise_time.split(' ')[1],
                pickupMethod: CONSTANT.PICK_UP_METHOD_IN_STORE,
              },
            }),
          );
        checkoutDispatch(
          setPrimaryCapacity({
            date: order.promise_time.split(' ')[0],
            guests: '1',
            selected_slot: {
              value: order.promise_time.split(' ')[1],
              label: convertTimeFormat12to24(order.promise_time.split(' ')[1]),
            },
          }),
        );
        loginDispatch(
          updateRecievingUserData({
            email: order?.customer?.email,
            first_name: order?.customer?.first_name,
            last_name: order?.customer?.last_name,
            phone: order?.customer?.phone,
            id: data?.id,
          }),
        );
        loginDispatch(
          updateBookingUserData({
            email: data?.email,
            first_name: data?.first_name,
            last_name: data?.last_name,
            phone: data?.phone,
            id: data?.id,
          }),
        );
        checkoutDispatch(updateIsOrderTypeSelected(true));
        setLocationAction(checkoutDispatch, order?.store_details);
        checkoutDispatch(updatePayInStoreToggle(order?.pay_in_store || false));
        checkoutDispatch(
          updateTextToPayToggle(
            order?.payment_details?.length <= 0 && order?.send_payment_request
              ? true
              : false,
          ),
        );
        checkoutDispatch(
          updateSendPaymentRequestEmail(
            order?.send_payment_request && data
              ? [data?.email || bookingUser?.email]
              : [],
          ),
        );
        checkoutDispatch(
          updateSendPaymentRequestPhone(
            order?.send_payment_request && data
              ? [data?.phone || bookingUser?.phone]
              : [],
          ),
        );

        checkoutDispatch(
          updateDeliveryTipObject({
            type: order.tip_type,
            tip: Number(Number(order.tip_amount)?.toFixed(2)) || '',
          }),
        );
        if (order?.payment_details.length > 1) {
          checkoutDispatch(updateIsSplitPayment(true));
        }
        order?.payment_details.map(async (payment) => {
          if (payment?.gift_card?.amount) {
            const giftCardReduxHelper = addedCards.length
              ? updateGiftCard
              : addGiftCard;
            const giftCardObj = {
              name: payment?.gift_card?.card_name,
              id: payment?.gift_card?.id,
              amount: payment?.gift_card?.amount,
              type: CONSTANT.GIFT_CARD,
              balance: payment?.gift_card?.balance,
            };
            const giftCardState = addedCards.length
              ? [giftCardObj]
              : giftCardObj;
            checkoutDispatch(giftCardReduxHelper(giftCardState));
            checkoutDispatch(updateIsSplitPayment(true));
          }
          if (payment?.card?.id) {
            let defaultPayment;

            const res = await paymentService.getCustomerPaymentMethods(
              order.customer_id,
            );
            const paymentRecords = res?.data?.data?.data || [];
            if (paymentRecords?.length) {
              defaultPayment = paymentRecords.find((paymentCard) => {
                return paymentCard?.id == payment?.card?.id;
              });
            }
            const paymentMethod = {
              id: defaultPayment?.id || payment?.card?.id,
              name: defaultPayment?.name || payment?.card?.card_name,
              number: `Card Ending in *${
                defaultPayment?.card.number || payment?.card?.last4
              }`,
              valid:
                defaultPayment?.card?.exp_month && defaultPayment?.card.exp_year
                  ? `Valid Until ${defaultPayment?.card.exp_month}/${defaultPayment?.card.exp_year}`
                  : '',
              amount: payment?.card?.amount,
            };
            checkoutDispatch(updatePaymentCard([paymentMethod]));
          }
          if (payment?.house_account?.id) {
            const res = await customerBusinessService.getHouseAccountsList(1);
            const houseAccountsList = res?.data?.data?.data || [];
            const usedHouseAccount = houseAccountsList?.find(
              (account) => account.id === payment?.house_account?.id,
            );
            if (usedHouseAccount) {
              dispatch(
                updateHouseAccount({
                  valueObject: {
                    value: payment?.house_account?.id,
                    label: payment?.house_account?.name,
                  },
                  houseAccountToggle: true,
                }),
              );
            }
          }
        });
        if (order?.is_tax_exempted) {
          const res = await customerBusinessService.getTaxExemptAccounts();
          const taxExamptList = res?.data?.data || [];
          const usedTaxExemptAccount = taxExamptList?.find(
            (account) => account.id === order?.tax_exempt_id,
          );
          if (usedTaxExemptAccount?.id) {
            dispatch(
              updateTaxExemptAccount({
                valueObject: {
                  value: usedTaxExemptAccount.id,
                  label: usedTaxExemptAccount.name,
                },
                taxExemptAccountToggle: true,
              }),
            );
          }
        }

        await syncCart(order.location_id, true);
        dispatch(editOrderWizardState());
        redirect(CONSTANT.STEP3);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const getNearbyLocations = async (payload: any) => {
    return await locationMenuService.getNearbyLocations(payload);
  };
  const operatorDetailFromRedux = useAppSelector(
    (state) => state.user.operator,
  );
  const OPManager =
    operatorDetailFromRedux?.role === 'operating partners' ||
    operatorDetailFromRedux?.role === 'manager';
  const handleSearchClick = async (searchValue = '') => {
    try {
      // setLocationNotFound(false);
      if (searchValue === '') return;
      // setNoneDisplay(false);
      // setLocationLoading(true);
      // setLocations([]);
      // setLatLongs([]);
      const search = searchValue;
      // setZipCode(search);
      // setZipCodeCopy(search);
      const array = [];
      const response = await getNearbyLocations({ address: search });
      // setLocationNotFound(false);
      // setLoading(false);
      const data = response.data.data;
      if (typeof response.data === 'undefined' || data.length == 0) {
        // setLocations([]);
        // setLocationLoading(false);
        // setNoneDisplay(true);
        dispatch(
          setLocation({
            currentLocation: '',
            zipCode: search || '',
          }),
        );
        return;
      }
      if (OPManager) {
        const operatorValidLocation = operatorDetailFromRedux.locations?.find(
          (location) => data.some((obj) => obj?.id === location?.id),
        );
        dispatch(
          setLocation({
            currentLocation: operatorValidLocation || '',
            zipCode: search || '',
          }),
        );
      } else {
        dispatch(
          setLocation({
            currentLocation: data[0],
            zipCode: search || '',
          }),
        );
      }
      // window.history.replaceState(null, '', `${ROUTE_STEP_1}`);
      // data.map(function (data) {
      //   array.push({
      //     latitude: data.lat,
      //     longitude: data.long,
      //     name: data.name,
      //     data: data,
      //     // order_type: order_type,
      //     // zip_code_param: zipParamsCode,
      //   });
      // });
      // setLatLongs(array);
    } catch (error) {
      // setLoading(false);
      // setLocationLoading(false);
      // setNoneDisplay(true);
    }
  };
  useEffect(() => {
    if (!isGuestUser(user)) {
      orderIdUrl && refetchGetCustomerPlacedOrders();
      order && isEditOrder && EditCart(order);
      order && isReOrder && reorder(order.id);
      phoneNumberState && handleSearch();
    }
  }, [
    orderIdUrl,
    order,
    user,
    isEditOrder,
    isReOrder,
    searchQuery,
    phoneNumberState,
  ]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const urll = new URL(currentUrl);
    const params = new URLSearchParams(urll.search);
    const storeName = params.get('store');
    const phoneNumber = params.get('phone');
    if (phoneNumber?.length || storeName?.length) {
      resetData();
      if (cart.is_edit_order) {
        handleExitEditOrder();
      }
    }
    if (phoneNumber?.length) {
      setPhoneNumberState(true);
      handleSearchQuery(phoneNumber);
    }
    if (storeName?.length) {
      let outputString = storeName?.replace(/-/g, ' ');
      handleSearchClick(outputString);
      dispatch(setFromURLTrue());
    }
    if (phoneNumber?.length || storeName?.length) {
      window.history.replaceState(null, '', `${ROUTE_STEP_1}`);
    }
    const startNewOrder = params.get('startNewOrder');
    const isDraftOrder = params.get('isDraftOrder');
    const draftOrderId = params.get('draftOrderId');
    const isEditOrder = params.get('isEditOrder');
    const isReOrder = params.get('isReOrder');
    if (
      (startNewOrder === 'true' ||
        isDraftOrder === 'true' ||
        isEditOrder === 'true' ||
        isReOrder === 'true') &&
      cart.is_edit_order
    ) {
      handleExitEditOrder();
    }
    if (cart.is_edit_order) return;
    const operatorCCToken = params.get('token');
    const userId = params.get('userId');
    const isNewOrder = params.get('isOrderListing');
    if (draftOrderId) {
      localStorage.setItem('draftOrderId', draftOrderId);
    }
    if (isDraftOrder) {
      sessionStorage.setItem('draftOrder', 'true');
    }
    const orderId = params.get('orderId');

    setOrderIdUrl(orderId);
    setIsEditOrder(isEditOrder === 'true' ? true : false);
    setIsReOrder(isReOrder === 'true' ? true : false);

    if (operatorCCToken) {
      const storedOperatorToken = getOperatorToken();
      if (storedOperatorToken !== operatorCCToken) {
        resetToBlank();
      }
      setOperatorToken(operatorCCToken);
    }
    if (userId) {
      const storedCustomerId = getOperatorCustomerId();
      if (storedCustomerId !== userId) {
        resetToBlank();
      }
      setOperatorCustomer(userId);
      if (!isDraftOrder || !isEditOrder)
        loginDispatch(removeRecievingUserData());
      wizardDispatch(resetSteps());
    }

    if (isNewOrder === 'true') {
      setOperatorCustomer(null);
      setNoUserFound(false);
      sessionStorage.setItem('noUserFound', String(false));
      resetToBlank();
    }

    // if (isNewOrder !== 'true') {
    //   // refetch();
    // }
    if (!phoneNumber?.length && !storeName?.length) {
      window.history.replaceState(null, '', `${ROUTE_STEP_1}`);
    }
  }, [isExitOrderLoading]);

  const recievingCustomerDetails = recievingUser?.first_name
    ? recievingUser
    : data;

  const handleSubmit = async (values) => {
    try {
      if (noUserFound) {
        const response = await signUpUser({
          is_catering: 1,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone.toString(),
        });

        // here we handle the success case
        Toast_Func({ status: true, message: 'Customer created' });
        const visitorId = getVisitorId();
        localStorage.setItem(
          CONSTANT.CURRENT_USER,
          JSON.stringify({ ...response, visitor_id: visitorId }),
        );
        sessionStorage.setItem('noUserFound', String(false));
        const receiverValues = {
          id: response?.id,
          first_name: formIkRef?.current
            ? formIkRef?.current?.values['first_name']
            : '',
          last_name: formIkRef?.current
            ? formIkRef?.current?.values['last_name']
            : '',
          email: formIkRef?.current ? formIkRef?.current?.values['email'] : '',
          phone: formIkRef?.current ? formIkRef?.current?.values['phone'] : '',
        };

        setOperatorCustomer(response?.id);
        loginDispatch(updateRecievingUserData(receiverValues));
        syncCartOnLogin(cartDispatch);
        loginDispatch(updateUserData(response));
        setNoUserFound(false);
        sessionStorage.setItem('noUserFound', String(false));
      } else {
        // on next click
        const visitorId = getVisitorId();
        localStorage.setItem(
          CONSTANT.CURRENT_USER,
          JSON.stringify({ ...data, visitor_id: visitorId }),
        );

        const receiverValues = {
          id: data?.id,
          first_name: formIkRef?.current
            ? formIkRef?.current?.values['first_name']
            : '',
          last_name: formIkRef?.current
            ? formIkRef?.current?.values['last_name']
            : '',
          email: formIkRef?.current ? formIkRef?.current?.values['email'] : '',
          phone: formIkRef?.current ? formIkRef?.current?.values['phone'] : '',
        };

        const bookingValues = {
          id: data?.id,
          first_name: receiveFormIkRef?.current
            ? receiveFormIkRef?.current?.values['first_name']
            : '',
          last_name: receiveFormIkRef?.current
            ? receiveFormIkRef?.current?.values['last_name']
            : '',
          email: receiveFormIkRef?.current
            ? receiveFormIkRef?.current?.values['email']
            : '',
          phone: receiveFormIkRef?.current
            ? receiveFormIkRef?.current?.values['phone']
            : '',
        };

        setOperatorCustomer(data?.id);
        loginDispatch(updateRecievingUserData(receiverValues));
        loginDispatch(updateBookingUserData(bookingValues));
        syncCartOnLogin(cartDispatch);
        loginDispatch(updateUserData(data));
        wizardDispatch(nextStep());
        history.push(ROUTE_CONSTANT.ROUTE_STEP_2);
      }
    } catch (error) {
      // Handle the error case
      console.error('Error submitting the form:', error);
      const {
        data: { message },
      } = (error as API_ERROR_MESSAGE).response;
      Toast_Func({ status: false, message });
    }
  };
  const { redirect } = useRedirectToSpecificStep();
  const draftOrderId = localStorage.getItem('draftOrderId');
  const { data: draftData } = useLoadDraft();

  useEffect(() => {
    if (draftOrderId && draftData && !noUserFound && data) {
      saveDratLoad({ redirect, visitorId, dispatch, data, draftData });
    }
  }, [data, draftOrderId, draftData, noUserFound]);
  const parentLoading =
    isFetching ||
    isFetchinngOperatorDetail ||
    isEditing ||
    reorderLoader ||
    userCreationLoading ||
    isFetchingEditOrder ||
    loading ||
    isExitOrderLoading;
  useEffect(() => {
    wizardDispatch(handleDisableStepperClick(parentLoading));
    return () => {
      dispatch(handleDisableStepperClick(false));
    };
  }, [parentLoading]);
  return parentLoading ? (
    <>
      <Loader modalSpinner={true} />
      <Footer
        validateRequiredSelection={validate}
        resetNoUserFound={setNoUserFound}
        nextButtonProps={{
          nextButtonDisabled: parentLoading,
        }}
      />
    </>
  ) : (
    <>
      <Container>
        {data ? (
          <Row className={`${styles.customerInfoWrapper}`}>
            <Col md="6" lg="6" className={`p-4 ${styles.bookingInfoCol}`}>
              <div className={styles.accDetailsWrap}>
                <p className={styles.heading}>Booking Customer Information</p>
              </div>
              <BookingCustomerInfo
                isReadonly
                firstName={bookingUser?.first_name || data?.first_name}
                lastName={bookingUser?.last_name || data?.last_name}
                email={bookingUser?.email || data?.email}
                phone={bookingUser?.phone || data?.phone}
                requiredPhone={
                  bookingUser?.phone?.toString()?.length > 0 ||
                  data?.phone?.toString()?.length > 0
                    ? true
                    : false
                }
                lessPhone={true}
                submitRef={receiveFormIkRef}
                handleSubmit={() => {
                  return;
                }}
              />
            </Col>
            <Col md="6" lg="6" className="p-4">
              <div className={styles.accDetailsWrap}>
                <p className={styles.heading}>Receiving Customer Information</p>
              </div>
              <BookingCustomerInfo
                firstName={recievingCustomerDetails?.first_name}
                lastName={recievingCustomerDetails?.last_name}
                email={recievingCustomerDetails?.email}
                phone={recievingCustomerDetails?.phone}
                requiredPhone={true}
                submitRef={formIkRef}
                isReadonly={false}
                handleSubmit={handleSubmit}
              />
            </Col>
          </Row>
        ) : (
          <SearchedCustomer
            handleSelectCustomer={handleSelectCustomer}
            searchQuery={searchQuery}
            handleSearchQuery={handleSearchQuery}
            isLoading={isSearchLoading}
            searchUser={searchUser}
            customersList={customersList}
            handleSearch={handleSearch}
          />
        )}
      </Container>
      {/* <Footer /> */}
      <Footer
        validateRequiredSelection={validate}
        resetNoUserFound={setNoUserFound}
        // nextButtonProps={{
        //   nextLoading:
        //     (handleContinueButton && isFetchingSlots) || isFindingNearest,
        //   nextButtonText: isFindingNearest
        //     ? 'Searching for nearest store...'
        //     : 'Next',
        // }
        // }
      />

      {noUserFound ? (
        <CustomModal
          showModal={true}
          size={'lg'}
          // showClose={false}
          onHide={false}
          //close the the modal and set the noUserFOund  to false in the localState and in the component state as well
          closeModal={handleCloseModal}
          title={
            <div className="">
              <p className={`${styles.heading} f-s16 font-plex mb-2`}>
                We Could Not Find Any Guest With This Info
              </p>
              <span className="f-s16 font-plex">
                New Guest? Sign Them Up Now!
              </span>
            </div>
          }
          titletextAlign={'text-center'}
          modalDialogClasses={`${styles.taxExemptionModal} ${styles.contactInfoModal}`}
        >
          <BookingCustomerInfo
            firstName={bookingUser?.first_name}
            lastName={bookingUser?.last_name}
            email={bookingUser?.email}
            phone={bookingUser?.phone}
            isReadonly={bookingUser?.email ? true : false}
            submitRef={noUserFound ? formIkRef : undefined}
            onSubmitLoading={userCreationLoading}
            isModalOpen={true}
            handleSubmit={handleSubmit}
            handldeClose={handleCloseModal}
          />
        </CustomModal>
      ) : null}
    </>
  );
};
const useValidateRequiredSelection = (formIkRef, receiveFormIkRef) => {
  // const requiredSelection = [LOCATION, PRIMARYCAPACITY];
  // const selectedLocation = useAppSelector((state) => state.user);
  // const validate = () => {
  //   for (let index = 0; index < requiredSelection.length; index++) {
  //     const currentRequiredSelection = requiredSelection[index];
  //     switch (currentRequiredSelection) {
  //       case LOCATION:
  //         if (!selectedLocation.selectedStore) {
  //           Toast_Func({
  //             status: false,
  //             message: 'Location not selected',
  //           });
  //         }
  //         break;
  //       case PRIMARYCAPACITY:
  //         if (formIkRef.current.initialValues.guests && !timeSlotValidation) {
  //           formIkRef.current.submitForm();
  //         } else {
  //           Toast_Func({
  //             status: false,
  //             message: 'Primary Capacity Not Selected',
  //           });
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };
  const validate = () => {
    if (receiveFormIkRef?.current) {
      receiveFormIkRef.current.submitForm();
    }
    if (
      Object.keys(receiveFormIkRef?.current?.errors || {})?.length <= 0 &&
      formIkRef?.current
    ) {
      formIkRef.current.submitForm();
    }
  };
  return { validate };
};
export default customerInfo;

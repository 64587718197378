import * as Constants from '../constants';

import { itemBuckets } from './buckets';
import {
  ADD_ONS,
  COMPLIMENTARY_MODIFIERS,
  CORE_MODIFIERS,
  CORE_RELATED,
  REQUIRED_MODIFIERS,
} from './constants';
import {
  isModifierGroupExistInMemoryChip,
  modifiersAgainstAnItem,
} from './helper';
import {
  ICalculatePriceForDifferentModifiers,
  ICurrentModifier,
} from './priceCalculation';

const calculatePriceForComplementary = ({
  currentModifier,
  item_id,
}: ICalculatePriceForDifferentModifiers) => {
  itemBuckets.updateItemBucket(currentModifier, item_id);
  const complementaryBucket = itemBuckets.getSingleBucket({
    name: COMPLIMENTARY_MODIFIERS,
    fromItem: item_id,
  });

  return {
    itemId: Constants.COMPLEMENTARY_ITEM,
    activeBucket: complementaryBucket,
    itemModifiers: modifiersAgainstAnItem({ itemId: item_id }),
  };
};

const calculatePriceForNestedItemComplementary = ({
  currentModifier,
  item_id,
}: ICalculatePriceForDifferentModifiers) => {
  itemBuckets.updateItemBucket(currentModifier, item_id);
  const complementaryBucket = itemBuckets.getSingleBucket({
    name: COMPLIMENTARY_MODIFIERS,
    fromItem: item_id,
  });

  return {
    itemId: item_id,
    activeBucket: complementaryBucket,
    itemModifiers: modifiersAgainstAnItem({ itemId: item_id }),
  };
};

const calculatePriceForRequired = ({
  currentModifier,
  item_id,
}: ICalculatePriceForDifferentModifiers) => {

  itemBuckets.updateItemBucket(currentModifier, item_id);
  const requiredModifierBucket = itemBuckets.getSingleBucket({
    name: REQUIRED_MODIFIERS,
    fromItem: item_id,
  });
  // ? Check if noDressing is true then make it false
  const noDressingExists = requiredModifierBucket?.noDressings?.find(
    (e) => e.id === currentModifier.modifier_group_id,
  );
  if (noDressingExists) {
    noDressingExists.noDressing = false;
  }

  return {
    itemId: item_id,
    activeBucket: requiredModifierBucket,
    itemModifiers: modifiersAgainstAnItem({ itemId: item_id }),
  };
};

const calculatePriceForAddonsAndCore = ({
  currentModifier,
  item_id,
}: ICalculatePriceForDifferentModifiers) => {
  const modifierIsCoreRelated = isModifierGroupExistInMemoryChip(
    currentModifier?.modifier_group_id,
    item_id - 1,
  );
  if (currentModifier.modifier_type === ADD_ONS && modifierIsCoreRelated) {
    currentModifier.treat_as = CORE_RELATED;
  }
  //? Keep updating bucket;
  itemBuckets.updateItemBucket(currentModifier, item_id);
  let coreModifierBucket = itemBuckets.getSingleBucket({
    name: CORE_MODIFIERS,
    fromItem: item_id,
  });
  if (!modifierIsCoreRelated) {
    coreModifierBucket = itemBuckets.getSingleBucket({
      name: ADD_ONS,
      fromItem: item_id,
    });
  }

  return {
    itemId: item_id,
    activeBucket: coreModifierBucket,
    itemModifiers: modifiersAgainstAnItem({ itemId: item_id }),
  };
};

/**
 * Calculates price for complimentary modifiers
 * @param currentModifier
 * @param item_id
 */
export const calculatePriceForItem = ({
  currentModifier,
  item_id,
}: ICalculatePriceForDifferentModifiers) => {
  if (currentModifier?.complimentary_modifier) {
    const bucket = itemBuckets.getBuckets();

    //? Keep updating bucket;
    if (bucket[bucket.length - 1].item === item_id) {
      return calculatePriceForComplementary({ currentModifier, item_id });
    } else {
      return calculatePriceForNestedItemComplementary({
        currentModifier,
        item_id,
      });
    }
  }
  if (currentModifier?.modifier_type === Constants.REQUIRED_MODIFIERS) {
    //? Keep updating bucket;
    return calculatePriceForRequired({ currentModifier, item_id });
  }
  if (
    currentModifier?.modifier_type === CORE_MODIFIERS ||
    currentModifier?.modifier_type === ADD_ONS
  ) {
    //? Change modifeir price, if it's core related
    return calculatePriceForAddonsAndCore({ currentModifier, item_id });
  }
};

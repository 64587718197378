import { useEffect } from 'react';
import { Col } from 'react-bootstrap';

import CounterCard from '../../components/Molecules/Card/CounterCard/CounterCard';
import SelectCard from '../../components/Molecules/Card/SelectCard/SelectCard';
import * as Constants from '../../constants';
import {
  isItSubstituteGroupFromMultipleRequired,
  verifyStatus,
} from '../../helper/customizedItem/customizedItem.helper';
import { selectedIngredient } from '../../helper/helperMethods';
import {
  IItemsRequiredModifierGroups,
  IRequiredModifiersGroupsModifiers,
} from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';
import { ICurrentModifier } from '../../priceCalculation/priceCalculation';

import NoDressing from './cards/NoDressing';
import OtherDressing from './cards/OtherDressing';
import styleClasses from './try2.module.scss';

interface IRequiredModifiers {
  requiredModifiers: IRequiredModifiersGroupsModifiers[];
  selectedIngredients: IRequiredModifiersGroupsModifiers[];
  extendable_limit: number;
  min: number;
  isLimitExceed: (extendable_limit: number) => boolean;
  handleClick: (data: any, in_item: boolean) => void;
  showIngredientsModalHandler: () => void;
  requiredModifierGroup: IItemsRequiredModifierGroups;
  noDressingHandler: (modifier: any, itemNo) => void;
  label: string;
  selectedSubstiueModifiers?: any;
  refScrollLeft?: any;
  showRedBorderAnimation: boolean;
  bucketkey: number;
}

const Dreesing: React.FC<IRequiredModifiers> = (props) => {
  const {
    requiredModifiers,
    selectedIngredients,
    extendable_limit,
    min,
    isLimitExceed,
    handleClick,
    showIngredientsModalHandler,
    requiredModifierGroup,
    noDressingHandler,
    label,
    selectedSubstiueModifiers,
    refScrollLeft,
    showRedBorderAnimation,
    bucketkey,
  } = props;
  let isOtherDreesingSelected = false;
  const requiredModifiersBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: bucketkey,
    });
  })();

  const checkModifierExistence = (
    additionalAdded: ICurrentModifier[],
    additionalRemoved: ICurrentModifier[],
    modifier,
  ) => {
    let modifierExistence = additionalAdded?.find(
      (mod) => mod.modifier_id === modifier.id,
    );
    if (!modifierExistence) {
      modifierExistence = additionalRemoved?.find(
        (mod) => mod.modifier_id === modifier.id,
      );
    }
    if (modifierExistence) {
      return true;
    } else {
      return false;
    }
  };

  const showModifier = (
    modifier: IRequiredModifiersGroupsModifiers,
    selected: any,
  ) => {
    const additionalAdded = requiredModifiersBucket?.additionalAdded?.modifiers;
    const additionalRemoved =
      requiredModifiersBucket?.additionalRemoved?.modifiers;
    const modifierExistence = checkModifierExistence(
      additionalAdded,
      additionalRemoved,
      modifier,
    );
    if (!isShowOtherDreesingCard()) {
      if (
        modifier?.in_item ||
        selected?.in_slide ||
        modifierExistence ||
        selectedSubstiueModifiers.current?.savedSelected.includes(modifier.id)
      ) {
        return true;
      } else {
        return true;
      }
    } else {
      if (
        modifier?.in_item ||
        selected?.in_slide ||
        modifierExistence ||
        selectedSubstiueModifiers.current?.savedSelected.includes(modifier.id)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isShowOtherDreesingCard = () => {
    let isTrue,
      isFalse = false;
    if (requiredModifiers) {
      isTrue = requiredModifiers.every((modifier) => modifier?.in_item);
      isFalse = requiredModifiers.every((modifier) => !modifier?.in_item);
    }
    if (isTrue || isFalse) {
      return false;
    } else {
      return true;
    }
  };

  const selectedDreesings = (modifier) => {
    const selectedModifier = selectedIngredient(modifier, selectedIngredients);
    if (selectedModifier && !isOtherDreesingSelected && !modifier?.in_item) {
      isOtherDreesingSelected = true;
    }
    return selectedModifier;
  };

  const isShowNoDressing = () => {
    if (
      requiredModifiersBucket?.noDressings?.find(
        (modGroup) => modGroup.id === requiredModifierGroup.id,
      )
    ) {
      return true;
    }
    return false;
  };

  const noDressing = () => {
    const requiredModifiersBucket = itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: bucketkey,
    });

    let returnValue = false;
    if (requiredModifiersBucket?.noDressings) {
      for (let v = 0; v < requiredModifiersBucket.noDressings.length; v++) {
        if (
          requiredModifiersBucket.noDressings[v].noDressing &&
          returnValue === false
        ) {
          returnValue = true;
          break;
        }
      }
    }
    return returnValue;
  };

  useEffect(() => {
    noDressing();
  });

  const decideCard = (modifier: IRequiredModifiersGroupsModifiers) => {
    return (
      selectedIngredients?.length > 0 &&
      !isItSubstituteGroupFromMultipleRequired(requiredModifierGroup) &&
      modifier?.max > 1
    );
  };

  return (
    <div
      key={`${requiredModifierGroup?.id}`}
      id="scroll_on_dressing_in_item_detail_page"
      className={`${styleClasses.items_inner_wrap} mb-3 row items--inner-wrap ${styleClasses.dressing_items_scrollbar}`}
      ref={refScrollLeft}
    >
      {requiredModifiers?.map(
        (modifier: IRequiredModifiersGroupsModifiers, index: number) => {
          // index+1 === requiredModifiers.length && scrollToLeft()
          const selectedModifier = selectedDreesings(modifier);
          return (
            showModifier(modifier, selectedModifier) &&
            (decideCard(modifier) ? (
              <Col lg="3" md="4" sm="4" xs="4" className="px-2">
                <CounterCard
                  id={modifier?.id}
                  key={modifier?.id}
                  brink_id={modifier?.brink_modifier_id}
                  image={`${Constants.s3BaseUrl}${modifier?.image}`}
                  title={modifier.name}
                  price={modifier.price}
                  calories={modifier.calories}
                  max={modifier.max}
                  groupMin={min}
                  is_active={verifyStatus(
                    modifier,
                    Constants.MODIFIER_LOCATION,
                  )}
                  priceAlternateLabel={selectedModifier ? ' ' : ' '}
                  handleClick={(data) => {
                    handleClick(data, modifier?.in_item);
                  }}
                  count={selectedModifier?.quantity || 0}
                  from={Constants.TRY_2_COMBO}
                  additionalPrice={selectedModifier?.additionalPrice}
                  extendableLimit={isLimitExceed(extendable_limit)}
                  extendableLimitValue={extendable_limit}
                  showRedBorderAnimation={showRedBorderAnimation}
                />
              </Col>
            ) : (
              <Col lg="3" md="4" sm="4" xs="4" className="px-2">
                <SelectCard
                  id={modifier?.id}
                  key={modifier?.id}
                  brink_id={modifier?.brink_modifier_id}
                  image={`${Constants.s3BaseUrl}${modifier?.image}`}
                  title={modifier.name}
                  price={modifier.price}
                  calories={modifier.calories}
                  groupMin={min}
                  is_active={verifyStatus(
                    modifier,
                    Constants.MODIFIER_LOCATION,
                  )}
                  priceAlternateLabel={selectedModifier ? ' ' : ' '}
                  handleClick={(data) => {
                    handleClick(data, modifier?.in_item);
                  }}
                  selected={Boolean(selectedModifier)}
                  default_selected={modifier.is_selected}
                  modifierAdditionalPrice={selectedModifier?.additionalPrice}
                  extendableLimit={isLimitExceed(extendable_limit)}
                  extendableLimitValue={extendable_limit}
                  showRedBorderAnimation={showRedBorderAnimation}
                />
              </Col>
            ))
          );
        },
      )}
      {(!isShowOtherDreesingCard() && requiredModifierGroup?.min === 0) ||
        (isShowNoDressing() && (
          <Col
            lg="3"
            md="4"
            sm="4"
            xs="4"
            className={`${styleClasses.no_Dressing_Card} px-2`}
          >
            <NoDressing
              label={requiredModifierGroup?.label}
              groupId={requiredModifierGroup?.id}
              noDressingHandler={noDressingHandler}
              isNoDressing={noDressing()}
              itemNo={Constants.SINGLE_ITEM_SELECTED_SECTION.toString()}
              noDressingCardBorderRadius={
                styleClasses.no_Dressing_Card_Border_radius
              }
              showRedBorderAnimation={showRedBorderAnimation}
            />
          </Col>
        ))}
      {isShowOtherDreesingCard() && (
        <Col lg="3" md="4" sm="4" xs="4" className="px-2">
          <OtherDressing
            groupMin={requiredModifierGroup?.min}
            otherDressingHandler={showIngredientsModalHandler}
            selectCard={isOtherDreesingSelected}
            isNoDressing={noDressing()}
            label={label}
            showRedBorderAnimation={showRedBorderAnimation}
          />
        </Col>
      )}
    </div>
  );
};

export default Dreesing;

import { createSlice } from '@reduxjs/toolkit';

const initialStateValues = {
  primaryCapacity: {
    guests: '',
    date: '',
    time: '',
    utensils: false,
    primaryCapacityItem: null,
    formFilled: false,
    selected_slot: {
      label: '',
      value: '',
    },
  },
};

export const specificFlowStates = createSlice({
  name: 'specificFlowStateHandling',
  initialState: initialStateValues,
  reducers: {
    setPrimaryCapacity: (state, action) => {
      state.primaryCapacity = { ...action.payload, formFilled: true };
    },
    resetPrimaryCapacity: (state) => {
      state.primaryCapacity = initialStateValues.primaryCapacity;
    },
    setPrimaryCapacityDraft: (state, action) => {
      state.primaryCapacity = {
        ...state.primaryCapacity,
        guests: action.payload.guest,
        date: action.payload.date,
        primaryCapacityItem: action.payload.primaryCapacityItem,
        selected_slot: {
          label: action.payload.timeLabel,
          value: action.payload.timeValue,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPrimaryCapacity,
  resetPrimaryCapacity,
  setPrimaryCapacityDraft,
} = specificFlowStates.actions;

export const specificFlowStatesReducer = specificFlowStates.reducer;

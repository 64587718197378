import httpClientCC from 'src/axios/operatorAxios';
import { CATERING_OPERATOR_SERVICE } from '../constants';

import { SendaQouteInstanceHTTP } from './instance.service';

class OperatorService extends SendaQouteInstanceHTTP {
  baseURL = CATERING_OPERATOR_SERVICE;

  getOperatorCustomer = ({id,clear_edit_cart}): Promise<any> =>
  this.get(`operator-customer/${id}`,{clear_edit_cart});
  registerOperatorCustomer = (data: any): Promise<any> =>
    this.post(`operator-customer`, data);
  searchOperatorCustomers = (query: string): Promise<any> =>
    this.get(`customer-search?catering=1&operator_query=${query}`);
  getOperatorDetail = (): Promise<any> => this.get('users/me?operator&with_accessible_locations=1');
  saveAsDraft = (body: any): Promise<any> =>
    httpClientCC.post(`draft-orders`, body);
  loadDraft = (id: string): Promise<any> =>
    httpClientCC.get(`load-draft-order/${id}`);
}

export const operatorService = new OperatorService();

import { IOfferItem } from 'src/models/rewards.model';

import { SINGLE_ITEM } from '../../../constants';
import { cartItem } from '../../../models/cart.model';

import CartComboItemCard from './CartComboItemCard';
import CartItemCard from './CartItemCard';

interface ICartItem {
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: () => void,
  ) => void;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number,
  ) => void;
  selectedOfferItem: IOfferItem;
  subTotal: number;
  item: cartItem;
  iteratingIndex: number;
  isActive: boolean;
  loading: boolean;
}

const CartItem = ({
  selectedOfferItem,
  subTotal,
  item,
  iteratingIndex,
  isActive,
  loading,
  handleItemRemove,
  handleQuantityChange,
}: ICartItem) => {
  return (
    <>
      {item.item_type === SINGLE_ITEM ? (
        <CartItemCard
          selectedOfferItem={selectedOfferItem}
          subTotal={subTotal}
          item={item}
          iteratingIndex={iteratingIndex}
          handleItemRemove={handleItemRemove}
          handleQuantityChange={handleQuantityChange}
          isActive={isActive}
          loading={loading}
        />
      ) : (
        <CartComboItemCard
          selectedOfferItem={selectedOfferItem}
          subTotal={subTotal}
          item={item}
          iteratingIndex={iteratingIndex}
          handleItemRemove={handleItemRemove}
          handleQuantityChange={handleQuantityChange}
          isActive={isActive}
          loading={loading}
        />
      )}
    </>
  );
};

export default CartItem;

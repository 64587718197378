import React, { useEffect, useState } from 'react';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';

import { MAKE_A_SELECTION, SELECT_AN_ITEM } from '../../../constants';
import styleClasses from '../../../containers/Modifiers/try2.module.scss';
import { IComboSection } from '../../../models/item.model';

import AddAndRemoveModal from './AddAndRemoveModal';
import SectionDetailTry2Combo from './SectionDetailTry2Combo';

const ComboSection: React.FC<IComboSection> = (props) => {
  const {
    id,
    title,
    image,
    onChange,
    itemName,
    changeItemSize,
    selectedItem,
    onRemoveClickHandler,
    onEditClickHandler,
    checked,
    textStart,
    addedItem,
    selectedtitle,
    weight,
    comingFromItem,
    kidComboItemDefaultName,
    isItemSelected,
    isCustomizeable,
    handleChangeTab,
    isSingleItem,
  } = props;

  useEffect(() => {
    onChange(selectedItem, false);
  }, [selectedItem]);

  const isItMobile = useCheckMobileScreen();
  const defaultItemName = isItMobile ? SELECT_AN_ITEM : MAKE_A_SELECTION;

  const [AddAndRemoveModalState, setAddAndRemoveModalState] =
    useState<boolean>(false);
  const AddAndRemoveModalHandler = () => {
    setAddAndRemoveModalState(true);
  };
  const AndRemoveModalCloseHandler = () => {
    setAddAndRemoveModalState(false);
  };

  const buttonHandleClick = (e) => {
    // handleChangeTab(e);'

    isItemSelected ? AddAndRemoveModalHandler() : onChange(id);
  };

  return (
    <>
      <div className={`select_item_size ${styleClasses.boxLunchesWrapper}`}>
        {/* <h6 className={`customtext-style2 text-uppercase mb-2 mb-md-3 f-sm-s11 d-none d-md-block ${textStart}`}>
          {title} 
        </h6> */}
        <input
          type="radio"
          id={id}
          data-testid={id}
          name="radio-group-try2Combo"
          onClick={buttonHandleClick}
          checked={checked}
        />

        <SectionDetailTry2Combo
          currentItemName={itemName}
          defaultItemName={defaultItemName}
          image={image}
          id={id}
          changeItemSize={changeItemSize}
          title={title}
          isHalf={addedItem?.half_brink_item_id}
          selectedtitle={selectedtitle}
          weight={weight}
        />
      </div>

      <AddAndRemoveModal
        onRemoveClickHandler={onRemoveClickHandler}
        onEditClickHandler={onEditClickHandler}
        showAddAndRemoveModal={AddAndRemoveModalState}
        hideAddAndRemoveModal={AndRemoveModalCloseHandler}
        name={itemName}
        id={id}
        isCustomizeable={isCustomizeable}
      />
    </>
  );
};

export default ComboSection;

import { useMutation } from "react-query";
import { orderMicroService } from "src/services";

interface IPayload {
  itemId?:number;
  userId?:string

}
const removeFavorite = async (payload:IPayload) => {
  const response = await orderMicroService.removeFromFavorites(payload?.userId,payload?.itemId)
  return response.data.data;
};

export default function useRemoveFavorite() {
  return useMutation((payload:IPayload) => removeFavorite(payload));
}

import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PICK_UP_ORDER_TYPE } from '../../constants';
import { useAppSelector } from '../../redux/store/store';
import CustomModal from '../customModal/CustomModal';
import ItemDeliveryDateAndTime from './ItemDeliveryDateAndTime';
import { OrderType } from './ItemPickup/PickupMethod/OrderType';

/*
 * TODO: use context to get list of items added in cart and calculate the bill
 */
interface IItemPickupDeliveryModal {
  subTotal?: number;
  tax?: number;
  total?: number;
  handleChangeOrderType?: boolean;
  openDeliveryMethodModal?: any;
  openPickupMethodModal?: any;
  handleClosePickupMethodModal?: (boolean) => void;
  handleCloseDeliveryMethodModal?: (boolean) => void;
  openPickupMethodModalOnCheckout?: boolean;
  openDeliveryMethodModalOnCheckout?: boolean;
  editPickupType?: (boolean) => void;
  editDeliveryType?: (boolean) => void;
  isEditSlot?: boolean;
}

const ItemPickupDeliveryModal = (props: IItemPickupDeliveryModal) => {
  const {
    subTotal,
    tax,
    total,
    handleChangeOrderType,
    openDeliveryMethodModal,
    openPickupMethodModal,
    handleClosePickupMethodModal,
    handleCloseDeliveryMethodModal,
    openPickupMethodModalOnCheckout,
    openDeliveryMethodModalOnCheckout,
    editPickupType,
    editDeliveryType,
    isEditSlot,
  } = props;

  const [openPickupMethod, setOpenPickupMethodModal] = useState(false);
  const [openDeliveryMethod, setOpenDeliveryMethodModal] = useState(false);
  const savedOrderType = useAppSelector((state) => state.cart.orderType);

  useEffect(() => {
    if (openPickupMethodModal || openDeliveryMethodModal) {
      if (savedOrderType == PICK_UP_ORDER_TYPE) setOpenPickupMethodModal(true);
      else setOpenDeliveryMethodModal(true);
    }
  }, [openPickupMethodModal, openDeliveryMethodModal]);

  useEffect(() => {
    if (openPickupMethodModalOnCheckout) {
      setOpenPickupMethodModal(true);
    }
    if (openDeliveryMethodModalOnCheckout) {
      setOpenDeliveryMethodModal(true);
    }
  }, [openPickupMethodModalOnCheckout, openDeliveryMethodModalOnCheckout]);

  const closePickUpMethodModal = () => {
    setOpenPickupMethodModal(false);
    handleClosePickupMethodModal(true);
  };
  const closeDeliveryMethodModal = () => {
    setOpenDeliveryMethodModal(false);
    handleCloseDeliveryMethodModal(true);
  };
  const editPickupTypeMethod = () => {
    editPickupType(true);
  };
  const editDeliveryTypeMethod = () => {
    editDeliveryType(true);
  };

  return (
    <>
      <CustomModal
        showModal={openPickupMethod}
        title={'Select A Date & Time'}
        closeModal={closePickUpMethodModal}
        modalDialogClasses={'defaultModalwrapper'}
        showClose={true}
        scrollable={false}
        titletextAlign={'text-start'}
        crossBtnIcom={'light-blue-icon'}
      >
        <OrderType
          subTotal={subTotal}
          tax={tax}
          total={total}
          isEditSlot={isEditSlot}
          closePickUpMethodModal={closePickUpMethodModal}
          editPickupType={editPickupTypeMethod}
          handleChangeOrderType={handleChangeOrderType}
        />
      </CustomModal>
      {/* Delivery Modal */}
      <CustomModal
        showModal={openDeliveryMethod}
        title={'Select A Date & Time'}
        closeModal={closeDeliveryMethodModal}
        modalDialogClasses={'defaultModalwrapper'}
        showClose={true}
        scrollable={false}
        titletextAlign={'text-start'}
        crossBtnIcom={'light-blue-icon'}
      >
        <ItemDeliveryDateAndTime
            subTotal={subTotal}
            tax={tax}
            total={total}
            isEditSlot={isEditSlot}
            closeDeliveryMethodModal={closeDeliveryMethodModal}
            editDeliveryType={editDeliveryTypeMethod}
            handleChangeOrderType={handleChangeOrderType}
          />
      </CustomModal>
    </>
  );
};

export default ItemPickupDeliveryModal;
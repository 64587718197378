import React, { useEffect, useRef, useState } from 'react';
import { isItSubstituteGroupFromMultipleRequired } from 'src/helper/customizedItem/customizedItem.helper';

import * as Constants from '../../constants';
import {
  IBucketSelectedIngredients,
  IItemsRequiredModifierGroups,
  IModifiersObjects,
  INoDressingModifier,
  IRequiredModifiersGroupsModifiers,
} from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';
import { ADDITIONAL_ADDED } from '../../priceCalculation/constants';
import { modifiersAgainstAnItem } from '../../priceCalculation/helper';
import {
  removeTemporarilyAddedPriceForItem,
  updateSpecificItemSelectedModifiers,
} from '../../redux/slices/itemCustomizationSlice';
import { useAppDispatch } from '../../redux/store/store';

import Dressing from './Dressing';
import SingleItemOtherDreesing from './OtherDreesing';

interface IRequiredModifiers {
  requiredModifierGroup: IItemsRequiredModifierGroups;
  handleIngredientSelection: (data: any, id?: 1 | 2 | 3 | 4) => void;
  bucketkey: any;
  isBoxLunch: boolean;
  editCartItem: any;
  showRedBorderAnimation: boolean;
}

const RequiredModifiers: React.FC<IRequiredModifiers> = (props) => {
  const dispatch = useAppDispatch();
  const {
    requiredModifierGroup,
    handleIngredientSelection,
    bucketkey,
    isBoxLunch,
    editCartItem,
    showRedBorderAnimation,
  } = props;
  const [otherDressingData, setOtherDressingData] = useState<boolean>(false);
  const [selectedOtherDressingData, setSelectedOtherDressingData] = useState<
    IModifiersObjects[]
  >([]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [noDressing, setNoDressing] = useState<boolean>(false);

  const refScrollLeft = useRef(null);

  const selectedSubstiueModifiers = useRef({
    currentSelected: null,
    savedSelected: [],
    copyForTemporaryHandling: null,
  });

  useEffect(() => {
    if (
      isBoxLunch &&
      editCartItem &&
      isItSubstituteGroupFromMultipleRequired(requiredModifierGroup)
    ) {
      const additionalAdded = editCartItem.required_modifiers.filter(
        (modifier) => modifier.modifier_group_id === requiredModifierGroup.id,
      );
      additionalAdded?.length > 0 &&
        selectedSubstiueModifiers.current.savedSelected.push(
          additionalAdded[0].modifier_id,
        );
    }
  }, [editCartItem]);

  const selectedIngredients: any[] = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.REQUIRED_MODIFIERS,
      bucketkey,
    );
  })();

  const selectedAddedIngredients: IBucketSelectedIngredients[] = (function () {
    const fromItem: any = bucketkey - 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const isLimitExceed = (extendable_limit: number) => {
    if (extendable_limit === 1) {
      return false;
    }
    const isNoDressingExist = selectedAddedIngredients.find(
      (mod: any) => mod.modifier_name === Constants.NO_DRESSING_CAPITALIZE,
    );
    if (isNoDressingExist) {
      extendable_limit += 1;
    }
    if (selectedAddedIngredients.length >= extendable_limit) {
      return true;
    } else {
      return false;
    }
  };

  const handleClick = (data, in_item) => {
    handleIngredientSelection(
      {
        ...data,
        ...{
          modifier_group_id: requiredModifierGroup?.id,
          modifier_group_max: requiredModifierGroup?.max,
          modifier_group_base: requiredModifierGroup?.base,
          brink_modifier_group_id:
            requiredModifierGroup[0]?.brink_modifier_group_id,
          in_item,
          in_slide: true,
          modifier_type: Constants.REQUIRED_MODIFIERS,
        },
      },
      bucketkey,
    );
  };

  const updateSelectedModifiers = (modifier, id?: 1 | 2 | 3 | 4): void => {
    updateSelectedOtherDressingData(modifier);
    if (isItSubstituteGroupFromMultipleRequired(requiredModifierGroup)) {
      selectedSubstiueModifiers.current.currentSelected = modifier.modifier_id;
    }
    handleIngredientSelection(modifier, id);
  };

  const updateSelectedOtherDressingData = (modifier): void => {
    const index = selectedOtherDressingData?.findIndex(
      (selectedModifier) =>
        selectedModifier.modifier_id === modifier.modifier_id,
    );
    if (index !== -1) {
      selectedOtherDressingData[index] = modifier;
      setSelectedOtherDressingData([...selectedOtherDressingData]);
    } else {
      setSelectedOtherDressingData([...selectedOtherDressingData, modifier]);
    }
  };

  const showIngredientsModalHandler = (): void => {
    selectedSubstiueModifiers.current.copyForTemporaryHandling =
      selectedSubstiueModifiers.current.savedSelected;
    itemBuckets.createBucketCopy();
    setModalShow(true);
  };

  const hideCustomizationModalHandler = (): void => {
    setModalShow(false);
  };

  useEffect(() => {
    if (!modalShow) {
      const container = refScrollLeft.current;

      // Scroll the container all the way to the left
      if (container) {
        setTimeout(() => {
          container.scrollLeft = 0;
        }, 200);
      }
    }
  }, [modalShow]);

  const closeModal = (): void => {
    removeSelectedIngredients();
    selectedSubstiueModifiers.current = {
      currentSelected: null,
      savedSelected: selectedSubstiueModifiers.current.copyForTemporaryHandling,
      copyForTemporaryHandling: null,
    };
    hideCustomizationModalHandler();
  };

  const removeSelectedIngredients = () => {
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.discardBucketChanges(bucketkey.toString()),
        item: bucketkey.toString(),
      }),
    );
    dispatch(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: bucketkey,
        }),
        itemId: bucketkey.toString(),
      }),
    );
    setSelectedOtherDressingData([]);
  };

  const noDressingHandler = (modifier: INoDressingModifier, itemNo): void => {
    const requiredModifiersBucket = itemBuckets.getSingleBucket({
      name: Constants.REQUIRED_MODIFIERS,
      fromItem: bucketkey,
    });
    if (requiredModifiersBucket?.noDressings) {
      const noDressingState = requiredModifiersBucket?.noDressings.find(
        (e) =>
          e.id ===
          requiredModifierGroup.required_modifiers_groups_modifiers[0]?.id,
      );
      setNoDressing(noDressingState?.noDressing);
    } else {
      setNoDressing(false);
    }
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.resetChangesOnNoDreesing(itemNo, modifier),
        item: itemNo,
      }),
    );
    dispatch(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: bucketkey,
        }),
        itemId: bucketkey.toString(),
      }),
    );
  };

  const sorted = (
    required_modifiers_groups_modifiers: IRequiredModifiersGroupsModifiers[],
  ) => {
    // Remove quantity keyword
    for (let i = 0; i < required_modifiers_groups_modifiers.length; i++) {
      if (required_modifiers_groups_modifiers[i].quantity) {
        delete required_modifiers_groups_modifiers[i].quantity;
      }
    }
    // Add selected quantity to modifier
    for (let i = 0; i < required_modifiers_groups_modifiers.length; i++) {
      for (let j = 0; j < selectedIngredients.length; j++) {
        if (!required_modifiers_groups_modifiers[i].quantity) {
          if (
            required_modifiers_groups_modifiers[i].id ===
            selectedIngredients[j].modifier_id
          ) {
            required_modifiers_groups_modifiers[i].quantity =
              selectedIngredients[j].quantity;
          } else {
            required_modifiers_groups_modifiers[i].quantity = 0;
          }
        }
      }
    }
    required_modifiers_groups_modifiers.sort((a, b) => {
      return b.quantity - a.quantity;
    });
  };

  const addSelectedIngredients = (): void => {
    if (
      isItSubstituteGroupFromMultipleRequired(requiredModifierGroup) &&
      !selectedSubstiueModifiers.current?.savedSelected.includes(
        selectedSubstiueModifiers.current.currentSelected,
      )
    )
      selectedSubstiueModifiers.current.savedSelected.push(
        selectedSubstiueModifiers.current.currentSelected,
      );
    sorted(requiredModifierGroup.required_modifiers_groups_modifiers);
    hideCustomizationModalHandler();
    setSelectedOtherDressingData([]);
    // scrollToLeft();
  };

  const applyClickHandler = () => {
    itemBuckets.resetCopyComboBucket();
    sorted(requiredModifierGroup.required_modifiers_groups_modifiers);
    hideCustomizationModalHandler();
    setSelectedOtherDressingData([]);
  };
  return (
    <>
      <Dressing
        requiredModifiers={
          requiredModifierGroup?.required_modifiers_groups_modifiers
        }
        requiredModifierGroup={requiredModifierGroup}
        selectedIngredients={selectedIngredients}
        extendable_limit={requiredModifierGroup?.extendable_limit}
        min={requiredModifierGroup?.min}
        isLimitExceed={isLimitExceed}
        handleClick={handleClick}
        showIngredientsModalHandler={showIngredientsModalHandler}
        noDressingHandler={noDressingHandler}
        label={requiredModifierGroup?.label}
        selectedSubstiueModifiers={selectedSubstiueModifiers}
        refScrollLeft={refScrollLeft}
        showRedBorderAnimation={showRedBorderAnimation}
        bucketkey={bucketkey}
      />
      <SingleItemOtherDreesing
        modalShow={modalShow}
        closeModal={closeModal}
        otherDressingData={otherDressingData}
        requiredModifierGroup={requiredModifierGroup}
        noDressingHandler={noDressingHandler}
        removeSelectedIngredients={removeSelectedIngredients}
        selectedIngredients={selectedIngredients}
        selectedAddedIngredients={selectedAddedIngredients}
        updateSelectedModifiers={updateSelectedModifiers}
        noDressing={noDressing}
        selectedOtherDressingData={selectedOtherDressingData}
        addSelectedIngredients={addSelectedIngredients}
        label={requiredModifierGroup?.label}
        applyClickHandler={applyClickHandler}
        bucketkey={bucketkey}
      />
    </>
  );
};

export default RequiredModifiers;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const footerSlice = createSlice({
  name: 'footer',
  initialState: initialState,
  reducers: {
    nextClicked: (state: any) => {
      console.log('next clicked');
    },
    previousClicked: (state) => {
      console.log('previous clicked');
    },
  },
});

export const { nextClicked, previousClicked } = footerSlice.actions;

export const footerSliceReducer = footerSlice.reducer;
